import { clinicalRoutes } from '@hc/routes';
import { useProfile } from '@hc/store/clinical/profile';
// import { AiOutlineBug } from 'react-icons';
import {
  Button,
  CameraIcon,
  Drawer,
  ForwardArrowIcon,
  LogoutConfirmIcon,
  LogoutIcon,
  SeekIcon,
  TrashIcon,
  TrashWithBorder
} from '@hc/ui/atoms';
import { ClinicalLayout, FreeConsultationCard } from '@hc/ui/components';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import {
  Avatar,
  Badge,
  Box,
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { MdOutlineFeedback } from 'react-icons/md';
import { RiBugLine } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import { version } from '../../../package.json';
import { profileView_style } from './style';

export default function ProfileView(props) {
  const { className = '', ...rest } = props;
  const {
    get,
    profile,
    profilePictureUpsert,
    updateProfilePicture,
    deleteProfileImage,
    getCorporateUserData,
  } = useProfile(
    (state) => ({
      profile: state.profile,
      profilePictureUpsert: state.profilePictureUpsert,
      get: state.get,
      updateProfilePicture: state.updateProfilePicture,
      loading: state.loading,
      profileUploadeLoading: state.profileUploadeLoading,
      deleteProfileImage: state.deleteProfileImage,
      getCorporateUserData: state.getCorporateUserData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  // const { clearAppointmentStore } = useAppointment((state) => ({
  //   clearAppointmentStore: state.clearAppointmentStore,
  // }));
  const [trashDrawerOpen, setTrashDrawerOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState();
  const [tokenData, setTokenData] = useState({});
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const checkUpoadFile = (value) => {
    let valid = false;
    if (Math.round(value / 1024) < 1024) {
      valid = true;
    }
    return valid;
  };

  const handleProfilePicUpload = async (e) => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const data = parseJwt(authToken);

    const isValid = checkUpoadFile(e?.target?.files[0]?.size, e);
    if (isValid) {
      const res = await profilePictureUpsert(e, data?.user_id);
      if (res?.status?.code === 200) {
        // UPDATE PROFILE PICTURE
        updateProfilePicture(res?.data?.profile_pic);
        return toast.success(
          res?.status?.message ?? 'User Profile Picture Updated Successfully',
        );
      }
    } else {
      return toast.error('File Size is Too Large it must be less than 1MB');
    }
    return toast.error('Something went wrong please try again!');
  };
  // Profile Picture Deleted
  const removeProfilePic = async () => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const data = parseJwt(authToken);
    const response = await deleteProfileImage(data?.user_id);

    if (response?.data?.status?.code === 200) {
      setTrashDrawerOpen(false);
    } else {
      return toast.error('Something went wrong please try again!');
    }
  };

  const logout = () => {
    setDrawerOpen(false);
    toast.success('Signed Out Successfully');
    localStorage.clear();
    // navigate(clinicalRoutes.login);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleOnChangePage = () => {
    navigate(clinicalRoutes?.medicalConditions, {
      state: {
        isFromClinical: true,
      },
    });
  };

  const handleMedicalCondition = () => {
    profile?.medical_allergies?.length === 0
      ? handleOnChangePage()
      : navigate(clinicalRoutes?.viewMedicalConditions);
  };
  // Get  free consultation data from corporate
  const getCorporateUserDataFunc = async (userId) => {
    await getCorporateUserData(userId);
  };
  // GET PROFILE DATA
  useEffect(() => {
    localStorage.setItem(localStorageKeys.clinicalVersion, version);
    const tokenDatas = localStorage.getItem(localStorageKeys.authToken);
    const parseTokenData = parseJwt(tokenDatas);
    setTokenData(parseTokenData);
    get();
    getCorporateUserDataFunc(parseTokenData?.user_id);
  }, []);

  return (
    <ClinicalLayout appBarRequired={false} className={`${className}`}>
      {/* {(loading === true || loading === null) && <ScreenLoader />}
      {loading === false && ( */}
      <Box sx={profileView_style.rootSx} {...rest}>
        <Grid container display="flex" justifyContent="center">
          <Grid item mt={3} height={112}>
            <Link to={clinicalRoutes.editProfile}>
              <Typography variant="body1" style={profileView_style.linkSx}>
                Edit
              </Typography>
            </Link>
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical:
                  tokenData?.employee_membership_code?.length > 0
                    ? 'top'
                    : 'bottom',
                horizontal: 'right',
              }}
              badgeContent={
                <label htmlFor="uplodebtn">
                  <Box sx={profileView_style.cameraSx}>
                    {profile?.profile_pic ? (
                      <TrashIcon onClick={() => setTrashDrawerOpen(true)} />
                    ) : (
                      <CameraIcon rootStyle={{ color: 'primary.main' }} />
                    )}
                    {!profile?.profile_pic && (
                      <input
                        hidden
                        onChange={handleProfilePicUpload}
                        type="file"
                        id="uplodebtn"
                        name="img"
                        accept="image/*"
                      />
                    )}
                  </Box>
                </label>
              }
            >
              <Avatar
                sx={profileView_style.profileSx}
                alt="profile_pic"
                src={profile?.profile_pic}
              >
                {profile?.name?.slice(0, 1) ?? 'A'}
              </Avatar>
            </Badge>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            {tokenData?.employee_membership_code?.length > 0 && (
              <Chip
                label={tokenData?.employee_membership_code ?? ''}
                icon={
                  tokenData?.employee_membership_code.length > 0 &&
                  profile.organization_logo ? (
                    <img
                      src={profile.organization_logo}
                      alt="organization_logo"
                      width="20px"
                      height="20px"
                      style={{ borderRadius: '50%' }}
                    />
                  ) : (
                    ''
                  )
                }
                size="small"
                variant="outlined"
                sx={profileView_style.profileChip}
              />
            )}
          </Grid>
          <Grid item>
            <Typography variant="h5" sx={profileView_style.nameSx}>
              {profile?.name}
            </Typography>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', placeItems: 'center' }}>
              <Typography variant="body2" sx={profileView_style.subnameSx}>
                {profile?.gender}
              </Typography>
              <Typography variant="body2" sx={profileView_style.subnameSx}>
                &nbsp; / {profile?.age} Yrs
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={profileView_style.subnameSx}>
              {profile?.country_code} {profile?.mobile_no}
            </Typography>
          </Grid>
          <Grid item pb={1}>
            <Typography variant="body2" sx={profileView_style.subnameSx}>
              {profile?.email_id ?? ''}
            </Typography>
          </Grid>
        </Grid>

        {trashDrawerOpen && (
          <Drawer
            show={trashDrawerOpen}
            rootStyle={profileView_style.drawerRootSx}
            onCloseDrawer={() => setTrashDrawerOpen(false)}
            footer={
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  buttonStyle={profileView_style.buttonSx}
                  onClick={() => setTrashDrawerOpen(false)}
                >
                  Cancel
                </Button>

                <Button
                  onClick={removeProfilePic}
                  buttonStyle={profileView_style.callButtonSx}
                >
                  Remove
                </Button>
              </Box>
            }
          >
            <Box sx={profileView_style.logoutConfirmSx}>
              <Box>
                <TrashWithBorder />
                <Typography
                  variant="body2"
                  sx={{ color: '#0F0B11', fontWeight: 500, mt: 2 }}
                >
                  Remove profile picture?
                </Typography>
              </Box>
            </Box>
          </Drawer>
        )}
      </Box>
      <Box sx={profileView_style.paperSx}>
        <Box>
          {/* <ProfileVitalsCard /> */}
          <Stack
            flexDirection="row"
            sx={profileView_style.vitalSx}
            onClick={handleMedicalCondition}
          >
            <Typography sx={profileView_style.medicalConditionFontSX}>
              Medical Conditions
            </Typography>
            <Box>
              {profile?.medical_allergies?.length === 0 ? (
                <Typography sx={profileView_style.addFontSX}> +Add</Typography>
              ) : (
                <ForwardArrowIcon />
              )}
            </Box>
          </Stack>
          <Box sx={{ mt: 1 }}>
            {tokenData?.employee_membership_code?.length > 0 && (
              <FreeConsultationCard
                header="Free Consultations"
                date={profile.expiry_date}
                freecosultation={profile.remaining_free_consultion}
                totalconsultation={profile.total_free_consultation}
              />
            )}
          </Box>
          <Box sx={{ pt: 4 }}>
            <IconButton disableRipple sx={{ p: '0px' }}>
              <a
                // href="https://healthcircles-help.freshdesk.com/support/tickets/new"
                href="https://healthcircles.freshdesk.com/support/tickets/new"
                alt=""
                target="blank"
              >
                <Box sx={profileView_style.profilexSx}>
                  <Box sx={profileView_style.profileseekSx}>
                    <SeekIcon />
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.primary',
                        p: '0px 10px',
                        fontWeight: 500,
                      }}
                    >
                      Seek Help
                    </Typography>
                  </Box>
                </Box>
              </a>
            </IconButton>
            <Box
              onClick={() => {
                navigate(clinicalRoutes.feedback);
              }}
              sx={{ display: 'flex', alignItems: 'center', pt: 2.5 }}
            >
              <IconButton sx={profileView_style.logoutSx} disableRipple>
                <Box sx={profileView_style.profilexSx}>
                  <MdOutlineFeedback
                    style={{
                      color: '#0E1824',
                      height: '25px',
                    }}
                  />
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'common.black',
                    p: '0px 10px',
                    fontWeight: 500,
                  }}
                >
                  Feedback
                </Typography>
              </IconButton>
            </Box>
            <Box
              onClick={() => {
                navigate(clinicalRoutes.bugreports);
              }}
              sx={{ display: 'flex', alignItems: 'center', pt: 2 }}
            >
              <IconButton sx={profileView_style.logoutSx} disableRipple>
                <Box sx={profileView_style.profilexSx}>
                  <RiBugLine
                    style={{
                      color: '#0E1824',
                      height: '25px',
                    }}
                  />
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'common.black',
                    p: '0px 10px',
                    fontWeight: 500,
                  }}
                >
                  Report Bug
                </Typography>
              </IconButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                pt: 2.5,
                fontWeight: 500,
              }}
            >
              <Grid
                container
                display="flex"
                justifyContent="space-between"
                pb={2}
              >
                <Grid item>
                  <IconButton
                    sx={profileView_style.logoutSx}
                    disableRipple
                    onClick={handleDrawerOpen}
                  >
                    <Box sx={profileView_style.profilexSx}>
                      <LogoutIcon
                        style={{
                          ml: 0.5,
                          pt: 0.5,
                        }}
                      />
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'error.main',
                        p: '0px 10px',
                        fontWeight: 500,
                      }}
                    >
                      Logout
                    </Typography>
                  </IconButton>
                </Grid>
                <Grid item>
                  <span
                    style={{
                      color: '#888888',
                      fontSize: '10px',
                    }}
                  >{`Version: ${localStorage.getItem(
                    localStorageKeys.clinicalVersion,
                  )}`}</span>
                </Grid>
              </Grid>
              {/* Drawer */}
              {drawerOpen && (
                <Drawer
                  show={drawerOpen}
                  onCloseDrawer={() => setDrawerOpen(false)}
                  rootStyle={profileView_style.drawerRootSx}
                  footer={
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Button
                        onClick={() => setDrawerOpen(false)}
                        buttonStyle={profileView_style.buttonSx}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => logout()}
                        buttonStyle={profileView_style.callButtonSx}
                      >
                        Logout
                      </Button>
                    </Box>
                  }
                >
                  <Box sx={profileView_style.logoutConfirmSx}>
                    <Box>
                      <LogoutConfirmIcon />
                      <Typography
                        variant="body2"
                        sx={{ color: '#0F0B11', fontWeight: 500 }}
                      >
                        Are you sure you want to Logout?
                      </Typography>
                    </Box>
                  </Box>
                </Drawer>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* )} */}
    </ClinicalLayout>
  );
}
