/* eslint-disable react/prop-types */
import { HalfRoundIcon, PdfIcon } from '@atoms';
import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { MdVisibility } from 'react-icons/md';

import { prescriptionCard_style } from './style';

export function PrescriptionCard(props) {
  const {
    doctorName = '',
    patientName = '',
    self = '',
    visit_report_url = '',
    onClick = () => {},
    rootStyle = {},
    className = '',
    ...rest
  } = props;
  return (
    <Box className={`${className}`} {...rest}>
      <Box
        sx={{
          ...prescriptionCard_style.rootSx,
          ...rootStyle,
          cursor: 'pointer',
        }}
        onClick={() => onClick(visit_report_url)}
      >
        <Box sx={{ position: 'relative' }}>
          <HalfRoundIcon rootStyle={prescriptionCard_style.halfRoundSx} />
          <Box sx={{ p: '6px 15px', pb: '6px!important' }}>
            <Box sx={prescriptionCard_style.appointmentCardSx}>
              <Box sx={prescriptionCard_style.doctornameSx}>
                <PdfIcon />
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: 'text.primary',
                        fontWeight: '600',
                      }}
                    >
                      {patientName}&nbsp;
                      <span>{`(${self})`}</span>
                    </Typography>
                  </Box>
                  <Box sx={{ color: '#5C6266', fontSize: '12px' }}>
                    <span>Dr.</span> {doctorName}
                  </Box>
                </Box>
              </Box>
              <Box sx={prescriptionCard_style.cancelIconSx}>
                <IconButton sx={prescriptionCard_style.iconbtnSx}>
                  <MdVisibility />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
PrescriptionCard.propTypes = {
  doctorName: PropTypes.string,
  Professional: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.node,
  sx: PropTypes.object,
};
