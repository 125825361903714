import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { clinicalHomeUtilsCard_style } from './style';
import {
  ClinicalHomeArrowIcon,
  ClinicalHomePageStethoscopeIcon,
} from '../../../atoms/icons/iconSet2';

function ClinicalHomeUtilsCard(props) {
  const {
    className = '',
    rootStyle = {},
    bgColor = '#F3F0FF',
    iconBgColor = '#E5E0FF',
    leftIcon = <ClinicalHomeArrowIcon bgColor="#F3F0FF" fill="#5e47d5" />,
    rightIcon = <ClinicalHomePageStethoscopeIcon />,
    title = 'Book Appointment',
    onClick = () => {},
    ...rest
  } = props;
  const media = useMediaQuery('(max-width:374px)');

  return (
    <Box
      sx={{
        ...clinicalHomeUtilsCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box
        sx={{
          borderRadius: 1,
          bgcolor: bgColor,
          p: 1.5,
          cursor: 'pointer',
          height: media ? '100px' : '',
        }}
        onClick={onClick}
      >
        <Grid container>
          <Grid item xs={6}>
            <Box
              sx={{
                borderRadius: 0.5,
                bgcolor: iconBgColor,
                height: 30,
                width: 30,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box alignSelf="center">{rightIcon}</Box>
            </Box>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="end">
            <Box>{leftIcon}</Box>
          </Grid>
          <Grid item xs={12} display="flex" pt={1}>
            <Typography alignSelf="end" fontSize={12} fontWeight={600}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

ClinicalHomeUtilsCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  bgColor: PropTypes.string,
  iconBgColor: PropTypes.string,
  title: PropTypes.string,
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.any,
  onClick: PropTypes.func,
};

export { ClinicalHomeUtilsCard };
