import { clinicalRoutes } from '@hc/routes';
import { Button } from '@hc/ui/atoms/button';
import { Drawer } from '@hc/ui/atoms/drawer';
import { ActivityCard } from '@hc/ui/components/clinical/mindBodyFood/activityCard';
import { ProgressUpdation } from '@hc/ui/components/clinical/mindBodyFood/progressUpdation';
import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MBFDontsIcon,
  MBFNotesIcon,
  MBFReferenceIcon,
} from '../../../../atoms';
import { FoodActivityUpdate } from '../foodactivityupdate';
import { todaysActivity_Style } from './style';

export function TodaysActivity(props) {
  const {
    todaysActivity = [],
    className = '',
    updateStores,
    editData,
    updateProgressStore,
    progressValue,
    onUpdate,
    loading,
    isMywellNessPlan,
    ...rest
  } = props;

  // const [activity, setActivity] = useState({});
  const [selected, setSelected] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const clickActivityCard = (val) => {
    if (!isMywellNessPlan) {
      setSelected(val);
      setDrawerOpen(true);
      updateStores(val);
    }
  };

  const reference = [
    {
      value: 1,
      name: 'Notes',
      icon: <MBFNotesIcon />,
      style: 'notesSx',
    },
    {
      value: 2,
      name: "Dont's",
      icon: <MBFDontsIcon />,
      style: 'dontsSx',
    },
    {
      value: 3,
      name: 'References',
      icon: <MBFReferenceIcon />,
      style: 'refenceSx',
    },
  ];

  const handleExpertNotes = (val) => {
    navigate(clinicalRoutes.expertNotes, {
      state: {
        page: val,
        notes: selected,
      },
    });
  };

  return (
    <Box className={`${className}`} {...rest}>
      <Box>
        {todaysActivity?.map((val, i) => {
          return (
            <Box key={i} onClick={() => clickActivityCard(val)}>
              <ActivityCard
                selected={selected}
                image={val?.activity_image}
                name={val?.activity_name}
                icon={val?.icon}
                time={
                  val?.quantity > 0 ? `${val?.quantity} ${val?.unit_name}` : ''
                }
                timeShow={val?.is_measurable ? true : false}
                specificTime={val?.activity_time === 6 ? true : false}
                specificFromTime={val?.specific_time?.from ?? ''}
                specificToTime={val?.specific_time?.to ?? ''}
                mbfStatus={val?.category_name}
                progressValue={
                  val?.is_measurable
                    ? (
                        ((val?.user_updated_quantity ?? 0) /
                          (val?.quantity ?? 1)) *
                        100
                      ).toFixed()
                    : val?.user_updated_quantity ?? 0
                }
              />
            </Box>
          );
        })}
      </Box>

      {drawerOpen && (
        <Drawer
          childrenStyle={{ backgroundColor: '#EEEBFF' }}
          headerStyle={{ width: '100%' }}
          show={drawerOpen}
          onCloseDrawer={() => setDrawerOpen(false)}
          header={
            <Box>
              <Box sx={todaysActivity_Style.drawerHeaderSx}>
                <Typography sx={todaysActivity_Style.yogatextSx}>
                  {selected?.activity_name}
                </Typography>
              </Box>
              <Stack
                mt={1}
                p={1}
                flexDirection="row"
                justifyContent="space-around"
                sx={{ width: '100%' }}
              >
                {reference.map((val, i) => (
                  <Stack
                    alignItems="center"
                    key={i}
                    onClick={() => handleExpertNotes(val.value)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {val.icon}
                    <Typography sx={todaysActivity_Style[val.style]}>
                      {val.name}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Box>
          }
          footer={
            <Box>
              <Button
                buttonStyle={{
                  bgcolor: '#6552CC',
                  borderRadius: 2,
                  '&:hover': {
                    bgcolor: '#6552CC',
                  },
                }}
                loading={loading}
                onClick={() => {
                  onUpdate();
                  setDrawerOpen(false);
                }}
              >
                Save
              </Button>
            </Box>
          }
        >
          <Box>
            {selected?.is_measurable ? (
              <Box pb={1}>
                <Typography sx={todaysActivity_Style.indicateTextSx}>
                  Slide to indicate your progress
                </Typography>
                <Box sx={todaysActivity_Style.progressUpdationSx}>
                  <ProgressUpdation
                    selected={selected}
                    editData={editData}
                    updateProgressStore={updateProgressStore}
                    progressUpdate={progressValue}
                  />
                </Box>
              </Box>
            ) : (
              <Box pb={1.5}>
                <FoodActivityUpdate
                  value={selected?.user_updated_quantity}
                  onChange={updateProgressStore}
                />
              </Box>
            )}
          </Box>
        </Drawer>
      )}
    </Box>
  );
}
