import { FemaleIcon, MaleIcon, OthersIcon } from '@atoms/icons';
import { Avatar, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { customRadioButton_style } from './style';

export function CustomRadio(props) {
  const {
    className = '',
    onChange,
    value,
    isError = false,
    errorMessage = '',
    avataractiveStyle = {},
    avatarinactiveStyle = {},
    ...rest
  } = props;
  const [active, setActive] = useState(value);

  const options = [
    {
      name: 'Male',
      value: 'Male',
      icon: <MaleIcon />,
    },
    {
      name: 'Female',
      value: 'Female',
      icon: <FemaleIcon />,
    },
    {
      name: 'Others',
      value: 'Others',
      icon: <OthersIcon />,
    },
  ];

  useEffect(() => {
    if (value !== active) {
      onChange(active);
    }
  }, [active]);

  useEffect(() => {
    setActive(value);
  }, [value]);

  return (
    <Box>
      <Box
        display="flex"
        gap={3.2}
        sx={{ my: 0.5 }}
        className={`${className}`}
        {...rest}
      >
        {options?.map((val, i) => (
          <Box
          id={`gender_${i}`}
            key={i}
            sx={{
              display: 'grid',
              placeItems: 'center',
              cursor: 'pointer',
              gap: 0.5,
              ...(val.value === active
                ? customRadioButton_style.activeSx
                : customRadioButton_style.radioSx),
            }}
            onClick={() => {
              setActive(val.value);
            }}
          >
            <Avatar
              id={`gender_${i}`}
              sx={
                val.value === active
                  ? {
                      ...customRadioButton_style.avataractiveSx,
                      ...avataractiveStyle,
                    }
                  : {
                      ...customRadioButton_style.avatarinactiveSx,
                      ...avatarinactiveStyle,
                    }
              }
            >
              {val?.icon}
            </Avatar>
            {val?.name && (
              <Typography
                sx={{
                  fontSize: '12px',
                  ...(val.value === active
                    ? customRadioButton_style.nameActiveSx
                    : customRadioButton_style.nameInactiveSx),
                }}
              >
                {val.name}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
      {isError && (
        <Typography sx={{ mt: 0.5 }} variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}

CustomRadio.propTypes = {
  avataractiveStyle: PropTypes.object,
  avatarinactiveStyle: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  maleIcon: PropTypes.node,
  femaleIcon: PropTypes.node,
  othersIcon: PropTypes.node,
  classes: PropTypes.object,
  sx: PropTypes.object,
};
