import EmptyStateBg from '@assets/emptyState.svg';
import { clinicalRoutes } from '@hc/routes';
import { useFamilyMember } from '@hc/store';
import { useBookAppointment } from '@hc/store/clinical/bookAppointment';
import { Button, EmptyStateIcon, Loader } from '@hc/ui/atoms';
import { ClinicalEmptyState, FamilyMemberSelect } from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { myFamilyScreen_style } from './style';

export default function SelectFamilyMember(props) {
  const { ...rest } = props;

  // Gernal Hooks
  const navigate = useNavigate();
  const location = useLocation();

  const { familyMembers, getAll, get, loading } = useFamilyMember(
    (state) => ({
      familyMembers: state.familyMembers,
      getAll: state.getAll,
      get: state.get,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const {
    bookAppointmentState,
    setFamilyMmeberDetails,
    // loading: loader,
  } = useBookAppointment(
    (state) => ({
      bookAppointmentState: state.bookAppointmentState,
      setFamilyMmeberDetails: state.setFamilyMmeberDetails,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const [selectFamilyMember, setSelectFamilyMember] = useState();
  // GET FAMILY MEMBER DATA
  const getInitialData = async () => {
    await getAll();
    // SET FAMILY MEMBER IF FAMILY MEMBER ALREADY SELECTED
    if (location?.state?.prevRoute) {
      const familyMembersCopy = JSON.parse(JSON.stringify(familyMembers));
      const index__ = familyMembersCopy.findIndex(
        (x) => x.family_member_id === bookAppointmentState?.family_member_id
      );
      setSelectFamilyMember(index__);
    }
  };

  // SELECT FAMILY MEMBER FUNCTION
  const selectFamilyMemberFnc = async () => {
    if (selectFamilyMember !== null && selectFamilyMember !== undefined) {
      const family_member_id_ = familyMembers.filter(
        (val, i) => i === selectFamilyMember
      );
      if (
        family_member_id_?.length > 0 &&
        family_member_id_?.[0]?.family_member_id
      ) {
        const name = await get(family_member_id_?.[0]?.family_member_id);
        await setFamilyMmeberDetails(name);

        location?.state?.prevRoute
          ? navigate(location?.state?.prevRoute)
          : navigate(clinicalRoutes.healthIssues);
      } else {
        return toast.error('Something went wrong please try again!');
      }
    } else {
      return toast.error('Please select family member!');
    }
  };

  const handleSelected = (index) => {
    setSelectFamilyMember(index);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const addFamily = () => {
    navigate('/familymembers');
  };

  return (
    <Screenlayout
      title="Family Member Selection"
      backRequired
      backRoute={
        location?.state?.prevRoute
          ? location?.state?.prevRoute
          : '/appointmentbooking/selectpatient'
      }
      childrenStyle={{
        minHeight: '60vh',
        pt: {
          xs: 10,
        },
      }}
      footer={
        <Box sx={{ gap: 2 }}>
          {familyMembers !== null && familyMembers?.length > 0 ? (
            <Button
              buttonStyle={myFamilyScreen_style.addBtnSx}
              onClick={selectFamilyMemberFnc}
            >
              Select Family Member
            </Button>
          ) : (
            ''
          )}
        </Box>
      }
      footerStyle={{ border: 'none', boxShadow: 'none' }}
      {...rest}
    >
      <Box>
        <Box sx={myFamilyScreen_style.boxRootSx}>
          {/* Family Member Card */}
          {familyMembers !== null && familyMembers?.length === 0 && !loading ? (
            <>
              <ClinicalEmptyState
                IconRequired={<EmptyStateIcon rootStyle={EmptyStateBg.src} />}
                emptyStateStyle={{ mt: 5.25 }}
                mainHead="You didn't add your Family Member Yet"
                subHead="Add Your family Member"
              />
              <Button
                sx={{
                  mt: 3,
                  width: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '24px auto',
                }}
                onClick={addFamily}
              >
                Go to My Family
              </Button>
            </>
          ) : null}
          {!loading && familyMembers !== null && familyMembers?.length > 0 && (
            <>
              <Typography
                variant="body1"
                sx={{ color: ' #5C6266', fontWeight: 500 }}
              >
                {`${familyMembers?.length} Family Members`}
              </Typography>
              <FamilyMemberSelect
                selectFamilyMember={selectFamilyMember}
                setSelectFamilyMember={handleSelected}
              />
            </>
          )}
        </Box>
      </Box>
      {loading && (
        <Loader
          rootStyle={{ display: 'flex', justifyContent: 'center', py: 30 }}
        />
      )}
    </Screenlayout>
  );
}
