/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { clinicalRoutes } from '@hc/routes';
import {
  useBookAppointment,
  useFamilyMember,
  useRelationShip,
  useReport,
  useReportType
} from '@hc/store';
import { Button, DocumentIcon, ImageIcon, UploadIcon } from '@hc/ui/atoms';
import { UploadDetails, UploadFiles } from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { localStorageKeys } from '@hc/utils/constants';
import {
  encodeImageFileAsURL,
  formatBytes,
  handleUploadFile,
  parseJwt,
  reportNameGenerator
} from '@hc/utils/helperFunctions';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { report_upload_style } from './style';

export default function ReportsUpload(props) {
  const { className = '', rootStyle = {}, ...rest } = props;
  const [active, setActive] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);
  const [buttonName, setButtonName] = useState('Upload');
  const [isLoading, setIsLoading] = useState(false);
  const [uploadButton, setUploadButton] = useState(false);
  // const [upload, setUpload] = useState(false);

  const radioList = [
    { value: 'self', name: 'Self' },
    { value: 'familyMember', name: 'FamilyMember' },
  ];

  const options = [
    {
      name: 'Image(PNG,JPG)',
      value: 'image',
      icon: <ImageIcon />,
    },
    {
      name: 'File(only PDF)',
      value: 'pdf',
      icon: <DocumentIcon />,
    },
  ];

  const { bookAppointmentState, deleteData, reportUpload, clearPageData } =
    useBookAppointment(
      (state) => ({
        bookAppointmentState: state?.bookAppointmentState,
        deleteData: state.deleteData,
        reportUpload: state.reportUpload,
        clearPageData: state.clearPageData,
      }),
      (prev, curr) => isEqual(prev, curr),
    );

  // General hooks
  const {
    reportState,
    uploadStoreFile,
    updateReportStore,
    updateReportStoreState,
    clearReportState,
    deleteFileData,
    updateReportError,
    upsertReport,
    handleToast,
    loading,
  } = useReport(
    (state) => ({
      reportState: state.reportState,
      uploadStoreFile: state.uploadStoreFile,
      updateReportStore: state.updateReportStore,
      clearReportState: state.clearReportState,
      updateReportStoreState: state.updateReportStoreState,
      deleteFileData: state.deleteFileData,
      updateReportError: state.updateReportError,
      upsertReport: state.upsertReport,
      handleToast: state.handleToast,
      loading: state?.loading,
    }),
    (prev, curr) => {
      const data = isEqual(prev, curr);
      return false;
    },
  );

  const { get, relationShipData } = useRelationShip(
    (state) => ({
      get: state?.get,
      relationShipData: state.relationShipData,
      loading: state?.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { getReportType, reportTypeData } = useReportType(
    (state) => ({
      getReportType: state?.getReportType,
      reportTypeData: state.reportTypeData,
      loading: state?.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { familyMembers, getAll } = useFamilyMember(
    (state) => ({
      familyMembers: state.familyMembers,
      getAll: state.getAll,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const navigate = useNavigate();
  const location = useLocation();

  const isIamValidToSave = () => {
    // const reportStateCopy = JSON.parse(JSON.stringify(reportState));
    const reportStateCopy = reportState;

    let isValid = true;
    const error = reportStateCopy?.error;

    // Checking formOthersRelationship
    if (reportState?.formReportFor === 'familyMember') {
      if (!reportState?.formOthersRelationship) {
        isValid = false;
        error.formOthersRelationship = 'Please select relationship';
      } else {
        error.formOthersRelationship = '';
      }
    } else {
      error.formOthersRelationship = '';
    }

    // Checking formOthersRelationship
    if (!reportState?.formReportType) {
      isValid = false;
      error.formReportType = 'Please select report type';
    } else {
      error.formReportType = '';
    }

    // UPDATE REPORT ERROR
    updateReportError(error);
    return isValid;
  };

  const checkUpoadFile = (type) => {
    if (Array.isArray(reportState?.[type]) && reportState?.[type]?.length > 0) {
      const Arr = [];
      reportState?.[type]?.map(async (val) => {
        if (val?.sizeNumber / (1024 * 1024) > 1) {
          console.log(val?.sizeNumber / (1024 * 1024) > 1);
          Arr.push(val?.sizeNumber);
        }
      });

      return !(Arr?.length > 0);
    }
  };

  const uploadData = async () => {
    if (
      reportState?.imageFiles_.length > 0 ||
      reportState?.pdfFiles_.length > 0
    ) {
      let resArr = [];
      for (const data of reportState?.imageFiles_) {
        if (data.status === 'ok') {
          resArr.push(data);
        }
      }
      await updateReportStore('imageFiles_', resArr);
      await upsertReport(data?.user_id, !reportState?.formReportId);
      if (!loading) {
        setIsLoading(false);
      }
      if (location.state.isBookAppoinment) {
        navigate(clinicalRoutes.uploadReports);
      } else {
        navigate(clinicalRoutes.reports);
      }
      // CLEAR Report STATE
      clearReportState(location.state.isBookAppoinment);
    }
  };
  const OnSave = async () => {
    if (reportState?.formReportFor?.length > 0) {
      const result = isIamValidToSave();
      if (result) {
        if (buttonName === 'Upload' || buttonName === 'Update') {
          if (reportState?.selectTypeOfFile === 'image') {
            if (checkUpoadFile('imageFiles')) {
              let array = [];
              setIsLoading(true);
              reportState?.imageFiles?.map(async (val) => {
                if (!val?.sizeNumber) {
                  return false;
                }

                array.push(handleUploadFile(val?.fileFormData));
              });
              Promise.all(array).then(async (results) => {
                results.map((result) => {
                  uploadStoreFile('imageFiles_', result[0]);
                });
                uploadData();
              });
              setUploadButton(true);
              setButtonName(reportState?.formReportId ? 'Update' : 'Save');

              return true;
            }
            return toast.error(
              'File Size is Too Large it must be less than 1MB',
            );
          }

          if (reportState?.selectTypeOfFile === 'pdf') {
            if (checkUpoadFile('pdfFiles')) {
              let array = [];
              // setIsLoading(true);
              reportState?.pdfFiles?.map(async (val) => {
                array.push(
                  handleUploadFile(
                    val?.fileFormData,
                    reportState?.formReportId,
                  ),
                );
              });
              Promise.all(array).then(async (results) => {
                results.map((result) => {
                  uploadStoreFile('pdfFiles_', result[0]);
                });

                uploadData();
              });
              setUploadButton(true);
              setButtonName(reportState?.formReportId ? 'Update' : 'Save');
              return true;
            }
            return toast.error(
              'File Size is Too Large it must be less than 1MB',
            );
          }
        }
      }
    } else {
      return toast.error('Please select report for!');
    }
  };

  // SKIP FUNCTION
  const onSkip = () => {
    navigate(-1);
    clearReportState(location.state.isBookAppoinment);
  };

  // DELETE REPORTS DATA
  const handleDelete_ = async (i, upload) => {
    updateReportStore('uploadId', null);
    if (upload) {
      if (reportState?.selectTypeOfFile === 'pdf') {
        await deleteFileData('pdfFiles_', i);
        await deleteFileData('pdfFiles', i);
        setButtonName('Upload');
        setActive(false);
      } else if (reportState?.selectTypeOfFile === 'image') {
        if (reportState?.imageFiles?.length === 1) {
          setButtonName('Upload');
          setActive(false);
        }
        await deleteFileData('imageFiles_', i);
        await deleteFileData('imageFiles', i);
      }
    } else if (reportState?.selectTypeOfFile === 'pdf') {
      await deleteFileData('pdfFiles', i);
      setActive(false);
    } else if (reportState?.selectTypeOfFile === 'image') {
      if (reportState?.imageFiles?.length === 1) {
        setActive(false);
      }
      await deleteFileData('imageFiles', i);
    }
  };

  const handleFileUpload = async (e) => {
    let fileList = e?.target?.files;
    if (e?.target?.files) {
      await updateReportStore('uploadId', null);
      let url = '';
      let arrayData = [];

      if (reportState?.selectTypeOfFile === 'image') {
        // setIsLoading(true);
        for (const file of fileList) {
          const fileFormData = file;
          url = await encodeImageFileAsURL(file);
          const size = formatBytes(file?.size ?? 0, 0);
          const filename = file?.name;
          const type = file?.type;
          const sizeNumber = file?.size ?? 0;
          arrayData.push({
            fileFormData: { 0: fileFormData, length: 1 },
            url: [url],
            size,
            filename,
            type,
            sizeNumber,
          });
        }
      } else if (reportState?.selectTypeOfFile === 'pdf') {
        // setIsLoading(true);
        const getData = await handleUploadFile(e.target.files);
        const data = getData.map((val) => val.downloadUrl);
        url = data;
      }
      const size_ = formatBytes(e?.target?.files?.[0]?.size ?? 0, 0);
      const arr = reportState?.imageFiles?.filter(
        (val) => val.filename === e?.target?.files?.[0]?.name,
      );

      if (arr?.length > 0) {
        toast.error(`Please Don't upload same image`);
      } else {
        if (reportState?.selectTypeOfFile === 'pdf') {
          const obj = {
            fileFormData: e.target.files,
            downloadUrl: url,
            filename: e?.target?.files?.[0]?.name ?? '',
            type: e?.target?.files?.[0]?.type ?? '',
            size: size_,
            sizeNumber: e?.target?.files?.[0]?.size ?? 0,
          };
          await uploadStoreFile(
            reportState?.selectTypeOfFile === 'image'
              ? 'imageFiles'
              : 'pdfFiles',
            obj,
          );
        } else if (reportState?.selectTypeOfFile === 'image') {
          for (const data of arrayData) {
            const obj = {
              fileFormData: data?.fileFormData,
              downloadUrl: data?.url,
              filename: data?.filename,
              type: data?.type,
              size: data?.size,
              sizeNumber: data?.sizeNumber,
            };
            await uploadStoreFile(
              reportState?.selectTypeOfFile === 'image'
                ? 'imageFiles'
                : 'pdfFiles',
              obj,
            );
          }
        }
        // STATE UPDATE FOR REPORTS
        if (reportState?.selectTypeOfFile === 'image') {
          if (
            Array.isArray(reportState?.imageFiles) &&
            reportState?.imageFiles?.length === 1
          ) {
            await updateReportStore(
              'formReportName',
              reportNameGenerator('image'),
            );
            await updateReportStore('formReportType', 3);
          }
        } else if (reportState?.selectTypeOfFile === 'pdf') {
          if (
            Array.isArray(reportState?.pdfFiles) &&
            reportState?.pdfFiles?.length === 1
          ) {
            await updateReportStore(
              'formReportName',
              reportNameGenerator('pdf'),
            );
            await updateReportStore('formReportType', 3);
          }
        }
        setActive(true);
      }
    }
  };

  const selectTypeOfFileFunction = (value) => {
    if (reportState?.selectTypeOfFile === value) {
      updateReportStore('selectTypeOfFile', '');
    } else {
      updateReportStore('selectTypeOfFile', value);
    }
  };

  const updateRadioButton = (value) => {
    updateReportStore('formReportFor', value);
  };

  const handleChange = (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    updateReportStore(key, value);
  };

  const getInitialData = async () => {
    await get();
    await getReportType();
  };

  // GET RELATIONSHIP DATA
  useEffect(() => {
    getInitialData();
    getAll();
    if (location?.state?.status === 'edit') {
      setIsEdit(true);
      setActive(true);
      setButtonName(reportState?.formReportId ? 'Update' : 'Save');
    } else {
      updateReportStore('formReportFor', 'self');
      updateReportStore('formReportIssueDate', new Date());
    }
  }, []);

  return (
    <Box
      sx={{ ...report_upload_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Screenlayout
        title="Upload Reports"
        backRequired="/"
        backRoute={onSkip}
        stateValue="bookAppoinment:false"
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button buttonStyle={report_upload_style.buttonSx} onClick={onSkip}>
              {active ? 'Back' : 'Cancel'}
            </Button>

            <Button
              buttonStyle={{ borderRadius: '8px', textTransform: 'none' }}
              onClick={OnSave}
              loading={isLoading}
              disabled={
                reportState?.selectTypeOfFile === 'image' &&
                reportState?.imageFiles?.length === 0
                  ? true
                  : reportState?.selectTypeOfFile === 'pdf' &&
                    reportState?.pdfFiles?.length === 0
                  ? true
                  : reportState?.selectTypeOfFile?.length === 0
              }
            >
              Upload
            </Button>
          </Box>
        }
      >
        <Box px={2.5}>
          <UploadFiles
            reportTitle="Upload reports (If any)"
            browserText="Click to browse and upload"
            icon={<UploadIcon />}
            uploadsText="Uploaded Reports"
            uploadFilesData={
              reportState?.selectTypeOfFile === 'image'
                ? Array.isArray(reportState?.imageFiles)
                  ? reportState?.imageFiles
                  : []
                : reportState?.selectTypeOfFile === 'pdf'
                ? Array.isArray(reportState?.pdfFiles)
                  ? reportState?.pdfFiles
                  : []
                : []
            }
            uploadFileStyle={report_upload_style.uploadFileSizeSx}
            uploadIconStyle={report_upload_style.uploadIconCenterSx}
            handleFileUpload={handleFileUpload}
            handleDelete={handleDelete_}
            progressBar={false}
            IsUploadOnesDone={active}
            options={options}
            IsReportUpload
            isEdit={isEdit}
            isLoading={isLoading}
            type={reportState?.selectTypeOfFile}
            selectTypeOfFileFns={selectTypeOfFileFunction}
          />
          {active && (
            <Box mt={3}>
              <UploadDetails
                storeData={reportState}
                handleChange={handleChange}
                updateRadioButton={updateRadioButton}
                radioList={radioList}
                relationship={familyMembers}
                handleToast={handleToast}
                relationshipData={
                  Array.isArray(relationShipData) ? relationShipData : []
                }
                reportTypeData={
                  Array.isArray(reportTypeData) ? reportTypeData : []
                }
                loading={isLoading}
              />
            </Box>
          )}
        </Box>
      </Screenlayout>
    </Box>
  );
}
