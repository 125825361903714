/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { getDateFormat } from '@hc/dayjs';
import { clinicalRoutes } from '@hc/routes';
import { useSpeciality } from '@hc/store';
import { useBookAppointment } from '@hc/store/clinical/bookAppointment';
import { useLanguage } from '@hc/store/clinical/language';
import { Button, Drawer, FilterIcon, Input, SearchIcon } from '@hc/ui/atoms';
import { DoctorDetailDrawer, SkeletonCard } from '@hc/ui/components';
import {
  AppointmentDateTimeSelection,
  DoctorFilter,
  DoctorProfileCard,
} from '@hc/ui/components/clinical';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { localStorageKeys } from '@hc/utils';
import { useDoctorDetails } from '@hc/store/clinical/doctorDetails';
import { doctorAvailablePayload } from '@hc/utils/helperFunctions';
import { Avatar, Badge, Box, IconButton, Typography } from '@mui/material';
import { useDebounce } from 'ahooks';
import { eachDayOfInterval, endOfWeek, startOfWeek } from 'date-fns';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { doctorAvailable_style } from './style';

export default function DoctorAvailable(props) {
  const { onClick, className = '', ...rest } = props;
  const location = useLocation();
  const navigate = useNavigate();
  // General Hooks
  const [drawerOpen, setDrawerOpen] = useState();
  // const navigate = useNavigate();
  const [filterShow, setFilterShow] = useState();
  const [isApplyClick, setIsApplyClick] = useState(false);
  const [showBadge, setShowBadge] = useState(false);
  const [search, setSearch] = useState('');
  const [initialPayload, setInitailPayload] = useState({});
  const debouncedSearchValue = useDebounce(search, { wait: 500 });
  const [DoctorDrawer, setDoctorDrawer] = useState(false);

  const paymentType = JSON.parse(
    JSON.stringify(localStorage.getItem(localStorageKeys.appoinmentType))
  );

  const {
    bookAppointmentState,
    getDoctorsList,
    setStartEndBetween,
    updateBAState,
    updateDoctorDetail,
    constructSlotDataState,
    loading: doctorsLoading,
  } = useBookAppointment(
    (state) => ({
      bookAppointmentState: state.bookAppointmentState,
      getDoctorsList: state.getDoctorsList,
      setStartEndBetween: state.setStartEndBetween,
      constructSlotDataState: state.constructSlotDataState,
      updateBAState: state.updateBAState,
      updateDoctorDetail: state.updateDoctorDetail,
      loading: state.loading,
    }),
    // (prev, curr) => isEqual(prev, curr)
    (prev, curr) => {
      const data = isEqual(prev, curr);
      return false;
    }
  );

  const { getSpecialityData, specialityData } = useSpeciality(
    (state) => ({
      getSpecialityData: state.getSpecialityData,
      specialityData: state.specialityData,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const {
    getLanguageData,
    languageData,
    // loading: languageLoader,
  } = useLanguage(
    (state) => ({
      getLanguageData: state.getLanguageData,
      languageData: state.languageData,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  const { getDoctorsDetails } = useDoctorDetails(
    (state) => ({
      getDoctorsDetails: state.getDoctorsDetails,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { doctorAvailableDetails, doctorSearch, languages, speciality } =
    bookAppointmentState;

  // GET DOCTOR LIST FUNCTION
  const getDoctorsListFunction = async (start, doctorSearchFilter) => {
    setIsApplyClick(true);
    if (!isApplyClick) {
      updateBAState('languages', []);
      updateBAState('speciality', '');
    }

    if (languages?.length > 0 || speciality !== '') {
      setShowBadge(true);
    } else {
      setShowBadge(false);
    }

    const payload = {
      ...doctorAvailablePayload(start),
      search:
        doctorSearchFilter === undefined ? doctorSearch : doctorSearchFilter,
      languages: languages ?? [],
      speciality: speciality ? speciality?.toString() : '',
      id: location?.state?.doctorId ? location?.state?.doctorId : '',
    };

    setInitailPayload(payload);

    await getDoctorsList(payload, false);
  };

  // CLEAR DOCTOR FILTER DRAWER
  const clearDrawer = () => {
    setIsApplyClick(false);
    updateBAState('languages', []);
    updateBAState('speciality', '');
    const start = startOfWeek(new Date());
    // getDoctorsListFunction(start);
  };

  // DOCTOR FILTER FUNCTION
  const doctorFilter = async () => {
    const start = startOfWeek(new Date());
    // GET DOCTOR LIST FUNCTION
    await getDoctorsListFunction(start);
    setFilterShow(false);
  };

  // CONSTRUCT SLOT DATA
  const constructSlotData = (seDate, value) => {
    constructSlotDataState(seDate, value);
  };

  // SELECT DATE FUNCTION
  const selectDate_ = (val, value) => {
    const selectedDat = getDateFormat(val, 'YYYY-MM-DD');
    // UPDATE STATE
    updateBAState('selectedDate', selectedDat);
    constructSlotData(selectedDat, value);
  };

  const getInitialData = async () => {
    const start = startOfWeek(new Date());
    const end = endOfWeek(new Date());
    const days = eachDayOfInterval({
      start,
      end,
    });
    // GET DOCTOR LIST FUNCTION
    await getDoctorsListFunction(start);
    // GET LANGUAGE FUNCTION
    await getLanguageData();
    // GET SPECIALITY FUNCTION
    await getSpecialityData();
    // SET START_OF_WEEK, END_OF_WEEK & BETWEEN_DATES IN STORE
    await setStartEndBetween(start, end, days);
  };

  const initialDrawerOpen = () => {
    const bookAppointmentStateCopy = JSON.parse(
      JSON.stringify(bookAppointmentState)
    );
    // SELECT DATE FUNCTION
    selectDate_(
      bookAppointmentStateCopy?.selectedDate,
      bookAppointmentStateCopy?.doctorDetail
    );
    // setDrawerOpen(true);
  };

  const handleDoctorVeiwDetail = (id) => {
    getDoctorsDetails(id);
    setDoctorDrawer(true);
  };

  const onCloseDrawer = () => {
    setFilterShow(false);
  };

  const handleIconDrawer = () => {
    if (!location.state?.doctorId) {
      setFilterShow(true);
    } else {
      toast('You must select a referral doctor', { icon: '⚠️' });
    }
  };

  const handleDrawerClose = () => {
    if (location?.state?.doctorId) {
      setDrawerOpen(true);
    } else {
      setDrawerOpen(false);
    }
  };

  // ONCHANGE FOR FILTER
  const onChange = (value, key) => {
    if (key === 'languages') {
      const arr = languages;
      languages?.push(value);
      return updateBAState('languages', arr);
    }
    updateBAState(key, value);
  };

  // CHIP ITEM DELETE
  const onChipDelete = (e) => {
    const result = languages?.filter((x) => x !== e);
    updateBAState('languages', result);
  };

  const onNext = () => {
    if (
      bookAppointmentState?.selectedDate &&
      bookAppointmentState?.selectedSlotStartTime
    ) {
      // UPDATE DOCOTR DETAILS
      updateDoctorDetail();
      // NAVIGATE TO REPORT UPLOAD PAGE
      navigate(clinicalRoutes.uploadReports);
    } else {
      return toast.error('Please select slot!');
    }
  };

  // GET DOCOTR LIST BASED ON DOCTOR NAME FILTER

  useEffect(() => {
    if (!location.state?.doctorId) {
      updateBAState('doctorSearch', debouncedSearchValue);
      const start = startOfWeek(new Date());
      getDoctorsListFunction(start, debouncedSearchValue);
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    getInitialData();
    if (
      bookAppointmentState?.doctorDetail?.id &&
      bookAppointmentState?.selectedDate?.length > 0
    ) {
      initialDrawerOpen();
    }
  }, [!drawerOpen]);

  useEffect(() => {
    if (location.state?.doctorId) {
      initialDrawerOpen();
      setDrawerOpen(true);
    } else {
      getInitialData();
      if (
        bookAppointmentState?.doctorDetail?.id &&
        bookAppointmentState?.selectedDate?.length > 0
      ) {
        initialDrawerOpen();
      }
    }
  }, []);
  console.log(doctorAvailableDetails);
  return (
    <Box sx={doctorAvailable_style.rootSx} className={`${className}`} {...rest}>
      <Screenlayout backRequired title="Book Appointment" notshowFooter>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Typography sx={doctorAvailable_style.doctorAvailableTextSx}>
              Doctors Available
            </Typography>
            {paymentType === 'free' ? (
              <Avatar alt="" sx={doctorAvailable_style.numberCountSx}>
                {doctorAvailableDetails?.filter(
                  (obj) => obj?.is_health_circles_doctor === true
                ).length > 0 &&
                doctorAvailableDetails?.filter(
                  (obj) => obj?.is_health_circles_doctor === true
                ).length < 10
                  ? `0${
                      doctorAvailableDetails?.filter(
                        (obj) => obj?.is_health_circles_doctor === true
                      )?.length
                    }`
                  : doctorAvailableDetails?.filter(
                      (obj) => obj?.is_health_circles_doctor === true
                    )?.length}
              </Avatar>
            ) : (
              <Avatar alt="" sx={doctorAvailable_style.numberCountSx}>
                {doctorAvailableDetails?.length > 0 &&
                doctorAvailableDetails?.length < 10
                  ? `0${doctorAvailableDetails?.length}`
                  : doctorAvailableDetails?.length}
              </Avatar>
            )}
          </Box>

          {/* Select Box */}
          <Box sx={doctorAvailable_style.SearchSelectSx}>
            <Input
              value={search}
              id="doctorSearch"
              disabled={location.state?.doctorId ? true : false}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              textFieldStyle={doctorAvailable_style.textFieldStyle}
              startAdornment={
                <IconButton aria-label="" edge="start" disableRipple>
                  <SearchIcon rootStyle={{ color: 'grey.500' }} />
                </IconButton>
              }
              placeholder="Doctor Name"
            />
            <Box
              sx={doctorAvailable_style.filterIconSx}
              onClick={handleIconDrawer}
            >
              {showBadge ? (
                <Badge color="primary" badgeContent=" " variant="dot">
                  <FilterIcon />
                </Badge>
              ) : (
                <FilterIcon />
              )}
            </Box>
          </Box>

          {/* filter Drawer fot Doctor */}
          <DoctorFilter
            filterShow={filterShow}
            onCloseDrawer={onCloseDrawer}
            clearDrawer={clearDrawer}
            getDoctorsListFunction={getDoctorsListFunction}
            doctorFilter={doctorFilter}
            filters={[
              {
                label: 'Specialty',
                value: speciality,
                component: 'Dropdown',
                onChange: (e) => onChange(e.target.value, 'speciality'),
                option:
                  Array.isArray(specialityData) && specialityData?.length > 0
                    ? specialityData
                    : [],
              },
              {
                label: 'Language',
                value: languages,
                component: 'MultiSelect',
                onChange: (e) => onChange(e?.target?.value, 'languages'),
                onChipDelete,
                ChipText: languages,
                languageData,
                option:
                  Array.isArray(languageData) && languageData?.length > 0
                    ? languageData
                        ?.map((x) =>
                          !languages?.includes(x?.value) ? x : false
                        )
                        .filter(Boolean)
                    : [],
              },
            ]}
          />

          {/* Doctor Profile Card */}
          {!doctorsLoading && doctorAvailableDetails && (
            <Box sx={doctorAvailable_style.doctorCardSx}>
              <Box sx={doctorAvailable_style.doctorAvailableSx}>
                {Array.isArray(doctorAvailableDetails) &&
                doctorAvailableDetails?.length > 0 ? (
                  paymentType === 'paid' ? (
                    doctorAvailableDetails.map((val, i) => (
                      <DoctorProfileCard
                        key={i}
                        rootStyle={doctorAvailable_style.availableCardSx}
                        src={val?.profile_pic}
                        doctorName={val?.name}
                        pricingRequired={val?.appointment_amount_rupees ?? ''}
                        payload={initialPayload}
                        value={val}
                        // aboutDoctor={val.aboutDoctor}
                        role={val?.doctor_specialities}
                        hospital={val?.working_at}
                        available={!val?.isUnavailable}
                        setDrawerOpennn={setDrawerOpen}
                        handleDoctorDetails={() =>
                          handleDoctorVeiwDetail(val.user_id)
                        }
                        servingFrom={val?.serving_from ?? ''}
                      />
                    ))
                  ) : (
                    doctorAvailableDetails
                      .filter((obj) => obj?.is_health_circles_doctor === true)
                      .map((val, i) => (
                        <DoctorProfileCard
                          paymentType={paymentType}
                          key={i}
                          rootStyle={doctorAvailable_style.availableCardSx}
                          src={val?.profile_pic}
                          doctorName={val?.name}
                          pricingRequired={val?.appointment_amount_rupees ?? ''}
                          payload={initialPayload}
                          value={val}
                          // aboutDoctor={val.aboutDoctor}
                          role={val?.doctor_specialities}
                          hospital={val?.working_at}
                          available={!val?.isUnavailable}
                          setDrawerOpennn={setDrawerOpen}
                          handleDoctorDetails={() =>
                            handleDoctorVeiwDetail(val.user_id)
                          }
                          servingFrom={val?.serving_from ?? ''}
                        />
                      ))
                  )
                ) : (
                  <Box
                    sx={{
                      display: 'grid',
                      minHeight: '70vh',
                      placeItems: 'center',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={doctorAvailable_style.subHeadSx}
                    >
                      No Match Found
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}

          <Drawer
            show={drawerOpen}
            // onCloseDrawer={() => handleDrawerClose()}
            // rootStyle={doctorAvailable_style.drawerRootSx}
            height="60vh"
            footer={
              <Box>
                <Button
                  loading={doctorsLoading}
                  buttonStyle={{
                    '&:hover': {
                      bgcolor: 'primary.main',
                    },
                  }}
                  onClick={onNext}
                >
                  Continue
                </Button>
              </Box>
            }
          >
            <AppointmentDateTimeSelection
              bookAppointmentStateData={bookAppointmentState}
            />
          </Drawer>

          <DoctorDetailDrawer
            drawerOpen={DoctorDrawer}
            handleDrawerClose={() => setDoctorDrawer(false)}
          />
        </Box>
        {/* use the setadddrawer to open the drawer , while the patient have free consultation.check for the patient data
       about paid status and integrate the freeconsultation modal.
       */}

        {doctorsLoading &&
          Array(5)
            .fill(1)
            .map((i) => <SkeletonCard key={i} />)}
      </Screenlayout>
    </Box>
  );
}
