import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { bookingMinDetails_style } from './style';

export function BookingMinDetails(props) {
  const {
    appointmentType = '',
    iconType = [],
    children,
    updateStyle = {},
    smsText = '',
    mobileNumber = '',
    className = '',
    ...rest
  } = props;
  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton>{iconType}</IconButton>
      </Box>
      <Box>
        <Typography sx={bookingMinDetails_style.appointmentTextSx}>{appointmentType}</Typography>
      </Box>
      <Box>{children}</Box>
      <Box>
        <Typography sx={{ ...bookingMinDetails_style.updatesTextSx, ...updateStyle }}>
          {smsText}
        </Typography>
        <Typography sx={bookingMinDetails_style.numberTextSx}>{mobileNumber}</Typography>
      </Box>
    </Box>
  );
}

BookingMinDetails.propTypes = {
  appointmentType: PropTypes.string,
  // iconType: PropTypes.object,
  children: PropTypes.array,
  smsText: PropTypes.string,
  mobileNumber: PropTypes.string,
  className: PropTypes.node,
  sx:PropTypes.object,
};