/* eslint-disable camelcase */
export const upload_details_style = {
  inputSx: {
    mt: 1,
    mb: 2,
   
    borderRadius: '8px',
    '& input::placeholder': {
      fontWeight: '400',
    },
    '& .MuiOutlinedInput-input':{
      fontWeight: '400',
    }
  },

  datePickerSx: {
    mt: 1,
    mb: 2,
  },
  dropDownSx: {
    mt: 1,
    mb: 2,
  },
};
