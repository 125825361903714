/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
import { Button, ShareReport } from '@atoms';
import { clinicalRoutes } from '@hc/routes';
import { useFamilyMember, useRelationShip } from '@hc/store';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation } from 'react-router-dom';
import { notificationPopup_style } from './style';

export function NotificationPopup(props) {
  const {
    notifyBtnStyle = {},
    notifyBtnsStyle = {},
    totalBoxStyle = {},
    subTitleStyle = {},
    notifyBtnStyletwo = {},
    // handleClose = () => false,
    subTitle = '',
    open = false,
    icon = false,
    icons,
    setOpen,
    className = '',
    loading,
    acceptButtonName = '',
    declineButtonName = '',
    onAccpet,
    onDecline,
    ...rest
  } = props;
  const location = useLocation();

  const {
    // familyMembers,
    getAll,
    // loading,
  } = useFamilyMember(
    (state) => ({
      familyMembers: state.familyMembers,
      getAll: state.getAll,
      get: state.get,
      acceptOrDeclineFamilyMemberRequest:
        state.acceptOrDeclineFamilyMemberRequest,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { get: getRelationShipData } = useRelationShip(
    (state) => ({
      get: state?.get,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const initialData = async () => {
    await getAll();
    await getRelationShipData();
  };

  // Getting the family member data to use across the screens
  useEffect(() => {
    if (!location.pathname === clinicalRoutes.login) {
      initialData();
    }
  }, []);

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={notificationPopup_style.totalModalSx}>
        <Box className={`${className}`} {...rest}>
          <Box sx={{ ...notificationPopup_style.totalBoxSx, ...totalBoxStyle }}>
            <Box pt={1}>{icons}</Box>
            {icon && (
              <ShareReport rootStyle={notificationPopup_style.notifyIconSx} />
            )}
            <Typography
              sx={{ ...notificationPopup_style.subTitleSx, ...subTitleStyle }}
            >
              {subTitle}
            </Typography>
            <Box sx={notificationPopup_style.totalBtnSx}>
              <Button
                buttonStyle={{
                  ...notificationPopup_style.notifyBtnSx,
                  backgroundColor: 'error.main',
                  ...notifyBtnStyletwo,
                }}
                onClick={onDecline}
              >
                {/* Decline */}
                {declineButtonName}
              </Button>
              <Button
                buttonStyle={{
                  ...notificationPopup_style.notifyBtnSx,
                  backgroundColor: 'primary.main',
                  ...notifyBtnsStyle,
                }}
                onClick={onAccpet}
                // loading={loading}
              >
                {/* Accept */}
                {acceptButtonName}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
NotificationPopup.propTypes = {
  notifyBtnStyle: PropTypes.object,
  open: PropTypes.bool,
  totalBoxStyle: PropTypes.object,
  onDecline: PropTypes.func,
  onAccpet: PropTypes.func,
  subTitle: PropTypes.string,
  className: PropTypes.string,
  acceptButtonName: PropTypes.string,
  declineButtonName: PropTypes.string,
  icon: PropTypes.bool,
  notifyBtnStyletwo: PropTypes.object,
};
