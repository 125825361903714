import { clinicalRoutes } from '@hc/routes';
import { useOnboarding } from '@hc/store/clinical/onboarding';
import {
  Button,
  CorporateSignUp,
  CreateAccountIcon,
  Input,
  Label,
  MobileInput,
} from '@hc/ui/atoms';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { Link, useSearchParams } from 'react-router-dom';
import { onboarding_style } from './style';

export function ClinicalOnboarding() {
  // const { className = '', ...rest } = props;
  const [searchParams] = useSearchParams();

  const { user, signUp, setUser, verifyOnboardUrl, loading } = useOnboarding(
    (state) => ({
      signUp: state.signUp,
      setUser: state.setUser,
      user: state.user,
      verifyOnboardUrl: state.verifyOnboardUrl,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const [values, setValues] = useState(user);
  const [data, setData] = useState({});
  const [showpassword, setPassword] = useState();
  const handleClickShowPassword = () => {
    setPassword(!showpassword);
  };
  const [showConfirmPassword, setConfirmPassword] = useState();
  const handleClickshowConfirmPassword = () => {
    setConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (val, index) => {
    if (index === 'mobileNumber') {
      setValues({
        ...values,
        countryCode: val.mobileCode,
        mobileNumber: val.mobile,
      });
    } else {
      setValues({ ...values, [index]: val });
    }
  };

  // Validation
  const isIamValideToSignUp = () => {
    let isValid = true;
    const error = values?.error;

    // Checking Mobile Number
    if (values?.mobileNumber.length === 0) {
      isValid = false;
      error.mobileNumber = 'Enter a valid 10 digit mobile number';
    } else if (values?.mobileNumber.length < 10) {
      isValid = false;
      error.mobileNumber = 'Enter a valid 10 digit mobile number';
    } else {
      error.mobileNumber = '';
    }

    // Checking password
    if (values?.password.length === 0) {
      isValid = false;
      error.password = 'Enter the password';
    } else {
      error.password = '';
    }

    if (values?.password.length < 8) {
      isValid = false;
      error.password = 'Password must be at least 8 characters long';
    } else if (
      !values?.password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/
      )
    ) {
      isValid = false;
      error.password =
        'Password must contain uppercase and lowercase letters, numbers, and special characters';
    } else {
      error.password = '';
    }

    // Checking Cofirm password
    if (values?.confirmPassword.length === 0) {
      isValid = false;
      error.confirmPassword = 'Enter the confirm password';
    } else {
      error.confirmPassword = '';
    }

    // Checking confirm and password are same
    if (
      values?.confirmPassword.length > 0 &&
      values?.confirmPassword !== values.password
    ) {
      isValid = false;
      error.confirmPassword = "Password doesn't matching, please check again";
    }
    setValues({ ...values, error });
    return isValid;
  };

  const onSignUp = () => {
    if (isIamValideToSignUp()) {
      const error = values?.error;
      error.confirmPassword = '';
      error.password = '';
      error.mobileNumber = '';
      setValues({ ...values, error });
      // calling the signup api

      signUp(
        values,
        data?.employee_membership_code?.length > 0
          ? 'corporate'
          : data?.family_member_id?.length > 0
          ? 'familyMember'
          : data?.user_id?.length > 0
          ? 'addByDoctorOrAdmin'
          : 'normal'
      );
    }
  };

  const checkOnboardURL = async () => {
    await verifyOnboardUrl();
  };

  useEffect(() => {
    setUser({
      ...user,
      error: {
        password: '',
        confirmPassword: '',
        mobileNumber: '',
      },
    });
    setValues({
      ...user,
      error: {
        password: '',
        confirmPassword: '',
        mobileNumber: '',
      },
    });

    if (searchParams.get('token')?.length > 0) {
      localStorage.setItem(
        localStorageKeys.authToken,
        searchParams.get('token')
      );
      checkOnboardURL();
      const tokenData = parseJwt(searchParams.get('token'));
      setData(tokenData);
      setValues({
        ...values,
        countryCode:
          tokenData?.mobile_no_country_code || tokenData?.country_code,
        mobileNumber: tokenData?.mobile_no,
      });
    }
  }, []);

  return (
    <Box>
      <Box sx={onboarding_style.imgContSx}>
        {data?.employee_membership_code?.length > 0 ||
        data?.family_member_id?.length > 0 ? (
          <CorporateSignUp rootStyle={onboarding_style.imgSecondSx} />
        ) : (
          <CreateAccountIcon rootStyle={onboarding_style.imgSx} />
        )}
      </Box>
      <Box sx={onboarding_style.paperSx}>
        <Typography variant="h5" sx={onboarding_style.titleSx}>
          {data?.employee_membership_code?.length > 0 ||
          data?.family_member_id?.length > 0 ||
          data?.user_id?.length > 0
            ? 'Join Health Circles!'
            : 'Create Account'}
        </Typography>
        {(data?.employee_membership_code?.length > 0 ||
          data?.family_member_id?.length > 0 ||
          data?.user_id?.length > 0) && (
          <Typography sx={onboarding_style.subTitleSx}>
            Become better version of yourself
          </Typography>
        )}
        <Box sx={onboarding_style.inputGroupSx}>
          <Label htmlFor="mobileNumber" isRequired>
            Phone number
          </Label>
          <MobileInput
            value={{
              mobile_code: values.countryCode ?? '+91',
              mobile: values.mobileNumber ?? '',
            }}
            id="mobileNumber"
            isError={values.error.mobileNumber !== ''}
            helperText={values.error.mobileNumber}
            onChange={(value) => handleChange(value, 'mobileNumber')}
            isReadonlyCC={
              data?.employee_membership_code?.length > 0 ||
              data?.family_member_id?.length > 0 ||
              data?.user_id?.length > 0
            }
            disabled={
              data?.employee_membership_code?.length > 0 ||
              data?.family_member_id?.length > 0 ||
              data?.user_id?.length > 0
            }
          />
        </Box>
        <Box sx={onboarding_style.inputGroupSx}>
          <Label htmlFor="password" isRequired>
            Create password
          </Label>
          <Input
            id="password"
            type={showpassword ? 'text' : 'password'}
            onChange={(e) => handleChange(e.target.value, 'password')}
            isError={values.error.password !== ''}
            errorMessage={values.error.password}
            value={values.password}
            endAdornment={
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClickShowPassword()}
                edge="end"
              >
                {showpassword ? (
                  <MdVisibilityOff
                    htmlColor="#848484"
                    sx={onboarding_style.eyeSx}
                  />
                ) : (
                  <MdVisibility
                    htmlColor="#848484"
                    sx={onboarding_style.eyeSx}
                  />
                )}
              </IconButton>
            }
          />
        </Box>
        <Box sx={onboarding_style.inputGroupSx}>
          <Label htmlFor="confirmPassword" isRequired>
            Confirm password
          </Label>
          <Input
            id="confirmPassword"
            type={showConfirmPassword ? 'text' : 'password'}
            onChange={(e) => handleChange(e.target.value, 'confirmPassword')}
            isError={values.error.confirmPassword !== ''}
            errorMessage={values.error.confirmPassword}
            value={values.confirmPassword}
            endAdornment={
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClickshowConfirmPassword()}
                edge="end"
              >
                {showConfirmPassword ? (
                  <MdVisibilityOff
                    htmlColor="#848484"
                    sx={onboarding_style.eyeSx}
                  />
                ) : (
                  <MdVisibility
                    htmlColor="#848484"
                    sx={onboarding_style.eyeSx}
                  />
                )}
              </IconButton>
            }
          />
        </Box>
        <Box sx={{ mt: 3, display: 'grid', gap: 3 }}>
          <Button
            fullWidth
            onClick={() => onSignUp()}
            loading={loading}
            buttonStyle={onboarding_style.buttonsStyle}
          >
            Sign Up
          </Button>
        </Box>
        <Box>
          <Typography sx={onboarding_style.lastText}>
            Already joined with us?
            <Link to={clinicalRoutes.login} style={{ textDecoration: 'none' }}>
              <Typography variant="body1" sx={onboarding_style.signSx}>
                Sign in
              </Typography>
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

// CreateAccountIcon;
