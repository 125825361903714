/* eslint-disable no-lonely-if */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-undef */
import { Button, Drawer, SheduleAppointment } from '@atoms';

import {
  AppointmentDateTimeSelection,
  CancelAppointment,
  NotificationPopup,
} from '@hc/ui/components/clinical';
import { AppointmentNotifyCard } from '@hc/ui/components/clinical/appointmentNotifyCard';
import { localStorageKeys, profileNotRequireRoutes } from '@hc/utils/constants';
import {
  doctorAvailablePayload,
  lazyLoadScript,
  parseJwt,
} from '@hc/utils/helperFunctions';
import { Box, Grid, Drawer as SlotDrawer, Typography } from '@mui/material';

import { envConfig } from '@hc/config';
import {
  compareWithCurrentDateTime,
  getDateFormat,
  getDaysOfInterval,
  getEndOfWeek,
  getSlotEndTimeToString,
  getSlotStartTime,
  getStartOfWeek,
  getTimeFormat,
  getTimeZoneFnc,
  getTodayDate,
} from '@hc/dayjs';
import { clinicalRoutes } from '@hc/routes';
import { useAppointment, useProfile, useRouting } from '@hc/store';
import { useBookAppointment } from '@hc/store/clinical/bookAppointment';
import { useJitsiEmbed } from '@hc/store/clinical/jitsiEmbed';
import { usePWANotificationEnabler } from '@hc/store/clinical/pwaNotificationPrompt';
import { Loader, MaximizeIcon, MinimizeIcon } from '@hc/ui/atoms';
import { queryClient } from '@hc/utils';
import { razorpayCall } from 'crayond_payment_gateway';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Toaster, toast } from 'react-hot-toast';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { JitsiMeetEmbed } from '../../doctor/jitsiMeetEmbed';
import { FreeConsultation } from '../appointmentBooking/freeConsultation';
import { rootLayout_style } from './style';

function ClinicalRootLayout(props) {
  const {
    className = '',
    children,
    backgroundStyle,
    rootStyle,
    ...rest
  } = props;

  const { profile, get, getCorporateUserData } = useProfile(
    (state) => ({
      profile: state.profile,
      get: state.get,
      getCorporateUserData: state.getCorporateUserData,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { jitsiState, onMeetlinkUpdate, onCallUpdate } = useJitsiEmbed(
    (state) => ({
      jitsiState: state.jitsiState,
      onMeetlinkUpdate: state.onMeetlinkUpdate,
      onCallUpdate: state.onCallUpdate,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const {
    appointmentState,
    updateAppointmentsData,
    cancelAppointment,
    getPastUpcomingAppointmentList,
    appointmentStateUpdate,
    checkSlotIsAvilableOrNot,
    clearAppointmentStore,
    loading,
  } = useAppointment((state) => ({
    clearAppointmentStore: state.clearAppointmentStore,
    appointmentState: state.appointmentState,
    updateAppointmentsData: state.updateAppointmentsData,
    appointmentStateUpdate: state.appointmentStateUpdate,
    cancelAppointment: state.cancelAppointment,
    getPastUpcomingAppointmentList: state.getPastUpcomingAppointmentList,
    checkSlotIsAvilableOrNot: state.checkSlotIsAvilableOrNot,

    loading: state.loading,
  }));

  const {
    bookAppointmentState,
    // selectSlotUpdateNew,
    updateBAState,
    constructSlotDataState,
    // getDoctorsList,
    getDoctorData,
    rescheduleAppointmentFnc,
    setStartEndBetween,
    clearBookAppointmentState,
    updatePaymentStatusDetails,
    updateDoctorDetail,
  } = useBookAppointment((state) => ({
    getDoctorData: state.getDoctorData,
    bookAppointmentState: state.bookAppointmentState,
    selectSlotUpdateNew: state.selectSlotUpdateNew,
    updateBAState: state.updateBAState,
    constructSlotDataState: state.constructSlotDataState,
    // getDoctorsList: state.getDoctorsList,
    rescheduleAppointmentFnc: state.rescheduleAppointmentFnc,
    setStartEndBetween: state.setStartEndBetween,
    clearBookAppointmentState: state.clearBookAppointmentState,
    updatePaymentStatusDetails: state.updatePaymentStatusDetails,
    updateDoctorDetail: state.updateDoctorDetail,
  }));

  const { setDeferredPrompt } = usePWANotificationEnabler(
    (state) => ({
      setDeferredPrompt: state.setDeferredPrompt,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  lazyLoadScript(
    `https://www.googletagmanager.com/gtag/js?id=${envConfig.google_analytics}`
  )
    .then(() => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', `${envConfig.google_analytics}`);
    })
    .catch((error) => {
      log('error', error);
      console.error('Failed to load Google Analytics:', error);
    });

  const { appointment_data } = appointmentState;

  const [loading_, setLoading] = useState(false);
  const [model, setModel] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const authToken = localStorage.getItem(localStorageKeys?.authToken);
  const data = parseJwt(authToken);

  const routeURL = useRouting((state) => state?.route);
  const routeState = useRouting((state) => state?.state);

  const [drawerOpen, setDrawerOpen] = useState();
  const [paymentDrawerOpen, setPaymentDrawerOpen] = useState(false);
  const [slotDrawerOpen, setSlotDrawerOpen] = useState(false);
  const [cancelDrawerOpen, setCancelDrawerOpen] = useState(false);
  const [paymentPendingFlow, setPaymentPendingFlow] = useState(false);
  const [showNotifyCard, setShowNotifyCard] = useState(false);
  const [jitsiId, setJitsiId] = useState(0);
  // const [minimizeMeetWindow, setMinimizeMeetWindow] = useState(false);
  // const [startCall, setStartCall] = useState(false);

  const [openfreeContract, setopenfreeContract] = useState(false);

  const tokenData_ = localStorage.getItem(localStorageKeys.authToken);
  const parseTokenData = parseJwt(tokenData_);

  const onClosebtn = (index, data) => {
    localStorage.removeItem(localStorageKeys?.isRescheduleDone);
    updateAppointmentsData(
      index,
      data?.id,
      data?.assigned_doctor_profile?.id,
      data?.event_id,
      data
    );
    setDrawerOpen(true);
  };

  const handleResumeBooking = async (index, data) => {
    localStorage.removeItem(localStorageKeys?.isRescheduleDone);
    localStorage.setItem(localStorageKeys?.responseAppointmentId, data?.id);
    updateAppointmentsData(
      index,
      data?.id,
      data?.assigned_doctor,
      data?.event_id,
      data
    );
    setPaymentDrawerOpen(true);
    setPaymentPendingFlow(true);
  };

  const handleShowNotifyCard = () => {
    if (
      location.pathname === clinicalRoutes.myAppointments ||
      location.pathname === clinicalRoutes.reports ||
      location.pathname === clinicalRoutes.myCircle ||
      location.pathname === clinicalRoutes.myFamily ||
      location.pathname === clinicalRoutes.home
    ) {
      setShowNotifyCard(true);
    } else {
      setShowNotifyCard(false);
    }
  };

  const callBackResponse = async (val) => {
    if (val?.statusCode === 200) {
      toast.success(val?.message ?? `Payment Success.`);
      localStorage.removeItem(localStorageKeys?.isRescheduleDone);
      const responseAppointmentId = localStorage.getItem(
        localStorageKeys?.responseAppointmentId
      );
      const payload = {
        clientTimeZone: getTimeZoneFnc(),
        appointment_id: responseAppointmentId ?? '',
        appointment_status_id: 1, // booked
        payment_json: val?.data ?? {},
        payment_type: 1,
      };
      const updateBookAppointmentResponse = await updatePaymentStatusDetails(
        payload
      );

      if (updateBookAppointmentResponse?.statusCode === 200) {
        setPaymentDrawerOpen(false);
        setSlotDrawerOpen(false);
        clearBookAppointmentState();
        localStorage.removeItem(localStorageKeys?.responseAppointmentId);
        // To invalidate the cache of performanceStats
        queryClient.invalidateQueries({
          queryKey: ['appointments'],
        });

        setSlotDrawerOpen(false);
        setopenfreeContract(false);
        navigate(clinicalRoutes?.bookingStatus, {
          state: {
            status: 'confirmed',
            appointmentId:
              updateBookAppointmentResponse?.data?.appointmentId ?? '',
            bookingMethod:
              updateBookAppointmentResponse?.data?.bookingMethod ?? '',
            familyMemberId:
              updateBookAppointmentResponse?.data?.familyMemberId ?? '',
          },
        });
      } else {
        return toast.error(
          updateBookAppointmentResponse?.message ??
            'Something went wrong please try again!'
        );
      }
    } else {
      toast.success(val?.message ?? `Something went wrong please try again!`);
    }
  };

  const makePayment = async () => {
    // Check slot available or not
    const payload = {
      id: appointment_data?.assigned_doctor ?? '',
      appointment_date: appointment_data?.appointment_date ?? '',
      appointment_time_slot: appointment_data?.appointment_time_slot ?? '',
      appointment_id: appointment_data?.id ?? null,
    };
    const response = await checkSlotIsAvilableOrNot(payload);

    if (response?.data?.status?.code === 200) {
      if (response?.data?.data?.is_availability) {
        // PAYMENT FLOW

        razorpayCall({
          baseURL: envConfig?.payment_url,
          // baseURL: import.meta.env.VITE_APP_API_BASE_URL,
          orderAmount:
            appointment_data?.assigned_doctor_profile?.consultation_fees ?? '',
          name: 'Health Circles',
          description: 'description',
          preFillName: appointment_data?.appointment_members?.[0]?.name ?? '',
          preFillEmail:
            appointment_data?.appointment_members?.[0]?.email_id ?? '',
          preFillContactNumber:
            appointment_data?.appointment_members?.[0]?.mobile_no ?? '',
          notesAddress: 'notesAddress',
          theme: '',
          paymentType: 1,
          setLoading,
          callBackResponse,
        });
      } else {
        // BOOK APPOINTMNET FLOW
        setModel(true);
      }
    } else {
      return toast.error('Something went wrong please try again!');
    }
  };

  const getInitialData = async (user_id) => {
    await getPastUpcomingAppointmentList(user_id, true);
  };

  const cancelAppointmentFnc = async () => {
    const payload = {
      appointed_doctor_id: appointmentState?.appointed_doctor_id ?? '',
      appointment_id: appointmentState?.appointment_id ?? '',
      cancellationReason: appointmentState?.cancellationReason ?? '',
      event_id: appointmentState?.event_id ?? null,
      canceledBy: data?.user_id,
    };
    // CANCEL APPOINTMENT SERVICE CALL
    const response = await cancelAppointment(payload);
    if (response?.data?.status?.code === 200) {
      clearAppointmentStore();
      toast.success(
        response?.data?.status?.message ?? `Appointment cancelled successfully.`
      );
      updateAppointmentsData();

      // To invalidate the cache of performanceStats
      queryClient.invalidateQueries({
        queryKey: ['appointments'],
      });
      getInitialData(data?.user_id);
      setPaymentDrawerOpen(false);
      setCancelDrawerOpen(false);
      setDrawerOpen(false);
    } else {
      return toast.error(
        response?.data?.status?.message ??
          response?.data?.message ??
          'Something went wrong please try again!'
      );
    }
  };

  // CONSTRUCT SLOT DATA
  const constructSlotData = (seDate, value) => {
    constructSlotDataState(seDate, value);
  };

  // GET DOCTOR LIST FUNCTION
  const getDoctorsListFunction = async (start) => {
    const payload = {
      ...doctorAvailablePayload(start),
      search: '',
      languages: [],
      speciality: '',
      id: appointmentState?.appointed_doctor_id ?? '',
    };
    // await getDoctorsList(payload, true);
    const response = await getDoctorData(payload);
    const selectedDat = getDateFormat(new Date(), 'YYYY-MM-DD');
    constructSlotData(selectedDat, response);
  };

  const getInitialDoctorData = async () => {
    const start = getStartOfWeek(new Date());
    const end = getEndOfWeek(new Date());
    const days = getDaysOfInterval(start, end);
    // GET DOCTOR LIST FUNCTION
    await getDoctorsListFunction(start);
    // SET START_OF_WEEK, END_OF_WEEK & BETWEEN_DATES IN STORE
    await setStartEndBetween(start, end, days);
  };

  // SELECT DATE FUNCTION
  const selectDate = async (val) => {
    const selectedDat = getDateFormat(val, 'YYYY-MM-DD');
    // UPDATE STATE
    updateBAState('selectedDate', selectedDat);

    const bookAppointmentStateCopy = JSON.parse(
      JSON.stringify(bookAppointmentState)
    );
    const doctorDetail_ =
      await bookAppointmentStateCopy?.doctorAvailableDetails.filter(
        (val) => val?.id === bookAppointmentStateCopy?.doctorDetail?.id
      )?.[0];
    constructSlotData(selectedDat, doctorDetail_);
  };

  const reschedule = async () => {
    await getInitialDoctorData();
    const selectedDate = getTodayDate('YYYY-MM-DD');
    selectDate(selectedDate);
    setSlotDrawerOpen(true);
    setDrawerOpen(false);
  };

  const onLivebtn = async (value) => {
    onCallUpdate('isStartCall', true);
    onCallUpdate('showSummary', false);
    onMeetlinkUpdate(value?.appointment_id);
    // onCallUpdate('roomId',value?.appointment_id);
    onCallUpdate('apptId', value.id);
  };

  // RESCHEDULE A APPOINTMENT
  const onNext = async (type) => {
    if (
      bookAppointmentState?.selectedDate &&
      bookAppointmentState?.selectedSlotStartTime
    ) {
      const isRescheduleDoneConst = localStorage.getItem(
        localStorageKeys?.isRescheduleDone
      );
      if (isRescheduleDoneConst && isRescheduleDoneConst === 'true') {
        if (type === 'paid') {
          razorpayCall({
            baseURL: envConfig?.payment_url,
            // baseURL: import.meta.env.VITE_APP_API_BASE_URL,
            orderAmount:
              appointment_data?.assigned_doctor_profile?.consultation_fees ??
              '',
            name: 'Health Circles',
            description: 'description',
            preFillName: appointment_data?.appointment_members?.[0]?.name ?? '',
            preFillEmail:
              appointment_data?.appointment_members?.[0]?.email_id ?? '',
            preFillContactNumber:
              appointment_data?.appointment_members?.[0]?.mobile_no ?? '',
            notesAddress: 'notesAddress',
            theme: '',
            paymentType: 1,
            setLoading,
            callBackResponse,
          });
        }
      } else {
        updateDoctorDetail();
        // CALL BOOK APPOINTMENT FUNCTIN
        const bookAppointmentStateCopy = JSON.parse(
          JSON.stringify(bookAppointmentState)
        );
        // CONSTRUCT PAYLAOD DATA FOR BOOK APPOINTMENT
        const payload = {
          appointment_date: getDateFormat(
            bookAppointmentState?.selectedSlotStartTime,
            'YYYY-MM-DD'
          ),
          appointment_time_slot: getTimeFormat(
            bookAppointmentState?.selectedSlotStartTime,
            'HH:mm:ss'
          ),
          appointment_client_start_time:
            bookAppointmentState?.selectedSlotStartTime,
          appointment_client_end_time:
            bookAppointmentState?.selectedSlotEndTime ?? '',
          appointed_doctor_details:
            bookAppointmentStateCopy?.appointed_doctor_details ?? {},
          doctor_id: appointmentState?.appointed_doctor_id ?? '',
          appointment_id: appointmentState?.appointment_id ?? '',
          bookedBy: data?.user_id ?? '',
          clientTimeZone: getTimeZoneFnc(),
        };
        const bookAppointmentResponse = await rescheduleAppointmentFnc(payload);
        if (bookAppointmentResponse?.statusCode === 200) {
          // PAYMENT FLOW
          localStorage.setItem(
            localStorageKeys?.responseAppointmentId,
            bookAppointmentResponse?.data?.appointmentId
          );
          localStorage.setItem(localStorageKeys?.isRescheduleDone, 'true');
          if (type === 'paid') {
            razorpayCall({
              baseURL: envConfig?.payment_url,
              // baseURL: import.meta.env.VITE_APP_API_BASE_URL,
              orderAmount:
                appointment_data?.assigned_doctor_profile?.consultation_fees ??
                '',
              name: 'Health Circles',
              description: 'description',
              preFillName:
                appointment_data?.appointment_members?.[0]?.name ?? '',
              preFillEmail:
                appointment_data?.appointment_members?.[0]?.email_id ?? '',
              preFillContactNumber:
                appointment_data?.appointment_members?.[0]?.mobile_no ?? '',
              notesAddress: 'notesAddress',
              theme: '',
              paymentType: 1,
              setLoading,
              callBackResponse,
            });
          }
        } else {
          return toast.error(
            bookAppointmentResponse?.message ??
              'Something went wrong please try again!'
          );
        }
      }
    } else {
      return toast.error('Please select slot!');
    }
  };

  const FreeConsultationNavFun = async () => {
    await onNext('free');
    const responseAppointmentId = localStorage.getItem(
      localStorageKeys?.responseAppointmentId
    );
    if (responseAppointmentId && responseAppointmentId?.length > 0) {
      const payload = {
        clientTimeZone: getTimeZoneFnc(),
        appointment_id: responseAppointmentId ?? '',
        appointment_status_id: 1, // booked
        payment_json: {},
        payment_type: 3,
      };
      const updateBookAppointmentResponse = await updatePaymentStatusDetails(
        payload
      );
      if (updateBookAppointmentResponse?.statusCode === 200) {
        localStorage.removeItem(localStorageKeys?.responseAppointmentId);
        // To invalidate the cache of performanceStats
        queryClient.invalidateQueries({
          queryKey: ['appointments'],
        });
        setSlotDrawerOpen(false);
        setopenfreeContract(false);
        navigate(clinicalRoutes?.bookingStatus, {
          state: {
            status: 'confirmed',
            appointmentId:
              updateBookAppointmentResponse?.data?.appointmentId ?? '',
            bookingMethod:
              updateBookAppointmentResponse?.data?.bookingMethod ?? '',
            familyMemberId:
              updateBookAppointmentResponse?.data?.familyMemberId ?? '',
          },
        });
      } else {
        return toast.error(
          updateBookAppointmentResponse?.message ??
            'Something went wrong please try again!'
        );
      }
    }
  };

  const handleDrawerCancel = (val) => {
    appointmentStateUpdate('cancellationReason', '');
    setCancelDrawerOpen(val);
  };

  const ReasonForCancellation = (event) => {
    // APPOINTMENT STATE UPDATE
    appointmentStateUpdate('cancellationReason', event?.target.value);
  };

  const openDoctorDrawer = async () => {
    setModel(false);
    await getInitialDoctorData();
    const selectedDate = getTodayDate('YYYY-MM-DD');
    selectDate(selectedDate);
    setDrawerOpen(false);
    setSlotDrawerOpen(true);
  };

  const goBack = () => {
    if (paymentPendingFlow) {
      setSlotDrawerOpen(false);
      setPaymentDrawerOpen(true);
    } else {
      setSlotDrawerOpen(false);
      setDrawerOpen(true);
    }
  };

  function handleBeforeInstallPrompt(event) {
    // Prevent the default install prompt from showing
    event.preventDefault();
    // Store the event for later use
    setDeferredPrompt(event);
  }

  const freeConsultationCheck = async () => {
    if (
      parseTokenData?.employee_membership_code?.length > 0 &&
      !profile?.contractExpired &&
      profile?.remaining_free_consultion > 0
    ) {
      setopenfreeContract(true);
    } else {
      await onNext('paid');
    }
  };

  useEffect(() => {
    if (parseTokenData?.employee_membership_code?.length > 0) {
      getCorporateUserData(parseTokenData.user_id);
    }
  }, []);

  useEffect(() => {
    // Perform actions on route change
    window.scrollTo(0, 0);
  }, [location]);

  // Intializing the firebase
  useEffect(() => {
    import('@hc/store').then((modules) => {
      const { initialize } = modules.useFirebase.getState();
      initialize();
    });
  }, []);

  // Getting the profile data to use across the screens
  useEffect(() => {
    const authToken = localStorage.getItem(localStorageKeys?.authToken);
    const data = parseJwt(authToken);
    if (
      data?.mobile_no &&
      data?.mobile_verified &&
      !profileNotRequireRoutes.some((path) => path === location?.pathname) &&
      !profile?.name?.length > 0
    ) {
      get();
      getInitialData(data?.user_id);
    }
  }, []);

  useEffect(() => {
    handleShowNotifyCard();
  }, [location.pathname]);

  useEffect(() => {
    if (routeURL !== null) {
      if (routeState) {
        navigate(routeURL, { state: routeState });
        setTimeout(() => {
          useRouting.setState({ route: null, state: null });
        }, 1000);
      } else {
        navigate(routeURL);
        setTimeout(() => {
          useRouting.setState({ route: null });
        }, 1000);
      }
    }
  }, [routeURL]);

  useEffect(() => {
    if (appointmentState?.live_appointments?.[0]?.appointment_date_time) {
      const myInterval = setInterval(() => {
        if (
          compareWithCurrentDateTime(
            appointmentState?.live_appointments?.[0]?.appointment_date_time
          )
        ) {
          // API CALL
          window.location.reload(false);
          getInitialData(data?.user_id);
          clearInterval(myInterval);
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [appointmentState?.live_appointments?.[0]?.appointment_date_time]);

  const meetClose = () => {
    onCallUpdate('isStartCall', false);
    onCallUpdate('minimizeMeetWindow', false);
    onCallUpdate('showSummary', true);
    setJitsiId(jitsiId + 1);
  };

  const meetMinimize = () => {
    if (jitsiState?.minimizeMeetWindow) {
      if (location.pathname !== clinicalRoutes.home) {
        navigate(clinicalRoutes.home);
        onCallUpdate('minimizeMeetWindow', !jitsiState?.minimizeMeetWindow);
        if (!jitsiState?.minimizeMeetWindow) {
          onCallUpdate('showSummary', true);
        } else {
          onCallUpdate('showSummary', false);
        }
      } else {
        onCallUpdate('minimizeMeetWindow', !jitsiState?.minimizeMeetWindow);
        if (!jitsiState?.minimizeMeetWindow) {
          onCallUpdate('showSummary', true);
        } else {
          onCallUpdate('showSummary', false);
        }
      }
    } else {
      onCallUpdate('minimizeMeetWindow', !jitsiState?.minimizeMeetWindow);
      if (!jitsiState?.minimizeMeetWindow) {
        onCallUpdate('showSummary', true);
      } else {
        onCallUpdate('showSummary', false);
      }
    }
  };

  useEffect(() => {
    if (location.pathname !== clinicalRoutes.home) {
      onCallUpdate('showSummary', true);
      onCallUpdate('minimizeMeetWindow', true);
    }
  }, [
    location.pathname !== clinicalRoutes.home,
    location.pathname === clinicalRoutes.home,
  ]);

  useEffect(() => {
    // Listen for the beforeinstallprompt event
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      );
    };
  }, []);

  console.log(jitsiState,"jitsiState")

  return (
    <Box
      sx={{ ...rootLayout_style.backgroundSx, ...backgroundStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box>
        <Toaster />
        <Box sx={{ ...rootLayout_style.rootSx, ...rootStyle }}>
          <Outlet />
          {jitsiState?.isStartCall && (
            <Box
            // maxWidth="425px"
            >
              <Box
                sx={
                  jitsiState?.minimizeMeetWindow
                    ? rootLayout_style.jitsiMeetMiniSx
                    : rootLayout_style.jitsiMeetMaxSx
                }
              >
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  sx={
                    jitsiState?.minimizeMeetWindow
                      ? rootLayout_style.jitsiGridContainerMiniSx
                      : rootLayout_style.jitsiGridContainerMaxSx
                  }
                >
                  <Grid item pb={1.5}>
                    <Button
                      startIcon={
                        !jitsiState?.minimizeMeetWindow ? (
                          <MinimizeIcon />
                        ) : (
                          <MaximizeIcon />
                        )
                      }
                      onClick={meetMinimize}
                      size="small"
                      buttonStyle={
                        jitsiState?.minimizeMeetWindow
                          ? rootLayout_style.jitsiButtonMiniSx
                          : rootLayout_style.jitsiButtonMaxSx
                      }
                    >
                      {!jitsiState?.minimizeMeetWindow
                        ? 'Minimize'
                        : 'Full screen'}
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={12} sx={{ pl: '0px' }}>
                    <JitsiMeetEmbed
                      minHeight="150px"
                      maxHeight="76vh"
                      patient
                      jitsiId={jitsiId}
                      apptId={jitsiState?.apptId}
                      onReadyToClose={meetClose}
                      roomId={jitsiState?.roomId}
                      minimizeMeetWindow={jitsiState?.minimizeMeetWindow}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
          {showNotifyCard && !jitsiState?.isStartCall && (
            <Box sx={rootLayout_style.boxSx}>
              {Array.isArray(appointmentState?.live_appointments) &&
                appointmentState?.live_appointments?.length > 0 &&
                appointmentState?.live_appointments.map((val, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <AppointmentNotifyCard
                    key={index}
                    totalBoxStyle={rootLayout_style.notifyCardSx}
                    dataKey={index}
                    data={val}
                    doctorName={`Dr. ${
                      val?.assigned_doctor_profile?.first_name ?? ''
                    }`}
                    src={val?.assigned_doctor_profile?.profile_pic ?? ''}
                    professional={
                      val?.assigned_doctor_profile?.doctor_specialities ?? []
                    }
                    type={val?.appType?.type}
                    min={
                      val?.appType?.type === 'upcoming'
                        ? val?.appType?.minutes
                        : 0
                    }
                    sec={
                      val?.appType?.type === 'upcoming'
                        ? // eslint-disable-next-line radix
                          parseInt(
                            val?.appType?.seconds?.toString().split('.')?.[0]
                          )
                        : 0
                    }
                    type_name={
                      val?.master_appointment_status?.appointment_status?.toLowerCase() ===
                      'timelapsed'
                        ? val?.master_appointment_status?.appointment_status
                        : 'Live Now'
                    }
                    button={
                      val?.appType?.type === 'upcoming'
                        ? 'disableJoin'
                        : val?.appType?.type === 'past'
                        ? 'past'
                        : 'enableJoin'
                    }
                    appointmentDate={val?.appointment_date_time}
                    appointmentStartTime={getSlotStartTime(
                      val?.appointment_date_time
                    )}
                    appointmentEndTime={getSlotEndTimeToString(
                      val?.appointment_date_time,
                      15
                    )}
                    onLivebtn={onLivebtn}
                    onClosebtn={onClosebtn}
                  />
                ))}
              {Array.isArray(appointmentState?.past_appointments) &&
                appointmentState?.past_appointments?.length > 0 &&
                appointmentState?.past_appointments.map((val, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Box key={index}>
                    {val?.master_appointment_status?.appointment_status ===
                      'InProgress' && (
                      <AppointmentNotifyCard
                        totalBoxStyle={rootLayout_style.notifyCardSx}
                        dataKey={index}
                        data={val}
                        doctorName={`Dr. ${
                          val?.assigned_doctor_profile?.first_name ?? ''
                        }`}
                        src={val?.assigned_doctor_profile?.profile_pic ?? ''}
                        professional={
                          val?.assigned_doctor_profile?.doctor_specialities ??
                          []
                        }
                        type={val?.appType?.type}
                        min={
                          val?.appType?.type === 'upcoming'
                            ? val?.appType?.minutes
                            : 0
                        }
                        sec={
                          val?.appType?.type === 'upcoming'
                            ? // eslint-disable-next-line radix
                              parseInt(
                                val?.appType?.seconds
                                  ?.toString()
                                  .split('.')?.[0]
                              )
                            : 0
                        }
                        type_name={
                          val?.master_appointment_status?.appointment_status?.toLowerCase() ===
                          'timelapsed'
                            ? val?.master_appointment_status?.appointment_status
                            : 'Live Now'
                        }
                        button={
                          val?.appType?.type === 'upcoming'
                            ? 'disableJoin'
                            : val?.appType?.type === 'past'
                            ? 'past'
                            : 'enableJoin'
                        }
                        appointmentDate={val?.appointment_date_time}
                        appointmentStartTime={getSlotStartTime(
                          val?.appointment_date_time
                        )}
                        appointmentEndTime={getSlotEndTimeToString(
                          val?.appointment_date_time,
                          15
                        )}
                        onLivebtn={onLivebtn}
                        onClosebtn={onClosebtn}
                      />
                    )}
                  </Box>
                ))}

              {Array.isArray(appointmentState?.payment_pending_appointments) &&
                appointmentState?.payment_pending_appointments?.length > 0 &&
                appointmentState?.payment_pending_appointments.map(
                  (val, index) => (
                    <AppointmentNotifyCard
                      key={index}
                      totalBoxStyle={rootLayout_style.notifyCardSx}
                      index={index}
                      data={val}
                      doctorName={`Dr. ${
                        val?.assigned_doctor_profile?.first_name ?? ''
                      }`}
                      src={val?.assigned_doctor_profile?.profile_pic ?? ''}
                      professional={
                        val?.assigned_doctor_profile?.doctor_specialities ?? []
                      }
                      type={val?.appType?.type}
                      type_name="paymentPending"
                      appointmentDate={val?.appointment_date_time}
                      appointmentStartTime={getSlotStartTime(
                        val?.appointment_date_time
                      )}
                      appointmentEndTime={getSlotEndTimeToString(
                        val?.appointment_date_time,
                        15
                      )}
                      onLivebtn={onLivebtn}
                      onClosebtn={onClosebtn}
                      handleResumeBooking={handleResumeBooking}
                    />
                  )
                )}

              {/* payment_pending_appointments */}
            </Box>
          )}
        </Box>
        {/* RESCHEDULE DRAWER */}
        {drawerOpen && (
          <Drawer
            show={drawerOpen}
            onCloseDrawer={() => setDrawerOpen(false)}
            footer={
              <>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button
                    buttonStyle={{
                      borderRadius: 2,
                      bgcolor: 'primary.main',
                      '&:hover': {
                        bgcolor: 'primary.main',
                      },
                    }}
                    onClick={() => reschedule()}
                  >
                    Yes, I want to reschedule
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                  <Button
                    buttonStyle={rootLayout_style.buttonSx}
                    onClick={() => setCancelDrawerOpen(true)}
                  >
                    No, I want to cancel
                  </Button>
                </Box>
              </>
            }
          >
            <Box sx={{ pt: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <SheduleAppointment />
                {/* <IconButton
                onClick={() => cancelAppointmentFnc()}
                sx={rootLayout_style.cancelSx}
              >
                {' '}
                <RiCloseLine
                  style={{
                    fontSize: '28px',
                  }}
                />
              </IconButton> */}
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Typography sx={rootLayout_style.subtextSx}>
                  You missed to join the consultation.
                </Typography>
                <Typography sx={rootLayout_style.prescriptionTextSx}>
                  But don&apos;t worry, all your information is stored safely
                  and book again now with the same doctor.
                </Typography>
                <Typography sx={rootLayout_style.subtextSx}>
                  Note: We couldn&apos;t process your refund.
                </Typography>
              </Box>
            </Box>
          </Drawer>
        )}
        {/* PAYMENT DRAWER */}
        {paymentDrawerOpen && (
          <Drawer
            show={paymentDrawerOpen}
            onCloseDrawer={() => setPaymentDrawerOpen(false)}
            footer={
              <>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button
                    buttonStyle={{
                      borderRadius: 2,
                      bgcolor: 'primary.main',
                      '&:hover': {
                        bgcolor: 'primary.main',
                      },
                    }}
                    loading={loading}
                    onClick={() => makePayment()}
                  >
                    Yes, I want to make payment
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                  <Button
                    buttonStyle={rootLayout_style.buttonSx}
                    disabled={loading === true}
                    onClick={() => setCancelDrawerOpen(true)}
                  >
                    No, I want to cancel
                  </Button>
                </Box>
              </>
            }
          >
            <Box sx={{ pt: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <SheduleAppointment />
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Typography sx={rootLayout_style.subtextSx}>
                  Your appointment payment is pending right now.
                </Typography>
                <Typography sx={rootLayout_style.prescriptionTextSx}>
                  {/* But don't worry, all your information is stored safely and book
                again now with the same doctor. */}
                </Typography>
                <Typography sx={rootLayout_style.subtextSx}>
                  {/* Note: We couldn't process your refund. */}
                </Typography>
              </Box>
            </Box>
          </Drawer>
        )}
        {/* APPOINTMENT SLOT SELECTION DRAWER */}
        {slotDrawerOpen && (
          <SlotDrawer
            anchor="bottom"
            sx={rootLayout_style.slotDrawerSx}
            open={slotDrawerOpen}
            onClose={goBack}
          >
            <Box px={2.5} py={2.5}>
              <AppointmentDateTimeSelection />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                p: 3,
                bgcolor: 'common.white',
                borderTop: '1.3px dashed',
                borderColor: 'border.main',
              }}
            >
              <Button
                buttonStyle={rootLayout_style.buttonSx}
                onClick={() => goBack()}
              >
                Go Back
              </Button>
              <Button
                buttonStyle={{
                  '&:hover': {
                    bgcolor: 'primary.main',
                  },
                }}
                onClick={() => freeConsultationCheck()}
              >
                Continue
              </Button>
            </Box>
          </SlotDrawer>
        )}
        {openfreeContract && (
          <FreeConsultation
            addDrawerOpen={openfreeContract}
            setAddDrawerOpen={setopenfreeContract}
            PaidConsultant={() => onNext('paid')}
            FreeConsultant={() => FreeConsultationNavFun()}
          />
        )}

        {/* CancelAppointment */}
        {cancelDrawerOpen && (
          <CancelAppointment
            onNext={() => cancelAppointmentFnc(false)}
            onBack={() => handleDrawerCancel(false)}
            key={appointmentState?.index}
            setDrawerOpen={handleDrawerCancel}
            drawerOpen={cancelDrawerOpen}
            ReasonForCancellation={ReasonForCancellation}
            value={appointmentState?.cancellationReason}
          />
        )}
        {/* Notification Popup */}
        {model && (
          <NotificationPopup
            open={model}
            subTitle={
              <div>
                Your appointment slot time is not available now. Please select
                some other slot and continue
              </div>
            }
            onDecline={() => setModel(false)}
            onAccpet={() => openDoctorDrawer()}
            acceptButtonName="Continue"
            declineButtonName="Cancel"
          />
        )}
        {loading_ && (
          <Loader rootStyle={{ display: 'flex', justifyContent: 'center' }} />
        )}
      </Box>
    </Box>
  );
}

export { ClinicalRootLayout };

