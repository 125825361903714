import { Button, CalenderIcon, ProfileIcon } from '@atoms';
import { camelCaseToTitleCase } from '@hc/utils/helperFunctions';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { RiMore2Fill } from 'react-icons/ri';
import { viewCard_style } from './style';

const typesFR = {
  // Green
  1: {
    color: 'secondary.main',
    backgroundColor: 'orangeTints.100',
  },
  // orange
  2: {
    color: 'primary.main',
    backgroundColor: 'primaryTints.100',
  },
};
const types = {
  // Green
  1: {
    color: 'primary.main',
    backgroundColor: 'primaryTints.100',
  },
  // orange
  2: {
    color: 'secondary.main',
    backgroundColor: 'orangeTints.100',
  },
  // Green
  3: {
    color: 'primary.main',
    backgroundColor: 'primaryTints.100',
  },
  // orange
  4: {
    color: 'secondary.main',
    backgroundColor: 'orangeTints.100',
  },
  // red
  5: {
    color: 'red.800',
    backgroundColor: 'red.50',
  },
  // red
  8: {
    color: 'red.800',
    backgroundColor: 'red.50',
  },
  // orange
  9: {
    color: 'secondary.main',
    backgroundColor: 'orangeTints.100',
  },
  // red
  10: {
    color: 'red.800',
    backgroundColor: 'red.50',
  },
  // Green
  11: {
    color: 'primary.main',
    backgroundColor: 'primaryTints.100',
  },
  // red
  12: {
    color: 'red.800',
    backgroundColor: 'red.50',
  },
};

export function ViewCard({
  isFollowUpReferral = false,
  data,
  app_id,
  doctor_id,
  event_id,
  src = '',
  UpdateTime = '',
  type = '',
  type_name = '',
  after = '',
  bookingText = '',
  disabled = false,
  handleClick,
  index,
  time,
  date,
  referredBy,
  doctorName = '',
  Professional = [],
  paitentName = '',
  self = '',
  className = '',
  profilepic = {},
  rootStyle = {},
  appointmentCardStyle = {},
  doctorNameStyle = {},
  typeStyle = {},
  bookReferralFnc,
  ...rest
}) {
  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={{ ...viewCard_style.rootSx, ...rootStyle }}>
        {/* Doctor & his Profession */}
        <Box p={1.5}>
          <Box
            sx={{
              ...viewCard_style.appointmentCardSx,
              ...appointmentCardStyle,
            }}
          >
            <Box sx={{ ...viewCard_style.doctornameSx, ...doctorNameStyle }}>
              <Avatar
                src={src}
                sx={{ ...viewCard_style.profilepicSx, ...profilepic }}
              />
              <Box sx={{ px: 1 }}>
                <Typography variant="body2" sx={{ fontWeight: '600' }}>
                  {`Dr. ${doctorName}`}
                </Typography>
                <Box sx={viewCard_style.professionalContainSx}>
                  {Array.isArray(Professional) &&
                    Professional?.length > 0 &&
                    Professional.map((val, i) => (
                      <Typography key={i} sx={viewCard_style.professionalSx}>
                        • {val?.master_speciality?.s ?? ''}&nbsp;
                      </Typography>
                    ))}
                  {/* {Professional} */}
                </Box>
              </Box>
            </Box>
            <Box sx={{ ...viewCard_style.typeSx, ...typeStyle }}>
              {UpdateTime ? (
                <Typography
                  variant="caption"
                  sx={{
                    ...viewCard_style.referchangingSx,
                    color: 'orangeTints.600',
                    backgroundColor: 'orangeTints.50',
                  }}
                >
                  {after}
                </Typography>
              ) : (
                <Typography
                  variant="caption"
                  sx={
                    isFollowUpReferral
                      ? {
                          ...viewCard_style.referchangingSx,
                          ...typesFR[type],
                        }
                      : {
                          ...viewCard_style.referchangingSx,
                          ...types[type],
                        }
                  }
                >
                  {camelCaseToTitleCase(type_name)}
                </Typography>
              )}
              <IconButton
                sx={{ p: 0 }}
                disableRipple
                onClick={(e) =>
                  handleClick(
                    e,
                    index,
                    app_id,
                    doctor_id,
                    event_id,
                    isFollowUpReferral,
                  )
                }
              >
                <RiMore2Fill
                  style={{
                    fontSize: '20px',
                    color: 'grey.800',
                    height: '18px',
                    '&:hover': {
                      backgroundColor: 'common.white',
                    },
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          {/* DateTime & Booking */}
          <Box sx={viewCard_style.appointmentDocCardSx}>
            <Box>
              <Box sx={viewCard_style.patientAndDateSx}>
                <ProfileIcon />
                <Box sx={{ px: 1 }}>
                  <Typography sx={viewCard_style.selfSx}>
                    {paitentName}
                    {self && <span>{`(${self})`}</span>}
                  </Typography>
                </Box>
              </Box>
              <Box sx={viewCard_style.selfSx}>
                <CalenderIcon />
                <Box sx={{ px: 1 }}>
                  {date ? (
                    <Typography sx={viewCard_style.selfSx}>
                      {date}
                      <span>{time}</span>
                    </Typography>
                  ) : (
                    <Typography sx={viewCard_style.selfSx}>Any time</Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box>
              <Button
                size="medium"
                buttonStyle={{
                  ...viewCard_style.btnSx,
                }}
                disabled={disabled}
                onClick={() =>
                  bookingText === 'Book' && isFollowUpReferral
                    ? bookReferralFnc(data)
                    : null
                }
              >
                {bookingText}
              </Button>
            </Box>
          </Box>
        </Box>
        {referredBy && (
          <Box sx={{ width: '100%', height: '30px', bgcolor: '#CCF3EC' }}>
            <Typography mt={1} textAlign="center" fontSize={12}>
              Referred By Dr.{referredBy}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

ViewCard.propTypes = {
  src: PropTypes.object,
  UpdateTime: PropTypes.string,
  type: PropTypes.string,
  type_name: PropTypes.string,
  after: PropTypes.string,
  bookingText: PropTypes.string,
  time: PropTypes.string,
  date: PropTypes.string,
  doctorName: PropTypes.string,
  paitentName: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.object,
  className: PropTypes.node,
  sx: PropTypes.object,
  isFollowUpReferral: PropTypes.bool,
};
