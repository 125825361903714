import { Screenlayout } from '@hc/ui/components/screenLayout';
import { Box, Grid, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCards } from 'swiper/modules';
import 'swiper/css/effect-cards';
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { useAwareness } from '@hc/store/clinical/awareness';
import { Loader } from '@hc/ui/atoms';
import { queryClient } from '@hc/utils';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';

import { viewAwareness_style } from './style';

export default function ViewAwareness() {
  const location = useLocation();

  const { awarenessState, getAwarenessContentDataById, loading } = useAwareness(
    (state) => ({
      awarenessState: state.awarenessState,
      getAwarenessContentDataById: state.getAwarenessContentDataById,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { awarenessContentData } = awarenessState;
  const { name, content } = awarenessContentData;

  const getInitialData = async (id) => {
    queryClient.invalidateQueries({
      queryKey: ['getAwarenessContentDataById'],
    });
    await getAwarenessContentDataById(id);
  };

  // GET AWARENESS CONTENT DATA
  useEffect(() => {
    if (location?.state?.id) {
      getInitialData(location?.state?.id);
    }
  }, [location?.state]);

  return (
    <Box sx={viewAwareness_style.rootSx}>
      <Screenlayout
        appBarStyle={{
          bgcolor: '#239179',
          borderBottom: '1px solid #3D4690',
        }}
        backIconStyle={{ color: '#3D4690' }}
        masterchildStyle={{ pb: 0 }}
        title={name ?? 'Awareness'}
        notshowFooter
        backRequired="/"
        childrenStyle={{
          minHeight: '100vh',
          backgroundColor: '#EEF3F9',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ pt: 2, pb: 2 }}>
          <Box sx={viewAwareness_style.boxRootSx}>
            {/* EmptyState Design */}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              height="80vh"
              sx={{
                '& .swiper': {
                  width: '270px',
                  height: '450px',
                },
                '& .swiper-3d .swiper-slide-shadow': {
                  background: 'none',
                },
                '& .swiper-slide': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '10px',
                  fontSize: '22px',
                  fontWeight: 'bold',
                  color: '#fff',
                  backgroundColor: '#fff',
                  boxShadow: '0px 0px 10px #00000029',
                },
              }}
            >
              <Swiper
                effect="cards"
                grabCursor
                modules={[EffectCards]}
                className="mySwiper"
                loop
              >
                {Array.isArray(content) && content?.length > 0 ? (
                  content.map((val, i) => (
                    <SwiperSlide key={i}>
                      <Box>
                        <Typography sx={viewAwareness_style.slidertitleSx}>
                          {val.title}
                        </Typography>
                        <Box
                          sx={{
                            height: '200px',
                            overflow: 'scroll',
                            cursor: 'pointer',
                          }}
                        >
                          <Typography sx={viewAwareness_style.subtitleSx}>
                            {val.content}
                          </Typography>
                        </Box>
                        <Typography
                          sx={viewAwareness_style.paginationSx}
                          mt={12}
                        >
                          {i + 1}/{content?.length}
                        </Typography>
                      </Box>
                    </SwiperSlide>
                  ))
                ) : (
                  <Typography
                    textAlign="center"
                    py={15}
                    color="#616161"
                    fontWeight={600}
                  >
                    No Content Data Found
                  </Typography>
                )}
              </Swiper>
            </Grid>
          </Box>
        </Box>
        {/* Loader */}
        {loading && <Loader rootStyle={{ py: 30 }} />}
      </Screenlayout>
    </Box>
  );
}
