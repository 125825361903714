import {
  CalenderIcon,
  CougeIcon,
  Document,
  HeartIcon,
  ClockIcon,
  HeartPulse,
  MicroIcon,
  PainIcon,
  PillIcon,
  UserIcon,
  VideoIcon,
} from '@atoms';
import { AppointmentChips } from '@components/appointmentChips';
import { AppointmentDetails } from '@components/appointmentDetails';
import { AppointmentList } from '@components/appointmentList';
import {
  AppointmentCard,
  AppointmentShedule,
  DoctorProfile,
} from '@components/clinical';
import { MedicationDetails } from '@components/medicationDetails';
import { ReportDetails } from '@components/reportDetails';
import { VitalDetails } from '@components/vitalDetails';
import { getDateFormat } from '@hc/dayjs';
import { useBookAppointment, useReport } from '@hc/store';
import { Box, Divider } from '@mui/material';
import { clinicalRoutes } from '@hc/routes';
import PropTypes from 'prop-types';
import { React } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';

import { appointmentSummary_style } from './style';

export function AppointmentSummary(props) {
  const { className = '', ...rest } = props;

  const navigate = useNavigate();

  // const authToken = localStorage.getItem(localStorageKeys.authToken);
  // const data = parseJwt(authToken);

  const { bookAppointmentState } = useBookAppointment(
    (state) => ({
      bookAppointmentState: state.bookAppointmentState,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { vitals } = bookAppointmentState;

  const { reportState } = useReport(
    (state) => ({
      reportState: state.reportState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  // CONSTRUCT VITALS DATA
  const constructVitalData = [
    {
      value: vitals?.height_in_cm,
      unit: 'cm',
      name: '(Height)',
    },
    {
      value: vitals?.weight_in_cm,
      unit: 'Kg',
      name: '(Weight)',
    },
    {
      value: vitals?.bmi_in_kg_m2,
      unit: 'kg/m2',
      name: '(BMI)',
    },
    {
      value: vitals?.temp_in_f,
      unit: 'F',
      name: '(Temp)',
    },
    {
      value: vitals?.puls_bpm,
      unit: 'Bpm',
      name: '(Pulse)',
    },
    {
      value: vitals?.blood_pressure,
      unit: 'Mmhg',
      name: 'BP',
    },
    {
      value: vitals?.o2_level,
      unit: '%',
      name: 'SPO2',
    },
  ].filter((val) => val?.value);

  // EDIT FUNCTION
  const editOnclick = (route) => {
    navigate(clinicalRoutes?.[route], {
      state: { prevRoute: clinicalRoutes.appointmentSummary },
    });
  };

  return (
    <Box
      sx={appointmentSummary_style.rootSx}
      className={`${className}`}
      {...rest}
    >
      <AppointmentCard
        header={
          <DoctorProfile
            src={bookAppointmentState?.doctorDetail?.profilePic ?? ''}
            doctorName={`Dr. ${bookAppointmentState?.doctorDetail?.name ?? ''}`}
            pricingRequired={
              bookAppointmentState?.doctorDetail?.appointment_charges
                ?.charge_in_rupees ?? ''
            }
            nameStyle={appointmentSummary_style.nameStyle}
            apointmentCardstyle={appointmentSummary_style.nameStyle}
          />
        }
        footer={
          <>
            <AppointmentShedule
              icon={<CalenderIcon rootStyle={{ color: '#000' }} />}
              dateText="Date"
              // yearText="09 Apr,2003"
              yearText={
                bookAppointmentState?.selectedDate
                  ? getDateFormat(
                      bookAppointmentState?.selectedDate,
                      'DD MMM,YYYY',
                    )
                  : ''
              }
              timeText="Time"
              timeIcon={<ClockIcon rootStyle={{ color: '#000' }} />}
              // timingClock="09:15 AM"
              timingClock={bookAppointmentState?.selectedSlot ?? ''}
              videoIcon={<VideoIcon />}
              confirmed
              // videoIconStyle={videoIconSx}
            />
            <Divider sx={appointmentSummary_style.dividerSx} />
            <AppointmentDetails
              // eslint-disable-next-line react/jsx-boolean-value
              expanded={true}
              heading="patient details"
              // content="Sarveshwara Narayanan"
              content={
                bookAppointmentState?.booking_method === 'myself' ||
                bookAppointmentState?.booking_method === 'family'
                  ? bookAppointmentState?.patientDetails?.name ?? ''
                  : bookAppointmentState?.booking_method === 'others'
                  ? bookAppointmentState?.booking_for?.name ?? ''
                  : ''
              }
              // number="9998798866"
              number={
                bookAppointmentState?.booking_method === 'myself' ||
                bookAppointmentState?.booking_method === 'family'
                  ? bookAppointmentState?.patientDetails?.country_code &&
                    bookAppointmentState?.patientDetails?.mobile_no
                    ? bookAppointmentState?.patientDetails?.country_code +
                        bookAppointmentState?.patientDetails?.mobile_no ?? ''
                    : ''
                  : bookAppointmentState?.booking_method === 'others'
                  ? bookAppointmentState?.booking_for?.mobileCode &&
                    bookAppointmentState?.booking_for?.mobile
                    ? `${bookAppointmentState?.booking_for?.mobileCode} ${bookAppointmentState?.booking_for?.mobile}` ??
                      ''
                    : ''
                  : ''
              }
              email={
                bookAppointmentState?.booking_method === 'myself' ||
                bookAppointmentState?.booking_method === 'family'
                  ? bookAppointmentState?.patientDetails?.email_id
                    ? bookAppointmentState?.patientDetails?.email_id
                    : ''
                  : bookAppointmentState?.booking_method === 'others'
                  ? bookAppointmentState?.booking_for?.email
                    ? bookAppointmentState?.booking_for?.email
                    : ''
                  : ''
              }
              // label="Male,28years"
              label={
                bookAppointmentState?.booking_method === 'myself' ||
                bookAppointmentState?.booking_method === 'family'
                  ? bookAppointmentState?.patientDetails?.gender &&
                    bookAppointmentState?.patientDetails?.age
                    ? `${bookAppointmentState?.patientDetails?.gender}, ${bookAppointmentState?.patientDetails?.age}` ??
                      ''
                    : ''
                  : bookAppointmentState?.booking_method === 'others'
                  ? bookAppointmentState?.booking_for?.gender &&
                    `${bookAppointmentState?.booking_for?.age} Yrs`
                    ? `${bookAppointmentState?.booking_for?.gender}, ${bookAppointmentState?.booking_for?.age} Yrs` ??
                      ''
                    : ''
                  : ''
              }
              icon={<UserIcon />}
              editOnclick={() =>
                editOnclick(
                  bookAppointmentState?.booking_method === 'others'
                    ? 'patientDetails'
                    : 'selectFamilyMember',
                )
              }
              myself={bookAppointmentState?.booking_method === 'myself'}
            />
            <Divider sx={appointmentSummary_style.dividerSx} />
            <AppointmentList
              heading="health issues"
              list={bookAppointmentState?.health_issues}
              icon={<HeartPulse />}
              editOnclick={() => editOnclick('healthIssues')}
              noDataFound="No health issues added."
            />
            <Divider sx={appointmentSummary_style.dividerSx} />
            <AppointmentChips
              heading="symptoms"
              list={bookAppointmentState?.symptoms}
              icon={<CougeIcon />}
              editOnclick={() => editOnclick('symptoms')}
              noDataFound="No symptoms added."
            />
            <Divider sx={appointmentSummary_style.dividerSx} />
            <AppointmentChips
              heading="problems"
              list={bookAppointmentState?.health_problems}
              icon={<PainIcon />}
              editOnclick={() => editOnclick('healthProblems')}
              noDataFound="No problems added."
            />
            <Divider sx={appointmentSummary_style.dividerSx} />
            <AppointmentChips
              heading="allergies"
              list={bookAppointmentState?.allergies}
              icon={<MicroIcon />}
              editOnclick={() => editOnclick('allergies')}
              noDataFound="No allergies added."
            />
            <Divider sx={appointmentSummary_style.dividerSx} />
            <MedicationDetails
              heading="active medications"
              content={bookAppointmentState?.drugs}
              icon={<PillIcon />}
              editOnclick={() => editOnclick('medications')}
              noDataFound="No medications added."
            />
            <Divider sx={appointmentSummary_style.dividerSx} />
            <ReportDetails
              heading="reports"
              // content={bookAppointmentState?.reports}
              newReportCount={
                // bookAppointmentState?.reports &&
                // Array.isArray(bookAppointmentState?.reports) &&
                // bookAppointmentState?.reports?.length > 0
                //   ? bookAppointmentState?.reports?.length
                //   : 0
                reportState?.uploadedReportId &&
                reportState?.uploadedReportId?.length > 0
                  ? reportState?.uploadedReportId?.length
                  : 0
              }
              customizeReportCount={
                (reportState?.uploadReportsCopy?.length -
                  reportState?.deselectedReports?.uploadedId?.length >
                0
                  ? reportState?.uploadReportsCopy?.length -
                    reportState?.deselectedReports?.uploadedId?.length
                  : 0) +
                (reportState?.caseSummaryReportsCopy?.length -
                  reportState?.deselectedReports?.caseSummaryId?.length >
                0
                  ? reportState?.caseSummaryReportsCopy?.length -
                    reportState?.deselectedReports?.caseSummaryId?.length
                  : 0)
              }
              count={
                (reportState?.uploadReportsCopy?.length -
                  reportState?.deselectedReports?.uploadedId?.length >
                0
                  ? reportState?.uploadReportsCopy?.length -
                    reportState?.deselectedReports?.uploadedId?.length
                  : 0) +
                (reportState?.caseSummaryReportsCopy?.length -
                  reportState?.deselectedReports?.caseSummaryId?.length >
                0
                  ? reportState?.caseSummaryReportsCopy?.length -
                    reportState?.deselectedReports?.caseSummaryId?.length
                  : 0) +
                (bookAppointmentState?.reports &&
                Array.isArray(bookAppointmentState?.reports) &&
                bookAppointmentState?.reports?.length > 0
                  ? bookAppointmentState?.reports?.length
                  : 0)
              }
              icon={<Document />}
              editOnclick={() => editOnclick('uploadReports')}
              noDataFound="No reports added."
              isEditIcon
            />
            <Divider sx={appointmentSummary_style.dividerSx} />
            <VitalDetails
              heading="vitals"
              content={constructVitalData}
              icon={<HeartIcon />}
              editOnclick={() => editOnclick('vitalsForm')}
              noDataFound="No vitals added."
            />
          </>
        }
      />
    </Box>
  );
}

AppointmentSummary.propTypes = {
  bookAppointmentState: PropTypes.object,
  constructVitalData: PropTypes.array,
  className: PropTypes.node,
  sx: PropTypes.object,
};
