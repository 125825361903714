/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import FollowUp from '@assets/followUp.svg';
import HC_Banner_1 from '@assets/HC_Banner_1.png';
import HC_Banner_2 from '@assets/HC_Banner_2.png';
import HC_Banner_3 from '@assets/HC_Banner_3.png';
import { envConfig } from '@hc/config';
import {
  getDateFormat,
  getDaysOfInterval,
  getEndOfWeek,
  getStartOfWeek,
  getTimeFormat,
  getTimeZoneFnc,
  getTodayDate,
  isAfterFns
} from '@hc/dayjs';
import { clinicalRoutes } from '@hc/routes';
import {
  useAppointment,
  useBookAppointment,
  useChatBot,
  useNotification,
  usePrescription,
  useProfile,
  useReport
} from '@hc/store';
import { useJitsiEmbed } from '@hc/store/clinical/jitsiEmbed';
import { usePWANotificationEnabler } from '@hc/store/clinical/pwaNotificationPrompt';
import {
  Animate,
  AppointmentIcon,
  AwarenessManagementIcon,
  Button,
  CalenderPlusIcon,
  CancelAppointmentIcon,
  CancelCalenderIcon,
  CancelIcon,
  ChatBotChatIcon,
  ClinicalHomeArrowIcon,
  ClinicalHomePageChatIcon,
  ClinicalHomePageHeathCheckupIcon,
  ClinicalHomePageStethoscopeIcon,
  ClinicalHomePageVitalIcon,
  Drawer,
  IconText,
  MyFamilyicon,
  NoActivePrescriptionIcon,
  ScheduleIcon
} from '@hc/ui/atoms';
import {
  AppointmentDateTimeSelection,
  ClinicalHomeUtilsCard,
  FreeConsultation,
  PrescriptionCard,
  PWANotificationPrompt,
  SkeletonCard,
  UpgradedCard
} from '@hc/ui/components';
import { ClinicalLayout, ViewCard } from '@hc/ui/components/clinical';
import { CancelAppointment } from '@hc/ui/components/clinical/appointmentBooking/cancelAppointment';
import { queryClient } from '@hc/utils';
import { localStorageKeys } from '@hc/utils/constants';
import {
  doctorAvailablePayload,
  IsBookButtonEnable,
  parseJwt,
  timeDiffCalc
} from '@hc/utils/helperFunctions';
import {
  Avatar,
  Box,
  Drawer as SlotDrawer,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { razorpayCall } from 'crayond_payment_gateway';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { version } from '../../../package.json';
import { homeScreen_style } from './style';

// import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
export default function Home(props) {
  const {
    doctorName = 'Dr. Mahesh Gokulnath',
    role = 'General Physician',
    className = '',
    ...rest
  } = props;
  const [searchParams] = useSearchParams();
  const authToken = localStorage.getItem(localStorageKeys?.authToken);
  const data = parseJwt(authToken);
  // General Hooks
  const [help, setHelp] = useState();
  const [drawerOpen, setDrawerOpen] = useState();

  const [anchorElCancel, setAnchorElCancel] = useState(null);
  const [anchorElRemove, setAnchorElRemove] = useState(null);
  const [cancelDrawerOpen, setCancelDrawerOpen] = useState(false);
  const [removeDrawerOpen, setRemoveDrawerOpen] = useState(false);

  const [UpgradedCardopen, setUpgradedCard] = useState(false);

  const [slotDrawerOpen, setSlotDrawerOpen] = useState(false);

  const [loading_, setLoading] = useState(false);
  const [isReferral, setIsReferral] = useState(false);
  const [appoinmentDetail, setAppoinmentDetail] = useState();

  const matches = useMediaQuery('(min-width:410px)');
  const navigate = useNavigate();

  const [openfreeContract, setopenfreeContract] = useState(false);

  const tokenData_ = localStorage.getItem(localStorageKeys.authToken);
  const parseTokenData = parseJwt(tokenData_);

  const {
    profile,
    get,
    refresh,
    getCorporateUserData,
    loading: profileloading,
  } = useProfile(
    (state) => ({
      profile: state.profile,
      get: state.get,
      refresh: state.refresh,
      getCorporateUserData: state.getCorporateUserData,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const {
    pwaNotificationChecker,
    isPwa,
    deferredPrompt,
    setDeferredPrompt,
    notificationStatus,
    showPWANotificationPrompt,
    setShowPWANotificationPrompt,
    showedFirstTime,
    updatePwaNotificationStatus,
    loading: pwaNotificationCheckerLoading,
  } = usePWANotificationEnabler(
    (state) => ({
      pwaNotificationChecker: state.pwaNotificationChecker,
      updatePwaNotificationStatus: state.updatePwaNotificationStatus,
      isPwa: state.isPwa,
      loading: state.loading,
      deferredPrompt: state.deferredPrompt,
      setDeferredPrompt: state.setDeferredPrompt,
      showedFirstTime: state.showedFirstTime,
      notificationStatus: state.notificationStatus,
      showPWANotificationPrompt: state.showPWANotificationPrompt,
      setShowPWANotificationPrompt: state.setShowPWANotificationPrompt,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { jitsiState, onCallUpdate } = useJitsiEmbed(
    (state) => ({
      jitsiState: state.jitsiState,
      // jitsiState: state.onMeetlinkUpdate,
      onCallUpdate: state.onCallUpdate,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  // const { meetLink, isStartCall } = jitsiState;

  const {
    appointmentState,
    getPastUpcomingAppointmentList,
    getFollowupReferralAppointmentList,
    cancelAppointment,
    removeAppointment,
    appointmentDrawerDataClear,
    updateAppointmentsData,
    appointmentStateUpdate,
    appoinmentLoader,
  } = useAppointment(
    (state) => ({
      appointmentState: state.appointmentState,
      getPastUpcomingAppointmentList: state.getPastUpcomingAppointmentList,
      getFollowupReferralAppointmentList:
        state.getFollowupReferralAppointmentList,
      cancelAppointment: state.cancelAppointment,
      // getActivePrescription: state.getActivePrescription,
      appointmentDrawerDataClear: state.appointmentDrawerDataClear,
      removeAppointment: state.removeAppointment,
      updateAppointmentsData: state.updateAppointmentsData,
      appointmentStateUpdate: state.appointmentStateUpdate,
      appoinmentLoader: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { appointment_data } = appointmentState;

  const { clearReportState } = useReport(
    (state) => ({
      clearReportState: state.clearReportState,
    }),
    (prev, curr) => {
      const data = isEqual(prev, curr);
      return false;
    },
  );

  const paymentType = JSON.parse(
    JSON.stringify(localStorage.getItem(localStorageKeys.appoinmentType)),
  );

  const {
    getAllPrescriptionsData,
    getPrescriptionData,
    allPrescriptionsData,
    prescriptionsLoading,
  } = usePrescription(
    (state) => ({
      getAllPrescriptionsData: state.getAllPrescriptionsData,
      getPrescriptionData: state.getPrescriptionData,
      allPrescriptionsData: state.allPrescriptionsData,
      prescriptionsLoading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const {
    bookAppointmentState,
    clearBookAppointmentState,
    getDoctorsList,
    getDoctorData,
    setStartEndBetween,
    constructSlotDataState,
    followUpAppointmentFnc,
    updateBAState,
    updateDoctorDetail,
    updateProfileData,
    updatePaymentStatusDetails,
  } = useBookAppointment(
    (state) => ({
      updateProfileData: state.updateProfileData,
      getDoctorData: state.getDoctorData,
      bookAppointmentState: state.bookAppointmentState,
      clearBookAppointmentState: state.clearBookAppointmentState,
      getDoctorsList: state.getDoctorsList,
      setStartEndBetween: state.setStartEndBetween,
      constructSlotDataState: state.constructSlotDataState,
      followUpAppointmentFnc: state.followUpAppointmentFnc,
      updateBAState: state.updateBAState,
      updateDoctorDetail: state.updateDoctorDetail,
      updatePaymentStatusDetails: state.updatePaymentStatusDetails,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { clearChatBotState } = useChatBot(
    (state) => ({
      clearChatBotState: state.clearChatBotState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { notificationState, softDeleteNotification } = useNotification(
    (state) => ({
      notificationState: state.notificationState,
      softDeleteNotification: state.softDeleteNotification,
    }),
    (prev, curr) => isEqual(prev, curr),
  );
  const { notificationData } = notificationState;

  const getInitialData = async () => {
    await get();
    await getPastUpcomingAppointmentList(data?.user_id, true);
    await getFollowupReferralAppointmentList(data?.user_id);
    getAllPrescriptionsData();
  };

  const onChatBotClick = () => {
    clearChatBotState();
    navigate(clinicalRoutes.chatBot);
  };
  // const handleDrawerOpen = () => {
  //   setDrawerOpen(true);
  // };
  const onBack = () => {
    navigate(clinicalRoutes?.home);
    setHelp(false);
  };
  const onNext = () => {
    navigate(clinicalRoutes?.home);
  };
  const onCancel = () => {
    setDrawerOpen(false);
  };
  const onRemove = () => {
    navigate(clinicalRoutes?.home);
    setDrawerOpen(false);
  };

  const clearBookStore = () => {
    clearBookAppointmentState();
    localStorage.removeItem(localStorageKeys?.bookAppointmentLocalStorage);
    localStorage.removeItem(localStorageKeys?.responseAppointmentId);
  };

  const onHomeClick = () => {
    if (appointmentState?.payment_pending_appointments?.length > 0) {
      toast.error(
        'Your Payment is pending, please proceed the payment process to book new appointment!',
      );
    } else {
      clearReportState();
      clearBookStore();
      navigate(clinicalRoutes.selectPatient);
    }
  };

  const onVitalClick = () => {
    navigate(clinicalRoutes.vitalMonitoring);
  };

  const homePageUtils = [
    {
      bgColor: '#F3F0FF',
      iconBgColor: '#E5E0FF',
      leftIcon: <ClinicalHomeArrowIcon bgColor="#F3F0FF" fill="#5e47d5" />,
      rightIcon: <ClinicalHomePageStethoscopeIcon />,
      title: 'Book Appointment',
      onClickVal: onHomeClick,
    },
    {
      bgColor: '#FEF9E0',
      iconBgColor: '#F5EFC9',
      leftIcon: <ClinicalHomeArrowIcon bgColor="#FEF9E0" fill="#c2ad28" />,
      rightIcon: <ClinicalHomePageChatIcon />,
      title: 'Chat with doctor',
      onClickVal: '',
    },
    {
      bgColor: '#EBF6FF',
      iconBgColor: '#D4ECFF',
      leftIcon: <ClinicalHomeArrowIcon bgColor="#EBF6FF" fill="#2279bc" />,
      rightIcon: <ClinicalHomePageHeathCheckupIcon />,
      title: 'Self health',
      onClickVal: '',
    },
    {
      bgColor: '#E4FFE5',
      iconBgColor: '#D3F7D4',
      leftIcon: <ClinicalHomeArrowIcon bgColor="#E4FFE5" fill="#35c03a" />,
      rightIcon: <ClinicalHomePageVitalIcon />,
      title: 'Vital Monitoring',
      onClickVal: onVitalClick,
    },
  ];

  const options = [
    {
      invisible: !(appointmentState?.upcoming_appointments?.length > 0),
      name: 'Appointments',
      icon: <AppointmentIcon />,
      path: clinicalRoutes.myAppointments,
      propValue: false,
    },
    {
      invisible: true,
      name: 'Reports',
      icon: <ScheduleIcon />,
      path: clinicalRoutes.reports,
      propValue: false,
    },
    {
      invisible: true,
      name: 'Awareness',
      icon: (
        <Box sx={{ background: '#fff6f0', p: 2.4 }}>
          {' '}
          <AwarenessManagementIcon
            rootStyle={{ color: '#eb6d13', width: '22px', height: '22px' }}
          />{' '}
        </Box>
      ),
      path: clinicalRoutes.awarenesspart,
      propValue: false,
    },
    {
      invisible: true,
      name: 'My Family',
      icon: <MyFamilyicon />,
      path: clinicalRoutes.myFamily,
      propValue: false,
    },
  ];

  const carouselOptions = [
    {
      image: HC_Banner_1,
    },
    {
      image: HC_Banner_2,
    },
    {
      image: HC_Banner_3,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const handleDrawerCancel = (val) => {
    // APPOINTMENT DRAWER DATA CLEAR
    appointmentDrawerDataClear();
    setCancelDrawerOpen(val);
  };

  const handleDrawerRemove = (val) => {
    // APPOINTMENT DRAWER DATA CLEAR
    appointmentDrawerDataClear();
    setRemoveDrawerOpen(val);
  };

  const handleDrawerCancelWithApp = (val) => {
    setCancelDrawerOpen(val);
  };

  const handleDrawerRemoveWithApp = (val) => {
    setRemoveDrawerOpen(val);
  };

  const cancelAppointmentFnc = async () => {
    const payload = {
      appointed_doctor_id: appointmentState?.appointed_doctor_id ?? '',
      appointment_id: appointmentState?.appointment_id ?? '',
      cancellationReason: appointmentState?.cancellationReason ?? '',
      event_id: appointmentState?.event_id ?? null,
      canceledBy: data?.user_id,
    };
    // CANCEL APPOINTMENT SERVICE CALL
    const response = await cancelAppointment(payload);
    if (response?.data?.status?.code === 200) {
      toast.success(
        response?.data?.status?.message ??
          `Appointment cancelled successfully.`,
      );
      updateAppointmentsData();
      handleDrawerCancel(false);
      getInitialData();
    } else {
      return toast.error(
        response?.data?.status?.message ??
          response?.data?.data?.message ??
          'Something went wrong please try again!',
      );
    }
  };

  const removeAppointmentFnc = async () => {
    const payload = {
      user_id: data?.user_id,
      appointment_id: appointmentState?.appointment_id ?? '',
      is_delete: true,
    };
    // CANCEL APPOINTMENT SERVICE CALL
    const response = await removeAppointment(payload);
    if (response?.data?.status?.code === 200) {
      toast.success(
        response?.data?.status?.message ?? `Appointment removed successfully.`,
      );
      updateAppointmentsData();
      handleDrawerRemove(false);
      getInitialData();
    } else {
      return toast.error(
        response?.data?.status?.message ??
          response?.data?.data?.message ??
          'Something went wrong please try again!',
      );
    }
  };

  const handleClick = (
    event,
    index,
    app_id,
    doctor_id,
    event_id,
    isFollowUpReferral,
  ) => {
    if (isFollowUpReferral) {
      if (anchorElRemove !== event.currentTarget) {
        setAnchorElRemove(event.currentTarget);
      }
      if (app_id) {
        updateAppointmentsData(index, app_id, doctor_id, event_id);
      } else {
        updateAppointmentsData();
      }
    } else {
      if (anchorElCancel !== event.currentTarget) {
        setAnchorElCancel(event.currentTarget);
      }
      if (app_id) {
        updateAppointmentsData(index, app_id, doctor_id, event_id);
      } else {
        updateAppointmentsData();
      }
    }
  };

  const handleCancelClose = () => {
    setAnchorElCancel(null);
  };

  const handleRemoveClose = () => {
    setAnchorElRemove(null);
  };

  const ReasonForCancellation = (event) => {
    // APPOINTMENT STATE UPDATE
    appointmentStateUpdate('cancellationReason', event?.target.value);
  };

  function enableNotification() {
    import('@hc/store').then((modules) => {
      const { enableNotification } = modules.useFirebase.getState();
      enableNotification(data?.user_id).then((state) => {
        updatePwaNotificationStatus(isPwa, state);
      });
    });
  }

  function handleInstallClick() {
    // Show the system install prompt

    if (!deferredPrompt)
      toast(
        'Click the Add to Home screen in the browser settings to get an app like experience',
      );
    deferredPrompt && deferredPrompt.prompt();

    // Wait for the user to respond to the prompt
    deferredPrompt?.userChoice &&
      deferredPrompt?.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          toast.success('App will be added to your home screen soon...');
        } else {
          toast('Get an app like experience by installing it!');
        }

        // Reset the deferredPrompt variable
        setDeferredPrompt(null);
      });
  }

  // CONSTRUCT SLOT DATA
  const constructSlotData = (seDate, value) => {
    constructSlotDataState(seDate, value);
  };

  // SELECT DATE FUNCTION
  const selectDate = async (val) => {
    const selectedDat = getDateFormat(val, 'YYYY-MM-DD');
    // UPDATE STATE
    updateBAState('selectedDate', selectedDat);

    const bookAppointmentStateCopy = JSON.parse(
      JSON.stringify(bookAppointmentState),
    );
    const doctorDetail_ =
      await bookAppointmentStateCopy?.doctorAvailableDetailsCopy?.filter(
        (val) => val?.id === bookAppointmentStateCopy?.doctorDetail?.id,
      )?.[0];

    constructSlotData(selectedDat, doctorDetail_);
  };

  // GET DOCTOR LIST FUNCTION
  const getDoctorsListFunction = async (start, value) => {
    const payload = {
      ...doctorAvailablePayload(start),
      search: '',
      languages: [],
      speciality: '',
      id: value?.assigned_doctor ?? '',
    };
    const response = await getDoctorData(payload);
    const selectedDat = value?.appointment_date ?? '';
    constructSlotData(selectedDat, response);
  };

  const getInitialDoctorData = async (date, value) => {
    const start = date
      ? getStartOfWeek(new Date(date))
      : getStartOfWeek(new Date());
    const end = date ? getEndOfWeek(new Date(date)) : getEndOfWeek(new Date());
    const days = getDaysOfInterval(start, end);
    // GET DOCTOR LIST FUNCTION
    await getDoctorsListFunction(start, value);
    // SET START_OF_WEEK, END_OF_WEEK & BETWEEN_DATES IN STORE
    await setStartEndBetween(start, end, days);
  };

  const bookReferralFnc = async (value) => {
    if (!value?.is_patient_referral) {
      setIsReferral(value?.appointment_type_id === 1 ? true : false);
      const followup_date = isAfterFns(value?.appointment_date)
        ? value?.appointment_date
        : getTodayDate();

      appointmentStateUpdate('appointment_id', value?.id);
      appointmentStateUpdate('appointment_data', value);
      await getInitialDoctorData(followup_date, value);

      const selectedDate = followup_date;
      selectDate(selectedDate);
      setSlotDrawerOpen(true);
    } else {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const data = parseJwt(authToken);
      localStorage.removeItem(localStorageKeys?.bookAppointmentLocalStorage);
      clearBookAppointmentState();
      localStorage.setItem(
        localStorageKeys?.bookAppointmentLocalStorage,
        JSON.stringify(bookAppointmentState),
      );
      localStorage.setItem(
        localStorageKeys?.isReferralAppoinment,
        JSON.stringify(value),
      );

      updateProfileData('myself', data, value, 'myself');

      navigate(clinicalRoutes.healthIssues, {
        state: {
          val: value,
          keyData: data,
          doctorId: value?.assigned_doctor ?? '',
        },
      });
    }
  };

  const onOpenAppoinmentPaymentTypeDrawer = (value) => {
    if (data?.employee_membership_code) {
      if (profile.remaining_free_consultion > 0) {
        if (
          value?.assigned_doctor_profile?.is_health_circles_doctor &&
          !value?.is_patient_referral
        ) {
          setAppoinmentDetail(value);
          setopenfreeContract(true);
        } else {
          localStorage.setItem(localStorageKeys.appoinmentType, 'paid');
          bookReferralFnc(value);
        }
      } else {
        localStorage.setItem(localStorageKeys.appoinmentType, 'paid');
        bookReferralFnc(value);
      }
    } else {
      localStorage.setItem(localStorageKeys.appoinmentType, 'paid');
      bookReferralFnc(value);
    }
  };

  const paidConsultationFun = () => {
    localStorage.setItem(localStorageKeys.appoinmentType, 'paid');
    bookReferralFnc(appoinmentDetail);
  };
  const FreeConsultationFunc = () => {
    localStorage.setItem(localStorageKeys.appoinmentType, 'free');
    bookReferralFnc(appoinmentDetail);
  };

  const goBack = () => {
    setSlotDrawerOpen(false);
  };

  const callBackResponse = async (val) => {
    if (val?.statusCode === 200) {
      toast.success(val?.message ?? `Payment Success.`);
      const responseAppointmentId = localStorage.getItem(
        localStorageKeys?.responseAppointmentId,
      );
      const payload = {
        clientTimeZone: getTimeZoneFnc(),
        appointment_id: responseAppointmentId ?? '',
        appointment_status_id: 1, // booked
        payment_json: val?.data ?? {},
        payment_type: val?.data?.payment_type ? 1 : 3,
      };

      const updateBookAppointmentResponse =
        await updatePaymentStatusDetails(payload);
      if (updateBookAppointmentResponse?.statusCode === 200) {
        setSlotDrawerOpen(false);
        clearBookAppointmentState();
        localStorage.removeItem(localStorageKeys?.responseAppointmentId);
        // To invalidate the cache of performanceStats
        queryClient.invalidateQueries({
          queryKey: ['appointments'],
        });

        navigate(clinicalRoutes?.bookingStatus, {
          state: {
            status: 'confirmed',
            appointmentId:
              updateBookAppointmentResponse?.data?.appointmentId ?? '',
            bookingMethod:
              updateBookAppointmentResponse?.data?.bookingMethod ?? '',
            familyMemberId:
              updateBookAppointmentResponse?.data?.familyMemberId ?? '',
          },
        });
      } else {
        return toast.error(
          updateBookAppointmentResponse?.message ??
            'Something went wrong please try again!',
        );
      }
    } else {
      toast.success(val?.message ?? `Something went wrong please try again!`);
    }
  };

  // RESCHEDULE A APPOINTMENT
  const onContinue = async (type) => {
    if (
      bookAppointmentState?.selectedDate &&
      bookAppointmentState?.selectedSlotStartTime
    ) {
      updateDoctorDetail();
      // CONSTRUCT PAYLAOD DATA FOR BOOK APPOINTMENT
      const payload = {
        user_id: data?.user_id ?? '',
        appointment_id: appointmentState?.appointment_id ?? '',

        appointment_date: getDateFormat(
          bookAppointmentState?.selectedSlotStartTime,
          'YYYY-MM-DD',
        ),
        appointment_time_slot: getTimeFormat(
          bookAppointmentState?.selectedSlotStartTime,
          'HH:mm:ss',
        ),
        appointment_client_start_time:
          bookAppointmentState?.selectedSlotStartTime,

        clientTimeZone: getTimeZoneFnc(),
      };
      const bookAppointmentResponse = await followUpAppointmentFnc(payload);
      if (bookAppointmentResponse?.status?.code === 200) {
        // PAYMENT FLOW
        localStorage.setItem(
          localStorageKeys?.responseAppointmentId,
          bookAppointmentResponse?.data?.appointment_id,
        );
        if (type === 'paid') {
          razorpayCall({
            baseURL: envConfig?.payment_url,
            // baseURL: import.meta.env.VITE_APP_API_BASE_URL,
            orderAmount:
              isReferral &&
              appointment_data?.assigned_doctor_profile?.followup_plan
                ?.followup_fees
                ? (
                    appointment_data?.assigned_doctor_profile?.followup_plan
                      ?.followup_fees / 100
                  ).toString()
                : appointment_data?.assigned_doctor_profile?.appoinment_plan
                    ?.appoinment_fees
                ? (
                    appointment_data?.assigned_doctor_profile?.appoinment_plan
                      ?.appoinment_fees / 100
                  ).toString()
                : 0,

            name: 'Health Circles',
            description: 'description',
            preFillName: appointment_data?.appointment_members?.[0]?.name ?? '',
            preFillEmail:
              appointment_data?.appointment_members?.[0]?.email_id ?? '',
            preFillContactNumber:
              appointment_data?.appointment_members?.[0]?.mobile_no ?? '',
            notesAddress: 'notesAddress',
            theme: '',
            paymentType: 1,
            setLoading,
            callBackResponse,
          });
        }
      } else {
        return toast.error(
          bookAppointmentResponse?.message ??
            'Something went wrong please try again!',
        );
      }
    } else {
      return toast.error('Please select slot!');
    }
  };

  const FreeConsultationNavFun = async () => {
    await onContinue('free');
    const responseAppointmentId = localStorage.getItem(
      localStorageKeys?.responseAppointmentId,
    );
    if (responseAppointmentId && responseAppointmentId?.length > 0) {
      const payload = {
        clientTimeZone: getTimeZoneFnc(),
        appointment_id: responseAppointmentId ?? '',
        appointment_status_id: 1, // booked
        payment_json: {},
        payment_type: 3,
      };
      const updateBookAppointmentResponse =
        await updatePaymentStatusDetails(payload);
      if (updateBookAppointmentResponse?.statusCode === 200) {
        localStorage.removeItem(localStorageKeys?.responseAppointmentId);
        // To invalidate the cache of performanceStats
        queryClient.invalidateQueries({
          queryKey: ['appointments'],
        });
        setSlotDrawerOpen(false);
        setopenfreeContract(false);
        navigate(clinicalRoutes?.bookingStatus, {
          state: {
            status: 'confirmed',
            appointmentId:
              updateBookAppointmentResponse?.data?.appointmentId ?? '',
            bookingMethod:
              updateBookAppointmentResponse?.data?.bookingMethod ?? '',
            familyMemberId:
              updateBookAppointmentResponse?.data?.familyMemberId ?? '',
          },
        });
      } else {
        return toast.error(
          updateBookAppointmentResponse?.message ??
            'Something went wrong please try again!',
        );
      }
    }
  };
  // Free consultation

  const freeConsultationCheck = async () => {
    if (
      parseTokenData?.employee_membership_code?.length > 0 &&
      !profile?.contractExpired &&
      profile?.remaining_free_consultion > 0 &&
      paymentType === 'free'
    ) {
      FreeConsultationNavFun();
    } else {
      await onContinue('paid');
    }
  };

  // const userToCorporateUserLogOutFnc = () => {
  //   window.location.href = clinicalRoutes?.login;
  //   setUpgradedCard(false);
  // };

  const logout = () => {
    toast.success('Signed Out Successfully');
    localStorage.clear();
    setUpgradedCard(false);
    // navigate(clinicalRoutes.login);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const userToCorporateUser = async (id) => {
    await softDeleteNotification(id);
    setUpgradedCard(true);
  };

  useEffect(() => {
    if (parseTokenData?.employee_membership_code?.length > 0) {
      queryClient.invalidateQueries({
        queryKey:['freeConsultation']
      })
      getCorporateUserData(parseTokenData.user_id);
    }
    if (searchParams.get('switchToOrganization')) {
      refresh().then(() => {
        setUpgradedCard(true);
      });
    }
    localStorage.setItem(localStorageKeys.clinicalVersion, version);
  }, []);

  // GET APPOINTMENT DATA
  useEffect(() => {
    getInitialData();
    pwaNotificationChecker();

    if (!showedFirstTime && !pwaNotificationCheckerLoading) {
      setTimeout(() => {
        if (
          !isPwa ||
          notificationStatus === 'denied' ||
          notificationStatus === 'default'
        ) {
          setShowPWANotificationPrompt(true);
        }
      }, [3000]);
    }
  }, [pwaNotificationCheckerLoading]);

  useEffect(() => {
    if (Array.isArray(notificationData) && notificationData?.length > 0) {
      const verfifyArr = notificationData?.filter(
        (val) =>
          JSON.parse(val?.content)?.actionUrl ===
            '/home?switchToOrganization=true' &&
          val?.is_read === false &&
          val?.is_actionable === true &&
          val?.is_active === true,
      );
      if (verfifyArr?.length > 0) {
        userToCorporateUser(verfifyArr?.[0]?.id);
      }
    }
  }, [notificationData]);

  return (
    <ClinicalLayout>
      <Box sx={homeScreen_style.rootSx} className={`${className}`} {...rest}>
        {jitsiState?.showSummary && (
          <Box>
            <Animate
              entryAnimation="driveInTop"
              exitAnimation="driveOutBottom"
              duration="0.5s"
              show={showPWANotificationPrompt}
            >
              <Box sx={homeScreen_style.promptSx}>
                <PWANotificationPrompt
                  onCloseClick={() => setShowPWANotificationPrompt(false)}
                  appInstalled={isPwa}
                  onEnable={enableNotification}
                  notificationEnabled={notificationStatus === 'granted'}
                  isblocked={notificationStatus === 'denied'}
                  onInstall={handleInstallClick}
                />
              </Box>
            </Animate>
            <Box sx={homeScreen_style.containerSx}>
              {/* Home banner */}
              {/* <HomeBanner
                descStyleSx={
                  matches ? { fontSize: '18px' } : { fontSize: '15px' }
                }
                desc="Want to consult a doctor?"
                buttonDescription="Book Appointment"
                image={<DoctorImageIcon />}
                onClickFnc={() => onClick()}
              /> */}
              <Box sx={{ bgcolor: '#fff', padding: 2, borderRadius: 2 }}>
                <Grid container spacing={2}>
                  {homePageUtils?.map((val, i) => (
                    <Grid key={i} item xs={6}>
                      <ClinicalHomeUtilsCard
                        bgColor={val?.bgColor ?? ''}
                        iconBgColor={val?.iconBgColor ?? ''}
                        leftIcon={val?.leftIcon}
                        rightIcon={val?.rightIcon}
                        title={val?.title ?? ''}
                        onClick={val?.onClickVal}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>

              {/* IconText */}
              <Box sx={homeScreen_style.mainSx}>
                {options.map((val, index) => (
                  <Animate
                    key={val?.name}
                    show
                    entryAnimation="pulsate"
                    delay={`${0.5 + index / 10}s`}
                    duration="0.5s"
                  >
                    <IconText
                      key={index}
                      invisible={val.invisible}
                      name={val.name}
                      icon={val.icon}
                      avatarinactiveStyle={{
                        border: 'none',
                      }}
                      rootStyle={{ cursor: 'pointer' }}
                      onClick={() => {
                        navigate(val.path, {
                          state: {
                            bookAppoinment: val.propValue,
                          },
                        });
                      }}
                    />
                  </Animate>
                ))}
              </Box>
              {/* Get Help */}
              {/* <Box sx={homeScreen_style.sosSX} >
                <Box sx={homeScreen_style.sosListSx}>
                  <Box
                    sx={
                      matches
                        ? { alignSelf: 'center' }
                        : { pt: 1, alignSelf: 'center' }
                    }
                  >
                    <ChatBotChatIcon />
                  </Box>

                  <Typography sx={homeScreen_style.emergencySx}>
                    Need Assistance?
                  </Typography>
                </Box>
                <Box
                  sx={
                    matches
                      ? homeScreen_style.resButtonStyle
                      : homeScreen_style.buttonStyle
                  }
                >
                  <Typography
                    sx={
                      matches
                        ? homeScreen_style.resGetHelpTextSx
                        : homeScreen_style.getHelpTextSx
                    }
                  >
                    We're here
                  </Typography>
                </Box>
              </Box> */}

              <Box
                sx={homeScreen_style?.chatBotBannerSx}
                onClick={() => onChatBotClick()}
              >
                <Grid container>
                  <Grid item xs={3} sm={3}>
                    <ChatBotChatIcon
                      rootStyle={{ height: '84px', width: '86px' }}
                    />
                    <Typography textAlign="center" fontSize={8}>
                      Powered by ChatGPT
                    </Typography>
                  </Grid>
                  <Grid p={1} item xs={9} sm={9}>
                    <Typography pb={1} fontWeight={600}>
                      Zenbliss
                    </Typography>
                    <Typography pb={1} fontSize={12}>
                      Your Personal Health Assistant
                    </Typography>

                    <Box
                      sx={{
                        bgcolor: '#fff',
                        display: 'flex',
                        // width: '200px',
                        position: 'relative',
                        justifyContent: 'center',
                        height: 25,
                        borderRadius: 1,
                        '&::before': {
                          content: '" "',
                          position: 'absolute',
                          width: '0px',
                          height: '0px',
                          border: '8px solid',
                          borderColor:
                            '  transparent #fff transparent transparent ',
                          left: '-15px',
                          top: '1px',
                        },
                      }}
                    >
                      <Typography alignSelf="center" fontSize={12}>
                        "I need to manage stress effectively !"
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* Drawer */}
              {help && (
                <Drawer
                  show={help}
                  onCloseDrawer={() => setHelp(false)}
                  rootStyle={homeScreen_style.drawerRootSx}
                  footer={
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Button
                        onClick={onBack}
                        buttonStyle={homeScreen_style.buttonSx}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={onNext}
                        buttonStyle={homeScreen_style.callButtonSx}
                      >
                        Call Now
                      </Button>
                    </Box>
                  }
                >
                  <Box sx={homeScreen_style.helpSx}>
                    <Typography
                      variant="body2"
                      sx={{ color: '#0F0B11', fontWeight: 500 }}
                    >
                      No need to panic! We are here to help you.
                    </Typography>
                    <Box sx={{ pt: 5.55, pb: 2 }}>
                      <Avatar sx={homeScreen_style.avatarSx} />
                    </Box>
                    <Typography variant="body1" sx={{ fontWeight: '600' }}>
                      {doctorName}
                    </Typography>
                    <Box sx={{ pt: 0.5, pb: 3.5 }}>
                      <Typography
                        variant="body2"
                        sx={homeScreen_style.assitantSx}
                      >
                        {role}
                      </Typography>
                    </Box>
                  </Box>
                </Drawer>
              )}
            </Box>

            {/* Follow up and referal */}
            {appoinmentLoader && <SkeletonCard />}
            {!appoinmentLoader &&
              appointmentState?.follow_up_referral?.length > 0 && (
                <Box
                  sx={{
                    ...homeScreen_style.followupSx,
                    backgroundImage: `url(${FollowUp.src})`,
                  }}
                >
                  <Box sx={homeScreen_style.followUpContentSx}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        variant="body2"
                        color="common.white"
                        sx={{ fontWeight: '600' }}
                      >
                        Follow-Up`s & Referrals
                      </Typography>

                      <Avatar
                        alt=""
                        sx={{
                          ...homeScreen_style.smallSx,
                          backgroundColor: 'common.white',
                          color: '#000',
                          fontWeight: 600,
                        }}
                      >
                        {appointmentState?.follow_up_referral?.length > 0 &&
                        appointmentState?.follow_up_referral?.length < 10
                          ? `0${appointmentState?.follow_up_referral?.length}`
                          : appointmentState?.follow_up_referral?.length}
                      </Avatar>
                    </Box>

                    {/* <ViewCard/> */}
                    <Box sx={homeScreen_style.followupScrollSx}>
                      {appointmentState?.follow_up_referral.map(
                        (val, index) => (
                          <ViewCard
                            index={index}
                            key={val?.id}
                            data={val}
                            event_id={val?.event_id ?? ''}
                            app_id={val?.id ?? ''}
                            doctor_id={
                              val?.assigned_doctor ??
                              val?.assigned_doctor_profile?.id ??
                              ''
                            }
                            doctorName={
                              val?.assigned_doctor_profile?.first_name ?? ''
                            }
                            Professional={
                              val?.assigned_doctor_profile
                                ?.doctor_specialities ?? []
                            }
                            src={
                              val?.assigned_doctor_profile?.profile_pic ?? ''
                            }
                            paitentName={
                              // val?.is_self_booking === true
                              //   ? val?.user_profile?.name ?? ''
                              // :
                              val?.appointment_members?.[0]?.name
                            }
                            rootStyle={homeScreen_style.cardStyle}
                            bookingText="Book"
                            appointmentCardStyle={
                              homeScreen_style.appointmentCardStyle
                            }
                            referchangingStyle={
                              homeScreen_style.referchangingStyle
                            }
                            type={val?.appointment_type_id}
                            type_name={
                              val?.master_appointment_type?.appointment_type
                            }
                            isFollowUpReferral={
                              val?.master_appointment_type?.appointment_type ??
                              false
                            }
                            referredBy={
                              val?.is_referral === true
                                ? val?.referred_by_profile?.first_name
                                : ''
                            }
                            self={
                              val?.is_self_booking === false
                                ? val?.appointment_members?.[0]
                                    ?.master_relationship?.releationship
                                : 'Self'
                            }
                            date={val?.appointment_date ?? ''}
                            time={
                              val?.appointment_date_time
                                ? getDateFormat(
                                    val?.appointment_date_time,
                                    ' hh:mm A',
                                  )
                                : ''
                            }
                            // cancel appointment props
                            handleClick={handleClick}
                            bookReferralFnc={() =>
                              onOpenAppoinmentPaymentTypeDrawer(val)
                            }
                          />
                        ),
                      )}
                    </Box>
                  </Box>
                </Box>
              )}

            {/* Upcoming Appointments */}
            <Box sx={homeScreen_style.upcomingSx}>
              <Box sx={homeScreen_style.follorupContentSx}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    sx={{ fontWeight: '600' }}
                  >
                    Upcoming Appointments
                  </Typography>
                  {appointmentState?.today_appointments?.length > 0 && (
                    <Avatar alt="" sx={homeScreen_style.smallSx}>
                      {appointmentState?.today_appointments?.length > 0 &&
                      appointmentState?.today_appointments?.length < 10
                        ? `0${appointmentState?.today_appointments?.length}`
                        : appointmentState?.today_appointments?.length}
                    </Avatar>
                  )}
                </Box>
                {appoinmentLoader && <SkeletonCard />}
                {!appoinmentLoader && (
                  <Box sx={homeScreen_style.upcomingListSx}>
                    {appointmentState?.today_appointments?.length === 0 && (
                      <Box sx={homeScreen_style.emptyState}>
                        <Box sx={homeScreen_style.iconStyleSx}>
                          <CancelCalenderIcon />
                        </Box>
                        <Box sx={homeScreen_style.upcomingAppointmentTextSx}>
                          <Typography
                            variant="body2"
                            sx={homeScreen_style.mainHeadSx}
                          >
                            No Upcoming Appointments!
                          </Typography>
                          <Typography sx={homeScreen_style.subHeadSx}>
                            You can book and get consultation anytime anywhere
                          </Typography>
                          <IconButton
                            sx={homeScreen_style.cancelBookSx}
                            disableRipple
                            onClick={() => onHomeClick()}
                          >
                            <CalenderPlusIcon
                              rootStyle={homeScreen_style.plusSx}
                            />
                            Book Now
                          </IconButton>
                        </Box>
                      </Box>
                    )}

                    {!appoinmentLoader &&
                      appointmentState?.today_appointments?.length > 0 &&
                      appointmentState?.today_appointments.map((val, index) => (
                        <ViewCard
                          index={index}
                          key={val?.id}
                          data={val}
                          event_id={val?.event_id ?? ''}
                          app_id={val?.id ?? ''}
                          doctor_id={
                            val?.assigned_doctor ??
                            val?.assigned_doctor_profile?.id ??
                            ''
                          }
                          doctorName={
                            val?.assigned_doctor_profile?.first_name ?? ''
                          }
                          Professional={
                            val?.assigned_doctor_profile?.doctor_specialities ??
                            []
                          }
                          src={val?.assigned_doctor_profile?.profile_pic ?? ''}
                          paitentName={
                            val?.is_self_booking === true
                              ? val?.user_profile?.name ?? ''
                              : val?.appointment_members?.[0]?.name
                          }
                          rootStyle={homeScreen_style.upcomingcardStyle}
                          bookingText="Join"
                          UpdateTime
                          type={val?.appointment_status}
                          type_name={
                            val?.master_appointment_status?.appointment_status
                          }
                          after={
                            val?.appointment_date_time
                              ? timeDiffCalc(val?.appointment_date_time)
                              : ''
                          }
                          self={
                            val?.is_self_booking === false
                              ? val?.appointment_members?.[0]
                                  ?.master_relationship?.releationship
                              : 'Self'
                          }
                          date={
                            val?.appointment_date_time
                              ? getDateFormat(
                                  val?.appointment_date_time,
                                  'DD MMM YY,',
                                )
                              : ''
                          }
                          time={
                            val?.appointment_date_time
                              ? getDateFormat(
                                  val?.appointment_date_time,
                                  ' hh:mm A',
                                )
                              : ''
                          }
                          disabled={
                            val?.appointment_date_time
                              ? IsBookButtonEnable(val?.appointment_date_time)
                              : true
                          }
                          // cancel appointment props
                          handleClick={handleClick}
                        />
                      ))}
                  </Box>
                )}
              </Box>
            </Box>

            {/* Active Prescriptions */}

            <Box sx={homeScreen_style.activeSx}>
              <Box sx={homeScreen_style.follorupContentSx}>
                <Typography sx={homeScreen_style.activetextSx}>
                  Active Prescriptions
                </Typography>
                {prescriptionsLoading &&
                  Array(4)
                    .fill(1)
                    .map((i) => (
                      <SkeletonCard key={i} isCardDetailsRequired={false} />
                    ))}
                {!prescriptionsLoading && (
                  <Box sx={homeScreen_style.activeListSx}>
                    {Array.isArray(allPrescriptionsData) &&
                    allPrescriptionsData?.length > 0 ? (
                      allPrescriptionsData.map((val, index) => (
                        <PrescriptionCard
                          key={val?.id ?? index}
                          doctorName={val?.assigned_doctor_profile?.first_name}
                          // Professional={
                          //   val?.is_self_booking === true
                          //     ? val?.user_profile?.name ?? ''
                          //     : val?.appointment_members?.[0]?.name
                          // }
                          patientName={val?.appointment_members?.name ?? ''}
                          self={
                            val?.is_self_booking === false
                              ? val?.appointment_members?.master_relationship
                                  ?.releationship
                              : 'Self'
                          }
                          rootStyle={homeScreen_style.activecardStyle}
                          visit_report_url={
                            val?.visit_summaries?.[0]?.visit_report_url
                          }
                          // onClick={handleDrawerOpen}
                          onClick={() => {
                            const { id } = val;
                            getPrescriptionData(id);
                            // navigate(`/prescription/${appoinmentId}`);
                          }}
                        />
                      ))
                    ) : (
                      <Box sx={homeScreen_style.emptyState}>
                        <Box sx={{ px: 2.5, py: 1.5 }}>
                          <NoActivePrescriptionIcon />
                        </Box>
                        <Box sx={homeScreen_style.upcomingAppointmentTextSx}>
                          <Typography
                            variant="body2"
                            sx={homeScreen_style.mainHeadSx}
                          >
                            Currently No Prescriptions Available
                          </Typography>
                          <Box pr={4}>
                            <Typography sx={homeScreen_style.subHeadSx}>
                              Complete a consultation to view prescriptions
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>

            {/* carousel */}
            <Box sx={homeScreen_style.adSx}>
              <Slider {...settings}>
                {carouselOptions.map((val, index) => (
                  <Box sx={{ p: 2.2 }} key={index}>
                    <img
                      src={val.image}
                      alt="carousel"
                      style={{ width: '100%' }}
                    />
                  </Box>
                ))}
              </Slider>
            </Box>

            {drawerOpen && (
              <Drawer
                show={drawerOpen}
                onCloseDrawer={() => setDrawerOpen(false)}
                footer={
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      buttonStyle={{
                        bgcolor: 'primaryTints.200',
                        color: 'primary.main',
                        borderRadius: '8px',
                        '&:hover': {
                          bgcolor: 'primaryTints.200',
                        },
                      }}
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      buttonStyle={{
                        bgcolor: '#F44F5A',
                        color: '#fff',
                        borderRadius: '8px',
                        '&:hover': {
                          bgcolor: '#F44F5A',
                        },
                      }}
                      onClick={onRemove}
                    >
                      Remove
                    </Button>
                  </Box>
                }
              >
                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CancelAppointmentIcon rootStyle={{ color: '#FFE0E0' }} />
                  </Box>
                  <Box>
                    <Typography sx={homeScreen_style.prescriptionTextSx}>
                      Are you sure you want to Remove this from Active
                      Prescription?
                    </Typography>
                  </Box>
                </Box>
              </Drawer>
            )}

            {anchorElCancel && (
              <Menu
                anchorEl={anchorElCancel}
                open={Boolean(anchorElCancel)}
                onClose={handleCancelClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={homeScreen_style.morelistSx}
              >
                <MenuItem
                  onClick={() => {
                    handleDrawerCancelWithApp(true);
                    handleCancelClose();
                  }}
                  sx={homeScreen_style.borderSx}
                >
                  <CancelIcon rootStyle={{ color: 'error.main' }} />
                  <Box component="span" sx={homeScreen_style.cancelAppointment}>
                    Cancel Appointment
                  </Box>
                </MenuItem>
              </Menu>
            )}
            {anchorElRemove && (
              <Menu
                anchorEl={anchorElRemove}
                open={Boolean(anchorElRemove)}
                onClose={handleRemoveClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={homeScreen_style.morelistSx}
              >
                <MenuItem
                  onClick={() => {
                    handleDrawerRemoveWithApp(true);
                    handleRemoveClose();
                  }}
                  sx={homeScreen_style.borderSx}
                >
                  <CancelIcon rootStyle={{ color: 'error.main' }} />
                  <Box component="span" sx={homeScreen_style.cancelAppointment}>
                    Remove
                  </Box>
                </MenuItem>
              </Menu>
            )}

            {/* CancelAppointment */}
            <CancelAppointment
              onNext={() => cancelAppointmentFnc(false)}
              onBack={() => handleDrawerCancel(false)}
              setDrawerOpen={handleDrawerCancel}
              drawerOpen={cancelDrawerOpen}
              ReasonForCancellation={ReasonForCancellation}
              value={appointmentState?.cancellationReason}
            />

            {/* RemoveAppointment */}
            <CancelAppointment
              onNext={() => removeAppointmentFnc(false)}
              onBack={() => handleDrawerRemove(false)}
              setDrawerOpen={handleDrawerRemove}
              drawerOpen={removeDrawerOpen}
              title="Are you sure you want to remove this appointment?"
              removeAppointmentDrawer
            />

            {slotDrawerOpen && (
              <SlotDrawer
                anchor="bottom"
                sx={homeScreen_style.slotDrawerSx}
                open={slotDrawerOpen}
                onClose={goBack}
              >
                <Box px={2.5} py={2.5}>
                  <AppointmentDateTimeSelection isReferral={isReferral} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    p: 3,
                    bgcolor: 'common.white',
                    borderTop: '1.3px dashed',
                    borderColor: 'border.main',
                  }}
                >
                  <Button
                    buttonStyle={homeScreen_style.buttonSx}
                    onClick={() => goBack()}
                  >
                    Go Back
                  </Button>
                  <Button
                    buttonStyle={{
                      '&:hover': {
                        bgcolor: 'primary.main',
                      },
                    }}
                    onClick={freeConsultationCheck}
                  >
                    Continue
                  </Button>
                </Box>
              </SlotDrawer>
            )}

            {openfreeContract && (
              <FreeConsultation
                addDrawerOpen={openfreeContract}
                setAddDrawerOpen={setopenfreeContract}
                PaidConsultant={() => paidConsultationFun()}
                FreeConsultant={() => FreeConsultationFunc()}
              />
            )}
          </Box>
        )}
      </Box>

      {/* {appointmentState?.docs?.uri && ( */}
      <Drawer
        rootStyle={{ backdropFilter: 'brightness(0.5)' }}
        hideBackdrop
        show={UpgradedCardopen}
        footerStyle={{
          pt: 1,
          pb: 2,
          bgcolor: 'common.white',
          borderColor: 'transparent',
        }}
        footer={
          <Box>
            <Button onClick={() => logout()}>Okay</Button>
          </Box>
        }
      >
        <UpgradedCard organisation={profile?.organisation_name ?? ''} />
      </Drawer>
    </ClinicalLayout>
  );
}
