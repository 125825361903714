import {
  CreateAccountIcon
} from '@hc/ui/atoms';
import { ClinicalCreateaccount } from '@hc/ui/components/clinical/createAccount';
import { Box } from '@mui/material';

import { signUp_style } from './style';

export default function Createaccount(props) {
  const { className = '', ...rest } = props;

  return (
    <Box sx={signUp_style.rootSx} className={`${className}`} {...rest}>
      <Box display="flex" justifyContent="center">
        <CreateAccountIcon />
      </Box>
      <ClinicalCreateaccount />
    </Box>
  );
}
