/* eslint-disable radix */
/* eslint-disable no-unused-vars */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { clinicalRoutes } from '@hc/routes';
import { httpRequest, queryClient } from '@hc/utils';
import {
  BMICalculationProfileFamily,
  isBloodPressure,
  isDecimalNumber,
  isNumber,
  isString
} from '@hc/utils/helperFunctions';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';
import { useRouting } from '../common/routing';

export const useFamilyMember = create((set, get) => ({
  familyMembers: null,
  familyMember: {
    login_user_mobile_number: '',
    login_user_country_code: '',
    family_member_id: '',
    name: '',
    relationship: '',
    gender: '',
    blood_group: 0,
    age: '',
    mobile_no: '',
    country_code: '',
    email_id: '',
    height: '',
    weight: '',
    bmi: '',
    blood_pressure: '',
    temp_in_f: '',
    puls_bpm: '',
    o2_level: '',
    error: {
      name: '',
      relationship: '',
      gender: '',
      age: '',
      mobile_no: '',
      email_id: '',
      blood_pressure: '',
    },
  },
  searchFamilyData: null,
  loading: null,
  searchLoading: false,
  error: null,
  getAll: async () => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      const { status, data, error, isFetching } = await queryClient.fetchQuery([
        '/familymembers/view-all-family-member',
      ]);
      return set((state) => ({
        loading: false,
        familyMembers: data,
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  get: async (family_member_id) => {
    try {
      // Hitting the get family member API
      const { status, data, error, isFetching } = await queryClient.fetchQuery([
        '/familymembers/view-family-member',
        'post',
        { id: family_member_id ?? '' },
      ]);
      set((state) => ({
        familyMember: {
          error: {
            ...state.familyMember.error,
          },
          ...state.familyMember,
          family_member_id: data?.family_member_id ?? '',
          name: data?.name ?? '',
          relationship: data?.relationship ?? '',
          gender: data?.gender ?? '',
          age: data?.age ?? '',
          mobile_no: data?.mobile_no ?? '',
          country_code: data?.country_code ?? '',
          email_id: data?.email_id ?? '',
          blood_group: data?.vitals?.blood_group ?? 0,
          height: data?.vitals?.height ?? '',
          weight: data?.vitals?.weight ?? '',
          bmi: data?.vitals?.bmi ?? '',
          blood_pressure: data?.vitals?.blood_pressure ?? '',
          temp_in_f: data?.vitals?.temp_in_f ?? '',
          puls_bpm: data?.vitals?.puls_bpm ?? '',
          o2_level: data?.vitals?.o2_level ?? '',
          is_use_my_number: data?.is_use_my_number ?? false,
        },
      }));
      return data;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  upsert: async (payload, isAdd, useMyNumber, isBookAppointment) => {
    try {
      set({ loading: true });
      const data = {
        name: payload?.name ?? '',
        gender: payload?.gender ?? '',
        age: parseInt(payload?.age) ?? '',
        mobile_no: payload?.mobile_no ?? '',
        country_code: payload?.country_code ?? '',
        email_id: payload?.email_id ?? '',
        relationship: payload?.relationship ?? '',
        vitals: {
          height: payload?.height ?? '',
          weight: payload?.weight ?? '',
          blood_group: payload?.blood_group
            ? parseInt(payload?.blood_group)
            : 0,
          bmi: payload?.bmi ?? '',
          blood_pressure: payload?.blood_pressure ?? '',
          temp_in_f: payload?.temp_in_f ?? '',
          puls_bpm: payload?.puls_bpm ?? '',
          o2_level: payload?.o2_level ?? '',
        },
        family_member_id: payload?.family_member_id ?? '',
        is_use_my_number: isAdd
          ? useMyNumber
          : payload?.is_use_my_number
          ? payload?.is_use_my_number
          : false,
      };
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/familymembers/upsert-family-member`,
        {
          ...data,
        },
        true,
      );
      if (!isBookAppointment) {
        if (response?.data?.status?.code === '200') {
          if (
            response?.data?.status?.message ===
            'Mobile Number Already Exists as a User in Healthcircles'
          ) {
            toast.success('Member updated successfully!');
          }
          if (!isAdd) {
            toast.success('Member updated successfully!');
          } else if (useMyNumber === true) {
            toast.success('Member added successfully!');
          } else if (useMyNumber === false) {
            toast.success('HC Invite sent successfully!');
          }
          return useRouting.setState({ route: clinicalRoutes.myFamily });
        }
      }

      return set({ loading: false });
    } catch (err) {
      useRouting.setState({ route: clinicalRoutes.myFamily });
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  // UPDATE FAMILY MEMBER ERROR
  updateFamilyMemberError: (error) => {
    const { familyMember } = get();
    set({
      familyMember: {
        ...familyMember,
        error,
      },
    });
  },
  // CLEAR FAMILY MEMBER STATE
  clearFamilyMemberState: () => {
    set({
      familyMember: {
        login_user_mobile_number: '',
        login_user_country_code: '',
        family_member_id: '',
        name: '',
        relationship: '',
        gender: '',
        blood_group: 0,
        age: '',
        mobile_no: '',
        country_code: '',
        email_id: '',
        height: '',
        weight: '',
        bmi: '',
        blood_pressure: '',
        temp_in_f: '',
        puls_bpm: '',
        o2_level: '',
        error: {
          name: '',
          relationship: '',
          gender: '',
          age: '',
          mobile_no: '',
          email_id: '',
          blood_pressure: '',
          temp_in_f: '',
          puls_bpm: '',
          o2_level: '',
        },
      },
      searchFamilyData: {},
      error: null,
    });
  },
  // HANDLE FAMILY MEMBER DATA CAHNGE
  // eslint-disable-next-line consistent-return
  handleFamilyMemberChange: (key, value) => {
    const { familyMember, updateFamilyMemberError } = get();
    // For preventing change to the original state
    const familyMemberCopy = JSON.parse(JSON.stringify(familyMember));

    if (key === 'height' || key === 'weight') {
      // BMI CALCULATION
      const BMI = BMICalculationProfileFamily(key, value, familyMember);
      return set({
        familyMember: {
          ...familyMemberCopy,
          [key]: value,
          bmi: BMI,
        },
      });
    }
    if (key === 'temp_in_f' || key === 'puls_bpm' || key === 'o2_level') {
      if (isDecimalNumber(value)) {
        return set({
          familyMember: {
            ...familyMemberCopy,
            [key]: value,
          },
        });
      }
    } else if (key === 'name') {
      if (isString(value)) {
        return set({
          familyMember: {
            ...familyMemberCopy,
            name: value,
          },
        });
      }
    } else if (key === 'mobileNumber') {
      return set({
        familyMember: {
          ...familyMemberCopy,
          mobile_no: value?.mobile,
          country_code: value?.mobileCode,
        },
      });
    } else if (key === 'age') {
      if (isNumber(value)) {
        const error = familyMemberCopy?.error;
        if (value <= 120) {
          error.age = '';
          updateFamilyMemberError(error);
        } else {
          // Checking age
          error.age = 'Age must be less than or equal to 120';
          updateFamilyMemberError(error);
        }
        if (value <= 120) {
          return set({
            familyMember: {
              ...familyMemberCopy,
              age: value,
            },
          });
        }
      }
    } else if (key === 'blood_pressure') {
      if (isBloodPressure(value)) {
        return set({
          familyMember: {
            ...familyMemberCopy,
            blood_pressure: value,
          },
        });
      }
    } else if (key === 'blood_group') {
      if (familyMember?.blood_group === value) {
        set({
          familyMember: {
            ...familyMemberCopy,
            blood_group: 0,
          },
        });
      } else {
        set({
          familyMember: {
            ...familyMemberCopy,
            blood_group: value,
          },
        });
      }
      return true;
    } else {
      return set({
        familyMember: {
          ...familyMemberCopy,
          [key]: value,
        },
      });
    }
  },
  // Search ExistingMember
  existingFamilyMember: async (payload) => {
    try {
      set({ searchLoading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['mobileNumberSearch', `${payload.mobile_no}`],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/familymembers/search-family-member`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });

      if (data) {
        set({
          searchLoading: false,
          searchFamilyData: data,
        });
        return data;
      }
      set({
        searchLoading: false,
        searchFamilyData: 'Not Found',
      });
    } catch (error) {
      set({ searchLoading: false });
      log('error', error);
      return toast.error(
        error?.data?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  // UPSER EXISTING MENBER FLOW
  upsertExistingMember: async (user_id) => {
    try {
      const { searchFamilyData, familyMember } = get();
      set({ loading: true });
      const data = {
        user_id: user_id ?? '',
        invited_user_id: searchFamilyData?.user_id ?? '',
        name: searchFamilyData?.name ?? '',
        gender: searchFamilyData?.gender ?? '',
        age: searchFamilyData?.age ?? '',
        mobile_no: searchFamilyData?.mobile_no ?? '',
        country_code: searchFamilyData?.country_code ?? '',
        email_id: searchFamilyData?.email_id ?? '',
        relationship: familyMember?.relationship ?? '',
        vitals: {
          height: searchFamilyData?.vitals?.height ?? '',
          weight: searchFamilyData?.vitals?.weight ?? '',
          blood_group: searchFamilyData?.vitals?.blood_group
            ? parseInt(searchFamilyData?.vitals?.blood_group)
            : 0,
          bmi: searchFamilyData?.vitals?.bmi ?? '',
          blood_pressure: searchFamilyData?.vitals?.blood_pressure ?? '',
          temp_in_f: searchFamilyData?.vitals?.temp_in_f ?? '',
          puls_bpm: searchFamilyData?.vitals?.puls_bpm ?? '',
          o2_level: searchFamilyData?.vitals?.o2_level ?? '',
        },
      };
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/familymembers/invite-existing-member`,
        {
          ...data,
        },
        true,
      );

      if (response?.data?.status?.code === '200') {
        toast.success('Invite sent successfully!');
        const { clearFamilyMemberState } = get();
        clearFamilyMemberState();
        return useRouting.setState({ route: clinicalRoutes.myFamily });
      }
      return set({ loading: false });
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  // ACCEPT OR DECLINE FAMILY MEMBER REQUEST
  acceptOrDeclineFamilyMemberRequest: async (payload) => {
    try {
      set({ loading: true });
      const response = await queryClient.fetchQuery([
        '/familymembers/update-request-status',
        'post',
        payload,
      ]);

      set({
        loading: false,
      });
      return response;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },
}));
