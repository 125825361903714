import {
  BookAppointmentRootLayout,
  ClinicalRootLayout,
  PageNotFound,
} from '@hc/ui/components';

import { clinicalRoutes } from '@hc/routes';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
// import MindBodyFoodHome from '@pages/mindbodyfood/home';
import ErrorBoundary from '@pages/errorBoundary';
import Home from '@pages/home';
import Login from '@pages/login';
import MindBodyFoodHome from '@pages/mindbodyfood/home';

// Onboarding
import Createaccount from '@pages/createaccount';
import ForgotPassword from '@pages/forgotpassword';
import Onboarding from '@pages/onboarding';
import OtpVerification from '@pages/otpverification';
import ResetPassword from '@pages/resetpassword';
// Notification
import MyAppointments from '@pages/appointments';
import Notification from '@pages/notification';
// Profile
import ProfileView from '@pages/profile';
import AddProfile from '@pages/profile/add';
import EditProfile from '@pages/profile/edit';

import BugReport from '@pages/bugreports';
import FeedBack from '@pages/feedback';

// FamilyMember
import AddExistingMember from '@pages/appointmentbooking/addExistingMember';
import SelectFamilyMember from '@pages/appointmentbooking/selectFamilyMember';
import MyFamily from '@pages/familymembers';
import EditFamilyMembers from '@pages/familymembers/add';
import EditFamilyMember from '@pages/familymembers/edit';

// mindBodyFood
import SelectPatient from '@pages/appointmentbooking/selectpatient';
import GetStarted from '@pages/mindbodyfood/getStarted';
import PlanEvaluation from '@pages/mindbodyfood/mbfRenewalForm';
import MoodForYou from '@pages/mindbodyfood/moodforyou';
import MoodStats from '@pages/mindbodyfood/moodStats';
import MyWellnessPlan from '@pages/mindbodyfood/myWellnessPlan';
import Performance from '@pages/mindbodyfood/performance';
import PlanSummary from '@pages/mindbodyfood/planSummary';
import Evaluation from '@pages/mindbodyfood/questionevaluation';
import EvaluationType from '@pages/mindbodyfood/questiontypeevaluation';
import WellnessJourney from '@pages/mindbodyfood/wellnessjourney';

// AppointmentBooking
import ActivePrescription from '@pages/activePrescription';
import Allergies from '@pages/appointmentbooking/allergies';
import BookAppointmentSummary from '@pages/appointmentbooking/appointmentsummary';
import DoctorAvailable from '@pages/appointmentbooking/availabledoctors';
import AppointmentBookingStatus from '@pages/appointmentbooking/bookingstatus';
import ConsultationReason from '@pages/appointmentbooking/consultationreason';
import HealthIssues from '@pages/appointmentbooking/healthissues';
import HealthProblems from '@pages/appointmentbooking/healthproblems';
import Medications from '@pages/appointmentbooking/medications';
import PatientDetails from '@pages/appointmentbooking/patientDetails';
import Symptoms from '@pages/appointmentbooking/symptoms';
import VitalsForm from '@pages/appointmentbooking/vitalsform';

// Reports
import UploadReports from '@pages/appointmentbooking/uploadreports';
import Reports from '@pages/reports';
import ReportsUpload from '@pages/uploadReports';

import { Animate } from '@hc/ui/atoms';
import HealthQueryChatBot from '@pages/chatBot';
import Leaderboard from '@pages/mindbodyfood/leaderboard';
import MbfFeedback from '@pages/mindbodyfood/mbfFeedback';
import Awareness from '../pages/awareness';
import AwarenessCategory from '../pages/awareness/awarenessCategory';
import AwarenessSlider from '../pages/awareness/awarenessSlider';
import ViewAwareness from '../pages/awareness/viewAwareness';
import MbfMoodSplash from '../pages/mbfMoodSplash';
import MbfSplash from '../pages/mbfSplash';
import MedicalConditions from '../pages/medicalConditions';
import Awards from '../pages/mindbodyfood/awards';
import MBFExpertNotes from '../pages/mindbodyfood/expertNotes';
import OtpSplash from '../pages/otpSplash';
import Splash from '../pages/splash';
import { PrivateRouter } from './privateRouter';
import { ViewDoctorDetails } from '../pages/ViewDoctorDetail';
import ViewMedicalConditions from '../pages/medicalConditions/ViewMedicalCondition';
import VitalMonitoring from '../pages/vitalMonitoring';
import VitalHistory from '../pages/vitalHistory';
import ClinicalLandingPage from '../pages/landingPage';

const pathSlicer = (path, sliceStart = 1) => path.slice(sliceStart);

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRouter>
        <ClinicalRootLayout />
      </PrivateRouter>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: (
          <Animate key="/" show>
            <ClinicalLandingPage />
          </Animate>
        ),
      },
      {
        // index: true,
        path: pathSlicer(clinicalRoutes?.home),
        element: (
          <Animate key={pathSlicer(clinicalRoutes?.home)} show>
            <Home />
          </Animate>
        ),
      },

      // onboarding
      {
        path: pathSlicer(clinicalRoutes?.signup),
        element: (
          <Animate key={pathSlicer(clinicalRoutes?.signup)} show>
            <Createaccount />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.onboarding),
        element: (
          <Animate show>
            <Onboarding />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.login),
        element: (
          <Animate show>
            <Login />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.forgotPassword),
        element: (
          <Animate show>
            <ForgotPassword />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.otpSplash),
        element: (
          <Animate show>
            <OtpSplash />
          </Animate>
        ),
      },

      {
        path: pathSlicer(clinicalRoutes?.resetPassword),
        element: (
          <Animate show>
            <ResetPassword />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.otp),
        element: (
          <Animate show>
            <OtpVerification />
          </Animate>
        ),
      },

      // profile
      {
        path: pathSlicer(clinicalRoutes?.profileView),
        element: (
          <Animate show>
            <ProfileView />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.addProfile),
        element: (
          <Animate show>
            <AddProfile />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.editProfile),
        element: (
          <Animate show>
            <EditProfile />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.vitalMonitoring),
        element: (
          <Animate show>
            <VitalMonitoring />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.vitalHistory),
        element: (
          <Animate show>
            <VitalHistory />
          </Animate>
        ),
      },

      // add existing member
      {
        path: pathSlicer(clinicalRoutes?.addexistingmember),
        element: (
          <Animate show>
            <AddExistingMember />
          </Animate>
        ),
      },

      // family members
      {
        path: pathSlicer(clinicalRoutes?.myFamily),
        element: (
          <Animate show>
            <MyFamily />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.addFamilyMember),
        element: (
          <Animate show>
            <EditFamilyMembers />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.editFamilyMember),
        element: (
          <Animate show>
            <EditFamilyMember />
          </Animate>
        ),
      },

      // reports
      {
        path: pathSlicer(clinicalRoutes?.reports),
        element: (
          <Animate show>
            <Reports />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.reportsUpload),
        element: (
          <Animate show>
            <ReportsUpload />
          </Animate>
        ),
      },

      // appointment booking
      {
        path: pathSlicer(clinicalRoutes?.selectPatient),
        element: (
          <BookAppointmentRootLayout>
            <Animate show>
              <SelectPatient />
            </Animate>
          </BookAppointmentRootLayout>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.healthIssues),
        element: (
          <BookAppointmentRootLayout>
            <Animate show>
              <HealthIssues />
            </Animate>
          </BookAppointmentRootLayout>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.consultationReason),
        element: (
          <BookAppointmentRootLayout>
            <Animate show>
              <ConsultationReason />
            </Animate>
          </BookAppointmentRootLayout>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.symptoms),
        element: (
          <BookAppointmentRootLayout>
            <Animate show>
              <Symptoms />
            </Animate>
          </BookAppointmentRootLayout>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.vitalsForm),
        element: (
          <BookAppointmentRootLayout>
            <Animate show>
              <VitalsForm />
            </Animate>
          </BookAppointmentRootLayout>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.doctorAvailable),
        element: (
          <BookAppointmentRootLayout>
            <Animate show>
              <DoctorAvailable />
            </Animate>
          </BookAppointmentRootLayout>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.healthProblems),
        element: (
          <BookAppointmentRootLayout>
            <Animate show>
              <HealthProblems />
            </Animate>
          </BookAppointmentRootLayout>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.allergies),
        element: (
          <BookAppointmentRootLayout>
            <Animate show>
              <Allergies />
            </Animate>
          </BookAppointmentRootLayout>
        ),
      },

      {
        path: pathSlicer(clinicalRoutes?.medications),
        element: (
          <BookAppointmentRootLayout>
            <Animate show>
              <Medications />
            </Animate>
          </BookAppointmentRootLayout>
        ),
      },

      {
        path: pathSlicer(clinicalRoutes?.uploadReports),
        element: (
          <BookAppointmentRootLayout>
            <Animate show>
              <UploadReports />
            </Animate>
          </BookAppointmentRootLayout>
        ),
      },

      {
        path: pathSlicer(clinicalRoutes?.appointmentSummary),
        element: (
          <BookAppointmentRootLayout>
            <Animate show>
              <BookAppointmentSummary />
            </Animate>
          </BookAppointmentRootLayout>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.bookingStatus),
        element: (
          <BookAppointmentRootLayout>
            <Animate show>
              <AppointmentBookingStatus />
            </Animate>
          </BookAppointmentRootLayout>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.selectFamilyMember),
        element: (
          <BookAppointmentRootLayout>
            <Animate show>
              <SelectFamilyMember />
            </Animate>
          </BookAppointmentRootLayout>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.patientDetails),
        element: (
          <BookAppointmentRootLayout>
            <Animate show>
              <PatientDetails />
            </Animate>
          </BookAppointmentRootLayout>
        ),
      },

      // appointments
      {
        path: pathSlicer(clinicalRoutes?.myAppointments),
        element: (
          <Animate show>
            <MyAppointments />
          </Animate>
        ),
      },

      // mind body food
      {
        path: pathSlicer(clinicalRoutes?.mindbodyfood),
        element: (
          <Animate show>
            <MindBodyFoodHome />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.leaderboard),
        element: (
          <Animate show>
            <Leaderboard />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.questionevaluation),
        element: (
          <Animate show>
            <Evaluation />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.splash),
        element: (
          <Animate show>
            <Splash />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.questiontypeevaluation),
        element: (
          <Animate show>
            <EvaluationType />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.PlanEvaluation),
        element: (
          <Animate show>
            <PlanEvaluation />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.getStarted),
        element: (
          <Animate show>
            <GetStarted />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.mbfSplash),
        element: (
          <Animate show>
            <MbfSplash />
          </Animate>
        ),
      },

      {
        path: pathSlicer(clinicalRoutes?.moodforyou),
        element: (
          <Animate show>
            <MoodForYou />
          </Animate>
        ),
      },

      {
        path: pathSlicer(clinicalRoutes?.wellnessjourney),
        element: (
          <Animate show key={pathSlicer(clinicalRoutes?.wellnessjourney)}>
            <WellnessJourney />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.performance),
        element: (
          <Animate show>
            <Performance />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.expertNotes),
        element: (
          <Animate show>
            <MBFExpertNotes />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.moodstats),
        element: (
          <Animate show>
            <MoodStats />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.mbfMoodSplash),
        element: (
          <Animate show>
            <MbfMoodSplash />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.mbfawards),
        element: (
          <Animate show>
            <Awards />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.mywellnessplan),
        element: (
          <Animate show>
            <MyWellnessPlan />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.mbffeedback),
        element: (
          <Animate show>
            <MbfFeedback />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.notification),
        element: (
          <Animate show>
            <Notification />
          </Animate>
        ),
      },
      // prescription
      {
        path: pathSlicer(clinicalRoutes?.prescription),
        element: (
          <Animate show>
            <ActivePrescription />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.plansummary),
        element: (
          <Animate show>
            <PlanSummary />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.awarenesspart),
        element: (
          <Animate show>
            <Awareness />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.viewAwareness),
        element: (
          <Animate show>
            <ViewAwareness />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.awarenessCategory),
        element: (
          <Animate show>
            <AwarenessCategory />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.awarenesSlider),
        element: (
          <Animate show>
            <AwarenessSlider />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.bugreports),
        element: (
          <Animate show>
            <BugReport />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.feedback),
        element: (
          <Animate show>
            <FeedBack />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.medicalConditions),
        element: (
          <Animate show>
            <MedicalConditions />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.viewMedicalConditions),
        element: (
          <Animate show>
            <ViewMedicalConditions />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.chatBot),
        element: (
          <Animate show>
            <HealthQueryChatBot />
          </Animate>
        ),
      },
    ],
  },

  {
    path: '*',
    errorElement: <ErrorBoundary />,
    element: <PageNotFound />,
  },
  {
    path: pathSlicer(clinicalRoutes?.doctorProfile),
    errorElement: <ErrorBoundary />,
    element: <ViewDoctorDetails />,
  },
  {
    path: '/',
    element: <ClinicalRootLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: (
          <Animate key="/" show>
            <Awareness />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.awarenessCategory),
        element: (
          <Animate show>
            <AwarenessCategory />
          </Animate>
        ),
      },
      {
        path: pathSlicer(clinicalRoutes?.awarenesSlider),
        element: (
          <Animate show>
            <AwarenessSlider />
          </Animate>
        ),
      },
      // {
      //   path: pathSlicer('/shareAwareness'),
      //   element: (
      //     <Animate show>
      //       <Awareness />
      //     </Animate>
      //   ),
      // },
      {
        path: pathSlicer('/awarenesscategory'),
        element: (
          <Animate show>
            <AwarenessCategory />
          </Animate>
        ),
      },
      {
        path: pathSlicer('/awarenesscategory/awarenessslide'),
        element: (
          <Animate show>
            <AwarenessSlider />
          </Animate>
        ),
      },
    ],
  },
]);

function RouterApp() {
  return <RouterProvider router={router} />;
}

export default RouterApp;
