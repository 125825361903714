/* eslint-disable react/jsx-key */
import {
  AwardIcon,
  IconText,
  JourneyIcon,
  MoodStatsIcon,
  PerformanceIcon,
} from '@hc/ui/atoms';
import { Box } from '@mui/material';

import { clinicalRoutes } from '@hc/routes';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { localStorageKeys, parseJwt } from '@hc/utils';

export function MBFIconTextOptions() {
  const navigate = useNavigate();
  const tokenData = localStorage.getItem(localStorageKeys.authToken);
  const parseTokenData = parseJwt(tokenData);
  //
  const options = [
    {
      invisible: true,
      name: 'Journey',
      icon: <JourneyIcon />,
      path: clinicalRoutes.wellnessjourney,
    },
    {
      invisible: true,
      name: 'Performance',
      icon: <PerformanceIcon />,
      path: clinicalRoutes.performance,
    },
    {
      invisible: true,
      name: 'Mood Stats',
      icon: <MoodStatsIcon />,
      path: clinicalRoutes.moodstats,
    },
    {
      invisible: true,
      name: 'Awards',
      icon: <AwardIcon />,
      path: clinicalRoutes.mbfawards,
    },
  ];
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        my: 4,
        justifyContent: 'space-evenly',
        cursor: 'pointer',
      }}
    >
      {options.map((val, index) => {
        if (val.name !== 'Awards') {
          return (
            <IconText
              key={[index]}
              invisible={val.invisible}
              name={val.name}
              icon={val.icon}
              rootStyle={{ cursor: 'pointer' }}
              avatarinactiveStyle={{ borderColor: 'purple.600' }}
              onClick={() => navigate(val.path)}
            />
          );
        }
        if (
          val.name === 'Awards' &&
          parseTokenData?.employee_membership_code?.length > 0
        ) {
          return (
            <IconText
              key={[index]}
              invisible={val.invisible}
              name={val.name}
              icon={val.icon}
              rootStyle={{ cursor: 'pointer' }}
              avatarinactiveStyle={{ borderColor: 'purple.600' }}
              onClick={() => navigate(val.path)}
            />
          );
        }
      })}
    </Box>
  );
}
