/* eslint-disable react/destructuring-assignment */
import { Box, Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import { BpCheckedIcon, BpIcon } from '../checkBox';

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  '.MuiFormControlLabel-label': checked && {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

function MyFormControlLabel(props) {
  let checked = false;
  if (props.value) {
    checked = props.data === props.value;
  }
  return <StyledFormControlLabel checked={checked} {...props} />;
}
MyFormControlLabel.propTypes = {
  value: PropTypes.any,
  data: PropTypes.any,
};

function CustomRadioGroup(props) {
  const {
    className = '',
    rootStyle = {},
    // OnCheck= ()=>{},
    setValue,
    radioList = [],
    value,
    ...rest
  } = props;

  return (
    <Box sx={{ ...rootStyle }} className={`${className}`} {...rest}>
      <RadioGroup>
        <Box>
          <Grid container display="flex" justifyContent="space-between">
            {radioList.map((data, i) => (
              <Grid key={i} item>
                <MyFormControlLabel
                  value={value ?? ''}
                  data={data?.value}
                  onChange={() => setValue(data?.value)}
                  label={data.name.toString()}
                  control={
                    <Radio icon={<BpIcon />} checkedIcon={<BpCheckedIcon />} />
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </RadioGroup>
    </Box>
  );
}

export { CustomRadioGroup };
CustomRadioGroup.propTypes = {
  radioList: PropTypes.array,
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  setValue: PropTypes.string,
  value: PropTypes.any,
};
