/* eslint-disable react/no-unescaped-entities */
import { envConfig } from '@hc/config';
import { Button } from '@hc/ui/atoms';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box, Grid, Stack, Typography } from '@mui/material';
import BackGroundImage from '../../../assets/BG.svg';
import { awarenessSlider_Style } from './style';

export const constructStoryData = (data) => {
  const arr = [];
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);

  if (Array.isArray(data) && data?.length > 0) {
    data?.map((val) => {
      arr.push({
        content: () => {
          return (
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  ...awarenessSlider_Style.rootSx,
                  background:
                    val?.gradient_left_color_code &&
                    val?.gradient_left_color_code
                      ? `linear-gradient(to bottom,  ${val?.gradient_left_color_code}, ${val?.gradient_right_color_code})`
                      : `linear-gradient(to bottom,  #20e3b2, #29ffc6)`,
                  pt: 8,
                }}
              >
                <Stack py={2} alignItems="center">
                  <Typography
                    sx={{
                      fontSize: '24px',
                      fontWeight: 600,
                      color: val?.title_color_code
                        ? val?.title_color_code
                        : '#fff',
                      pl: 3,
                      pr: 3,
                    }}
                  >
                    {val?.title ?? ''}
                  </Typography>
                </Stack>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={val?.awareness_category_note_image}
                    alt="awaress"
                    style={{
                      display: 'block',
                      width: '400px',
                      height: '300px',
                      objectFit: 'contain',
                    }}
                  />
                </Box>

                <Stack py={4} alignItems="center">
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 600,
                      color: val?.content_color_code
                        ? val?.content_color_code
                        : '#fff',
                      textAlign: 'center',
                      pl: 3,
                      pr: 3,
                      letterSpacing: '0px',
                    }}
                  >
                    {val?.content ?? ''}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          );
        },
      });
    });
    if (!tokenData?.user_id) {
      arr.push({
        content: () => {
          return (
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <Grid
                container
                sx={{
                  ...awarenessSlider_Style.rootSx,
                  background: '#E7F7FF',
                }}
              >
                <Grid
                  item
                  height="50vh"
                  xs={12}
                  sm={12}
                  display="flex"
                  justifyContent="center"
                >
                  <Box>
                    <img
                      src={BackGroundImage}
                      style={{ marginTop: '-107px' }}
                      alt=""
                    />
                  </Box>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  height="50vh"
                  item
                  display="flex"
                  justifyContent="center"
                >
                  <Grid
                    container
                    sx={{
                      pt: 2,
                      background: 'linear-gradient(#00E3FD 10%,#007DFE)',
                    }}
                  >
                    <Grid
                      xs={12}
                      sm={12}
                      item
                      display="flex"
                      alignItems="center"
                    >
                      <Typography
                        sx={{
                          fontSize: '24px',
                          fontWeight: 600,
                          color: '#fff',
                          alignSelf: 'center',
                          textAlign: 'center',
                          pl: 2.5,
                          pr: 2.5,
                          letterSpacing: '0px',
                        }}
                      >
                        Discover the secrets to a healthier life!! 😎
                      </Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      item
                      // py={4}
                      display="flex"
                      alignItems="center"
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#fff',
                          alignSelf: 'start',
                          textAlign: 'center',
                          pl: 3,
                          pr: 3,
                          letterSpacing: '0px',
                        }}
                      >
                        "From health awareness to action – your journey starts
                        here"
                      </Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      item
                      display="flex"
                      justifyContent="center"
                    >
                      <Button
                        buttonStyle={{
                          backgroundColor: '#fff',
                          color: '#0098FD',
                          width: '150px',
                          textTransform: 'capitalize',
                          alignSelf: 'start',
                          cursor: 'pointer',
                          zIndex: 1000,
                          position: 'fixed',
                          '&:hover': {
                            backgroundColor: '#dceaf3',
                          },
                        }}
                      >
                        <a
                          style={{ color: '#0098FD', fontWeight: 600 }}
                          href={
                            tokenData?.user_id
                              ? `${envConfig.awareness_share_domain}login`
                              : `${envConfig.awareness_share_domain}createaccount`
                          }
                        >
                          View more
                        </a>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          );
        },
      });
    }
  }

  return arr;
};

export const constructVideoStoryData = (data) => {
  const arr = [];
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);

  if (Array.isArray(data) && data?.length > 0) {
    data?.map((val) => {
      arr.push({
        url: val?.awareness_category_note_image,
        type: 'video',
      });
    });
    if (!tokenData?.user_id) {
      arr.push({
        content: () => {
          return (
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <Grid
                container
                sx={{
                  ...awarenessSlider_Style.rootSx,
                  background: '#E7F7FF',
                }}
              >
                <Grid
                  item
                  height="50vh"
                  xs={12}
                  sm={12}
                  display="flex"
                  justifyContent="center"
                >
                  <Box>
                    <img
                      src={BackGroundImage}
                      style={{ marginTop: '-107px' }}
                      alt=""
                    />
                  </Box>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  height="50vh"
                  item
                  display="flex"
                  justifyContent="center"
                >
                  <Grid
                    container
                    sx={{
                      pt: 2,
                      background: 'linear-gradient(#00E3FD 10%,#007DFE)',
                    }}
                  >
                    <Grid
                      xs={12}
                      sm={12}
                      item
                      display="flex"
                      alignItems="center"
                    >
                      <Typography
                        sx={{
                          fontSize: '24px',
                          fontWeight: 600,
                          color: '#fff',
                          alignSelf: 'center',
                          textAlign: 'center',
                          pl: 2.5,
                          pr: 2.5,
                          letterSpacing: '0px',
                        }}
                      >
                        Discover the secrets to a healthier life!! 😎
                      </Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      item
                      // py={4}
                      display="flex"
                      alignItems="center"
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#fff',
                          alignSelf: 'start',
                          textAlign: 'center',
                          pl: 3,
                          pr: 3,
                          letterSpacing: '0px',
                        }}
                      >
                        "From health awareness to action – your journey starts
                        here"
                      </Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      item
                      display="flex"
                      justifyContent="center"
                    >
                      <Button
                        buttonStyle={{
                          backgroundColor: '#fff',
                          color: '#0098FD',
                          width: '150px',
                          textTransform: 'capitalize',
                          alignSelf: 'start',
                          cursor: 'pointer',
                          zIndex: 1000,
                          position: 'fixed',
                          '&:hover': {
                            backgroundColor: '#dceaf3',
                          },
                        }}
                      >
                        <a
                          style={{ color: '#0098FD', fontWeight: 600 }}
                          href={
                            tokenData?.user_id
                              ? `${envConfig.awareness_share_domain}login`
                              : `${envConfig.awareness_share_domain}createaccount`
                          }
                        >
                          View more
                        </a>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          );
        },
      });
    }
  }
  return arr;
};
