/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-key */
import { Button, Drawer, FilterToggleButton } from '@atoms';
import { useReport } from '@hc/store';
import { Box, Grid, Typography } from '@mui/material';
import { isEqual } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { report_filter_style } from './style';

export function ReportFilter(props) {
  const {
    className = '',
    filterShow,
    onApply = () => {},
    onCancel = () => {},
    setFilterShow,
    filters = [],
    dateFilterData = [],
    handleClickFnc = () => {},
    filterData,
    filterName,
    IsBookAppoinmentReport,
    tabIndex,
    ...rest
  } = props;

  const { loading } = useReport(
    (state) => ({
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  return (
    <Box
      sx={{ ...report_filter_style.rootSx }}
      className={`${className}`}
      {...rest}
    >
      <Drawer
        show={filterShow}
        drawerRightClose
        onCloseDrawer={() => setFilterShow(false)}
        header="Filters"
        closeStyle={{ color: 'grey', height: '14px', width: '12px' }}
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}
        rootStyle={report_filter_style.drawerSx}
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              onClick={() => onCancel()}
              disabled={loading === true}
              buttonStyle={report_filter_style.cancelBtnSx}
            >
              Reset
            </Button>
            <Button
              onClick={() => onApply()}
              loading={loading}
              buttonStyle={report_filter_style.applyBtnSx}
            >
              Apply
            </Button>
          </Box>
        }
      >
        <Box mt={1} mb={1}>
          <Typography fontSize={12} fontWeight={500}>
            {IsBookAppoinmentReport
              ? filterName
              : tabIndex === '2'
              ? 'Case Summary Date'
              : 'Report Date'}
          </Typography>
          <Box sx={report_filter_style.suggestionSx} display="flex">
            <Grid container display="flex" columnSpacing={1}>
              {Array.isArray(dateFilterData) && dateFilterData?.length > 0
                ? dateFilterData.map((value, index) => (
                    <Grid item>
                      <FilterToggleButton
                        key={index}
                        label={value?.label ?? ''}
                        value={value?.value ?? ''}
                        filterType="reportDate"
                        handleClick={handleClickFnc}
                        filterData={filterData}
                      />
                    </Grid>
                  ))
                : null}
            </Grid>
          </Box>
        </Box>
        {filters.map((val, i) => (
          <Box key={i} mt={1} mb={1}>
            <Typography fontSize={12} fontWeight={500}>
              {val?.name}
            </Typography>
            <Box sx={report_filter_style.suggestionSx} display="flex">
              <Grid container display="flex" columnSpacing={1}>
                {Array.isArray(val?.data) && val?.data?.length > 0
                  ? val?.data.map((value, index) => (
                      <Grid item>
                        <FilterToggleButton
                          key={`${i}_${index}`}
                          label={value?.label ?? ''}
                          value={value?.value ?? ''}
                          //  value={ val?.keyName === "reportType" ? value?.label ?? '' :value?.value ?? '' }
                          filterType={val?.keyName ?? ''}
                          handleClick={handleClickFnc}
                          filterData={filterData}
                        />
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Box>
          </Box>
        ))}
      </Drawer>
    </Box>
  );
}
ReportFilter.propTypes = {
  className: PropTypes.string,
  filterShow: PropTypes.bool,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  setFilterShow: PropTypes.bool,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.array,
    }),
  ),
  handleClickFnc: PropTypes.func,
  filterData: PropTypes.object,
  dateFilterData: PropTypes.array,
};
