import { clinicalRoutes } from '@hc/routes';
import { useMBFRewards } from '@hc/store/clinical/mbfRewards';
import { SkeletonCard } from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Awards_style } from './style';

const useIntersectionObserver = (ref, options) => {
  // debugger;
  const [isIntersecting, setIsIntersecting] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }
  }, []);
  return isIntersecting;
};

export default function Awards() {
  // const { rootStyle = {} } = props;
  const [offset, setOffset] = React.useState(0);
  const IntersectionRef = useRef();
  const onScreen = useIntersectionObserver(IntersectionRef, { threshold: 1 });
  const { loading, mbfRewardsState, getMBFRewards, getBadgesByPlanId } =
    useMBFRewards((state) => ({
      loading: state.loading,
      mbfRewardsState: state.mbfRewardsState,
      getMBFRewards: state.getMBFRewards,
      getBadgesByPlanId: state.getBadgesByPlanId,
    }));

  const { mbfRewardsData, userPlanCount } = mbfRewardsState;

  const handleGetBadge = async (data, index) => {
    await getBadgesByPlanId(data, index);
  };

  const initialData = async () => {
    await getMBFRewards(offset, true);
  };

  // ----------------------Handle INFINITE SCROLL-----------------------

  const handleObserver = async () => {
    if (userPlanCount > 0 && userPlanCount > mbfRewardsData?.length) {
      await getMBFRewards(offset + 1, false);
      setOffset(offset + 1);
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  // -------------------------FEtch ON SCROLL------------------------------
  useEffect(() => {
    handleObserver();
  }, [onScreen]);

  return (
    <Screenlayout
      title="Awards"
      appBarStyle={Awards_style.appBarSx}
      backRequired
      notshowFooter
      backIconStyle={{ color: 'mbf.main' }}
      rootStyle={{ bgcolor: 'purple.50' }}
      childrenStyle={Awards_style.childrenSx}
      backRoute={clinicalRoutes?.mindbodyfood}
    >
      {Array.isArray(mbfRewardsData) &&
        mbfRewardsData?.length > 0 &&
        mbfRewardsData.map((val, i) => (
          <>
            <Typography
              sx={{
                pl: 3,
                fontSize: '18px',
                fontWeight: 500,
                color: '#888585',
              }}
            >
              {val?.plan_name ?? ''}
            </Typography>
            <Grid container height="100%" p={2} spacing={2} key={i}>
              {Array.isArray(val?.reward_details) &&
                val?.reward_details?.length > 0 &&
                // eslint-disable-next-line array-callback-return
                val.reward_details.map((card, index) => {
                  if (
                    (val?.offset === 0 && val?.rewards_count === '4') ||
                    (val?.offset === 0 && index < 3) ||
                    (val?.offset > 0 && index < val?.offset * 10 - 1) ||
                    (val?.offset > 0 &&
                      (val?.offset * 10) % val?.rewards_count === 0)
                  ) {
                    return (
                      <Grid item xs={6} key={index}>
                        <Stack sx={Awards_style.cardSx}>
                          <img
                            src={card?.reward_url}
                            alt="award"
                            width={100}
                            style={{ display: 'block', borderRadius: '50%' }}
                          />
                          <Typography
                            sx={{
                              fontSize: '18px',
                              fontWeight: 500,
                              color: '#888585',
                            }}
                          >
                            {card.reward_name}
                          </Typography>
                        </Stack>
                      </Grid>
                    );
                  }
                })}

              {(val?.offset === 0 && val?.rewards_count > 4) ||
              (val?.offset > 0 &&
                (val?.offset * 10) % val?.rewards_count !== 0 &&
                val?.rewards_count > val?.offset * 10) ? (
                <Grid item xs={6} key="add" style={{ opacity: 1 }}>
                  <Stack
                    sx={Awards_style.cardSx}
                    onClick={() => handleGetBadge(val, i)}
                  >
                    <img
                      src={
                        val?.offset === 0
                          ? val?.reward_details?.[3]?.reward_url
                          : val?.reward_details?.[val?.offset * 10 - 1]
                              ?.reward_url
                      }
                      alt="award"
                      width={100}
                      style={{ display: 'block', borderRadius: '50%' }}
                    />
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: 500,
                        color: '#888585',
                      }}
                    >
                      {val?.offset === 0
                        ? val?.reward_details?.[3]?.reward_name
                        : val?.reward_details?.[val?.offset * 10 - 1]
                            ?.reward_name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: 600,
                        color: '#fff',
                        position: 'absolute',
                        zIndex: 100,
                        opacity: 1,
                      }}
                    >
                      {`+${
                        val?.offset === 0
                          ? val?.rewards_count - 4
                          : val?.rewards_count - val?.offset * 10
                      }`}
                    </Typography>
                  </Stack>
                </Grid>
              ) : null}
            </Grid>
          </>
        ))}

      {/* HANDLE EMPTY STATE */}
      {!loading &&
        Array.isArray(mbfRewardsData) &&
        mbfRewardsData?.length === 0 && (
          <Typography
            style={{
              color: '#888888',
              fontSize: '14px',
              fontWeight: 500,
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            No rewards found
          </Typography>
        )}
      {/* LOADER  */}
      {loading &&
        Array(2)
          .fill(1)
          .map((val, i) => <SkeletonCard key={i} award color="#D5CCFF" />)}
      <div ref={IntersectionRef} style={{ marginTop: '-20px', opacity: 0 }}>
        intersection
      </div>
    </Screenlayout>
  );
}
