/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import {
  CalenderColorIcon,
  ClockIcon,
  DataTabs,
  ForwardIcon,
  LeftArrowIcon,
  PreviousIcon,
  RightArrowIcon
} from '@atoms';
import { getDateFormat, getEndTimeFromStartTime } from '@hc/dayjs';
import { useBookAppointment } from '@hc/store';
import { doctorAvailablePayload } from '@hc/utils/helperFunctions';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery
} from '@mui/material';
import {
  addDays,
  addMinutes,
  addMonths,
  differenceInCalendarMonths,
  eachDayOfInterval,
  endOfWeek,
  format,
  isAfter,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths
} from 'date-fns';
import PropTypes from 'prop-types';
import { useState } from 'react';
import isEqual from 'react-fast-compare';

import { calenderSlot_style } from './style';

export function CalenderSlot(props) {
  const { className = '', bookAppointmentStateData, ...rest } = props;
  const media = useMediaQuery('(max-width:360px)');
  const [tabIndex, setTabIndex] = useState('1');

  // co;

  const breaks = [
    {
      value: '1',
      label: 'morning',
    },
    {
      value: '2',
      label: 'afternoon',
    },
    {
      value: '3',
      label: 'evening',
    },
    {
      value: '4',
      label: 'night',
    },
  ];

  const {
    bookAppointmentState,
    // getDoctorsList,
    getDoctorData,
    setStartEndBetween,
    updateBAState,
    selectSlotUpdate,
    constructSlotDataState,
    // eslint-disable-next-line no-unused-vars
    loading,
  } = useBookAppointment(
    (state) => ({
      bookAppointmentState: state.bookAppointmentState,
      getDoctorData: state.getDoctorData,
      // getDoctorsList: state.getDoctorsList,
      setStartEndBetween: state.setStartEndBetween,
      constructSlotDataState: state.constructSlotDataState,
      updateBAState: state.updateBAState,
      selectSlotUpdate: state.selectSlotUpdate,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const {
    morningSlotData,
    afternoonSlotData,
    eveningSlotData,
    nightSlotData,
    selectedSlot,
    selectedDate,
    calenderData,
    doctorSearch,
    languages,
    doctorDetail,
    speciality,
  } = bookAppointmentState;

  const returnTimeSlot = (type) => {
    switch (type) {
      case "1":
        return morningSlotData;
      case '2':
        return afternoonSlotData;
      case "3":
        return eveningSlotData;
      case '4':
        return nightSlotData;
      default:
        return morningSlotData;
    }
  };

  const returnSlotType = (type) => {
    switch (type) {
      case "1":
        return 'morning';
      case "2":
        return 'afternoon';
      case "3":
        return 'evening';
      case "4":
        return 'night';
      default:
        return 'morning';
    }
  };

  // SELECT SLOT FUNCTION
  const onSelectSlot = (value) => {
    const selectedSlotStartTime = value?.client_date_time;
    const selectedSlotEndTime = getEndTimeFromStartTime(
      value?.client_date_time,
      15
    );
    // SELECT SLOT UPDATE
    selectSlotUpdate(selectedSlotStartTime, selectedSlotEndTime, value?.slot);
  };

  // GET DOCTOR LIST FUNCTION
  const getDoctorsListFunction = async (start, doctorSearchFilter) => {
    const payload = {
      ...doctorAvailablePayload(start),
      search:
        doctorSearchFilter === undefined ? doctorSearch : doctorSearchFilter,
      languages: languages ?? [],
      speciality: speciality ? speciality?.toString() : '',
      id: doctorDetail?.id,
    };
    await getDoctorData(payload);
  };

  // GET PREVIOUS WEEK DATA
  const previousWeek = () => {
    const bookAppointmentStateCopy = JSON.parse(
      JSON.stringify(bookAppointmentState)
    );
    const start = format(
      subDays(
        new Date(bookAppointmentStateCopy?.calenderData?.betweenDates[0]),
        7
      ),
      'yyyy,L,dd'
    );
    const end = format(
      subDays(
        new Date(
          bookAppointmentStateCopy?.calenderData?.betweenDates[
            bookAppointmentStateCopy?.calenderData?.betweenDates?.length - 1
          ]
        ),
        7
      ),
      'yyyy,L,dd'
    );
    const betweenDates = eachDayOfInterval({
      start: new Date(start),
      end: new Date(end),
    });
    setStartEndBetween(new Date(start), new Date(end), betweenDates);
    // GET DOCTOR LIST FUNCTION
    getDoctorsListFunction(start);
    // SET START_OF_WEEK, END_OF_WEEK & BETWEEN_DATES IN STORE
  };

  // GET NEXT WEEK DATA
  const nextWeek = () => {
    const bookAppointmentStateCopy = JSON.parse(
      JSON.stringify(bookAppointmentState)
    );
    const start = format(
      addDays(
        new Date(bookAppointmentStateCopy?.calenderData?.betweenDates[0]),
        7
      ),
      'yyyy,L,dd'
    );
    const end = format(
      addDays(
        new Date(
          bookAppointmentStateCopy?.calenderData?.betweenDates[
            bookAppointmentStateCopy?.calenderData?.betweenDates?.length - 1
          ]
        ),
        7
      ),
      'yyyy,L,dd'
    );
    const betweenDates = eachDayOfInterval({
      start: new Date(start),
      end: new Date(end),
    });

    // SET START_OF_WEEK, END_OF_WEEK & BETWEEN_DATES IN STORE
    setStartEndBetween(new Date(start), new Date(end), betweenDates);
    // GET DOCTOR LIST FUNCTION
    getDoctorsListFunction(new Date(start));
  };

  // GET PREVIOUS MONTH DATA
  const previousMonth = () => {
    const bookAppointmentStateCopy = JSON.parse(
      JSON.stringify(bookAppointmentState)
    );
    const current_month = subMonths(
      new Date(
        bookAppointmentStateCopy?.calenderData?.betweenDates[
          bookAppointmentStateCopy?.calenderData?.betweenDates?.length - 1
        ]
      ),
      1
    );
    const start_of_month = startOfMonth(new Date(current_month));
    const start_week = startOfWeek(new Date(start_of_month));
    const end_week = endOfWeek(new Date(start_of_month));
    const betweenDates = eachDayOfInterval({
      start: new Date(format(start_week, 'yyyy,L,dd')),
      end: new Date(format(end_week, 'yyyy,L,dd')),
    });
    // SET START_OF_WEEK, END_OF_WEEK & BETWEEN_DATES IN STORE
    setStartEndBetween(
      new Date(start_week, 'yyyy,L,dd'),
      new Date(end_week, 'yyyy,L,dd'),
      betweenDates
    );
    // GET DOCTOR LIST FUNCTION
    getDoctorsListFunction(start_week);
  };

  // GET NEXT MONTH 7 DATA
  const nextMonth = () => {
    const bookAppointmentStateCopy = JSON.parse(
      JSON.stringify(bookAppointmentState)
    );
    const current_month = addMonths(
      new Date(
        bookAppointmentStateCopy?.calenderData?.betweenDates[
          bookAppointmentStateCopy?.calenderData?.betweenDates?.length - 1
        ]
      ),
      1
    );
    const start_of_month = startOfMonth(new Date(current_month));
    const start_week = startOfWeek(new Date(start_of_month));
    const end_week = endOfWeek(new Date(start_of_month));
    const betweenDates = eachDayOfInterval({
      start: new Date(format(start_week, 'yyyy,L,dd')),
      end: new Date(format(end_week, 'yyyy,L,dd')),
    });
    // SET START_OF_WEEK, END_OF_WEEK & BETWEEN_DATES IN STORE
    setStartEndBetween(new Date(start_week), new Date(end_week), betweenDates);
    // GET DOCTOR LIST FUNCTION
    getDoctorsListFunction(start_week);
  };

  // SELECT DATE FUNCTION
  const selectDate = async (val) => {
    const selectedDat = getDateFormat(val, 'YYYY-MM-DD');
    // UPDATE STATE
    updateBAState('selectedDate', selectedDat);

    const bookAppointmentStateCopy = JSON.parse(
      JSON.stringify(bookAppointmentState)
    );

    // CONSTRUCT SLOT DATA
    const constructSlotData = (seDate, value) => {
      constructSlotDataState(seDate, value);
    };

    const doctorDetail_ =
      await bookAppointmentStateCopy?.doctorAvailableDetailsCopy.filter(
        (val) => val?.id === bookAppointmentStateCopy?.doctorDetail?.id
      )?.[0];

    constructSlotData(selectedDat, doctorDetail_);
  };

  return (
    <Box className={`${className}`} {...rest}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" marginLeft={2}>
          <IconButton disableRipple>
            <CalenderColorIcon />
          </IconButton>
          <Typography sx={calenderSlot_style.CalendarSx}>
            {getDateFormat(
              calenderData?.betweenDates?.[
                calenderData?.betweenDates?.length - 1
              ],
              'MMM, YYYY'
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginRight={2}>
          <IconButton disableRipple onClick={() => previousMonth()}>
            <PreviousIcon />
          </IconButton>
          <IconButton
            disableRipple
            disabled={
              differenceInCalendarMonths(
                startOfMonth(
                  new Date(
                    addMonths(
                      new Date(
                        bookAppointmentState?.calenderData?.betweenDates[
                          bookAppointmentState?.calenderData?.betweenDates
                            ?.length - 1
                        ]
                      ),
                      1
                    )
                  )
                ),
                startOfMonth(new Date())
              ) > 2
            }
          >
            <ForwardIcon
              opacity={
                differenceInCalendarMonths(
                  startOfMonth(
                    new Date(
                      addMonths(
                        new Date(
                          bookAppointmentState?.calenderData?.betweenDates[
                            bookAppointmentState?.calenderData?.betweenDates
                              ?.length - 1
                          ]
                        ),
                        1
                      )
                    )
                  ),
                  startOfMonth(new Date())
                ) > 2
                  ? '0.4'
                  : '1'
              }
              onClick={() => nextMonth()}
            />
          </IconButton>
        </Box>
      </Box>
      <Box sx={calenderSlot_style.rootDateSx}>
        <IconButton disableRipple onClick={() => previousWeek()}>
          <LeftArrowIcon />
        </IconButton>
        <Box sx={calenderSlot_style.dateSelectionWrapperSx}>
          {Array.isArray(calenderData?.betweenDates) &&
          calenderData?.betweenDates?.length > 0
            ? calenderData?.betweenDates?.map((val, i) => (
                <Box
                  key={i}
                  sx={{
                    ...calenderSlot_style.dateSelectionSx,
                    ...(getDateFormat(new Date(val), 'YYYY-MM-DD') ===
                      getDateFormat(new Date(), 'YYYY-MM-DD') &&
                    getDateFormat(new Date(val), 'YYYY-MM-DD') === selectedDate
                      ? calenderSlot_style.todayButton
                      : getDateFormat(new Date(val), 'YYYY-MM-DD') ===
                        getDateFormat(new Date(), 'YYYY-MM-DD')
                      ? calenderSlot_style.unSelectButton
                      : getDateFormat(new Date(val), 'YYYY-MM-DD') <
                        getDateFormat(new Date(), 'YYYY-MM-DD')
                      ? calenderSlot_style.pastButton
                      : getDateFormat(new Date(val), 'YYYY-MM-DD') ===
                        selectedDate
                      ? calenderSlot_style.todayButton
                      : calenderSlot_style.unSelectButton),
                  }}
                  onClick={() =>
                    !(
                      getDateFormat(new Date(val), 'YYYY-MM-DD') <
                      getDateFormat(new Date(), 'YYYY-MM-DD')
                    ) && selectDate(val)
                  }
                >
                  <Box
                    sx={{
                      display: 'grid',
                      placeItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontSize: '14px', fontWeight: 500 }}
                    >
                      {/* {val?.date} */}
                      {getDateFormat(val, 'DD')}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: '10px', fontWeight: 500 }}
                    >
                      {/* {val?.day} */}
                      {getDateFormat(val, 'ddd')}
                    </Typography>
                  </Box>
                </Box>
              ))
            : null}
        </Box>
        <IconButton
          disableRipple
          disabled={
            differenceInCalendarMonths(
              startOfMonth(
                new Date(
                  addDays(
                    new Date(
                      bookAppointmentState?.calenderData?.betweenDates[
                        bookAppointmentState?.calenderData?.betweenDates
                          ?.length - 1
                      ]
                    ),
                    7
                  )
                )
              ),
              startOfMonth(new Date())
            ) > 2
          }
        >
          <RightArrowIcon
            opacity={
              differenceInCalendarMonths(
                startOfMonth(
                  new Date(
                    addDays(
                      new Date(
                        bookAppointmentState?.calenderData?.betweenDates[
                          bookAppointmentState?.calenderData?.betweenDates
                            ?.length - 1
                        ]
                      ),
                      7
                    )
                  )
                ),
                startOfMonth(new Date())
              ) > 2
                ? '0.4'
                : '1'
            }
            onClick={() => nextWeek()}
          />
        </IconButton>
      </Box>
      <Divider
        sx={{ mt: 1.3, border: '.5px solid', borderColor: 'grey.400' }}
      />
      <Box sx={calenderSlot_style.stickySx}>
        <Box>
          <Typography sx={calenderSlot_style.availableTextSx}>
            {/* Slots available on 9th Aug, 2022 */}
            {morningSlotData?.length > 0 ||
            afternoonSlotData?.length > 0 ||
            eveningSlotData?.length > 0 ||
            nightSlotData?.length > 0
              ? `Slots available on ${getDateFormat(
                  selectedDate,
                  'Do MMM,YYYY'
                )}`
              : `No slots available on ${getDateFormat(
                  selectedDate,
                  'Do MMM,YYYY'
                )}`}
          </Typography>
        </Box>
        {(morningSlotData?.length > 0 ||
          afternoonSlotData?.length > 0 ||
          eveningSlotData?.length > 0 ||
          nightSlotData?.length > 0) && (
          <Box my={2}>
            <DataTabs
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
              totalStyle={calenderSlot_style.totalSx}
              tabStyle={calenderSlot_style.tabSx}
              underTabSx={calenderSlot_style.underTabCalenderStyle}
              tabs={breaks}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'grid',
        }}
      >
        <Box>
          {/* Morning Slot */}
          {/* {state?.data?.length > 0 && ( */}

          <Grid container spacing={2}>
            {returnTimeSlot(tabIndex).map((val, i) => (
              <Grid key={i} item xs={media ? 6 : 4}>
                <Box
                  sx={{
                    ...calenderSlot_style.timeSelectionSx,
                    ...(val?.slot === selectedSlot
                      ? calenderSlot_style.selectTimeButton
                      : // : isAfter(new Date(), new Date(val?.client_date_time))
                      // User can book after 15 mins slots only. (If time is 9.46 pm user only book after 10.01 slot only)
                      isAfter(
                          addMinutes(new Date(), 15),
                          new Date(val?.client_date_time)
                        )
                      ? calenderSlot_style.pastTimeButton
                      : calenderSlot_style.unSelectTimeButton),
                  }}
                  onClick={() =>
                    // !isAfter(new Date(), new Date(val?.client_date_time)) &&
                    // User can book after 15 mins slots only. (If time is 9.46 pm user only book after 10.01 slot only)
                    !isAfter(
                      addMinutes(new Date(), 15),
                      new Date(val?.client_date_time)
                    ) && onSelectSlot(val)
                  }
                >
                  <Box sx={calenderSlot_style.iconWithTimeSelectionSx}>
                    {val?.slot === selectedSlot ? (
                      <IconButton disableRipple>
                        <ClockIcon
                          rootStyle={calenderSlot_style.SelectIconChange}
                        />
                      </IconButton>
                    ) : (
                      <IconButton disableRipple>
                        <ClockIcon
                          rootStyle={calenderSlot_style.unSelectIconChange}
                        />
                      </IconButton>
                    )}

                    <Typography
                      variant="body1"
                      sx={calenderSlot_style.timeTextSx}
                    >
                      {val?.slot}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>

          {/* // )} */}
        </Box>
      </Box>
      {(morningSlotData?.length > 0 ||
        afternoonSlotData?.length > 0 ||
        eveningSlotData?.length > 0 ||
        nightSlotData?.length > 0) &&
        returnTimeSlot(tabIndex)?.length === 0 && (
          <Typography
            variant="body1"
            sx={{ ...calenderSlot_style.timeTextSx, ml: 1.6 }}
          >
            {`No slots available at ${returnSlotType(tabIndex)}`}
          </Typography>
        )}
    </Box>
  );
}
CalenderSlot.propTypes = {
  morningSlotData: PropTypes.array,
  onSelectSlot: PropTypes.func,
  afternoonSlotData: PropTypes.array,
  eveningSlotData: PropTypes.array,
  nightSlotData: PropTypes.array,
  selectedSlot: PropTypes.string,
  className: PropTypes.node,
  sx: PropTypes.object,
};
