import { clinicalRoutes } from '@hc/routes';
import { useAwareness } from '@hc/store/clinical/awareness';
import {
  AwrSpeakerIcon,
  Button,
  ForwardIconAlt,
  ForwardIconBtn,
  HealthCircleIcon,
} from '@hc/ui/atoms';
import { SkeletonCard } from '@hc/ui/components';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Link, useNavigate } from 'react-router-dom';
import { clinical_landing_page_style } from './style';

export default function ClinicalLandingPage() {
  const navigate = useNavigate();
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);
  const [isLengthTrue, setIsLenthTrue] = useState(false);
  const [awareData, setAwareData] = useState(false);

  const { awarenessState, getAwarenessData, updateAwarenessState, loading } =
    useAwareness(
      (state) => ({
        updateAwarenessState: state.updateAwarenessState,
        awarenessState: state.awarenessState,
        getAwarenessData: state.getAwarenessData,
        loading: state.loading,
      }),
      (prev, curr) => isEqual(prev, curr),
    );

  const { awarenessData } = awarenessState;

  const getInitialData = async () => {
    queryClient.invalidateQueries({
      queryKey: ['getAwarenessData'],
    });
    await getAwarenessData();
  };

  const onLoginClick = () => {
    navigate(clinicalRoutes?.login);
  };

  const onViewMoreClick = () => {
    setAwareData(awarenessData);
    setIsLenthTrue(false);
  };

  // GET AWARENESS LIST DATA
  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (awarenessData?.length > 4) {
      setIsLenthTrue(true);
      const value = awarenessData?.slice(0, 3);
      setAwareData(value);
    } else {
      setIsLenthTrue(false);
      setAwareData(awarenessData);
    }
  }, [awarenessData]);

  return (
    <Box sx={clinical_landing_page_style.rootSx}>
      <Box sx={{ pt: 2 }}>
        <Box sx={clinical_landing_page_style.rootSx}>
          {/* EmptyState Design */}
          {!loading && (
            <Box>
              <Grid
                container
                sx={{
                  height: awarenessData?.length > 2 ? '72vh' : '54vh',
                  overflowY: 'scroll',
                }}
                spacing={0.5}
              >
                <Grid item xs={12}>
                  <Box pl={2} mb={1} display="flex">
                    <HealthCircleIcon />
                    <Typography fontWeight={600} color="#007965" ml={1}>
                      Health Circles
                    </Typography>
                  </Box>

                  <Typography pl={2} fontSize={14} fontWeight={600}>
                    {' '}
                    Being Aware: What aspects of awareness are you curious
                    about?
                  </Typography>
                </Grid>
                {Array.isArray(awareData) && awareData?.length > 0 ? (
                  awareData.map((val, i) => (
                    <Grid
                      key={i}
                      item
                      xs={6}
                      sx={{
                        display: 'flex',
                        justifyContent: 'start',
                      }}
                      onClick={() => {
                        if (data?.user_id) {
                          navigate(clinicalRoutes?.awarenessCategory);
                        } else {
                          navigate('/awarenesscategory');
                        }
                        updateAwarenessState('awareness_id', val.awareness_id);
                        updateAwarenessState(
                          'awareness_name',
                          val.awareness_name,
                        );
                      }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                          borderRadius: '8px',
                          cursor: 'pointer',
                          overflow: 'hidden',
                        }}
                      >
                        <img
                          src={val.awareness_image}
                          loading="lazy"
                          alt="Awarenesss"
                          style={{
                            borderRadius: '10px',
                            width: '100%',
                            objectFit: 'cover',
                          }}
                        />
                        <Typography sx={clinical_landing_page_style.titleSx}>
                          {val.awareness_name}{' '}
                        </Typography>
                        <Box sx={clinical_landing_page_style.navSx}>
                          <ForwardIconBtn />
                        </Box>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Typography
                    textAlign="center"
                    py={15}
                    color="#616161"
                    fontWeight={600}
                  >
                    No Awareness Data Found
                  </Typography>
                )}
                {isLengthTrue && (
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        border: '1px dashed #007965',
                        height: '200px',
                        width: '159px',
                        borderRadius: '8px',
                        mx: 2,
                        my: 1.6,
                        cursor: 'pointer',
                      }}
                      onClick={() => onViewMoreClick()}
                    >
                      <Box display="flex" height={139} justifyContent="center">
                        <Box alignSelf="center">
                          <AwrSpeakerIcon />
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          fontSize={12}
                          color="#007965"
                          fontWeight={600}
                          textAlign="center"
                        >
                          View more awareness
                        </Typography>
                        <Box pl={1.5} pt={0.5}>
                          <ForwardIconAlt />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                alignSelf="end"
                sx={clinical_landing_page_style.footerSx}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Typography sx={clinical_landing_page_style.lastText}>
                    Already a member?
                  </Typography>
                </Grid>
                <Grid item px={4} xs={12} sm={12} md={12}>
                  <Button
                    fullWidth
                    onClick={() => onLoginClick()}
                    loading={loading}
                    buttonStyle={clinical_landing_page_style.buttonsStyle}
                  >
                    Login
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography sx={clinical_landing_page_style.toMoreSx}>
                    To explore more:
                    <Link
                      to={clinicalRoutes.signup}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography
                        variant="body1"
                        sx={clinical_landing_page_style.signSx}
                      >
                        Sign up
                      </Typography>
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
      {/* Loader */}
      {loading &&
        Array(2)
          .fill(1)
          .map((val, i) => (
            <SkeletonCard key={i} award color="#8ee0d3" awardTitle={false} />
          ))}
    </Box>
  );
}
