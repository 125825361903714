/* eslint-disable no-unused-vars */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient
} from '@hc/utils';
import { toast } from 'react-hot-toast';
import create from 'zustand';

export const useMBFRewards = create((set, get) => ({
  mbfRewardsState: {
    mbfRewardsData: [],
    userPlanCount: 0,
    mbfRewardsPageLimit: 10,
  },
  loading: null,
  error: null,
  getMBFRewards: async (offset, initialCall) => {
    const { mbfRewardsState } = get();
    const { mbfRewardsData, mbfRewardsPageLimit } = mbfRewardsState;
    try {
      queryClient.invalidateQueries({
        queryKey: [`mbfGetPlanRewards-${offset}`],
      });
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: [`mbfGetPlanRewards-${offset}`],
        queryFn: async () => {
          const data = await httpRequest(
            'post',
            `${envConfig.api_url}/mbf/get-rewards`,
            { user_id: tokenData?.user_id, offset, limit: mbfRewardsPageLimit },
            true
          );
          return data;
        },
        staleTime: Infinity,
      });
      if (status === 200) {
        if (initialCall) {
          set(() => ({
            loading: false,
            mbfRewardsState: {
              ...mbfRewardsState,
              mbfRewardsData:
                Array.isArray(data?.data?.data) && data?.data?.data?.length > 0
                  ? data?.data?.data
                  : [],
              userPlanCount: data?.data?.count ?? 0,
            },
          }));
        } else {
          set(() => ({
            loading: false,
            mbfRewardsState: {
              ...mbfRewardsState,
              mbfRewardsData:
                Array.isArray(data?.data?.data) && data?.data?.data?.length > 0
                  ? [...mbfRewardsData, ...data?.data?.data]
                  : mbfRewardsData,
              userPlanCount: data?.data?.count ?? 0,
            },
          }));
        }
      } else {
        set({ loading: false });
        return toast.error('Something went wrong please try again!');
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  getBadgesByPlanId: async (objData, index) => {
    const { mbfRewardsState } = get();
    const { mbfRewardsData, mbfRewardsPageLimit } = mbfRewardsState;
    try {
      queryClient.invalidateQueries({
        queryKey: [`getBadgesByPlanId-${objData?.offset}`],
      });
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: [`getBadgesByPlanId-${objData?.offset}`],
        queryFn: async () => {
          const data = await httpRequest(
            'post',
            `${envConfig.api_url}/mbf/get-rewards-by-plan-id`,
            {
              mbf_user_plan_id: objData?.mbf_user_plan_id,
              offset: objData?.offset,
              limit: objData?.limit,
            },
            true
          );
          return data;
        },
        staleTime: Infinity,
      });
      if (status === 200) {
        if (objData?.offset === 0) {
          const mbfRewardsDataCopy = JSON.parse(JSON.stringify(mbfRewardsData));
          // eslint-disable-next-line operator-assignment
          mbfRewardsDataCopy[index].offset =
            mbfRewardsDataCopy?.[index]?.offset + 1;
          mbfRewardsDataCopy[index].reward_details =
            Array.isArray(data?.data) && data?.data?.length > 0
              ? data?.data
              : [];
          set(() => ({
            loading: false,
            mbfRewardsState: {
              ...mbfRewardsState,
              mbfRewardsData: mbfRewardsDataCopy,
            },
          }));
        } else {
          const mbfRewardsDataCopy = JSON.parse(JSON.stringify(mbfRewardsData));
          // eslint-disable-next-line operator-assignment
          mbfRewardsDataCopy[index].offset =
            mbfRewardsDataCopy?.[index]?.offset + 1;
          mbfRewardsDataCopy[index].reward_details =
            Array.isArray(data?.data) && data?.data?.length > 0
              ? [...mbfRewardsDataCopy[index].reward_details, ...data?.data]
              : mbfRewardsDataCopy[index].reward_details;
          set(() => ({
            loading: false,
            mbfRewardsState: {
              ...mbfRewardsState,
              mbfRewardsData: mbfRewardsDataCopy,
            },
          }));
        }
      } else {
        set({ loading: false });
        return toast.error('Something went wrong please try again!');
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
