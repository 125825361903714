/* eslint-disable no-lonely-if */
/* eslint-disable no-restricted-syntax */
import { clinicalRoutes } from '@hc/routes';
import { useProfile, useVitalMonitoring } from '@hc/store';
import {
  BmiBgIcon,
  BpIcon,
  Button,
  ClockIcon,
  Drawer,
  HeightIcon,
  InfoIcon,
  Input,
  PulseIcon,
  SpoIcon,
  TemperatureIcon,
  WeightIcon,
} from '@hc/ui/atoms';
import {
  Screenlayout,
  VitalMonitoringCard,
  VitalTemperatureSlider,
} from '@hc/ui/components';
import { isValidBloodPressure, queryClient } from '@hc/utils';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { vital_monitoring_style } from './style';

export default function VitalMonitoring(props) {
  const { className = '', ...rest } = props;
  const navigate = useNavigate();
  const [openInfoDrawer, setOpenInfoDrawer] = useState();
  const [infoDrawerContent, setInfoDrawerContent] = useState();
  const [dataDrawer, setDataDrawer] = useState();
  const [infoText, setInfoText] = useState([]);
  const [bmiDrawer, setBmiDrawer] = useState();

  const { profile, get } = useProfile(
    (state) => ({
      profile: state.profile,
      get: state.get,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const {
    updateVitalData,
    getVitalLastUpdateHistoryData,
    vitalMonitoringState,
    upserVitalData,
    updateStatevalue,
    loading,
  } = useVitalMonitoring(
    (state) => ({
      updateStatevalue: state.updateStatevalue,
      upserVitalData: state.upserVitalData,
      updateVitalData: state.updateVitalData,
      vitalMonitoringState: state.vitalMonitoringState,
      getVitalLastUpdateHistoryData: state.getVitalLastUpdateHistoryData,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { dataDrawerContent } = vitalMonitoringState;

  const date = new Date(vitalMonitoringState?.vitalLastUpdateData?.updated_at);
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const getDate = date.getDate();
  const getMonth = date.getMonth();
  const monthAbbreviation = monthNames[getMonth];
  const lastUpdatedDate = `${monthAbbreviation} ${getDate}`;

  const data = [
    {
      icon: <BpIcon />,
      type: 'Blood Pressure',
      subType: 'mmHg',
      key: 'blood_pressure',
      keyValue: 1,
      value: profile?.blood_pressure ?? '',
      isAdd: profile?.blood_pressure?.length === 0 ? true : false,
      content: `Blood pressure (BP) is measured to assess the force of blood against the walls of the arteries. It is an essential indicator of cardiovascular health and helps in diagnosing conditions like hypertension (high blood pressure) or hypotension (low blood pressure)./

        Normal Blood Pressure Ranges:/
        
        Adults (18 years and older):/
        
        Normal: Systolic < 120 mm Hg and Diastolic < 80 mm Hg/
        
        Elevated: Systolic 120-129 mm Hg and Diastolic < 80 mm Hg/
        
        Hypertension Stage 1: Systolic 130-139 mm Hg or Diastolic 80-89 mm Hg/
        
        Hypertension Stage 2: Systolic ≥ 140 mm Hg or Diastolic ≥ 90 mm Hg/
        
        Children (age-dependent):/
        
        Normal ranges vary with age. A healthcare provider will compare a child's blood pressure to age-appropriate norms.`,
    },
    {
      icon: <PulseIcon />,
      type: 'Pulse',
      subType: 'bpm',
      key: 'puls_bpm',
      keyValue: 2,
      value: profile?.puls_bpm ?? '',
      isAdd: profile?.puls_bpm?.length === 0 ? true : false,
      content: `Pulse is measured to assess heart rate, a key indicator of cardiovascular health. /The procedure involves feeling the rhythmic pulsation of an artery, often at the wrist. /Normal pulse rates vary by age and activity level:

      Adults (18 and older): Normal resting heart rate is generally 60-100 beats per minute./
      
      Children (age-dependent): Normal pulse rates vary by age, with newborns having higher rates (120-160 bpm) that gradually decrease with age./
      
      Athletes: Athletes may have lower resting heart rates, often below 60 bpm, reflecting their cardiovascular conditioning.`,
    },
    {
      icon: <TemperatureIcon />,
      type: 'Temperature',
      subType: 'Fo',
      key: 'temp_in_f',
      keyValue: 3,
      value: profile?.temp_in_f ?? '',
      isAdd: profile?.temp_in_f?.length === 0 ? true : false,
      content: `Temperature is measured to assess body health and detect fever, an indicator of infection or illness. The procedure involves using a thermometer, typically placed under the tongue, in the ear, or on the forehead. /Normal body temperature ranges are:

      Adults: Normal oral temperature is around 98.6°F (37°C)./
      
      Children: Normal ranges can vary but are generally similar to adults.`,
    },
    {
      icon: <SpoIcon />,
      type: 'Blood Oxygen',
      subType: '%',
      key: 'o2_level',
      keyValue: 4,
      value: profile?.o2_level ?? '',
      isAdd: profile?.o2_level?.length === 0 ? true : false,
      content: `Oxygen level, or oxygen saturation, is measured to assess the amount of oxygen in the bloodstream. 
      /This is crucial for monitoring respiratory health. The procedure involves using a pulse oximeter, typically placed on a finger. /Normal oxygen saturation levels are:

        Normal Range: 95-100%/
        
        If oxygen levels fall below the normal range, it may indicate respiratory or cardiovascular issues./ Monitoring oxygen saturation is especially important for individuals with respiratory conditions`,
    },

    {
      icon: <HeightIcon />,
      type: 'Height',
      subType: 'cm',
      key: 'height',
      keyValue: 5,
      value: profile?.height ?? '',
      isAdd: profile?.height?.length === 0 ? true : false,
      content: `Height is measured to assess physical growth and development, providing valuable information about an individual's health and well-being./ The procedure involves using a stadiometer or similar device, with the person standing straight and measuring from the base to the top of the head. Normal height ranges vary by age and gender:/

        Children: Growth charts are used to compare a child's height to age-appropriate norms/.
        
        Adults: Average height varies globally, but for general reference:/
        
        Men: Around 5.5 to 6.2 feet (167 to 188 cm)/
        
        Women: Around 5.1 to 5.8 feet (155 to 175 cm)/`,
    },
    {
      icon: <WeightIcon />,
      type: 'Weight',
      subType: 'kg',
      key: 'weight',
      keyValue: 6,
      value: profile?.weight ?? '',
      isAdd: profile?.weight?.length === 0 ? true : false,
      content: `Weight is measured to assess an individual's body mass, providing important information about overall health and nutritional status. The procedure involves using a scale, with the person standing still and weight evenly distributed. Normal weight ranges can vary:/

       Children: Growth charts help assess a child's weight compared to age-appropriate norms./
       
       Adults: Average weight varies based on factors such as height, body composition, and gender./
       
       For reference:/
       
       Men: Typically, a healthy weight falls within the BMI range of 18.5 to 24.9./
       
       Women: Similarly, a healthy weight is often within the BMI range of 18.5 to 24.9./`,
    },
  ];

  const onCardClick = (obj) => {
    updateStatevalue('dataDrawerContent', obj);
    navigate(clinicalRoutes?.vitalHistory);
  };

  const onInfoClick = (obj) => {
    // debugger
    setOpenInfoDrawer(true);
    setInfoDrawerContent(obj);
    const slicedVal = obj?.content?.split('/');
    setInfoText(slicedVal);
  };

  const onCloseInfoDrawer = () => {
    setOpenInfoDrawer(false);
  };

  const onCloseDataDrawer = () => {
    setDataDrawer(false);
  };

  const onAddOrUpdateClick = async (obj) => {
    queryClient.invalidateQueries({
      queryKey: ['vitalLastUpdate'],
    });

    const response = await getVitalLastUpdateHistoryData(obj?.keyValue);
    if (response?.code === '200') {
      updateStatevalue('dataDrawerContent', obj);
      setDataDrawer(true);
    }
  };

  const getInitialData = async () => {
    await get();
  };

  const toFindBMICategory = (BMI_Value) => {
    if (parseFloat(BMI_Value) < 18.5) {
      return 'Underweight';
    }
    if (parseFloat(BMI_Value) > 18.5 && parseFloat(BMI_Value) < 25) {
      return 'Normal';
    }
    if (parseFloat(BMI_Value) > 25 && parseFloat(BMI_Value) < 30) {
      return 'Overweight';
    }
    if (parseFloat(BMI_Value) > 30) {
      return 'Obesity';
    }
    return 'Normal';
  };

  const isIamValidToSave = (key) => {
    const vitalCopy = JSON.parse(JSON.stringify(vitalMonitoringState?.vital));

    let isValid = true;
    let error = JSON.parse(
      JSON.stringify(vitalMonitoringState?.error?.[dataDrawerContent?.key]),
    );
    if (key === 'o2_level') {
      if (vitalCopy?.o2_level.length > 0) {
        // Checking o2_level pressure
        if (vitalCopy.o2_level < 95 || vitalCopy.o2_level > 100) {
          isValid = false;
          error = 'Range between 95 and 100';
        } else {
          error = '';
        }
      } else {
        isValid = false;
        error = 'Range between 95 and 100';
      }
    } else if (key === 'puls_bpm') {
      // Checking puls_bpm pressure

      if (vitalCopy?.puls_bpm.length > 0) {
        if (vitalCopy.puls_bpm < 60 || vitalCopy.puls_bpm > 100) {
          isValid = false;
          error = 'Range between 60 and 100';
        } else {
          error = '';
        }
      } else {
        isValid = false;
        error = 'Range between 60 and 100';
      }
    } else if (key === 'temp_in_f') {
      // Checking temp_in_f pressure
      if (vitalCopy?.temp_in_f > 0) {
        if (vitalCopy.temp_in_f < 80 || vitalCopy.temp_in_f > 120) {
          isValid = false;
          error = 'Range between 80 and 120';
        } else {
          error = '';
        }
      } else {
        isValid = false;
        error = 'Range between 80 and 120';
      }
    } else if (key === 'blood_pressure') {
      // Checking blood pressure
      const value = `${
        vitalMonitoringState?.vital?.[dataDrawerContent?.key]?.SBP
      }/${vitalMonitoringState?.vital?.[dataDrawerContent?.key]?.DBP}`;
      if (value.length > 0) {
        if (isValidBloodPressure(value)) {
          if (value?.includes('/')) {
            if (value?.split('/')[0] && value?.split('/')[1]) {
              if (
                value?.split('/')[0] < 90 ||
                value?.split('/')[0] > 120 ||
                value?.split('/')[1] < 60 ||
                value?.split('/')[1] > 80
              ) {
                isValid = false;
                error = 'Range between 90/60mmHg and 120/80mmHg';
              } else {
                error = '';
              }
            } else {
              isValid = false;
              error = 'Range between 90/60mmHg and 120/80mmHg';
            }
          } else {
            isValid = false;
            error = 'Range between 90/60mmHg and 120/80mmHg';
          }
        } else {
          isValid = false;
          error = 'Range between 90/60mmHg and 120/80mmHg';
        }
      } else {
        isValid = false;
        error = 'Range between 90/60mmHg and 120/80mmHg';
      }
    } else if (key === 'height') {
      if (!vitalCopy?.height?.length > 0) {
        isValid = false;
        error = 'Please enter your height';
      } else {
        error = '';
      }
    } else {
      if (!vitalCopy?.weight?.length > 0) {
        isValid = false;
        error = 'Please enter your weight';
      } else {
        error = '';
      }
    }

    // UPDATE PROFILE ERROR
    updateVitalData('error', error, dataDrawerContent?.key);
    return isValid;
  };

  const onInfoBmiClick = () => {
    setBmiDrawer(true);
    const obj = {
      type: 'BMI',
      content: `The Body Mass Index (BMI) is a numerical measure of an individual's body mass based on their height and weight./ The BMI value range is categorized as follows:/

      Underweight: BMI less than 18.5/
      
      Normal weight: BMI between 18.5 and 24.9/
      
      Overweight: BMI between 25 and 29.9/
      
      Obese: BMI 30 or greater`,
    };

    setInfoDrawerContent(obj);
    const slicedVal = obj?.content?.split('/');
    setInfoText(slicedVal);
  };

  const onInfoBmiDrawerClose = () => {
    setBmiDrawer(false);
  };

  const onSubmit = async () => {
    const result = isIamValidToSave(dataDrawerContent?.key);
    if (result) {
      queryClient.invalidateQueries({
        queryKey: ['vitalLastUpsert'],
      });
      let response;
      if (dataDrawerContent?.keyValue !== 1) {
        if (
          vitalMonitoringState?.vital?.[dataDrawerContent?.key] !==
          profile?.[dataDrawerContent?.key]
        ) {
          response = await upserVitalData(
            dataDrawerContent?.keyValue,
            vitalMonitoringState?.vital?.[dataDrawerContent?.key],
          );
        } else {
          toast('You must change the value and proceed', { icon: '⚠️' });
        }
      } else {
        const value = `${
          vitalMonitoringState?.vital?.[dataDrawerContent?.key]?.SBP
        }/${vitalMonitoringState?.vital?.[dataDrawerContent?.key]?.DBP}`;
        if (value !== profile?.blood_pressure) {
          response = await upserVitalData(dataDrawerContent?.keyValue, value);
        } else {
          toast('You must change the value and proceed', { icon: '⚠️' });
        }
      }

      if (response?.code === '200') {
        setDataDrawer(false);
        getInitialData();
      }
    }
  };

  const updateVitalsData = (key, value, subKey, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    updateVitalData(key, value, subKey);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    const bloodPressure = profile?.blood_pressure.split('/');

    const data = [
      { key: 'blood_pressure', subKey: 'SBP', value: bloodPressure[0] },
      { key: 'blood_pressure', subKey: 'DBP', value: bloodPressure[1] },
      { key: 'puls_bpm', subKey: '', value: profile?.puls_bpm ?? '' },
      { key: 'temp_in_f', subKey: '', value: profile?.temp_in_f ?? '' },
      { key: 'o2_level', subKey: '', value: profile?.o2_level ?? '' },
      { key: 'height', subKey: '', value: profile?.height ?? '' },
      { key: 'weight', subKey: '', value: profile?.weight ?? '' },
    ];

    for (const object of data) {
      updateVitalData(object?.key, object?.value, object?.subKey);
    }
  }, [profile]);

  return (
    <Screenlayout
      notshowFooter
      masterchildStyle={{ py: 0, pb: { xs: 0 } }}
      backRequired
      title="Vital"
      className={`${className}`}
    >
      <Box sx={{ ...vital_monitoring_style.rootSx }} {...rest}>
        <Grid container spacing={2.5}>
          {data?.map((obj, i) => (
            <Grid item key={i} xs={6}>
              <VitalMonitoringCard
                icon={obj?.icon}
                type={obj?.type}
                subType={obj?.subType}
                value={obj?.value}
                isAdd={obj?.isAdd}
                onCardClick={() => onCardClick(obj)}
                onInfoClick={() => onInfoClick(obj)}
                onAddOrUpdateClick={() => onAddOrUpdateClick(obj)}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Box
              sx={{
                py: 1,
                px: 1.5,
                bgcolor: '#fff',
                borderRadius: 1.5,
              }}
            >
              <Grid container display="flex" justifyContent="space-between">
                <Grid item display="flex">
                  <BmiBgIcon
                    rootStyle={{ height: '32px', width: '32px', mt: '1.5px' }}
                  />
                  <Box alignSelf="center">
                    <Typography
                      ml={1}
                      fontSize={14}
                      color="#5C6266"
                      fontWeight={500}
                    >
                      BMI
                    </Typography>
                  </Box>
                </Grid>
                <Grid item alignSelf="center" justifyContent="end">
                  <Box display="flex">
                    <Typography fontWeight={600}>{profile?.bmi}</Typography>
                    <Typography
                      mt="4px"
                      ml="4px"
                      fontSize={12}
                      color={
                        toFindBMICategory(profile?.bmi) === 'Underweight'
                          ? '#01b0f1'
                          : toFindBMICategory(profile?.bmi) === 'Normal'
                          ? '#92d14f'
                          : toFindBMICategory(profile?.bmi) === 'Overweight'
                          ? '#ffff01'
                          : '#fe0000'
                      }
                    >
                      {toFindBMICategory(profile?.bmi)}
                    </Typography>
                    <Box
                      sx={{ cursor: 'pointer' }}
                      onClick={() => onInfoBmiClick()}
                    >
                      <InfoIcon rootStyle={{ color: '#888888' }} />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Drawer
          show={openInfoDrawer}
          onCloseDrawer={() => onCloseInfoDrawer()}
          header={infoDrawerContent?.type}
          height="50vh"
          headerStyle={{ fontWeight: 600 }}
          footer={
            <Box>
              <Button
                buttonStyle={{
                  bgcolor: '#DCF5F1',
                  color: 'primary.main',
                  textTransform: 'capitalize',
                  '&:hover': {
                    bgcolor: '#DCF5F1',
                    color: 'primary.main',
                  },
                }}
                onClick={() => onCloseInfoDrawer()}
              >
                Close
              </Button>
            </Box>
          }
        >
          <Box p={1}>
            {infoText?.map((text, i) => (
              <Typography key={i} mb={1} fontSize={14} color="#5C6266">
                {text}
              </Typography>
            ))}
          </Box>
        </Drawer>

        <Drawer
          show={bmiDrawer}
          onCloseDrawer={() => onCloseInfoDrawer()}
          header={infoDrawerContent?.type}
          height="50vh"
          headerStyle={{ fontWeight: 600 }}
          footer={
            <Box>
              <Button
                buttonStyle={{
                  bgcolor: '#DCF5F1',
                  color: 'primary.main',
                  textTransform: 'capitalize',
                  '&:hover': {
                    bgcolor: '#DCF5F1',
                    color: 'primary.main',
                  },
                }}
                onClick={() => onInfoBmiDrawerClose()}
              >
                Close
              </Button>
            </Box>
          }
        >
          <Box p={1}>
            {infoText?.map((text, i) => (
              <Typography key={i} mb={1} fontSize={14} color="#5C6266">
                {text}
              </Typography>
            ))}
          </Box>
        </Drawer>
        <Drawer
          show={dataDrawer}
          onCloseDrawer={() => onCloseDataDrawer()}
          header={dataDrawerContent?.type}
          height="40vh"
          headerStyle={{ fontWeight: 600 }}
          footer={
            <Grid container columnSpacing={2}>
              <Grid item xs={6} sm={6} md={6}>
                <Button
                  onClick={() => onCloseDataDrawer()}
                  buttonStyle={{
                    bgcolor: '#DCF5F1',
                    color: 'primary.main',
                    textTransform: 'capitalize',
                    '&:hover': {
                      bgcolor: '#DCF5F1',
                      color: 'primary.main',
                    },
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Button
                  onClick={() => onSubmit()}
                  buttonStyle={{ textTransform: 'capitalize' }}
                  loading={loading}
                >
                  confirm
                </Button>
              </Grid>
            </Grid>
          }
        >
          <Box p={1}>
            <Typography
              mt={2}
              fontSize={20}
              textAlign="center"
              fontWeight={600}
            >
              {dataDrawerContent?.type === 'Temperature'
                ? 'What is your temperature?'
                : 'Enter the Value'}
            </Typography>
            {dataDrawerContent?.type === 'Blood Pressure' ? (
              <Box>
                <Box display="flex" mt={5} justifyContent="center">
                  <Input
                    type="number"
                    textFieldStyle={{
                      ...vital_monitoring_style.inputSx,
                      mr: '14px',
                    }}
                    onChange={(event) =>
                      updateVitalsData(
                        'blood_pressure',
                        event?.target.value,
                        'SBP',
                        3,
                      )
                    }
                    value={
                      vitalMonitoringState?.vital?.blood_pressure?.SBP ?? ''
                    }
                  />

                  <Typography mt="12px">/</Typography>
                  <Input
                    textFieldStyle={{
                      ...vital_monitoring_style.inputSx,
                      ml: '14px',
                    }}
                    type="number"
                    onChange={(event) =>
                      updateVitalsData(
                        'blood_pressure',
                        event?.target.value,
                        'DBP',
                        2,
                      )
                    }
                    value={
                      vitalMonitoringState?.vital?.blood_pressure?.DBP ?? ''
                    }
                  />
                </Box>
                {vitalMonitoringState.error?.blood_pressure.length > 0 && (
                  <Typography
                    mt={1}
                    textAlign="center"
                    fontSize={12}
                    color="	#ff3333"
                  >
                    {vitalMonitoringState.error?.blood_pressure}
                  </Typography>
                )}
              </Box>
            ) : dataDrawerContent?.type === 'Temperature' ? (
              <Box>
                <Box display="flex" justifyContent="center" mt={3}>
                  <Typography
                    mb={2}
                    fontSize={18}
                    color="#101010"
                    fontWeight={600}
                  >
                    {`${vitalMonitoringState?.vital?.temp_in_f ?? ''} F`}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                  <VitalTemperatureSlider
                    min={80}
                    max={120}
                    step={0.1}
                    value={vitalMonitoringState?.vital?.temp_in_f ?? ''}
                    onChange={(value) =>
                      updateVitalsData('temp_in_f', value, '', 3)
                    }
                  />
                </Box>
                {vitalMonitoringState.error?.temp_in_f.length > 0 && (
                  <Typography
                    mt={1}
                    textAlign="center"
                    fontSize={12}
                    color="	#ff3333"
                  >
                    {vitalMonitoringState.error?.temp_in_f}
                  </Typography>
                )}
              </Box>
            ) : (
              <Box>
                <Box display="flex" mt={5} justifyContent="center">
                  <Input
                    textFieldStyle={vital_monitoring_style.inputSx}
                    onChange={(event) =>
                      updateVitalsData(
                        dataDrawerContent?.key,
                        event?.target.value,
                        '',
                        3,
                      )
                    }
                    type="number"
                    value={
                      vitalMonitoringState?.vital?.[dataDrawerContent?.key] ??
                      ''
                    }
                  />
                </Box>
                {vitalMonitoringState.error?.[dataDrawerContent?.key]?.length >
                  0 && (
                  <Typography
                    mt={1}
                    textAlign="center"
                    fontSize={12}
                    color="	#ff3333"
                  >
                    {vitalMonitoringState.error?.[dataDrawerContent?.key]}
                  </Typography>
                )}
              </Box>
            )}
            {!dataDrawerContent?.isAdd && (
              <Box display="flex" mt={5.8} justifyContent="center">
                <ClockIcon rootStyle={{ color: '#888888' }} />
                <Typography
                  fontSize={12}
                  ml={1}
                  color="#888888"
                  fontWeight={500}
                >
                  Last recorded on
                </Typography>
              </Box>
            )}
            {!dataDrawerContent?.isAdd && (
              <Typography
                mt={1}
                fontSize={12}
                fontWeight={600}
                textAlign="center"
              >
                {lastUpdatedDate}
              </Typography>
            )}
          </Box>
        </Drawer>
      </Box>
    </Screenlayout>
  );
}

VitalMonitoring.propTypes = {
  className: PropTypes.string,
};
