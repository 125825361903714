export const prescriptionCard_style = {
  rootSx: {
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'grey.400',
    boxShadow: 'none',
  },
  appointmentCardSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'inherit',
    padding: '10px 0px',
  },

  doctornameSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 0.5,
  },
  cancelIconSx: {
    display: 'flex',
    alignItems: 'start',
  },
  halfRoundSx: {
    position: 'absolute',
    right: '0px',
    width: '56px',
    height: '88px',
    top: '-8px',
  },
  iconbtnSx: {
    p: '6px 6px',
    m: '0px -8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};
