/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-key */
import {
  DontsEmptyStateIcon,
  NotesEmptyStateIcon,
  ReferenceEmptyStateIcon,
} from '@hc/ui/atoms';
import { ActivityCard, HomeEmptyState, Screenlayout } from '@hc/ui/components';
import TabContext from '@mui/lab/TabContext/TabContext';
import TabList from '@mui/lab/TabList/TabList';
import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useLocation } from 'react-router-dom';
import { MBFExpertNotes_style } from './style';

export default function MBFExpertNotes(props) {
  const { className = '', ...rest } = props;
  const [tabIndex, setTabIndex] = useState('1');
  const { state } = useLocation();
  const { notes } = state;
  const notesData = notes.activity_notes;
  const dontsData = notes.activity_dont;
  const referenceData = notes.activity_references;

  const breaks = [
    {
      label: 'Notes',
      value: '1',
    },
    {
      label: "Dont's",
      value: '2',
    },
    {
      label: 'References',
      value: '3',
    },
  ];

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    setTabIndex(state.page.toString());
  }, []);

  return (
    <Screenlayout
      isExpertAppBar
      appBarStyle={MBFExpertNotes_style.appBarStyleSx}
      backRequired
      backIconStyle={{ color: '#6552CC' }}
      rootStyle={{ backgroundColor: '#FCFBFF' }}
      title={notes.activity_name}
      masterchildStyle={MBFExpertNotes_style.screenLayoutChildernSx}
      notshowFooter
    >
      <Box
        sx={MBFExpertNotes_style.rootSx}
        className={`${className}`}
        {...rest}
      >
        <Box sx={MBFExpertNotes_style.expertAppbarSx}>
          <Box
            sx={{
              height: '80px',
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'center',
              pb: 0.3,
            }}
          >
            <TabContext value={tabIndex}>
              <Box
                sx={{
                  width: '100%',
                  height: '40px',
                  backgroundColor: '#D5CCFF',
                  position: 'relative',
                  py: 0.4,
                  px: 0,
                  borderRadius: '8px',
                }}
              >
                <TabList
                  sx={{
                    '& .MuiTabs-flexContainer': {
                      display: 'flex',
                      justifyContent: 'space-around',
                    },
                  }}
                  onChange={handleChange}
                  TabIndicatorProps={{
                    style: {
                      width: '110px',
                      height: '32px', // Increase the height to cover the content
                      top: 1, // Adjust the negative top margin to align the tab indicator
                      zIndex: 0, // Set a lower zIndex for the tab indicator
                      backgroundColor: ' rgb(101, 82, 204)',
                      borderRadius: '8px',
                    },
                  }}
                >
                  {breaks.length > 0 &&
                    breaks?.map((tab, i) => {
                      return (
                        <Tab
                          key={i}
                          sx={MBFExpertNotes_style.tabButtonSx}
                          disableTouchRipple
                          label={tab.label}
                          value={tab.value}
                          icon={tab.count}
                          iconPosition="end"
                        />
                      );
                    })}
                </TabList>
              </Box>
            </TabContext>
          </Box>
        </Box>
        <Box
          pt={17}
          pb={5}
          px={2}
          sx={{ overflowY: 'scroll', cursor: 'context-menu' }}
        >
          <ActivityCard
            image={notes?.activity_image}
            name={notes?.activity_name}
            icon={notes?.icon}
            time={`${notes?.quantity} ${notes?.unit_name}`}
            timeShow={notes?.is_measurable ? true : false}
            specificTime={notes?.activity_time === 6 ? true : false}
            specificFromTime={notes?.specific_time?.from ?? ''}
            specificToTime={notes?.specific_time?.to ?? ''}
            mbfStatus={notes?.category_name}
            progressValue={
              notes?.is_measurable
                ? (
                    ((notes?.user_updated_quantity ?? 0) /
                      (notes?.quantity ?? 1)) *
                    100
                  ).toFixed()
                : notes?.user_updated_quantity ?? 0
            }
          />
          <span style={{ marginBottom: '100px' }} />
          {tabIndex === '1' &&
            (!Object.keys(notesData).length === 0 ? (
              <ReactQuill theme="bubble" value={notesData} readOnly />
            ) : (
              <HomeEmptyState
                illustartion={<NotesEmptyStateIcon />}
                title="No notes from expert"
                description={<span>Yay! You are keeping yourself healthy</span>}
              />
            ))}

          {tabIndex === '2' &&
            (!Object.keys(dontsData).length === 0 ? (
              <ReactQuill theme="bubble" value={dontsData} readOnly />
            ) : (
              <HomeEmptyState
                illustartion={<DontsEmptyStateIcon />}
                title="No dont's"
                description={<span>Yay! You are keeping yourself healthy</span>}
              />
            ))}

          {tabIndex === '3' &&
            (referenceData[0].imageUrl ? (
              <>
                {referenceData.map((ref, i) => {
                  return (
                    <Stack gap={2} pt={1} key={i}>
                      <Typography sx={MBFExpertNotes_style.expertLinkTitle}>
                        {ref.linkName}
                      </Typography>
                      <Link sx={MBFExpertNotes_style.expertLink} href={ref.url}>
                        {ref.url}
                      </Link>
                      <Box>
                        <img
                          src={ref.imageUrl}
                          alt="activity reference"
                          style={{ width: '100%', height: '100%' }}
                        />
                      </Box>
                      <Divider />
                    </Stack>
                  );
                })}
              </>
            ) : (
              <HomeEmptyState
                illustartion={<ReferenceEmptyStateIcon />}
                title="No references"
                description={<span>Yay! You are keeping yourself healthy</span>}
              />
            ))}
        </Box>
      </Box>
    </Screenlayout>
  );
}

MBFExpertNotes.propTypes = {
  className: PropTypes.string,
};
