export const awareness_style = {
  boxRootSx: {
    backgroundColor: '#fff',
    p: 1,
  },
  rootSx: {
    backgroundColor: '#E2F7F4',
    p: 1,
    height: '100vh',
  },
  subHeadSx: {
    fontSize: '16px',
    color: 'text.primary',
    p: 5.5,
    fontWeight: '25px',
  },

  titleSx: {
    fontSize: '12px',
    color: '#fff',
    p: 3,
    fontWeight: '600',
    position: 'absolute',
    marginTop: '-98px',
    marginLeft: '3px',
    textShadow: '0px 1px 6px #0000003D',
  },

  navSx: {
    position: 'absolute',
    marginTop: '-54px',
    marginLeft: '26px',
  },
  backgroundImg: {},
  lastText: {
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 600,
    display: 'flex',
    gap: 0.5,
    justifyContent: 'center',
  },

  toMoreSx: {
    color: 'text.secondary',
    fontSize: 14,
    opacity: 1,
    textAlign: 'center',

    display: 'flex',
    gap: 0.5,
    justifyContent: 'center',
  },

  buttonsStyle: {
    textTransform: 'none',
    height: '40px',
    mt: 1,
    mb: 2,
  },

  signSx: {
    fontWeight: 600,
    fontSize: '14px',
    color: 'primary.main',
    opacity: 1,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  footerSx: {
    position: 'fixed',
    top: '79%',
    left: '50%',
    marginLeft: '-220px',
    width: '425px',
    display: 'flex',
    justifyContent: 'center',
  },
};
