import { useBookAppointment } from '@hc/store/clinical/bookAppointment';
import { useProfile } from '@hc/store/clinical/profile';
import {
  FamilyIcon,
  MySelfIcon,
  OtherHealthIcon,
} from '@hc/ui/atoms/illustration';
import { IllustrationCard } from '@hc/ui/components/clinical';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box } from '@mui/material';
import { clinicalRoutes } from '@hc/routes';
import React from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { selectPatient_style } from './style';

export function SelectPatientAppointment() {
  const { updateProfileData } = useBookAppointment(
    (state) => ({
      updateProfileData: state.updateProfileData,
      loading: state?.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { getProfileById } = useProfile(
    (state) => ({
      getProfileById: state.getProfileById,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  // Gernal Hooks
  const navigate = useNavigate();

  const selectCrad = async (value, url) => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const data = parseJwt(authToken);
    if (value === 'myself') {
      const profileData = await getProfileById(data?.user_id);
      // UPDATE PROFILE DATA
      updateProfileData(value, data, profileData, 'myself');
    } else {
      // UPDATE PROFILE DATA
      updateProfileData(value, data);
    }
    if (value) {
      navigate(clinicalRoutes[url]);
    }
  };

  return (
    <Box>
      <Box sx={selectPatient_style.BookingTextSx}>Who are you booking for?</Box>
      <Box mt={2} onClick={() => selectCrad('myself', 'healthIssues')}>
        <IllustrationCard
          healthStatus="Myself"
          healthStatusContent="Book an appointment for yourself"
          icon={<MySelfIcon />}
          rootStyle={selectPatient_style.myselfImageSx}
        />
      </Box>
      <Box mt={2} onClick={() => selectCrad('family', 'selectFamilyMember')}>
        <IllustrationCard
          healthStatus="Family"
          healthStatusContent="Book for your parents, spouse, kids & In-laws"
          icon={<FamilyIcon />}
          rootStyle={selectPatient_style.familyimageSx}
        />
      </Box>
      <Box mt={2} onClick={() => selectCrad('others', 'patientDetails')}>
        <IllustrationCard
          healthStatus="Others"
          healthStatusContent="Book for friends, colleagues & known persons"
          icon={<OtherHealthIcon />}
          rootStyle={selectPatient_style.otherimageSx}
        />
      </Box>
    </Box>
  );
}
