export const appointmentChips_style = {
  rootSx: {
    p: 1,
  },
  titleSx: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  headerSx: {
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#18574CB7',
    fontWeight: 500,
  },
  noDataFound: {
    fontSize: '10px',
    color: 'PrimaryTints.A100',
  },
  labelSx: {
    backgroundColor: 'grey.100',
    p: 0.5,
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'grey.400',
    mr: 1,
    fontWeight: 600,
    fontSize: '12px',
    mb: 1,
  },
  pointsSx: {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    pl: 0.5,
    pr: 0.5,
    pt: 0.3,
    pb: 0.3,
    ml: 1,
    fontSize: '12px',
    backgroundColor: 'grey.100',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'text.secondary',
  },
  addDetails: {
    fontSize: '14px',
    color: '#ffdb1f',
    fontWeight: 500,
    pl: 1,
    cursor: 'pointer',
  },
};
