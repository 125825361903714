/* eslint-disable no-unused-vars */
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { filter_toggle_button_style } from './style';

function FilterToggleButton(props) {
  const {
    handleClick,
    key = '',
    label = '',
    value = '',
    filterType = '',
    filterData,
    rootStyle = {},
    color,
    ...rest
  } = props;

  return (
    <Box
      sx={
        filterType === 'reportDate' &&
        filterData?.searchReportDateType === value
          ? { ...filter_toggle_button_style.activeBoxSx, ...rootStyle }
          : filterType === 'reportType' &&
            filterData?.searchReportType === value
          ? { ...filter_toggle_button_style.activeBoxSx, ...rootStyle }
          : filterType === 'reportFor' && filterData?.searchReportFor === label
          ? { ...filter_toggle_button_style.activeBoxSx, ...rootStyle }
          : { ...filter_toggle_button_style.boxSx, ...rootStyle }
      }
      key={key}
      onClick={() => handleClick(filterType, value, label)}
    >
      <Typography color={color ? color : '#000'} fontSize={14} fontWeight={500}>
        {label}
      </Typography>
    </Box>
  );
}

export { FilterToggleButton };

FilterToggleButton.propTypes = {
  buttonData: PropTypes.object,
  value: PropTypes.string,
  label: PropTypes.string,
  key: PropTypes.string,
  handleClick: PropTypes.func,
  filterType: PropTypes.string,
  filterData: PropTypes.any,
  rootStyle: PropTypes.object,
  color: PropTypes.any,
};
