import { LinearProgressBar } from '@atoms';
import { useMBFWellnessJourney } from '@hc/store';
import { titleCase } from '@hc/utils/helperFunctions';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { React } from 'react';
import { wellness_activity_card_Style } from './style';

function WellnessActivityCard(props) {
  const { rootStyle = {}, className = '', ...rest } = props;

  const { mbfState } = useMBFWellnessJourney((state) => ({
    mbfState: state.mbfState,
  }));

  return (
    <Box
      sx={{ ...wellness_activity_card_Style.rotSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box px={2.5} py={2}>
        {Object.keys(mbfState).length > 0 ? (
          <Box sx={wellness_activity_card_Style.cardRootSx}>
            <Box p={1}>
              <Typography variant="subtitle1" pb={2} fontWeight={600}>
                Activities
              </Typography>
              {mbfState?.activities?.map((LinearPrgressData,i) => (
                <Box  pb={2} key={i}>
                  <LinearProgressBar
                    activityName={titleCase(LinearPrgressData?.activity_name)}
                    value={LinearPrgressData?.percentage}
                    isWellnessJourneyProgress
                    showActivityLabel
                    showPercentageLabel
                    activityNameStyle={{ fontWeight: 400 }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          <Typography py={15} color="#8C8A8D" textAlign="center">
            No Data Found
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export { WellnessActivityCard };

WellnessActivityCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};
