export const homeScreen_style = {
  rootSx: {
    // minHeight: '100vh',
    bgcolor: '#F5F5F5',
  },
  morelistSx: {
    '& .MuiPopover-paper': {
      boxShadow: '0px 1px 6px 0px #b7b7b7',
      borderRadius: '12px',
    },
    '& .MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list': {
      padding: '0px',
    },
  },
  chatBotBannerSx: {
    height: 120,
    borderRadius: '16px',
    bgcolor: '#8ee0d3',
    mt:3,
    cursor:'pointer',
    p:1
  },
  borderSx: {
    fontSize: '12px',
    fontWeight: 600,
    margin: 0,
    color: 'error.main',
    borderRadius: '16px',
    // p: 1.5,
    '&:hover': {
      backgroundColor: 'common.white',
    },
  },
  cancelAppointment: {
    ml: 0.5,
  },
  containerSx: {
    pl: 2.2,
    pr: 2.2,
    pt: 3.4,
  },
  mainSx: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 4,
    justifyContent: 'space-between',
    // cursor: 'pointer'
  },
  promptSx: {
    position: 'fixed',
    top: 48,
    right: 0,
    left: 0,
    maxWidth: 425,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'white',
    zIndex: 1000,
  },
  sosSX: {
    width: '100%',
    borderRadius: '8px',
    mt: 4,
    display: 'flex',
    justifyContent: 'space-between',
    border: '2px solid #a2efe3',
    cursor: 'pointer',
  },

  sosListSx: {
    display: 'flex',
    flexDirection: 'row',
    p: 1.8,
    gap: 1,
    cursor: 'pointer',
  },
  buttonSx: {
    bgcolor: 'primaryTints.200',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
  slotDrawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      height: '60vh',
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    // p: 2,
    overflow: 'overlay',
  },
  callButtonSx: {
    borderRadius: 2,
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },

  getHelpTextSx: {
    fontSize: '12px',
    pt: 1,
    px: 1,
    mt: 2.5,
    fontWeight: 600,
  },
  resGetHelpTextSx: {
    fontSize: '12px',
    p: 1,
    mt: 1,
    fontWeight: 600,
  },
  emergencySx: {
    fontSize: '14px',
    color: 'text.primary',
    fontWeight: 600,
    alignSelf: 'center',
  },
  buttonStyle: {
    display: 'flex',
    justifyContent: 'center',
    borderBottom: ' 72px solid #a2efe3 ',
    borderLeft: '25px solid transparent',
    height: 0,
    width: '120px',
    color: '#007965',
    // '&:hover': {
    //   backgroundColor: 'error.light',
    // },
  },
  resButtonStyle: {
    borderBottom: ' 56px solid #a2efe3 ',
    borderLeft: '25px solid transparent',
    height: 0,
    width: '120px',
    color: '#007965',
  },
  followupSx: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: 'primary.dark',
    width: '100%',
    height: '100%',
    mt: 3,
  },
  upcomingSx: {
    mt: 3,
  },

  activeSx: {
    mt: 3,
  },

  adSx: {
    mt: 3,
    pb: 14,
  },

  followUpContentSx: {
    px: 2,
    py: 2.2,
  },
  follorupContentSx: {
    pl: 2.2,
    pr: 2.2,
  },

  referaltextSx: {
    fontSize: '14px',
    fontWeight: 600,
    color: 'white',
  },

  upcomingtextSx: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#111',
  },

  activetextSx: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#111',
  },
  subHeadSxx: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'text.secondary',
    mt: 1,
  },

  cardStyle: {
    minWidth: '332px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '145px',
    bgcolor:"#fff",
    mt: 1.2,
    '::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },

  upcomingcardStyle: {
    mt: 1.2,
  },

  activecardStyle: {
    mt: 1.2,
  },

  followupScrollSx: {
    // display: 'flex',

    overflowX: 'scroll',
    gap: 1,
    '::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },

  upcomingListSx: {
    gap: 1,
  },

  activeListSx: {
    gap: 1,
  },

  pointsSx: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    pl: 0.5,
    pr: 0.5,
    pt: 0.3,
    pb: 0.3,
    ml: 1,
    fontSize: '12px',
    backgroundColor: '#fff',
    color: '#111',
  },

  upcomingPointsSx: {
    width: '23px',
    height: '23px',
    fontSize: '12px',
    ml: 1,
    backgroundColor: '#111',
    color: '#fff',
  },
  helpSx: {
    textAlign: 'center',
    pt: 1,
  },
  avatarSx: {
    width: '120px',
    height: '120px',
    margin: '0px auto',
  },
  assitantSx: {
    color: 'text.hint',
    fontWeight: 500,
  },
  prescriptionTextSx: {
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 'medium',
    mt: 2.1,
  },
  emptyState: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mt: 1.75,
    p: 0.2,
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'grey.400',
    bgcolor: 'grey.50',
  },
  iconStyleSx: {
    p: 1.5,
  },
  upcomingAppointmentTextSx: {
    pl: 1.25,
    py: 2,
    // ml: 2,
    borderTopRightRadius: '6px',
    background: '#fff',
    borderBottomRightRadius: '6px',
  },

  plusSx: {
    pr: 1,
  },
  cancelBookSx: {
    fontFamily: 'poppins',
    fontSize: '14px',
    fontWeight: 500,
    color: 'primary.main',
    p: 0,
  },
  subHeadSx: {
    fontSize: '12px',
    color: 'text.hint',
    pb: 1,
  },
  mainHeadSx: {
    pb: 0.75,
    color: 'text.secondary',
    fontWeight: 500,
  },
  smallSx: {
    backgroundColor: 'text.primary',
    width: '24px',
    height: '24px',
    fontSize: '12px',
    ml: 1,
  },
  appointmentCardStyle: {
    width: '100%',
  },
  referchangingStyle: {
    ml: 0,
    mr: 0,
  },
  jitsiMeetMiniSx: {
    zIndex: 1,
    width: '250px',
    height: '50px',
    bottom: 210,
    right: 20,
    position: 'fixed',
  },
  jitsiMeetMaxSx: {
    zIndex: 1,
    width: '100%',
    position: 'absolute',
  },
  jitsiGridContainerMiniSx: {
    ml: 0,
    width: '100% ',
    pb: 1.5,
    borderRadius: '16px 16px 16px 16px',
    bgcolor: '#000',
    pt: 1.5,
  },
  jitsiGridContainerMaxSx: {
    ml: 0,
    width: '100% ',
    borderRadius: '0',
    bgcolor: '#000',
    pt: 1.5,
  },
  jitsiButtonMiniSx: {
    bgcolor: '#000',
    textTransform: 'lowercase',
    '&:hover': {
      bgcolor: '#000',
    },
  },
  jitsiButtonMaxSx: {
    bgcolor: '#424242',
    textTransform: 'lowercase',
    '&:hover': {
      bgcolor: '#424242',
    },
  },
};
