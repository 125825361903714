export const forgotPassword_style = {
  rootSx: {
    display: 'grid',
    gridTemplateRows: 'minmax(auto, 34%) minmax(auto, 66%)',
    backgroundColor: 'primary.lighter',
    gap: 1,
    minHeight: '100vh',
    position: 'relative',
  },
  imgSx: {
    width: '100%',
    height: '100%',
    pt: 2,
    borderRadius: '0%',
  },
  inputGroupSx: { display: 'grid', gap: 0.5 },

  imgContSx: {
    maxWidth: '90%',
    mx: 'auto',
    my: 'auto',
  },
};
