import { Button, Dropdown, Label, MobileInput, NoMembers } from '@atoms';
import { ClinicalEmptyState, SelectFamilyMemberCard } from '@components';
import { useFamilyMember, useRelationShip } from '@hc/store';
import { Box, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import isEqual from 'react-fast-compare';
import { existingMember_Style } from './style';
// import { SelectFamilyMemberCard } from '../../appointmentBooking/selectFamilyMemberCard';

export function ExistingMember(props) {
  const { className = '', ...rest } = props;

  // Store Data
  const {
    searchFamilyData,
    familyMember,
    searchLoading,
    existingFamilyMember,
    handleFamilyMemberChange,
    updateFamilyMemberError,
  } = useFamilyMember(
    (state) => ({
      searchFamilyData: state.searchFamilyData,
      familyMember: state.familyMember,
      existingFamilyMember: state.existingFamilyMember,
      handleFamilyMemberChange: state.handleFamilyMemberChange,
      updateFamilyMemberError: state.updateFamilyMemberError,
      searchLoading: state.searchLoading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { relationShipData } = useRelationShip(
    (state) => ({
      get: state?.get,
      relationShipData: state.relationShipData,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  // Checking Search Validation
  const isIamValid = () => {
    const familyMemberCopy = JSON.parse(JSON.stringify(familyMember));

    let isValid = true;
    const error = familyMemberCopy?.error;

    // Checking mobile_no
    if (familyMember?.mobile_no?.length > 0) {
      if (familyMember?.mobile_no?.length !== 10) {
        isValid = false;
        error.mobile_no = 'Please enter your valid mobilenumber';
      } else {
        error.mobile_no = '';
      }
    } else {
      isValid = false;
      error.mobile_no = 'Please enter your mobilenumber';
    }

    // UPDATE FAMILY MEMBER ERROR
    updateFamilyMemberError(error);
    return isValid;
  };

  // On Search API Call
  const onSearchMember = async () => {
    const results = isIamValid();
    if (results) {
      await existingFamilyMember(familyMember);
    }
  };

  const showComponent = () => {
    onSearchMember();
  };

  const handleChange = (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    // HANDLE FAMILY MEMBER DATA CAHNGE
    handleFamilyMemberChange(key, value);
  };

  return (
    <Box className={`${className}`} {...rest}>
      <Typography sx={existingMember_Style.headerTextSx}>
        Search using mobile number to add existing member to your family
      </Typography>
      <Box sx={existingMember_Style.commonHeightSx}>
        <Label isRequired>Phone Number</Label>
        <Box sx={existingMember_Style.commonHeighSx}>
          <MobileInput
            id="mobileNumber"
            value={{
              mobile_code: familyMember?.country_code ?? '+91',
              mobile: familyMember?.mobile_no ?? '',
            }}
            isError={!!familyMember?.error?.mobile_no}
            helperText={familyMember?.error?.mobile_no}
            onChange={(value) => handleChange('mobileNumber', value, 10)}
            mobileInputStyle={existingMember_Style.inputFieldSx}
          />
        </Box>
      </Box>
      <Box sx={existingMember_Style.commonHeightSx}>
        <Button
          buttonStyle={existingMember_Style.buttonSx}
          onClick={showComponent}
          loading={searchLoading}
        >
          Search
        </Button>
      </Box>

      {searchFamilyData ? (
        <Box sx={{ mt: 5 }}>
          {searchFamilyData?.mobile_no ? (
            <>
              <Divider />
              <Typography
                sx={{ fontSize: '12px', color: 'text.primary', mt: 3, pb: 1 }}
              >
                {' '}
                Member Found
              </Typography>
              <Box sx={existingMember_Style.commonHeighSx}>
                <SelectFamilyMemberCard />

                {/* TODO: want to add dropdown */}
                <Box sx={{ mt: 2 }}>
                  <Label isRequired>Relationship To You</Label>
                  <Dropdown
                    rootStyle={{ mt: 1 }}
                    id="relationship"
                    selectOption={
                      Array.isArray(relationShipData) ? relationShipData : []
                    }
                    value={familyMember?.relationship}
                    onChange={(value) => {
                      handleChange('relationship', value?.target?.value);
                    }}
                    isError={!!familyMember?.error?.relationship}
                    helperText={familyMember?.error?.relationship}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <Box sx={existingMember_Style.boxRootSx}>
              <ClinicalEmptyState
                IconRequired={<NoMembers />}
                emptyStateStyle={{ mt: 5 }}
                subHead="  No Member Found !!!"
                subHeadStyle={existingMember_Style.noMemberSx}
              />
            </Box>
          )}
        </Box>
      ) : null}
    </Box>
  );
}
ExistingMember.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  relationShipData: PropTypes.array,
};
