/* eslint-disable no-restricted-syntax */
import { clinicalRoutes } from '@hc/routes';
import { useChatBot, useProfile } from '@hc/store';
import { Button, DropDown } from '@hc/ui/atoms';
import {
  ChatBotChatListing,
  ChatBotMessageBox,
  Screenlayout,
} from '@hc/ui/components';
import { queryClient } from '@hc/utils';
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { health_query_chat_bot_style } from './style';

export default function HealthQueryChatBot() {
  const [isTimer, setIsTimer] = useState(false);
  const [isScroll, setIsScroll] = useState(false);

  const {
    chatBotState,
    getHistoryData,
    updateChatBotData,
    upsertQuestion,
    messageLoading,
    setUnResolvedQuestion,
    getPreviousHistoryData,
    getRemainingQuesCount,
  } = useChatBot((state) => ({
    upsertQuestion: state.upsertQuestion,
    updateChatBotData: state.updateChatBotData,
    chatBotState: state.chatBotState,
    getHistoryData: state.getHistoryData,
    messageLoading: state.messageLoading,
    setUnResolvedQuestion: state.setUnResolvedQuestion,
    getPreviousHistoryData: state.getPreviousHistoryData,
    getRemainingQuesCount: state.getRemainingQuesCount,
  }));

  const { profile } = useProfile((state) => ({
    profile: state.profile,
  }));

  const { profile_pic } = profile;

  const getInitialData = async () => {
    queryClient.invalidateQueries({
      queryKey: ['chatHistory-get-chat-history'],
    });
    queryClient.invalidateQueries({
      queryKey: ['chatHistory-get-previous-chat-history'],
    });
    const response = await getHistoryData();
    await getPreviousHistoryData();
    queryClient.invalidateQueries({
      queryKey: ['chatHistory-remaining-question-count'],
    });
    await getRemainingQuesCount();
    if (response === 1 || response === 3) {
      setIsTimer(true);
    } else {
      setIsTimer(false);
    }
  };

  const onPreviousChatClick = () => {
    setIsScroll(false);
    const allData = chatBotState?.chatHistory?.filter(
      (val) =>
        val?.title !== 'Is your concern addressed?' &&
        val?.title !== 'Hey !! What would you like to ask?'
    );
    const lastIndex = chatBotState?.previousHistory?.slice(-1).pop();
    const newArr = [];
    for (const obj of lastIndex?.history) {
      newArr.push(obj);
    }
    for (const obj of allData) {
      newArr.push(obj);
    }
    newArr.push(chatBotState?.preDefineData?.[0]);
    const previousData = [];
    for (const obj of chatBotState?.previousHistory) {
      if (obj?.id !== lastIndex?.id) {
        previousData.push(obj);
      }
    }

    updateChatBotData('chatHistory', newArr);
    updateChatBotData('previousHistory', previousData);
  };

  const onChange = (value) => {
    if (value?.length > 0) {
      setIsTimer(false);
    } else {
      setIsTimer(true);
    }
    const data = [];
    const obj = {
      title: '',
      role: 'user',
      content: value,
    };
    data.push(obj);
    updateChatBotData('questionData', data);
    updateChatBotData('questioninitialData', data);
  };

  const onSendMessage = async (clickValue, object) => {
    if (chatBotState?.questionData?.[0]?.content?.length > 0 || clickValue) {
      setIsScroll(true);
      updateChatBotData('chat_status_id', 1);
      const allData = chatBotState?.chatHistory?.filter(
        (val) =>
          val?.title !== 'Is your concern addressed?' &&
          val?.title !== 'Hey !! What would you like to ask?'
      );

      const arr = [
        clickValue
          ? {
              ...object,
            }
          : {
              ...chatBotState?.questionData?.[0],
            },
        {
          title: '',
          role: 'assistant',
          content: '',
        },
      ];
      for (const object of arr) {
        allData.push(object);
      }

      updateChatBotData('chatHistory', allData);

      queryClient.invalidateQueries({
        queryKey: ['chatHistory-get-answer'],
      });
      updateChatBotData('questionData', []);
      const response = await upsertQuestion();
      if (response === 200) {
        setIsTimer(true);
        queryClient.invalidateQueries({
          queryKey: ['chatHistory-remaining-question-count'],
        });
        await getRemainingQuesCount();
        updateChatBotData('chat_status_id', 1);
      }
    }
  };

  const onClickMessage = async (value) => {
    if (value === "Yes, Sorted and I' m good to go!") {
      const data = [];
      const obj = {
        title: '',
        role: 'user',
        content: value,
      };
      data.push(obj);
      updateChatBotData('questioninitialData', data);

      const allData = [];
      for (const val of chatBotState?.chatHistory) {
        if (val?.title !== 'Is your concern addressed?') {
          allData.push(val);
        }
      }
      const arr = [{ ...obj }];
      for (const object of arr) {
        allData.push(object);
      }

      updateChatBotData('chatHistory', allData);
      queryClient.invalidateQueries({
        queryKey: ['chatHistory-resolve-chat'],
      });
      const response = await setUnResolvedQuestion('resolved');

      if (response === 200) {
        updateChatBotData('chat_status_id', 2);
      }
    } else if (value === "I've got more questions!") {
      const data = [];
      for (const val of chatBotState?.chatHistory) {
        if (val?.title !== 'Is your concern addressed?') {
          data.push(val);
        }
      }
      data.push(chatBotState?.preDefineData?.[0]);
      updateChatBotData('chatHistory', data);
    } else if (value === 'No, My concern still persists!') {
      const response = await setUnResolvedQuestion('unresolved');

      if (response === 200) {
        updateChatBotData('chat_status_id', 3);
      }
      const data = [];
      for (const val of chatBotState?.chatHistory) {
        if (val?.title !== 'Is your concern addressed?') {
          data.push(val);
        }
      }
      data.push(chatBotState?.preDefineData?.[0]);
      updateChatBotData('chatHistory', data);
    } else {
      const data = [];
      const obj = {
        title: '',
        role: 'user',
        content: value,
      };
      data.push(obj);
      updateChatBotData('questioninitialData', data);
      onSendMessage(true, obj);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    let timeInterval;
    if (isTimer) {
      timeInterval = setInterval(() => {
        const allData = chatBotState?.chatHistory;
        allData.push(chatBotState?.resolvedQuentionData);
        updateChatBotData('chatHistory', allData);
        setIsTimer(false);
      }, 30000);
    } else {
      clearTimeout(timeInterval);
    }

    return () => {
      clearInterval(timeInterval);
    };
  }, [isTimer]);


  return (
    <Box sx={health_query_chat_bot_style.rootSx}>
      <Screenlayout
        title="Zenbliss - Your Health Assistant"
        backRequired
        notshowFooter
        appBarStyle={health_query_chat_bot_style?.appBarSx}
        titleStyle={{ color: '#000', fontSize: '16px', ml: '-40px' }}
        headerRootStyle={{ justifyContent: 'center' }}
        masterchildStyle={{ p: 0, pb: 0 }}
        backRoute={clinicalRoutes?.home}
      >
        <Box sx={{ ...health_query_chat_bot_style.chatBotBoxRootSx }}>
          <Box sx={{ ...health_query_chat_bot_style.chatBotRootSx }}>
            <Grid container display="flex">
              <Grid xs={12} alignSelf="end" item>
                <Box>
                  <ChatBotMessageBox
                    placeholder="Type your Query...."
                    remainingChatCount={chatBotState?.remainingChatCount ?? 0}
                    height={
                      chatBotState?.chat_status_id === 2 ? '65vh' : '72vh'
                    }
                    showMessageTimer={chatBotState?.chat_status_id === 2}
                    onChange={onChange}
                    value={chatBotState?.questionData?.[0]?.content ?? ''}
                    handleClick={onSendMessage}
                    isScroll={isScroll}
                    historyDeleteTime={chatBotState?.lastUpdatedTime}
                  >
                    {chatBotState?.previousHistory.length > 0 && (
                      <Box display="flex" justifyContent="center">
                        <Button
                          buttonStyle={
                            health_query_chat_bot_style?.previousButtonSx
                          }
                          startIcon={
                            <DropDown
                              rootStyle={{
                                color: '#007965',
                                transform: 'rotate(-180deg)',
                              }}
                            />
                          }
                          onClick={() => onPreviousChatClick()}
                        >
                          Previous history
                        </Button>
                      </Box>
                    )}

                    <Box
                      sx={{
                        overflowY: 'scroll',
                        pt: chatBotState?.previousHistory.length > 0 ? 4 : 2,
                      }}
                    >
                      {chatBotState?.chatHistory?.map((val, i) => (
                        <Box key={i}>
                          <ChatBotChatListing
                            isUser={val?.role === 'user' ? true : false}
                            message={val?.content}
                            messageTime={val?.date}
                            isInitialMessage={
                              val?.role?.length > 0 ? false : true
                            }
                            profileUrl={profile_pic}
                            onClickMessage={onClickMessage}
                            data={val?.content ?? []}
                            header={val?.title}
                            loadingMessage={messageLoading}
                          />
                        </Box>
                      ))}
                    </Box>
                  </ChatBotMessageBox>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Screenlayout>
    </Box>
  );
}
