import { Box, Typography } from '@mui/material';
// import { Button } from '@atoms';
import { clinicalRoutes } from '@hc/routes';
import { usePrescription } from '@hc/store';
import { Doctor } from '@hc/ui/atoms';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { PrescDoctorDetailCard_style } from './style';

export function PrescDoctorDetailCard(props) {
  const {
    // name = '',
    className = '',
    rootStyleSx = {},
    // department = '',
    ...rest
  } = props;
  const navigate = useNavigate();

  const { prescriptionData } = usePrescription(
    (state) => ({
      prescriptionData: state.prescriptionData,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  useEffect(() => {
    if (!prescriptionData) {
      return navigate(clinicalRoutes?.home);
    }
  }, []);
  return (
    <Box
      sx={{ ...PrescDoctorDetailCard_style.rootSx, ...rootStyleSx }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={PrescDoctorDetailCard_style.cardWrapsx}>
        <Box>
          <Doctor />
        </Box>
        <Typography sx={PrescDoctorDetailCard_style.docNameSx}>
          {`Dr. ${prescriptionData?.doctorName}`}
        </Typography>
        <Box sx={PrescDoctorDetailCard_style.dotSx}> </Box>
        {(prescriptionData?.doctorSpecialities ?? []) &&
          (prescriptionData?.doctorSpecialities ?? [])?.length > 0 &&
          (prescriptionData?.doctorSpecialities ?? []).map((val, index) => (
            <>
              {index > 0 ? (
                <span style={PrescDoctorDetailCard_style.labelSxx}>
                  {' '}
                  {', '}{' '}
                </span>
              ) : null}
              <Typography key={index} sx={PrescDoctorDetailCard_style.departmentSx}>
                {val?.master_speciality?.s ?? ''}
              </Typography>
            </>
          ))}
      </Box>
    </Box>
  );
}

PrescDoctorDetailCard.propTypes = {
  name: PropTypes.string,
  classes: PropTypes.object,
  sx: PropTypes.object,
};
