import { getDateFormat } from '@hc/dayjs';
import { clinicalRoutes } from '@hc/routes';
import {
  useMBFActivity,
  useMBFMyWellnessPlanSlice,
  useMBFWellnessJourney,
} from '@hc/store';
import {
  AnyTimeIcon,
  MoonIcon,
  NoonIcon,
  SpecificTimeIcon,
  SunRiseIcon,
  SunSetIcon,
} from '@hc/ui/atoms';
import { TodaysActivity } from '@hc/ui/components/clinical/mindBodyFood/todaysActivity';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { queryClient } from '@hc/utils';
import { localStorageKeys } from '@hc/utils/constants';
import { groupBy, parseJwt } from '@hc/utils/helperFunctions';
import { Box, CircularProgress, Typography } from '@mui/material';
import {
  addDays,
  eachDayOfInterval,
  endOfWeek,
  isAfter,
  isBefore,
  startOfDay,
  startOfWeek,
  subDays,
} from 'date-fns';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { myWellnessPlan_Style } from './style';

export default function MyWellnessPlan(props) {
  const { className = '', ...rest } = props;

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);
  const navigate = useNavigate();

  const {
    wellnessPlanData,
    getwellnessplan,
    constructDataState,
    setStartEndBetween,
    updateStartEndDate,
    updatePlanState,
    loading,
  } = useMBFMyWellnessPlanSlice((state) => ({
    wellnessPlanData: state.wellnessPlanData,
    loading: state.loading,
    getwellnessplan: state.getwellnessplan,
    setStartEndBetween: state.setStartEndBetween,
    constructDataState: state.constructDataState,
    updateStartEndDate: state.updateStartEndDate,
    updatePlanState: state.updatePlanState,
  }));

  const { mbfState } = useMBFActivity((state) => ({
    mbfState: state.mbfState,
  }));

  const {
    planstatus,
    planConstructData,
    selectedDate,
    calenderData,
    startDate,
    endDate,
  } = wellnessPlanData;

  const { planStartDate, planEndDate, getWellnessJourney } =
    useMBFWellnessJourney((state) => ({
      planStartDate: state.planStartDate,
      planEndDate: state.planEndDate,
      getWellnessJourney: state.getWellnessJourney,
    }));

  const [isCurrentDate, setIsCurrentDate] = useState(false);

  // Construct Data
  const constructData = async (seDate, value) => {
    const response = await constructDataState(seDate, value);
    if (Array.isArray(response) && response?.length > 0) {
      let groupByResponse = [];
      groupByResponse = await groupBy(response, 'activity_time_name');
      updatePlanState('planConstructData', groupByResponse);
    } else {
      updatePlanState('planConstructData', []);
    }
  };

  // Initial Call
  const initialPlanData = async () => {
    let payload;

    payload = {
      start_date: moment().startOf('week'),
      end_date: moment().endOf('week'),
      user_id: data?.user_id ?? '',
      user_plan_id: mbfState?.planId ?? '',
    };
    if (isBefore(new Date(planEndDate), new Date())) {
      payload = {
        start_date: startOfWeek(new Date(planEndDate)),
        end_date: endOfWeek(new Date(planEndDate)),
        user_id: data?.user_id ?? '',
        user_plan_id: mbfState?.planId ?? '',
      };
    }

    updateStartEndDate(payload.start_date, payload.end_date);

    if (isBefore(new Date(planEndDate), new Date(selectedDate))) {
      const selectedDat = getDateFormat(
        startOfDay(new Date(planEndDate)),
        'YYYY-MM-DD'
      );
      updatePlanState('selectedDate', selectedDat);
      const wellnessPlanDataCopy = JSON.parse(JSON.stringify(wellnessPlanData));
      const plans = await wellnessPlanDataCopy?.planstatus;
      constructData(selectedDat, plans);
    }

    const betweenDates = eachDayOfInterval({
      start: new Date(payload.start_date),
      end: new Date(payload.end_date),
    });

    setStartEndBetween(
      new Date(payload.start_date),
      new Date(payload.end_date),
      betweenDates
    );
    queryClient.invalidateQueries({
      queryKey: ['wellnessPlan'],
    });

    if (payload?.user_plan_id?.length > 0) {
      const response = await getwellnessplan(payload);
      if (Array.isArray(response) && response?.length > 0) {
        let groupByResponse = [];
        groupByResponse = await groupBy(response, 'activity_time_name');
        updatePlanState('planConstructData', groupByResponse);
      }
    } else {
      navigate(clinicalRoutes.mindbodyfood);
    }
  };

  // On Week Change
  const onWeekChange = async (type) => {
    let payload;
    if (type === 'previous') {
      payload = {
        start_date: subDays(new Date(startDate), 7),
        end_date: subDays(new Date(startDate), 1),
        user_id: data?.user_id ?? '',
        user_plan_id: mbfState?.planId ?? '',
      };
    } else if (type === 'next') {
      payload = {
        start_date: addDays(new Date(endDate), 1),
        end_date: addDays(new Date(endDate), 7),
        user_id: data?.user_id ?? '',
        user_plan_id: mbfState?.planId ?? '',
      };
    }

    const betweenDates = eachDayOfInterval({
      start: new Date(payload.start_date),
      end: new Date(payload.end_date),
    });

    await updateStartEndDate(
      new Date(payload.start_date),
      new Date(payload.end_date)
    );

    await setStartEndBetween(
      new Date(payload.start_date),
      new Date(payload.end_date),
      betweenDates
    );
    queryClient.invalidateQueries({
      queryKey: ['wellnessPlan'],
    });
    if (payload?.user_plan_id?.length > 0) {
      await getwellnessplan({
        ...payload,
        start_date: moment(getDateFormat(payload.start_date)).startOf('week'),
        end_date: moment(getDateFormat(payload.end_date)).endOf('week'),
      });
    } else {
      navigate(clinicalRoutes.mindbodyfood);
    }
  };

  // Select Date
  const selectDate = async (val) => {
    // eslint-disable-next-line no-undef
    const selectedDat = getDateFormat(val, 'YYYY-MM-DD');
    updatePlanState('selectedDate', selectedDat);
    const wellnessPlanDataCopy = JSON.parse(JSON.stringify(wellnessPlanData));
    const plans = await wellnessPlanDataCopy?.planstatus;
    constructData(selectedDat, plans);
    if (selectedDat === getDateFormat(new Date(), 'YYYY-MM-DD')) {
      setIsCurrentDate(true);
    } else {
      setIsCurrentDate(false);
    }
  };

  useEffect(() => {
    planStartDate && initialPlanData();
    getWellnessJourney();
  }, [planStartDate]);

  return (
    <Box className={`${className}`} {...rest}>
      <Screenlayout
        appBarStyle={myWellnessPlan_Style.appBarStyleSx}
        backRequired
        // endDate={endDate}
        // startDate={startDate}
        endDate={
          isAfter(new Date(planEndDate), new Date(startDate)) &&
          isBefore(new Date(planEndDate), new Date(endDate))
            ? planEndDate
            : endDate
        }
        startDate={
          isAfter(new Date(planStartDate), new Date(startDate)) &&
          isBefore(new Date(planStartDate), new Date(endDate))
            ? planStartDate
            : startDate
        }
        onWeekChange={onWeekChange}
        selectDate={selectDate}
        selectedDate={selectedDate}
        calenderData={calenderData}
        planstatus={planstatus}
        planStartDate={planStartDate}
        planEndDate={planEndDate}
        notshowFooter
        wellnessPlanData={wellnessPlanData}
        mindbodfoodCalendar
        backIconStyle={{ color: 'mbf.main' }}
        rootStyle={{ bgcolor: 'purple.50' }}
        title="My Wellness Plan"
      >
        {!loading && planStartDate && planEndDate && (
          <Box sx={myWellnessPlan_Style.activitySx}>
            {isCurrentDate && (
              <Typography sx={myWellnessPlan_Style.titleTextSx}>
                Today&apos;s Activity
              </Typography>
            )}
            {planConstructData &&
              Object.keys(planConstructData).length > 0 &&
              [
                'Morning',
                'Noon',
                'Evening',
                'Night',
                'Anytime',
                'Specific time',
              ].map((i, key) => {
                if (!planConstructData[i] || planConstructData[i].length === 0)
                  return;
                return (
                  <Box key={key} px={0.5}>
                    <Box
                      display="flex"
                      gap={1}
                      mt={1}
                      sx={myWellnessPlan_Style.appBarStyleSxx}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          textTransform: 'capitalize',
                          fontWeight: 'medium',
                        }}
                      >
                        {i}
                      </Typography>
                      {planConstructData?.[i]?.[0]?.icon}
                      {i === 'Morning' && <SunRiseIcon />}
                      {i === 'Noon' && <NoonIcon />}
                      {i === 'Evening' && <SunSetIcon />}
                      {i === 'Night' && <MoonIcon />}
                      {i === 'Anytime' && <AnyTimeIcon />}
                      {i === 'Specific time' && <SpecificTimeIcon />}
                    </Box>
                    <TodaysActivity
                      isMywellNessPlan
                      loading={loading}
                      todaysActivity={planConstructData?.[i]}
                    />
                  </Box>
                );
              })}
          </Box>
        )}
        {loading && (
          <Box
            sx={{ display: 'grid', minHeight: '90vh', placeItems: 'center' }}
          >
            <CircularProgress sx={{ color: 'mbf.main' }} />
          </Box>
        )}
      </Screenlayout>
    </Box>
  );
}
