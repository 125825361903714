import { clinicalRoutes } from '@hc/routes';
import { useOnboarding } from '@hc/store';
import { Button, Label, OTPInput } from '@hc/ui/atoms';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { otpVerification_style } from './style';

export function ClinicalOtpVerification() {
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [remainingTime, setRemainingTime] = useState(60);

  const { user, loading, sendOtp, sendingOTP, verifyOtp, otpVerified } =
    useOnboarding(
      (state) => ({
        user: state.user,
        otpVerified: state.otpVerified,
        sendOtp: state.sendOtp,
        verifyOtp: state.verifyOtp,
        loading: state.loading,
        sendingOTP: state.sendingOTP,
      }),
      (prev, curr) => isEqual(prev, curr)
    );

  const handleOTPVerify = () => {
    verifyOtp(otp);
  };

  const handleOtpchange = (value) => {
    setOtp(value);
  };

  const handleChange = () => {
    if (user?.type === 'sign-in') {
      navigate(clinicalRoutes.login);
    } else if (user?.type === 'sign-up') {
      navigate(clinicalRoutes.signup);
    } else if (user?.type === 'forgot-password') {
      navigate(clinicalRoutes.forgotPassword);
    }
  };
  useEffect(() => {
    if (remainingTime === 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
      const timer = setTimeout(() => {
        setRemainingTime(remainingTime - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [remainingTime]);

  useEffect(() => {
    if (!user?.mobileNumber) {
      navigate(clinicalRoutes.login);
    }
  }, [user]);

  useEffect(() => {
    if (otpVerified) {
      useOnboarding.setState({ otpVerified: false });
      navigate(clinicalRoutes.otpSplash);
    }
  }, [otpVerified]);

  return (
    <Box>
      <Box sx={otpVerification_style.rootPaperSx}>
        <Box sx={{ display: 'grid', gap: 1.5 }}>
          <Typography variant="h5" sx={otpVerification_style.headingSx}>
            Tell us something that only we know,
          </Typography>
          <Box>
            <Label labelStyle={otpVerification_style.codeSentSx}>
              code sent to
            </Label>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: ' center',
              }}
            >
              <Typography sx={otpVerification_style.numberSx}>
                {`${user?.countryCode ?? '+91'} ${user?.mobileNumber}`}
              </Typography>
              {user?.type === 'corporate' ||
                (user?.type === 'familyMember' && (
                  <Box
                    as="span"
                    sx={otpVerification_style.changeSx}
                    onClick={() => handleChange()}
                  >
                    CHANGE
                  </Box>
                ))}
            </Box>
          </Box>
          <Box marginTop={3}>
            <OTPInput
              handleChange={handleOtpchange}
              value={otp}
              isError={otp.length > 3}
            />
          </Box>
          <Box marginTop={4}>
            <Button
              loading={loading}
              buttonStyle={otpVerification_style.verifySx}
              onClick={() => handleOTPVerify()}
              disabled={otp.length !== 4}
            >
              Verify
            </Button>
          </Box>
        </Box>
        {/* {user?.type !== 'forgot-password' && ( */}
        <Box
          sx={{ display: 'grid', placeItems: 'center', gap: 1, mb: 4, pt: 6 }}
        >
          <Typography sx={otpVerification_style.receiveSx}>
            Didn&#39;t receive the OTP?
          </Typography>
          <Button
            disabled={buttonDisabled}
            loading={sendingOTP}
            sx={otpVerification_style.resendSx}
            onClick={() => {
              sendOtp();
              setRemainingTime(60);
            }}
            size="small"
            variant="text"
          >
            {buttonDisabled ? `Wait ${remainingTime} seconds` : 'Resend'}
          </Button>
        </Box>
        {/* )} */}
      </Box>
    </Box>
  );
}
