import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import { useBloodGroup, useProfile } from '@hc/store';
import { useBookAppointment } from '@hc/store/clinical/bookAppointment';
import {
  BodyCamera,
  HeightNoBgIcon,
  Input,
  Label,
  PressureNoBgIcon,
  PulseVitalsIcon,
  SpoVitalsIcon,
  TemperatureVitalsIcon,
  WeightNoBgIcon,
} from '@hc/ui/atoms';
import isEqual from 'react-fast-compare';
import { BloodGroupSelection } from '../../../bloodGroupSelection';
import { uploadReport_style } from './style';

export function VitalsFormAppointment(props) {
  const { IsReadOnly = false } = props;
  const {
    bookAppointmentState,
    handleVitalsDataChange,
    // loading: loader,
  } = useBookAppointment(
    (state) => ({
      bookAppointmentState: state?.bookAppointmentState,
      handleVitalsDataChange: state.handleVitalsDataChange,
      loading: state?.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { get, profile } = useProfile(
    (state) => ({
      get: state.get,
      profile: state.profile,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { bloodGroupData, getBloodGroupData } = useBloodGroup((state) => ({
    bloodGroupData: state.bloodGroupData,
    getBloodGroupData: state.getBloodGroupData,
  }));

  const { vitals, booking_method, vitalsInitialData } = bookAppointmentState;

  const handleChange = (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }

    // HANDLE PATIENT DATA CAHNGE
    handleVitalsDataChange(key, value);
  };

  const toFindBMICategory = (BMI_Value) => {
    if (parseFloat(BMI_Value) < 18.5) {
      return 'Underweight';
    }
    if (parseFloat(BMI_Value) > 18.5 && parseFloat(BMI_Value) < 25) {
      return 'Normal';
    }
    if (parseFloat(BMI_Value) > 25 && parseFloat(BMI_Value) < 30) {
      return 'Overweight';
    }
    if (parseFloat(BMI_Value) > 30) {
      return 'Obesity';
    }
    return 'Normal';
  };

  useEffect(() => {
    if (!profile?.name?.length > 0) {
      get();
    }
    getBloodGroupData();
  }, []);

  return (
    <Box sx={uploadReport_style.vitalFormGroup}>
      <Box>
        <Typography sx={uploadReport_style.VitalsTextSx}>
          Enter Your Vitals (Optional)
        </Typography>
      </Box>
      <Grid container columnSpacing={2}>
        <Grid item xs={6} sm={6} md={6}>
          <Label labelStyle={uploadReport_style.vitalLabelSx}>Height </Label>
          <Input
            textFieldStyle={uploadReport_style.inputFieldSx}
            type="number"
            isReadOnly={vitalsInitialData?.height_in_cm ? IsReadOnly : ''}
            value={vitals?.height_in_cm}
            onChange={(e) => {
              handleChange('height_in_cm', e.target.value, 3);
            }}
            htmlFor="height_in_cm"
            startAdornment={
              <IconButton disableRipple aria-label="" edge="start">
                <HeightNoBgIcon />
              </IconButton>
            }
            endAdornment={
              <Typography sx={uploadReport_style.endAdornmentTextSx}>
                cm
              </Typography>
            }
            isError={!!vitals?.error?.height_in_cm}
            errorMessage={vitals?.error?.height_in_cm}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Label labelStyle={uploadReport_style.vitalLabelSx}>Weight </Label>
          <Input
            textFieldStyle={uploadReport_style.inputFieldSx}
            type="number"
            isReadOnly={vitalsInitialData?.weight_in_cm ? IsReadOnly : ''}
            value={vitals?.weight_in_cm}
            onChange={(e) => {
              handleChange('weight_in_cm', e.target.value, 3);
            }}
            htmlFor="weight_in_cm"
            startAdornment={
              <IconButton disableRipple aria-label="" edge="start">
                <WeightNoBgIcon />
              </IconButton>
            }
            endAdornment={
              <Typography sx={uploadReport_style.endAdornmentTextSx}>
                kg
              </Typography>
            }
            isError={!!vitals?.error?.weight_in_cm}
            errorMessage={vitals?.error?.weight_in_cm}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          {vitals?.bmi_in_kg_m2.length > 0 && (
            <Box sx={uploadReport_style.bmiCalculationGroupSx}>
              <Stack direction="row" spacing={1}>
                <BodyCamera />
                <Typography sx={uploadReport_style.bmiCalculationValueSx}>
                  <Typography fontSize={12} color="#184457">
                    Your BMI is
                  </Typography>{' '}
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {vitals?.bmi_in_kg_m2 ?? ''}
                    {}
                  </span>
                  <span
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    {vitals?.bmi_in_kg_m2
                      ? toFindBMICategory(vitals?.bmi_in_kg_m2)
                      : 'Normal'}
                    {}
                  </span>
                </Typography>
              </Stack>
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid container mt={2} columnSpacing={2}>
        <Grid item xs={6} sm={6} md={6}>
          <Label labelStyle={uploadReport_style.vitalLabelSx}>
            Temperature{' '}
          </Label>
          <Input
            textFieldStyle={uploadReport_style.inputFieldSx}
            type="number"
            value={vitals?.temp_in_f}
            onChange={(e) => {
              handleChange('temp_in_f', e.target.value, 4);
            }}
            htmlFor="temp_in_f"
            startAdornment={
              <IconButton disableRipple aria-label="" edge="start">
                <TemperatureVitalsIcon />
              </IconButton>
            }
            endAdornment={
              <Typography sx={uploadReport_style.endAdornmentTextSx}>
                F
              </Typography>
            }
            isError={!!vitals?.error?.temp_in_f}
            errorMessage={vitals?.error?.temp_in_f}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Label labelStyle={uploadReport_style.vitalLabelSx}>Pulse</Label>
          <Input
            textFieldStyle={uploadReport_style.inputFieldSx}
            type="number"
            value={vitals?.puls_bpm}
            onChange={(e) => {
              handleChange('puls_bpm', e.target.value, 3);
            }}
            startAdornment={
              <IconButton disableRipple aria-label="" edge="start">
                <PulseVitalsIcon />
              </IconButton>
            }
            endAdornment={
              <Typography sx={uploadReport_style.endAdornmentTextSx}>
                Bpm
              </Typography>
            }
            htmlFor="puls_bpm"
            isError={!!vitals?.error?.puls_bpm}
            errorMessage={vitals?.error?.puls_bpm}
          />
        </Grid>
        <Grid item mt={2} xs={6} sm={6} md={6}>
          <Label labelStyle={uploadReport_style.vitalLabelSx}>
            Blood Pressure
          </Label>
          <Input
            textFieldStyle={uploadReport_style.inputFieldSx}
            // type="number"
            value={vitals?.blood_pressure}
            onChange={(e) => {
              handleChange('blood_pressure', e.target.value, 7);
            }}
            htmlFor="blood_pressure"
            startAdornment={
              <IconButton disableRipple aria-label="" edge="start">
                <PressureNoBgIcon />
              </IconButton>
            }
            endAdornment={
              <Typography sx={uploadReport_style.endAdornmentTextSx}>
                mmHg
              </Typography>
            }
            isError={!!vitals?.error?.blood_pressure}
            errorMessage={vitals?.error?.blood_pressure}
          />
        </Grid>
        <Grid item mt={2} xs={6} sm={6} md={6}>
          <Label labelStyle={uploadReport_style.vitalLabelSx}>SPO2 </Label>
          <Input
            textFieldStyle={uploadReport_style.inputFieldSx}
            type="number"
            value={vitals?.o2_level}
            onChange={(e) => {
              handleChange('o2_level', e.target.value, 3);
            }}
            htmlFor="o2_level"
            startAdornment={
              <IconButton disableRipple aria-label="" edge="start">
                <SpoVitalsIcon />
              </IconButton>
            }
            endAdornment={
              <Typography sx={uploadReport_style.endAdornmentTextSx}>
                %
              </Typography>
            }
            isError={!!vitals?.error?.o2_level}
            errorMessage={vitals?.error?.o2_level}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item mt={1} xs={12}>
          <Label labelStyle={uploadReport_style.vitalLabelSx}>
            Blood Group
          </Label>
          <Box mt={1} sx={{ overflowY: 'scroll' }}>
            <BloodGroupSelection
              name="blood_group"
              value={
                booking_method !== 'others'
                  ? profile
                  : bookAppointmentState?.vitals
              }
              bloodData={
                booking_method !== 'others'
                  ? profile
                  : bookAppointmentState?.vitals
              }
              handleChange={handleChange}
              id="bloodgroup"
              bloodGroupData={bloodGroupData}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
