export const bookingMinDetails_style = {
  appointmentTextSx: {
    color: 'text.primary',
    fontSize: '20px',
    fontWeight: 600,
    textAlign: 'center',
    mt: 2.36,
  },
  updatesTextSx: {
    textAlign: 'center',
    color: 'text.primary',
    fontSize: '14px',
  },
  numberTextSx: {
    textAlign: 'center',
    color: 'text.primary',
    fontSize: '14px',
    fontWeight: 'medium',
    mt: 0.2,
  },
};
