import EmptyStateBg from '@assets/emptyState.svg';
import { clinicalRoutes } from '@hc/routes';
import { useFamilyMember, useNotification, useRelationShip } from '@hc/store';
import { Button, EmptyStateIcon } from '@hc/ui/atoms';
import { ClinicalEmptyState, SkeletonCard } from '@hc/ui/components';
import { NotificationPopup } from '@hc/ui/components/clinical';
import { MyFamilyMemberSelect } from '@hc/ui/components/clinical/appointmentBooking/myFamilyMemberSelect';
import { DrawerSelectMemberType } from '@hc/ui/components/clinical/familyProfile/drawerSelectMemberType';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { myFamilyScreen_style } from './style';

export default function MyFamily(props) {
  const { className = '', ...rest } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [addDrawerOpen, setAddDrawerOpen] = useState(false);

  const {
    familyMembers,
    get,
    getAll,
    acceptOrDeclineFamilyMemberRequest,
    clearFamilyMemberState,
    loading: loader,
  } = useFamilyMember(
    (state) => ({
      familyMembers: state.familyMembers,
      get: state.get,
      getAll: state.getAll,
      clearFamilyMemberState: state.clearFamilyMemberState,
      acceptOrDeclineFamilyMemberRequest:
        state.acceptOrDeclineFamilyMemberRequest,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const {
    get: getRelationShipData,
    // relationShipData,
    // loading: loader
  } = useRelationShip(
    (state) => ({
      get: state?.get,
      relationShipData: state.relationShipData,
      loading: state?.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { softDeleteNotification } = useNotification(
    (state) => ({
      softDeleteNotification: state.softDeleteNotification,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const [notifyModalData, setNotifyModalData] = useState({
    fromNotification: false,
    senderUserId: '',
    senderUserName: '',
    receiverUserId: '',
    familyMemberId: '',
    notificationId: '',
  });
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const initialData = async () => {
    await getAll();
    await getRelationShipData();
  };

  const addFamily = () => {
    clearFamilyMemberState();
    useFamilyMember.setState({
      searchFamilyData: null,
    });
    setAddDrawerOpen(true);
  };

  const routeAddFamily = () => {
    navigate(clinicalRoutes.addFamilyMember);
  };
  const routeExistingMember = () => {
    navigate(clinicalRoutes.addexistingmember);
  };

  const onAccpet = async () => {
    const res = await acceptOrDeclineFamilyMemberRequest({
      family_member_id: notifyModalData?.familyMemberId ?? '',
      notification_id: notifyModalData?.notificationId ?? '',
      to_user_id: notifyModalData?.senderUserId ?? '',
      user_id: data?.user_id ?? '',
      status: true,
    });

    if (res?.status?.code === '200') {
      softDeleteNotification(notifyModalData?.notificationId);
      setNotifyModalData({ ...notifyModalData, fromNotification: false });
      return toast.success(
        res?.status?.message ?? 'Request status of Family member Updated!'
      );
    }
    return toast.error(
      res?.status?.message ?? 'Something went wrong please try again!'
    );
  };

  const editFamily = async (data) => {
    await get(data?.family_member_id);
    navigate(clinicalRoutes.editFamilyMember);
  };
  const onDecline = async () => {
    const res = await acceptOrDeclineFamilyMemberRequest({
      family_member_id: notifyModalData?.familyMemberId ?? '',
      notification_id: notifyModalData?.notificationId ?? '',
      to_user_id: notifyModalData?.senderUserId ?? '',
      user_id: data?.user_id ?? '',
      status: false,
    });

    if (res?.status?.code === '200') {
      softDeleteNotification(notifyModalData?.notificationId);
      setNotifyModalData({
        ...notifyModalData,
        fromNotification: false,
      });
      return toast.success(
        res?.status?.message ?? 'Request status of Family member Updated!'
      );
    }
    return toast.error(
      res?.status?.message ?? 'Something went wrong please try again!'
    );
  };

  // Getting the family member data to use across the screens
  useEffect(() => {
    initialData();
  }, []);

  useEffect(() => {
    if (
      location?.state?.fromNotification === true ||
      location?.state?.fromNotification === 'true'
    ) {
      setNotifyModalData({
        fromNotification: location?.state?.fromNotification,
        senderUserId: location?.state?.senderUserId ?? '',
        senderUserName: location?.state?.senderUserName ?? '',
        receiverUserId: location?.state?.receiverUserId ?? '',
        familyMemberId: location?.state?.familyMemberId ?? '',
        notificationId: location?.state?.notificationId ?? '',
      });
    }
  }, []);

  return (
    <Screenlayout
      className={`${className}`}
      {...rest}
      title="My Family"
      backRequired={clinicalRoutes?.home}
      backRoute={clinicalRoutes?.home}
      childrenStyle={{
        minHeight: '60vh',
        pt: {
          xs: 10,
        },
      }}
      footer={
        <Box sx={{ gap: 2 }}>
          <Button
            buttonStyle={myFamilyScreen_style.addBtnSx}
            onClick={() => addFamily()}
          >
            Add Family Member
          </Button>
        </Box>
      }
    >
      <Box mt="16px">
        <Box sx={myFamilyScreen_style.boxRootSx}>
          {/* Loader */}
          {loader &&
            Array(5)
              .fill(1)
              .map((i) => <SkeletonCard key={i} isCardDetailsRequired={false} />)}

          {/* EmptyState Design */}
          {familyMembers !== null && familyMembers?.length === 0 && !loader ? (
            <ClinicalEmptyState
              IconRequired={<EmptyStateIcon rootStyle={EmptyStateBg.src} />}
              emptyStateStyle={{ mt: 9.25 }}
              mainHead="Healthy Family equals Healthy Life"
              subHead="Bring your family into Health Circles"
            />
          ) : null}

          {/* Family Member Card */}
          {!loader && familyMembers !== null && familyMembers?.length > 0 && (
            <>
              <Typography
                variant="body1"
                sx={{ color: ' #5C6266', fontWeight: 500 }}
              >
                {`${familyMembers?.length} Family Members`}
              </Typography>
              <MyFamilyMemberSelect
                editFamily={editFamily}
                familyMembers={familyMembers}
              />
            </>
          )}

          {/* Notification Popup */}
          <NotificationPopup
            open={notifyModalData?.fromNotification}
            locationData={location?.state}
            subTitle={
              <div>
                Wants to become family member of{' '}
                <span style={{ fontWeight: 600 }}>
                  {notifyModalData?.senderUserName}
                </span>
                ?
              </div>
            }
            acceptButtonName="Accept"
            declineButtonName="Decline"
            onDecline={onDecline}
            onAccpet={onAccpet}
          />

          <DrawerSelectMemberType
            routeAddFamily={routeAddFamily}
            routeExistingMember={routeExistingMember}
            addDrawerOpen={addDrawerOpen}
            setAddDrawerOpen={setAddDrawerOpen}
          />
        </Box>
      </Box>
    </Screenlayout>
  );
}
