/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
// 1. Imports - Refer coding_standard docs for the import order
import { DateRangePicker } from '@atoms/datePicker';
import { Dropdown } from '@atoms/dropdown';
import { Input } from '@atoms/input';
import { Label } from '@atoms/label';
import { CustomRadioGroup } from '@atoms/radioGroupWithLabel';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { upload_details_style } from './style';

function UploadDetails(props) {
  const {
    className = '',
    rootStyle = {},
    radioList = [],
    storeData = {},
    handleChange,
    relationship = [],
    handleToast = () => null,
    // setValue,
    updateRadioButton,
    // relationshipData = [],
    reportTypeData = [],
    ...rest
  } = props;

  return (
    <Box
      sx={{ ...upload_details_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Label>Report For</Label>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <CustomRadioGroup
                radioList={radioList}
                setValue={
                  !relationship?.length > 0 ? handleToast : updateRadioButton
                }
                value={storeData?.formReportFor ?? ''}
              />
            </Grid>
            {storeData?.formReportFor === 'familyMember' && (
              <Grid item sx={upload_details_style.dropDownSx} xs={12}>
                <Dropdown
                  selectOption={relationship?.map((value) => {
                    return {
                      label: value?.name,
                      value: value?.family_member_id,
                    };
                  })}
                  value={storeData?.formOthersRelationship}
                  onChange={(value) => {
                    handleChange(
                      'formOthersRelationship',
                      value?.target?.value
                    );
                  }}
                  isError={!!storeData?.error?.formOthersRelationship}
                  helperText={storeData?.error?.formOthersRelationship}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Label isRequired for="formReportName">
            Report Name
          </Label>
          <Input
            id="formReportName"
            placeholder="Type Report Name"
            textFieldStyle={upload_details_style.inputSx}
            value={storeData?.formReportName ?? ''}
            onChange={(e) => {
              handleChange('formReportName', e.target.value);
            }}
            isError={!!storeData?.error?.formReportName}
            errorMessage={storeData?.error?.formReportName}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Label isRequired>Report Type</Label>
          <Box sx={upload_details_style.dropDownSx}>
            <Dropdown
              selectOption={reportTypeData ?? []}
              value={storeData?.formReportType}
              onChange={(value) => {
                handleChange('formReportType', value?.target?.value);
              }}
              isError={!!storeData?.error?.formReportType}
              helperText={storeData?.error?.formReportType}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Label isRequired>Report Issue Date</Label>
          <Box sx={upload_details_style.date}>
            <DateRangePicker
              dateFormat="dd-MM-yyyy"
              startDate={storeData?.formReportIssueDate ?? ''}
              onChange={(value) => handleChange('formReportIssueDate', value)}
              maxDate={new Date()}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

// 3. Export
export { UploadDetails };
UploadDetails.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  radioList: PropTypes.array,
  storeData: PropTypes.object,
  setValue: PropTypes.bool,
  relationshipData: PropTypes.array,
  reportTypeData: PropTypes.array,
  // handleChangeFns: PropTypes.func,
};
