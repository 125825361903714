import { Box, Grid, Typography } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { linearProgressBar_style } from './style';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F7F7F7',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#7A67D9',
  },
}));

function LinearProgressBar(props) {
  const {
    value,
    activityName = '',
    valueBuffer,
    linearStyle = {},
    isWellnessJourneyProgress,
    showPercentageLabel,
    showActivityLabel,
    activityNameStyle = {},
    rootStyle = {},
    className = '',
    ...rest
  } = props;

  return (
    <Box
      sx={{ ...linearProgressBar_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Grid container display="flex" justifyContent="space-between">
        <Grid item xs={6} sm={6} md={6}>
          {showActivityLabel && (
            <Typography
              sx={{ fontSize: '12px', fontWeight: 500, ...activityNameStyle }}
            >
              {activityName}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          {showPercentageLabel && (
            <Typography fontSize={10} fontWeight={500}>
              {value}%
            </Typography>
          )}
        </Grid>
        <Grid item mt={1} xs={12}>
          {isWellnessJourneyProgress && (
            <BorderLinearProgress variant="determinate" value={value} />
          )}
          {!isWellnessJourneyProgress && (
            <LinearProgress
              valueBuffer={valueBuffer}
              sx={{ ...linearProgressBar_style.linearSx, ...linearStyle }}
              variant="determinate"
              value={value}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
export { LinearProgressBar };
LinearProgressBar.propTypes = {
  valueBuffer: PropTypes.any,
  value: PropTypes.number,
  activityNameStyle: PropTypes.object,
  linearStyle: PropTypes.object,
  rootStyle: PropTypes.object,
  className: PropTypes.string,
  isWellnessJourneyProgress: PropTypes.bool,
  activityName: PropTypes.string,
  showPercentageLabel: PropTypes.bool,
  showActivityLabel: PropTypes.bool,
};
