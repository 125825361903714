/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { useBookAppointment } from '@hc/store';
import { Button, Drawer, Dropdown, Label, MultiSelect } from '@hc/ui/atoms';
import { Box } from '@mui/material';
import { isEqual } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { doctorFilter_style } from './style';

export function DoctorFilter(props) {
  const {
    data,
    className = '',
    filterShow,
    clearDrawer = () => {},
    doctorFilter = () => {},
    filters = [],
    onCloseDrawer,
    ...rest
  } = props;

  const { loading } = useBookAppointment(
    (state) => ({
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const ComponentToRender = (val) => ({
    Dropdown: (
      <Dropdown
        selectOption={val.option}
        value={val.value}
        onChange={val.onChange}
        isError={val.error}
        helperText={val.helperText}
      />
    ),
    MultiSelect: (
      <MultiSelect
        multiSelectOption={val.option}
        value={val.value}
        languageData={val?.languageData}
        onChange={val.onChange}
        isError={val.error}
        helperText={val.helperText}
        ChipText={val.ChipText}
        onChipDelete={val.onChipDelete}
      />
    ),
  });

  return (
    <Box className={`${className}`} {...rest}>
      <Drawer
        show={filterShow}
        onCloseDrawer={onCloseDrawer}
        header="Filters"
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}
        rootStyle={doctorFilter_style.drawerRootSx}
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              onClick={clearDrawer}
              buttonStyle={doctorFilter_style.onClearSx}
              disabled={loading === true}
            >
              clear
            </Button>
            <Button
              onClick={doctorFilter}
              buttonStyle={doctorFilter_style.onNextSx}
              loading={loading}
            >
              Apply
            </Button>
          </Box>
        }
      >
        <Box sx={doctorFilter_style.rootSx}>
          {filters.map((val,i) => (
            <Box key={i} sx={doctorFilter_style.totalSx}>
              <Label rootStyle={doctorFilter_style.labelSx}>{val.label}</Label>
              <Box sx={doctorFilter_style.componentSx}>
                {ComponentToRender(val)[val?.component]}
              </Box>
            </Box>
          ))}
        </Box>
      </Drawer>
    </Box>
  );
}
DoctorFilter.propTypes = {
  data: PropTypes.object,
  filters: PropTypes.array,
  className: PropTypes.node,
  sx: PropTypes.object,
};
