/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-return-assign */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { envConfig } from '@hc/config';
import { getDateFormat } from '@hc/dayjs';
import { log } from '@hc/logger';
import { clinicalRoutes } from '@hc/routes';
import { queryClient } from '@hc/utils';
import { httpRequest } from '@hc/utils/axios';
import { getFileType, reportNameGenerator } from '@hc/utils/helperFunctions';
import toast from 'react-hot-toast';
import { create } from 'zustand';
import { useRouting } from '../common/routing';
// import { httpRequest, routeTo, localStorageKeys} from '@hc/utils';

export const useReport = create((set, get) => ({
  reportState: {
    selectAllReport: true,
    selectAllCaseSummary: true,
    deselectUplaodId: [],
    deselectCaseSummaryId: [],
    reportsDetails: [],
    reportAccessType: '',
    reportId: '',
    searchCaseSummary: [],
    selectTypeOfFile: '',
    // LIST REPORT DATA KEYS
    uploadedFilterReports: [],
    uploadReports: [],
    searchUploadReports: [],
    uploadedReportId: [],
    caseSummaryReports: [],
    searchCaseSummaryReports: [],
    uploadReportsCopy: [],
    uploadReportsInitialData: [],
    caseSummaryReportsCopy: [],
    deselectedReports: {
      uploadedId: [],
      caseSummaryId: [],
    },
    // SEARCH REPORT DATA KEYS
    searchNameReportFor: '',
    filterData: {
      searchStartReportDate: 'allDates',
      searchEndReportDate: 'allDates',
      searchReportType: 'allReportType',
      searchReportFor: 'All',
      searchReportDateType: 'allDates',
    },
    // FOR SAVE
    formReportId: null,
    uploadId: null,
    formReportFor: '',
    formOthersRelationship: '',
    formReportName: '',
    formReportType: '',
    formReportIssueDate: '',
    imageFiles: [],
    pdfFiles: [],
    imageFiles_: [],
    pdfFiles_: [],
    error: {
      formOthersRelationship: '',
      formReportType: '',
    },
    prevRoute: '',
  },
  loading: null,
  error: null,

  selectDeselectStore: async (tabType, reportId) => {
    const { reportState } = get();
    const { deselectedReports } = reportState;
    const { uploadedId, caseSummaryId } = deselectedReports;
    if (
      Array.isArray(reportState?.deselectedReports?.[tabType]) &&
      reportState?.deselectedReports?.[tabType]?.length > 0 &&
      reportState?.deselectedReports?.[tabType]?.includes(reportId)
    ) {
      if (reportId) {
        const arrayCopy = reportState?.deselectedReports?.[tabType];
        const index = arrayCopy.indexOf(reportId);
        if (index > -1) {
          arrayCopy.splice(index, 1);

          if (tabType === 'uploadedId') {
            return set({
              reportState: {
                ...reportState,
                deselectedReports: {
                  // caseSummaryId,
                  ...deselectedReports,
                  uploadedId: arrayCopy,
                },
              },
            });
          }
          if (tabType === 'caseSummaryId') {
            return set({
              reportState: {
                ...reportState,
                deselectedReports: {
                  ...deselectedReports,
                  uploadedId,
                  caseSummaryId: arrayCopy,
                },
              },
            });
          }
        }
      }
    } else {
      const arrayCopy_ = reportState?.deselectedReports?.[tabType];
      arrayCopy_.push(reportId);

      if (tabType === 'uploadedId') {
        return set({
          reportState: {
            ...reportState,
            deselectedReports: {
              caseSummaryId,
              uploadedId: arrayCopy_,
            },
          },
        });
      }
      if (tabType === 'caseSummaryId') {
        return set({
          reportState: {
            ...reportState,
            deselectedReports: {
              ...deselectedReports,
              uploadedId,
              caseSummaryId: arrayCopy_,
            },
          },
        });
      }
    }
  },
  selectAllStoreUpdate: async (tabType, uplaodTab, tabArrayId) => {
    const { reportState } = get();
    const { deselectedReports } = reportState;
    const { uploadedId, caseSummaryId } = deselectedReports;
    if (reportState?.[tabType]) {
      const arrayCopy = [];
      if (
        Array.isArray(reportState?.[uplaodTab]) &&
        reportState?.[uplaodTab]?.length > 0
      ) {
        reportState?.[uplaodTab]?.map((val, i) => {
          arrayCopy?.push(val?.id);
        });
        if (tabArrayId === 'uploadedId') {
          set({
            reportState: {
              ...reportState,
              [tabType]: false,
              deselectedReports: {
                // ...deselectedReports,
                caseSummaryId,
                uploadedId: arrayCopy,
              },
            },
          });
        } else if (tabArrayId === 'caseSummaryId') {
          set({
            reportState: {
              ...reportState,
              [tabType]: false,
              deselectedReports: {
                // ...deselectedReports,
                uploadedId,
                caseSummaryId: arrayCopy,
              },
            },
          });
        }
      } else {
        set({
          reportState: {
            ...reportState,
            [tabType]: false,
            deselectedReports: {
              ...deselectedReports,
              [tabArrayId]: [],
            },
          },
        });
      }
    } else {
      set({
        reportState: {
          ...reportState,
          [tabType]: true,
          deselectedReports: {
            ...deselectedReports,
            [tabArrayId]: [],
          },
        },
      });
    }
  },
  getAllReportsDetails: async (id) => {
    try {
      set({ loading: true });
      const { data } = await queryClient.fetchQuery([
        '/report/getAll',
        'post',
        { id: id ?? '' },
      ]);

      return set((state) => ({
        loading: false,
        reportState: {
          ...state.reportState,
          reportsDetails: data?.case_summary,
        },
      }));
    } catch (error) {
      set({ loading: false });
      // eslint-disable-next-line no-undef
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'something went worng please try aagin !',
      );
    }
  },
  getPublicPrivateAccess: async (userid, reportAccess, reportId) => {
    try {
      set({ loading: true });
      const payloadData = {
        id: userid ?? '',
        report_id: '',
        visit_summary_id: reportId ?? '',
        is_private: reportAccess !== 'private',
      };
      const { data } = await queryClient.fetchQuery([
        '/report/update-access',
        'post',
        { ...payloadData },
      ]);
      return set((state) => ({
        loading: false,
        reportState: {
          ...state.reportState,
          ...data,
        },
      }));
    } catch (error) {
      set({ loading: false });
      // eslint-disable-next-line no-undef
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'something went wrong please try again!',
      );
    }
  },
  // REPORT STATE UPDATE
  updateReportStore: (key, value) => {
    const { reportState } = get();
    set({
      reportState: {
        ...reportState,
        [key]: value,
      },
    });
  },
  updateReportStoreState: () => {
    const { reportState } = get();
    set({
      reportState: {
        ...reportState,
      },
    });
  },
  // UPSERT FILTER DATA
  upsertFilterData: (key, value) => {
    const { reportState } = get();
    const { filterData } = reportState;
    set({
      reportState: {
        ...reportState,
        filterData: {
          ...filterData,
          [key]: value,
        },
      },
    });
  },
  // CLEAR FILTER DATA
  clearFilterData: () => {
    const { reportState } = get();
    set({
      reportState: {
        ...reportState,
        filterData: {
          searchStartReportDate: 'allDates',
          searchEndReportDate: 'allDates',
          searchReportType: 'allReportType',
          searchReportFor: 'All',
          searchReportDateType: 'allDates',
        },
      },
    });
  },
  getAllReports: async (user_id, tabIndex) => {
    try {
      const { reportState } = get();
      const { searchNameReportFor, filterData } = reportState;
      set({ loading: true });

      const payload = {
        // id: '928acc1e-f174-4a1f-801d-30700a082018',
        id: user_id ?? '',
        search: searchNameReportFor ?? '',
        report_for:
          filterData?.searchReportFor === 'All'
            ? ''
            : filterData?.searchReportFor === 'Self'
            ? 'self'
            : filterData?.searchReportFor
            ? filterData?.searchReportFor
            : '',
        summary_for:
          filterData?.searchReportFor === 'All'
            ? ''
            : filterData?.searchReportFor === 'Self'
            ? 'self'
            : filterData?.searchReportFor
            ? filterData?.searchReportFor
            : '',
        start_date:
          filterData?.searchStartReportDate === 'allDates'
            ? ''
            : filterData?.searchStartReportDate
            ? filterData?.searchStartReportDate
            : '',
        end_date:
          filterData?.searchEndReportDate === 'allDates'
            ? ''
            : filterData?.searchEndReportDate
            ? filterData?.searchEndReportDate
            : '',
        report_type:
          filterData?.searchReportType === 'allReportType'
            ? ''
            : filterData?.searchReportType
            ? filterData?.searchReportType
            : '',
      };

      const { data, status } = await queryClient.fetchQuery([
        '/report/getAll',
        'post',
        {
          ...payload,
        },
      ]);

      const uploadedSortData = data?.uploaded?.sort(
        (objA, objB) =>
          new Date(objB.updatedAt).getTime() -
          new Date(objA.updatedAt).getTime(),
      );
      const caseSummarySortData = data?.case_summary.sort(
        (objA, objB) =>
          new Date(objB.updatedAt).getTime() -
          new Date(objA.updatedAt).getTime(),
      );

      if (tabIndex) {
        if (tabIndex === '1') {
          return set((state) => ({
            loading: false,
            reportState: {
              ...state.reportState,
              uploadReports: uploadedSortData,
            },
          }));
        }
        if (tabIndex === '2') {
          return set((state) => ({
            loading: false,
            reportState: {
              ...state.reportState,
              caseSummaryReports: caseSummarySortData,
            },
          }));
        }
      } else {
        return set((state) => ({
          loading: false,
          reportState: {
            ...state.reportState,
            uploadReports: data?.uploaded,
            caseSummaryReports: data?.case_summary,
            uploadReportsCopy: data?.uploaded,
            caseSummaryReportsCopy: data?.case_summary,
            uploadReportsInitialData: data?.uploaded,
          },
        }));
      }
    } catch (error) {
      set({ loading: false });
      // eslint-disable-next-line no-undef
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'something went worng please try aagin !',
      );
    }
  },
  clearUploadReportState: () =>
    set((state) => ({
      reportState: {
        ...state.reportState,

        imageFiles: [],
        pdfFiles: [],
        imageFiles_: [],
        pdfFiles_: [],
      },
    })),
  clearReportState: (isBookAppoinmetEdit) => {
    const { reportState } = get();
    set((state) => ({
      reportState: {
        ...state.reportState,
        deselectUplaodId: [],
        deselectCaseSummaryId: [],
        reportsDetails: [],
        reportAccessType: '',
        reportId: '',
        searchCaseSummary: [],
        uploadedFilterReports: [],
        uploadedReportId: isBookAppoinmetEdit
          ? reportState?.uploadedReportId
          : [],
        selectTypeOfFile: isBookAppoinmetEdit
          ? reportState?.selectTypeOfFile
          : 'image',
        // SEARCH REPORT DATA KEYS
        searchNameReportFor: '',
        filterData: {
          searchStartReportDate: 'allDates',
          searchEndReportDate: 'allDates',
          searchReportType: 'allReportType',
          searchReportFor: 'All',
          searchReportDateType: 'allDates',
        },
        // FORM DATA
        formReportId: null,
        uploadId: null,
        formReportFor: '',
        formOthersRelationship: '',
        formReportName: '',
        formReportType: '',
        formReportIssueDate: '',
        imageFiles: [],
        pdfFiles: [],
        imageFiles_: [],
        pdfFiles_: [],
        error: {
          formOthersRelationship: '',
          formReportType: '',
        },
        prevRoute:''
      },
    }));
  },

  // DELETE DATA
  clearBookAppointmentReports: () => {
    const { reportState } = get();
    set({
      reportState: {
        ...reportState,
        deselectedReports: {
          uploadedId: [],
          caseSummaryId: [],
        },
      },
    });
  },
  uploadStoreFile: (key, value) => {
    const { reportState } = get();
    const array = reportState?.[key];
    array.push({
      id: array?.length,
      ...value,
    });
    return set({
      reportState: {
        ...reportState,
        [key]: array,
      },
    });
  },
  // DELETE DATA
  deleteFileData: (type, i) => {
    const { reportState } = get();
    if (type === 'pdfFiles' || type === 'pdfFiles_') {
      set({
        reportState: {
          ...reportState,
          [type]: [],
        },
      });
    } else if (type === 'imageFiles' || type === 'imageFiles_') {
      // const copyData = JSON.parse(JSON.stringify(reportState?.[type]));
      const copyData = reportState?.[type];
      copyData.splice(i, 1);
      set({
        reportState: {
          ...reportState,
          [type]: copyData,
        },
      });
    }
  },
  // UPDATE REPORT ERROR
  updateReportError: (error_) => {
    const { reportState } = get();
    const { error } = reportState;
    set({
      reportState: {
        ...reportState,
        error: {
          ...error,
          error_,
        },
      },
    });
  },

  updateNewdata: (key, value) => {
    const { reportState } = get();
    const array = reportState?.[key];
    array.push({ ...value });
    return set({
      reportState: {
        ...reportState,
        [key]: array,
      },
    });
  },

  getUploadedReports: () => {
    const { reportState } = get();
    const allReports = reportState?.uploadReports;
    return allReports;
  },

  // UPSERT REPORT
  upsertReport: async (userId, isAdd, isBookAppoinment, reportFor) => {
    try {
      const { reportState, getAllReports, loading } = get();
      set({ loading: true });

      let reportName = '';
      let response;
      let date = new Date();
      if (isBookAppoinment) {
        reportName = reportNameGenerator(reportState?.selectTypeOfFile);
      }
      toast.loading('Report uploading....');

      let pdfdata = [];
      pdfdata.push(reportState?.pdfFiles_?.[0]);
      const data = {
        user_id: userId ?? '',
        // EDIT
        user_profile_report_id: reportState?.formReportId ?? '',
        // EDIT
        reports: {
          upload_id: reportState?.uploadId ?? '',
          upload_data:
            reportState?.selectTypeOfFile === 'image'
              ? reportState?.imageFiles_
              : reportState?.selectTypeOfFile === 'pdf'
              ? pdfdata
              : [],
          relationship:
            reportState?.formReportFor === 'self'
              ? ''
              : reportState?.formOthersRelationship,
          report_name: isBookAppoinment
            ? reportName
            : reportState?.formReportName,
          report_type: isBookAppoinment ? 3 : reportState?.formReportType ?? '',
          report_date: isBookAppoinment
            ? getDateFormat(date, 'yyyy-MM-DD')
            : reportState?.formReportIssueDate
            ? getDateFormat(reportState?.formReportIssueDate, 'yyyy-MM-DD')
            : '',
          is_self_report: isBookAppoinment
            ? reportFor === 'myself'
              ? true
              : false
            : reportState?.formReportFor === 'self'
            ? true
            : false,
        },
      };

      response = await httpRequest(
        'post',
        isAdd
          ? `${envConfig.api_url}/report/upload`
          : `${envConfig.api_url}/report/edit`,
        {
          ...data,
        },
        true,
      );

      if (response?.data?.status?.code === 200) {
        toast.dismiss();
        toast.success(
          response?.data?.status?.message ?? 'Report Uploaded Succesfully!',
        );

        await getAllReports(userId, '1');
        isBookAppoinment
          ? useRouting.setState({ route: clinicalRoutes.uploadReports })
          : useRouting.setState({ route: clinicalRoutes.reports });

        return response;
      }

      return set({ loading: false });
    } catch (err) {
      set({ loading: false });
      // eslint-disable-next-line no-undef
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  deleteReport: async (userId, value, tabIndex, isBookAppoinment) => {
    try {
      set({ loading: true });
      const { reportState, getAllReports, updateNewdata } = get();
      const data = {
        user_profile_report_id: value ?? '',
      };

      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/report/delete`,
        {
          ...data,
        },
        true,
      );

      if (response?.data?.status?.code === 200) {
        const result = reportState?.uploadedReportId.filter(
          (val) => val.id !== value,
        );

        set((state) => ({
          reportState: {
            ...state.reportState,
            uploadedReportId: result,
          },
        }));

        const fileteredReports = reportState?.uploadReports.filter(
          (val) => val.id !== value,
        );

        set((state) => ({
          reportState: {
            ...state.reportState,
            uploadReports: fileteredReports,
          },
        }));

        set({ loading: false });
        toast.success(
          response?.data?.status?.message ?? 'Report Delated Succesfully!',
        );
        return;
      }
    } catch (err) {
      set({ loading: false });
      // eslint-disable-next-line no-undef
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  constructReportEditData: (value) => {
    const { reportState } = get();
    set({
      reportState: {
        ...reportState,
        // FOR SAVE
        selectTypeOfFile: value?.upload?.upload_data?.[0]?.downloadUrl
          ? getFileType(value?.upload?.upload_data?.[0]?.downloadUrl) === 'pdf'
            ? 'pdf'
            : 'image'
          : '',
        formReportId: value?.id ?? '',
        uploadId: value?.upload_id ?? '',
        formReportFor: value?.user_family_member?.name
          ? 'familyMember'
          : 'self',
        formOthersRelationship: value?.user_family_member?.id,
        formReportName: value?.report_name ?? '',
        formReportType: value?.report_type ?? '',
        formReportIssueDate: value?.report_date
          ? new Date(value?.report_date)
          : '',
        imageFiles: value?.upload?.upload_data?.[0]?.downloadUrl
          ? getFileType(value?.upload?.upload_data?.[0]?.downloadUrl) !== 'pdf'
            ? value?.upload?.upload_data
            : []
          : [],
        imageFiles_: value?.upload?.upload_data?.[0]?.downloadUrl
          ? getFileType(value?.upload?.upload_data?.[0]?.downloadUrl) !== 'pdf'
            ? value?.upload?.upload_data
            : []
          : [],
        pdfFiles: value?.upload?.upload_data?.[0]?.downloadUrl
          ? getFileType(value?.upload?.upload_data?.[0]?.downloadUrl) === 'pdf'
            ? value?.upload?.upload_data
            : []
          : [],
        pdfFiles_: value?.upload?.upload_data?.[0]?.downloadUrl
          ? getFileType(value?.upload?.upload_data?.[0]?.downloadUrl) === 'pdf'
            ? value?.upload?.upload_data
            : []
          : [],
        error: {
          formOthersRelationship: '',
          formReportType: '',
        },
      },
    });
  },
  handleToast: () => {
    return toast.error('You have no family Member please add a family Member!');
  },

  setSelfFilter: () => {
    const { reportState } = get();
    let uploadReportsInitialDataCopy = JSON.parse(
      JSON.stringify(reportState.uploadReportsInitialData),
    );
    let Arr = uploadReportsInitialDataCopy?.filter(function (val) {
      return val?.is_self_report === true;
    });
    set((state) => ({
      loading: false,
      reportState: {
        ...state.reportState,
        uploadReports: Arr,
      },
    }));
  },

  updateStateValue: (key, value) => {
    const { reportState } = get();
    set({
      reportState: {
        ...reportState,
        [key]: value,
      },
    });
  },
}));
