import { getDateFormat, getTimeFormat } from '@hc/dayjs';
import { clinicalRoutes } from '@hc/routes';
import { usePrescription } from '@hc/store';
import { CalenderIcon, UserProfileIcon } from '@hc/ui/atoms';
import { Box, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { PatientDetailCard_style } from './style';

export function PrescPatientDetailCard(props) {
  const { className = '', year = '', rootStyleSx = {}, ...rest } = props;

  const navigate = useNavigate();
  const { prescriptionData } = usePrescription(
    (state) => ({
      prescriptionData: state.prescriptionData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  useEffect(() => {
    if (!prescriptionData) {
      return navigate(clinicalRoutes?.home);
    }
  }, []);

  return (
    <Box
      sx={{ ...PatientDetailCard_style.rootSx, ...rootStyleSx }}
      className={`${className}`}
      {...rest}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 0.5,
        }}
      >
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box>
            <UserProfileIcon rootStyle={{ color: 'orangeTints.600' }} />
          </Box>
          <Box>
            <Typography sx={PatientDetailCard_style.inputNameSx}>
              {prescriptionData?.patientName ?? ''}
            </Typography>
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              {' '}
              <Typography sx={PatientDetailCard_style.inputSx}>
                {prescriptionData?.patientAge ?? ''},
              </Typography>
              <Typography sx={PatientDetailCard_style.inputSx}>
                {prescriptionData?.patientGender ?? ''}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box>
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <Typography sx={PatientDetailCard_style.inputNameSx}>
                {getDateFormat(
                  prescriptionData?.visitSummaryUpdatedAt,
                  'Do MMM, YYYY',
                )}
              </Typography>
              <Typography sx={PatientDetailCard_style.inputSx}>
                {year}
              </Typography>
            </Box>
            <Typography sx={PatientDetailCard_style.inputTimeSx}>
              {getTimeFormat(
                prescriptionData?.visitSummaryUpdatedAt,
                'hh:mm A',
              )}
            </Typography>
          </Box>
          <Box>
            <CalenderIcon rootStyle={{ color: 'orangeTints.600' }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

PrescPatientDetailCard.propTypes = {
  name: PropTypes.string,
  classes: PropTypes.object,
  sx: PropTypes.object,
};
