/* eslint-disable jsx-a11y/iframe-has-title */
import { clinicalRoutes } from '@hc/routes';
import { useProfile } from '@hc/store';
import { useBugReports } from '@hc/store/clinical/bugReport';
import {
  Button,
  CustomTextArea,
  UploadCloseIcon,
  UploadIcon
} from '@hc/ui/atoms';
import { PatientReportUploadCard } from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { handleUploadFile } from '@hc/utils';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { bugReport_style } from './style';

export default function BugReport() {
  const [sendBtn, setsendBtn] = useState(false);
  const navigate = useNavigate();

  const {
    SendbugReport,
    changeState,
    bugReportPictureList,
    bugReport,
    bugReportPictures,
    isValid,
    bugPicDelete,
    error,
    clearbugReport,
    loading,
  } = useBugReports(
    (state) => ({
      SendbugReport: state.SendbugReport,
      changeState: state.changeState,
      bugReportPictureList: state.bugReportPictureList,
      bugPicDelete: state.bugPicDelete,
      bugReport: state.bugReport,
      bugReportPictures: state.bugReportPictures,
      isValid: state.isValid,
      error: state.error,
      clearbugReport: state.clearbugReport,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  const { profile } = useProfile(
    (state) => ({
      profile: state.profile,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const handleBugs = (value) => {
    changeState(value);
    if (sendBtn) isValid();
  };

  const handleActivityPicUpload = async (e) => {
    const [res] = await handleUploadFile(e.target.files);
    if (res?.message === 'Upload Successfully!') {
      bugReportPictureList(res);
    }
  };

  const handleSendBugs = () => {
    setsendBtn(true);
    const valid = isValid();
    if (valid) {
      SendbugReport(profile);
      navigate(clinicalRoutes.profileView);
    }
  };
  useEffect(() => clearbugReport(), []);
  return (
    <Box sx={bugReport_style.rootSx}>
      <Screenlayout
        title="Report Bugs"
        backRequired="/"
        childrenStyle={{
          minHeight: '60vh',
          pt: 0,
          backgroundColor: 'common.white',
        }}
        // notshowFooter="false"
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              sx={{ borderRadius: '10px' }}
              onClick={() => handleSendBugs()}
              loading={loading}
            >
              Submit
            </Button>
          </Box>
        }
      >
        <Box sx={{ p: 2 }}>
          <Box>
            <Typography sx={{ fontSize: '16px', fontWeight: 500, pb: 2 }}>
              Write your comment
            </Typography>
            <CustomTextArea
              data_testId="address"
              placeholder=""
              textFieldStyle={bugReport_style.customtextSx}
              value={bugReport}
              onChange={(e) => handleBugs(e.target.value)}
            />
            <Typography
              sx={bugReport_style.errorText}
              display={{
                display: error.bugReport ? 'block' : 'none',
              }}
            >
              {error.bugReport}
            </Typography>
          </Box>
          <Box py={2}>
            {bugReportPictures.length === 0 && (
              <Box sx={bugReport_style.activeUploadFileSx}>
                <Box component="label" sx={bugReport_style.uploadIconSx}>
                  <UploadIcon />
                  <input
                    hidden
                    name="img"
                    accept="image/*"
                    multiple="multiple"
                    type="file"
                    id="uplodebtnv"
                    onChange={handleActivityPicUpload}
                  />
                </Box>
                <Box>
                  <Typography sx={bugReport_style.browseTextSx}>
                    Click to browse and upload
                  </Typography>
                </Box>
              </Box>
            )}
            {bugReportPictures && (
              <Box mb={1}>
                {Array.isArray(bugReportPictures) &&
                  bugReportPictures.length > 0 &&
                  bugReportPictures.map((val, i) => (
                    <PatientReportUploadCard
                      key={i}
                      showReportName
                      icon={<UploadCloseIcon />}
                      isUploadCard
                      reportName={val?.filename ?? ''}
                      uploadedFor={val?.size ?? ''}
                      uploadedData={[{ file: `${val?.downloadUrl ?? ''}` }]}
                      IconOnClick={() => bugPicDelete(i)}
                    />
                  ))}
              </Box>
            )}
            {bugReportPictures && bugReportPictures.length > 0 && (
              <Box component="label" sx={{ cursor: 'pointer' }}>
                <Typography pt={2} sx={bugReport_style.addMoreButtonSx}>
                  + Add More
                </Typography>
                <input
                  hidden
                  name="img"
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={handleActivityPicUpload}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Screenlayout>
    </Box>
  );
}
