import OtpBg from '@assets/otpBg.svg';
import { ForgotPasswordIcon } from '@hc/ui/atoms/illustration';
import { ClinicalForgotPassword } from '@hc/ui/components/clinical/forgotPassword';
import { Box } from '@mui/material';
import React from 'react';

import { forgotPassword_style } from './style';

export default function ForgotPassword(props) {
  const { className = '', ...rest } = props;
  
  return (
    <Box
      sx={{
        ...forgotPassword_style.rootSx,
        backgroundImage: `url(${OtpBg.src})`,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={forgotPassword_style.imgContSx}>
        <ForgotPasswordIcon rootStyle={forgotPassword_style.imgSx} />
      </Box>
      <ClinicalForgotPassword />
      {/* ResetPassword Design */}
      {/* {!resetPassword && (
        <Box sx={forgotPassword_style.resetSx}>
          <Avatar src={Success} sx={forgotPassword_style.avatarSx} />
          <Typography variant="body1" sx={forgotPassword_style.titleResetSx}>
            Reset Password link sent to registered phone number
          </Typography>
        </Box>
      )} */}
    </Box>
  );
}
