import { useBookAppointment } from '@hc/store/clinical/bookAppointment';
import { CustomTextArea } from '@hc/ui/atoms';
import { QusAnsSelectionLisiting } from '@hc/ui/components';
import { useKeyPress } from 'ahooks';
import React, { useState } from 'react';

export function HealthIssuesSelection() {
  const [text, setText] = useState('');

  const {
    bookAppointmentState,
    addHealthIssue,
    deleteData,
    clearPageData,
    // loading: loader,
  } = useBookAppointment((state) => ({
    bookAppointmentState: state?.bookAppointmentState,
    addHealthIssue: state.addHealthIssue,
    deleteData: state.deleteData,
    clearPageData: state.clearPageData,
    loading: state?.loading,
  }));
  const { health_issues } = bookAppointmentState;

  // DELETE HEALTH ISSUE FUNCTION
  const handleDelete = (i) => {
    // DELETE HEALTH ISSUE
    deleteData('health_issues', i);
  };

  // CLEAR HEALTH ISSUE FUNCTION
  const handleClear = () => {
    // CLEAR HEALTH ISSUE
    clearPageData('health_issues');
  };

  // ADD HEALTH ISSUE FUNCTION
  const handleAdd = () => {
    if (text && text?.length > 0) {
      // ADD HEALTH ISSUE
      addHealthIssue(text);
      // CLEAR TEXT STATE DATA
      setText('');
    }
  };

  // While onEnterKeyPress Text  will Added
  useKeyPress(['Enter'], (event) => {
    event.preventDefault();
    handleAdd();
  });

  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <QusAnsSelectionLisiting
      QusAnsSelectionHeading="Describe your health issues"
      QusAnsListingHeading="Health Issues"
      QusAnsListingAdded="Added"
      OnClearAll={handleClear}
      displaySelectionValues={health_issues}
      OnDelete={handleDelete}
      QusAnsSelectionListing={
        <CustomTextArea
          rows={5}
          rowsMax={10}
          add={handleAdd}
          onChange={handleChange}
          value={text}
          placeholder="Eg. Having stomach pain for more than 3 days."
          enterKeyPress={useKeyPress}
          buttonRequired
        />
      }
    />
  );
}
