export const signIn_style = {
  rootSx: {
    display: 'grid',
    gridTemplateRows: 'minmax(auto, 26%) minmax(auto, 74%)',
    backgroundColor: 'primary.lighter',
    gap: 3.5,
    minHeight: '100vh',
  },

  inputGroupSx: { display: 'grid', gap: 0.8 },

  imgSx: {
    width: '100%',
    height: '100%',
    pt: 2,
    pb: 4,
    borderRadius: '0%',
  },

  imgContSx: {
    maxWidth: '90%',
    mx: 'auto',
    my: 'auto',
  },

  paperSx: {
    borderRadius: '24px 24px 0px 0px',
    px: 2.5,
    pt: 5,
    pb: 4,
    bgcolor: '#FDFFFF',
    display: 'grid',
    gap: 2,
    height: 'auto',
  },

  eyeSx: {
    width: '20px',
    height: '20px',
  },

  lastText: {
    color: 'text.secondary',
    fontSize: 14,
    opacity: 1,
    textAlign: 'center',
    mt: 1,
    display: 'flex',
    gap: 0.5,
    justifyContent: 'center',
  },

  signSx: {
    fontWeight: 600,
    fontSize: '14px',
    color: 'primary.main',
    opacity: 1,
    cursor: 'pointer',
    textDecoration: 'none',
  },

  googleStyle: {
    color: '#ACACAC',
    border: '1px solid',
    borderColor: 'grey.400',
    textTransform: 'none',
  },

  buttonsStyle: {
    textTransform: 'none',
    height: '40px',
  },

  titleSx: {
    color: 'text.primary',
    fontSize: '20px',
    fontWeight: 600,
  },

  forgotpasswordSx: {
    float: 'right',
    marginTop: '10px',
    fontWeight: 600,
    fontSize: '14px',
    cursor: 'pointer',
    color: 'primary.main',
    opacity: 1,
    textAlign: 'end',
    textDecoration: 'none',
  },
};
