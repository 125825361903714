/* eslint-disable no-lonely-if */
import { getTimeZoneFnc } from '@hc/dayjs';
import { clinicalRoutes } from '@hc/routes';
import {
  useBookAppointment,
  useFamilyMember,
  useProfile,
  useReport,
} from '@hc/store';

import { Button, Loader } from '@hc/ui/atoms';
import {
  AppointmentSummary,
  NotificationPopup,
} from '@hc/ui/components/clinical';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { queryClient } from '@hc/utils';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { config } from '@lib/config/envConfig';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { razorpayCall } from 'crayond_payment_gateway';
import { React, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { bookAppointmentSummary_style } from './style';

export default function BookAppointmentSummary(props) {
  const { className = '', ...rest } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState();
  const [loading_, setLoading] = useState(false);
  const media = useMediaQuery('(max-width:360px)');
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);
  // const [addDrawerOpen, setAddDrawerOpen] = useState(false);

  const tokenData_ = localStorage.getItem(localStorageKeys.authToken);
  const parseTokenData = parseJwt(tokenData_);

  const paymentType = JSON.parse(
    JSON.stringify(localStorage.getItem(localStorageKeys.appoinmentType))
  );

  const { profile, upsertProfile, getCorporateUserData } = useProfile(
    (state) => ({
      profile: state.profile,
      upsertProfile: state.upsert,
      getCorporateUserData: state.getCorporateUserData,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  // const { getFollowupReferralAppointmentList } = useAppointment(
  //   (state) => ({
  //     getFollowupReferralAppointmentList:
  //       state.getFollowupReferralAppointmentList,
  //   }),
  //   (prev, curr) => isEqual(prev, curr)
  // );

  const {
    bookAppointmentState,
    bookAppointmentFnc,
    bookAppointmentStateHealthQuries,
    updatePaymentStatusDetails,
    referralUpdate,
    loading,
  } = useBookAppointment(
    (state) => ({
      bookAppointmentStateHealthQuries: state.bookAppointmentStateHealthQuries,
      bookAppointmentState: state.bookAppointmentState,
      bookAppointmentFnc: state.bookAppointmentFnc,
      updatePaymentStatusDetails: state.updatePaymentStatusDetails,
      referralUpdate: state.referralUpdate,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  const { reportState } = useReport(
    (state) => ({
      reportState: state.reportState,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { familyMember, upsertFamilyMember } = useFamilyMember(
    (state) => ({
      familyMember: state.familyMember,

      upsertFamilyMember: state.upsert,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  // const { patientDetails } = bookAppointmentState;

  const callBackResponse = async (val) => {
    const ReferralUpadateData = localStorage.getItem(
      localStorageKeys?.isReferralAppoinment
    );
    const isReferralUpadate = JSON.parse(ReferralUpadateData);
    if (val?.statusCode === 200) {
      toast.success(val?.message ?? `Payment Success.`);
      // const bookAppointmentStateCopy = JSON.parse(
      //   JSON.stringify(bookAppointmentState)
      // );

      if (isReferralUpadate?.id) {
        localStorage.removeItem(localStorageKeys?.responseAppointmentId);
      }

      const responseAppointmentId = localStorage.getItem(
        localStorageKeys?.responseAppointmentId
      );

      const payload = {
        clientTimeZone: getTimeZoneFnc(),
        appointment_id: responseAppointmentId
          ? responseAppointmentId
          : isReferralUpadate.id
          ? isReferralUpadate.id
          : '',
        appointment_status_id: 1, // booked
        payment_json: val?.data ?? {},
        payment_type: 1,
      };
      const updateBookAppointmentResponse = await updatePaymentStatusDetails(
        payload
      );

      if (updateBookAppointmentResponse?.statusCode === 200) {
        // getFollowupReferralAppointmentList(parseTokenData?.user_id);
        localStorage.removeItem(localStorageKeys?.responseAppointmentId);
        // To invalidate the cache of performanceStats
        queryClient.invalidateQueries({
          queryKey: ['appointments'],
        });

        navigate(clinicalRoutes?.bookingStatus, {
          state: {
            status: 'confirmed',
            appointmentId:
              updateBookAppointmentResponse?.data?.appointmentId ?? '',
            bookingMethod:
              updateBookAppointmentResponse?.data?.bookingMethod ?? '',
            familyMemberId:
              updateBookAppointmentResponse?.data?.familyMemberId ?? '',
          },
        });
      } else {
        return toast.error(
          updateBookAppointmentResponse?.message ??
            'Something went wrong please try again!'
        );
      }
    }
  };

  const razorPayCallFunc = () => {
    razorpayCall({
      baseURL: config?.payment_url,
      // baseURL: import.meta.env.VITE_APP_API_BASE_URL,
      orderAmount: bookAppointmentState?.doctorDetail?.appointment_charges
        ?.charge_in_paisa
        ? (
            bookAppointmentState?.doctorDetail?.appointment_charges
              ?.charge_in_paisa / 100
          ).toString()
        : 0,
      name: 'Health Circles',
      description: 'description',
      preFillName:
        bookAppointmentState?.booking_method === 'myself' ||
        bookAppointmentState?.booking_method === 'family'
          ? bookAppointmentState?.patientDetails?.name ?? ''
          : bookAppointmentState?.booking_method === 'others'
          ? bookAppointmentState?.booking_for?.name ?? ''
          : '',
      preFillEmail:
        bookAppointmentState?.booking_method === 'myself' ||
        bookAppointmentState?.booking_method === 'family'
          ? bookAppointmentState?.patientDetails?.email_id
            ? bookAppointmentState?.patientDetails?.email_id
            : ''
          : bookAppointmentState?.booking_method === 'others'
          ? bookAppointmentState?.booking_for?.email
            ? bookAppointmentState?.booking_for?.email
            : ''
          : '',
      preFillContactNumber:
        bookAppointmentState?.booking_method === 'myself' ||
        bookAppointmentState?.booking_method === 'family'
          ? bookAppointmentState?.patientDetails?.mobile_no
            ? bookAppointmentState?.patientDetails?.mobile_no ?? ''
            : ''
          : bookAppointmentState?.booking_method === 'others'
          ? bookAppointmentState?.booking_for?.mobile
            ? ` ${bookAppointmentState?.booking_for?.mobile}` ?? ''
            : ''
          : '',
      notesAddress: 'notesAddress',
      theme: '',
      paymentType: 1,
      setLoading,
      callBackResponse,
    });
  };
  // BOOK APPOINTMENT FUNCTION
  const bookAppointment = async (type) => {
    const responseAppointmentId = localStorage.getItem(
      localStorageKeys?.responseAppointmentId
    );
    const ReferralUpadateData = localStorage.getItem(
      localStorageKeys?.isReferralAppoinment
    );
    const isReferralUpadate = JSON.parse(ReferralUpadateData);
    if (responseAppointmentId) {
      if (type === 'paid') {
        razorPayCallFunc();
      }
    } else {
      if (!isReferralUpadate?.is_patient_referral) {
        const bookAppointmentStateCopy = JSON.parse(
          JSON.stringify(bookAppointmentState)
        );

        const bookAppointmentStateHealthQuriesCopy = JSON.parse(
          JSON.stringify(bookAppointmentStateHealthQuries)
        );

        const payload = {
          booking_method: bookAppointmentStateCopy?.booking_method ?? '',
          family_member_id:
            bookAppointmentStateCopy?.booking_method === 'family'
              ? bookAppointmentStateCopy?.family_member_id
              : '',
          booking_for: {
            name: bookAppointmentStateCopy?.booking_for?.name ?? '',
            gender: bookAppointmentStateCopy?.booking_for?.gender ?? '',
            dob_age: {
              dob: bookAppointmentStateCopy?.booking_for?.dob ?? '',
              age:
                bookAppointmentStateCopy?.booking_for?.age?.length > 0
                  ? parseFloat(bookAppointmentStateCopy?.booking_for?.age)
                  : 0,
            },
            relationship: bookAppointmentStateCopy?.booking_for?.relationship
              ?.value
              ? bookAppointmentStateCopy?.booking_for?.relationship?.value
              : bookAppointmentStateCopy?.booking_for?.relationship
              ? bookAppointmentStateCopy?.booking_for?.relationship
              : '',
            mobile: {
              mobile_code:
                bookAppointmentStateCopy?.booking_for?.mobileCode ?? '',
              mobile: bookAppointmentStateCopy?.booking_for?.mobile ?? '',
            },
            email: bookAppointmentStateCopy?.booking_for?.email ?? '',
          },
          reason_for_consultation: 'Health Issues',
          // bookAppointmentStateCopy?.reason_for_consultation ?? '',
          health_issues: bookAppointmentStateCopy?.health_issues ?? [],
          symptoms:
            Array.isArray(bookAppointmentStateCopy?.symptoms) &&
            bookAppointmentStateCopy?.symptoms?.length > 0
              ? bookAppointmentStateCopy?.symptoms
                  .filter((val) => val?.value !== val?.labelShow)
                  .map((obj) => ({
                    id: obj?.value ?? '',
                  }))
              : [],
          user_symptoms:
            bookAppointmentStateHealthQuriesCopy?.additionalSymptoms,
          health_problems:
            Array.isArray(bookAppointmentStateCopy?.health_problems) &&
            bookAppointmentStateCopy?.health_problems?.length > 0
              ? bookAppointmentStateCopy?.health_problems
                  .filter((val) => val?.value !== val?.labelShow)
                  .map((obj) => ({
                    id: obj?.value ?? '',
                  }))
              : [],
          user_health_problems:
            bookAppointmentStateHealthQuriesCopy?.additionalHealth_problems,
          allergies:
            Array.isArray(bookAppointmentStateCopy?.allergies) &&
            bookAppointmentStateCopy?.allergies?.length > 0
              ? bookAppointmentStateCopy?.allergies
                  .filter((val) => val?.value !== val?.labelShow)
                  .map((obj) => ({
                    id: obj?.value ?? '',
                  }))
              : [],
          user_allergies:
            bookAppointmentStateHealthQuriesCopy?.additionalAllergies,
          drugs:
            Array.isArray(bookAppointmentStateCopy?.drugs) &&
            bookAppointmentStateCopy?.drugs?.length > 0
              ? bookAppointmentStateCopy?.drugs
                  .filter((val) => val?.value !== val?.labelShow)
                  .map((obj) => ({
                    id: obj?.value ?? '',
                  }))
              : [],
          user_medications:
            bookAppointmentStateHealthQuriesCopy?.additionalDrugs,
          appointed_doctor_details:
            bookAppointmentStateCopy?.appointed_doctor_details ?? {},
          deselected_reports:
            reportState?.deselectedReports?.uploadedId?.length > 0 ||
            reportState?.deselectedReports?.caseSummaryId?.length > 0
              ? {
                  uploaded_id: reportState?.deselectedReports?.uploadedId,
                  case_summary_id:
                    reportState?.deselectedReports?.caseSummaryId,
                }
              : {
                  uploaded_id: [],
                  case_summary_id: [],
                },

          vitals: {
            height_in_cm: bookAppointmentStateCopy?.vitals?.height_in_cm
              ? parseFloat(bookAppointmentStateCopy?.vitals?.height_in_cm)
              : 0,
            weight_in_cm: bookAppointmentStateCopy?.vitals?.weight_in_cm
              ? parseFloat(bookAppointmentStateCopy?.vitals?.weight_in_cm)
              : 0,
            bmi_in_kg_m2: bookAppointmentStateCopy?.vitals?.bmi_in_kg_m2
              ? parseFloat(bookAppointmentStateCopy?.vitals?._in_kg_m2)
              : 0,
            temp_in_f: bookAppointmentStateCopy?.vitals?.temp_in_f
              ? parseFloat(bookAppointmentStateCopy?.vitals?.temp_in_f)
              : 0,
            puls_bpm: bookAppointmentStateCopy?.vitals?.puls_bpm
              ? parseFloat(bookAppointmentStateCopy?.vitals?.puls_bpm)
              : 0,
            bp_sys: bookAppointmentStateCopy?.vitals?.blood_pressure?.split(
              '/'
            )?.[0]
              ? parseFloat(
                  bookAppointmentStateCopy?.vitals?.blood_pressure?.split(
                    '/'
                  )?.[0]
                )
              : 0,
            bp_dia: bookAppointmentStateCopy?.vitals?.blood_pressure?.split(
              '/'
            )?.[1]
              ? parseFloat(
                  bookAppointmentStateCopy?.vitals?.blood_pressure?.split(
                    '/'
                  )?.[1]
                )
              : 0,
            o2_level: bookAppointmentStateCopy?.vitals?.o2_level
              ? parseFloat(bookAppointmentStateCopy?.vitals?.o2_level)
              : 0,
          },
          bookedBy: bookAppointmentStateCopy?.bookedBy ?? '',
          clientTimeZone: getTimeZoneFnc(),
        };

        const bookAppointmentResponse = await bookAppointmentFnc(payload);

        if (bookAppointmentResponse?.statusCode === '200') {
          // setAddDrawerOpen(false);
          // const bookAppointmentStateCop = JSON.parse(
          //   JSON.stringify(bookAppointmentState)
          // );
          if (type === 'paid') {
            razorPayCallFunc();
          }
          if (bookAppointmentState?.booking_method === 'myself') {
            const obj = {
              age: bookAppointmentState?.patientDetails?.age ?? '',
              blood_group: bookAppointmentState?.vitals?.blood_group ?? '',
              blood_pressure:
                bookAppointmentState?.vitals?.blood_pressure ?? '',
              bmi: bookAppointmentState?.vitals?.bmi_in_kg_m2 ?? '',
              country_code:
                bookAppointmentState?.patientDetails?.country_code ?? '',
              email_id: bookAppointmentState?.patientDetails?.email_id ?? '',
              error: {
                name: '',
                gender: '',
                age: '',
                email_id: '',
                mobile_no: '',
              },
              gender: bookAppointmentState?.patientDetails?.gender ?? '',
              height: bookAppointmentState?.vitals?.height_in_cm ?? '',
              mobile_no: bookAppointmentState?.patientDetails?.mobile_no ?? '',
              name: bookAppointmentState?.patientDetails?.name ?? '',
              o2_level: bookAppointmentState?.vitals?.o2_level ?? '',
              profile_pic: '',
              puls_bpm: bookAppointmentState?.vitals?.puls_bpm ?? '',
              temp_in_f: bookAppointmentState?.vitals?.temp_in_f ?? '',
              weight: bookAppointmentState?.vitals?.weight_in_cm ?? '',
            };
            await upsertProfile(obj, false, true);
          } else if (bookAppointmentState?.booking_method === 'family') {
            const obj = {
              age: bookAppointmentState?.patientDetails?.age ?? '',
              blood_group: bookAppointmentState?.vitals?.blood_group ?? '',
              blood_pressure:
                bookAppointmentState?.vitals?.blood_pressure ?? '',
              bmi: bookAppointmentState?.vitals?.bmi_in_kg_m2 ?? '',
              country_code:
                bookAppointmentState?.patientDetails?.country_code ?? '',
              email_id: bookAppointmentState?.patientDetails?.email_id ?? '',
              error: {
                name: '',
                relationship: '',
                gender: '',
                age: '',
                mobile_no: '',
                blood_pressure: '',
                email_id: '',
              },
              family_member_id: bookAppointmentState?.family_member_id ?? '',
              gender: bookAppointmentState?.patientDetails?.gender ?? '',
              height: bookAppointmentState?.vitals?.height_in_cm ?? '',
              is_use_my_number: true,
              login_user_country_code: '',
              login_user_mobile_number: '',
              mobile_no: bookAppointmentState?.patientDetails?.mobile_no ?? '',
              name: bookAppointmentState?.patientDetails?.name ?? '',
              o2_level: bookAppointmentState?.vitals?.o2_level ?? '',
              puls_bpm: bookAppointmentState?.vitals?.puls_bpm ?? '',
              relationship: familyMember?.relationship ?? '',
              temp_in_f: bookAppointmentState?.vitals?.temp_in_f ?? '',
              weight: bookAppointmentState?.vitals?.weight_in_cm ?? '',
            };
            await upsertFamilyMember(obj, false, false, true);
          }
        } else {
          return toast.error('Something went wrong please try again!');
        }
      } else if (isReferralUpadate?.is_patient_referral) {
        const bookAppointmentStateCopy = JSON.parse(
          JSON.stringify(bookAppointmentState)
        );
        const bookAppointmentStateHealthQuriesCopy = JSON.parse(
          JSON.stringify(bookAppointmentStateHealthQuries)
        );
        const payload = {
          appointment_id: isReferralUpadate?.id ?? '',
          booking_method: bookAppointmentStateCopy?.booking_method ?? '',
          reason_for_consultation: 'Health Issues',
          health_issues: bookAppointmentStateCopy?.health_issues ?? [],
          symptoms:
            Array.isArray(bookAppointmentStateCopy?.symptoms) &&
            bookAppointmentStateCopy?.symptoms?.length > 0
              ? bookAppointmentStateCopy?.symptoms
                  .filter((val) => val?.value !== val?.labelShow)
                  .map((obj) => ({
                    id: obj?.value ?? '',
                  }))
              : [],
          health_problems:
            Array.isArray(bookAppointmentStateCopy?.health_problems) &&
            bookAppointmentStateCopy?.health_problems?.length > 0
              ? bookAppointmentStateCopy?.health_problems
                  .filter((val) => val?.value !== val?.labelShow)
                  .map((obj) => ({
                    id: obj?.value ?? '',
                  }))
              : [],
          allergies:
            Array.isArray(bookAppointmentStateCopy?.allergies) &&
            bookAppointmentStateCopy?.allergies?.length > 0
              ? bookAppointmentStateCopy?.allergies
                  .filter((val) => val?.value !== val?.labelShow)
                  .map((obj) => ({
                    id: obj?.value ?? '',
                  }))
              : [],
          drugs:
            Array.isArray(bookAppointmentStateCopy?.drugs) &&
            bookAppointmentStateCopy?.drugs?.length > 0
              ? bookAppointmentStateCopy?.drugs
                  .filter((val) => val?.value !== val?.labelShow)
                  .map((obj) => ({
                    id: obj?.value ?? '',
                  }))
              : [],
          appointed_doctor_details:
            bookAppointmentStateCopy?.appointed_doctor_details ?? {},
          deselected_reports:
            reportState?.deselectedReports?.uploadedId?.length > 0 ||
            reportState?.deselectedReports?.caseSummaryId?.length > 0
              ? {
                  uploaded_id: reportState?.deselectedReports?.uploadedId,
                  case_summary_id:
                    reportState?.deselectedReports?.caseSummaryId,
                }
              : {
                  uploaded_id: [],
                  case_summary_id: [],
                },

          vitals: {
            height_in_cm: bookAppointmentStateCopy?.vitals?.height_in_cm
              ? parseFloat(bookAppointmentStateCopy?.vitals?.height_in_cm)
              : 0,
            weight_in_cm: bookAppointmentStateCopy?.vitals?.weight_in_cm
              ? parseFloat(bookAppointmentStateCopy?.vitals?.weight_in_cm)
              : 0,
            bmi_in_kg_m2: bookAppointmentStateCopy?.vitals?.bmi_in_kg_m2
              ? parseFloat(bookAppointmentStateCopy?.vitals?._in_kg_m2)
              : 0,
            temp_in_f: bookAppointmentStateCopy?.vitals?.temp_in_f
              ? parseFloat(bookAppointmentStateCopy?.vitals?.temp_in_f)
              : 0,
            puls_bpm: bookAppointmentStateCopy?.vitals?.puls_bpm
              ? parseFloat(bookAppointmentStateCopy?.vitals?.puls_bpm)
              : 0,
            bp_sys: bookAppointmentStateCopy?.vitals?.blood_pressure?.split(
              '/'
            )?.[0]
              ? parseFloat(
                  bookAppointmentStateCopy?.vitals?.blood_pressure?.split(
                    '/'
                  )?.[0]
                )
              : 0,
            bp_dia: bookAppointmentStateCopy?.vitals?.blood_pressure?.split(
              '/'
            )?.[1]
              ? parseFloat(
                  bookAppointmentStateCopy?.vitals?.blood_pressure?.split(
                    '/'
                  )?.[1]
                )
              : 0,
            o2_level: bookAppointmentStateCopy?.vitals?.o2_level
              ? parseFloat(bookAppointmentStateCopy?.vitals?.o2_level)
              : 0,
          },
          bookedBy: bookAppointmentStateCopy?.bookedBy ?? '',
          clientTimeZone: getTimeZoneFnc(),
          user_symptoms:
            bookAppointmentStateHealthQuriesCopy?.additionalSymptoms,

          user_health_problems:
            bookAppointmentStateHealthQuriesCopy?.additionalHealth_problems,

          user_allergies:
            bookAppointmentStateHealthQuriesCopy?.additionalAllergies,

          user_medications:
            bookAppointmentStateHealthQuriesCopy?.additionalDrugs,
        };

        const referralUpdateResponse = await referralUpdate(payload);

        if (referralUpdateResponse?.statusCode === 200) {
          // setAddDrawerOpen(false);

          if (type === 'paid') {
            razorPayCallFunc();
          }
          if (bookAppointmentStateCopy?.booking_method === 'myself') {
            const obj = {
              age: profile?.age ?? '',
              blood_group: profile?.blood_group ?? '',
              blood_pressure:
                bookAppointmentState?.vitals?.blood_pressure ?? '',
              bmi: bookAppointmentState?.vitals?.bmi_in_kg_m2 ?? '',
              country_code: profile?.country_code ?? '',
              email_id: profile?.email_id ?? '',
              error: {
                name: '',
                gender: '',
                age: '',
                email_id: '',
                mobile_no: '',
              },
              gender: profile?.gender ?? '',
              height: bookAppointmentState?.vitals?.height_in_cm ?? '',
              mobile_no: profile?.mobile_no ?? '',
              name: profile?.name ?? '',
              o2_level: bookAppointmentState?.vitals?.o2_level ?? '',
              profile_pic: profile?.profile_pic ?? '',
              puls_bpm: bookAppointmentState?.vitals?.puls_bpm ?? '',
              temp_in_f: bookAppointmentState?.vitals?.temp_in_f ?? '',
              weight: bookAppointmentState?.vitals?.weight_in_cm ?? '',
            };
            await upsertProfile(obj, false, true);
          }
        } else {
          return toast.error('Something went wrong please try again!');
        }
      }
    }
  };

  // FREE CONSULATATION FOR ORGANIZATION USER

  const FreeConsultationNavFun = async () => {
    const ReferralUpadateData = localStorage.getItem(
      localStorageKeys?.isReferralAppoinment
    );
    const isReferralUpadate = JSON.parse(ReferralUpadateData);
    await bookAppointment('free');

    if (isReferralUpadate?.id) {
      localStorage.removeItem(localStorageKeys?.responseAppointmentId);
    }
    const responseAppointmentId = localStorage.getItem(
      localStorageKeys?.responseAppointmentId
    );
    if (
      (responseAppointmentId &&
        responseAppointmentId?.length > 0 &&
        !undefined) ||
      (isReferralUpadate?.id && isReferralUpadate?.id.length > 0)
    ) {
      const payload = {
        clientTimeZone: getTimeZoneFnc(),
        appointment_id: responseAppointmentId
          ? responseAppointmentId
          : isReferralUpadate.id
          ? isReferralUpadate.id
          : '',
        appointment_status_id: 1, // booked
        payment_json: {},
        payment_type: 3,
      };
      const updateBookAppointmentResponse = await updatePaymentStatusDetails(
        payload
      );

      if (updateBookAppointmentResponse?.statusCode === 200) {
        // debugger;
        // getFollowupReferralAppointmentList(parseTokenData?.user_id);
        localStorage.removeItem(localStorageKeys?.responseAppointmentId);
        // To invalidate the cache of performanceStats
        queryClient.invalidateQueries({
          queryKey: ['appointments'],
        });

        navigate(clinicalRoutes?.bookingStatus, {
          state: {
            status: 'confirmed',
            appointmentId:
              updateBookAppointmentResponse?.data?.appointmentId ?? '',
            bookingMethod:
              updateBookAppointmentResponse?.data?.bookingMethod ?? '',
            familyMemberId:
              updateBookAppointmentResponse?.data?.familyMemberId ?? '',
          },
        });
      } else {
        return toast.error(
          updateBookAppointmentResponse?.message ??
            'Something went wrong please try again!'
        );
      }
    }
  };

  const freeConsultationCheck = async () => {
    if (
      parseTokenData?.employee_membership_code?.length > 0 &&
      !profile?.contractExpired &&
      profile?.remaining_free_consultion > 0 && paymentType === 'free'
    ) {
      FreeConsultationNavFun();
    } else {
      await bookAppointment('paid');
    }
  };
  const openPopUp = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onGoToHome = () => {
    queryClient.invalidateQueries({
      queryKey: ['appointments'],
    });
    navigate(clinicalRoutes?.home);
  };
  useEffect(() => {
    if (parseTokenData?.employee_membership_code?.length > 0) {
      getCorporateUserData(parseTokenData.user_id);
    }
  }, []);

  return (
    <Box px={media ? 2 : 3} className={`${className}`} {...rest}>
      <Screenlayout
        backRequired="/appointmentbooking/vitalsform"
        title="Book Appointment "
        masterchildStyle={{ pb: 10 }}
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              buttonStyle={bookAppointmentSummary_style.buttonSx}
              onClick={() => openPopUp()}
              disabled={loading === true}
            >
              Go To Home
            </Button>
            <Button
              buttonStyle={{
                borderRadius: '8px',
                textTransform: 'none',
              }}
              onClick={() => freeConsultationCheck()}
              loading={loading}
            >
              {paymentType === 'free' ? 'Book Appoinment ' : 'Make Payment'}
            </Button>
          </Box>
        }
      >
        {/* Book Appointment Summary */}
        <Box>
          <Typography sx={bookAppointmentSummary_style.summaryTextSx}>
            Appointment Summary
          </Typography>
        </Box>
        <Box mt={2.5}>
          <AppointmentSummary
            myself={bookAppointmentState?.patientDetails?.id === data?.user_id}
          />
        </Box>
        {loading_ && (
          <Loader rootStyle={{ display: 'flex', justifyContent: 'center' }} />
        )}

        {open && (
          <NotificationPopup
            // icons={<WarningIcon />}
            open={open}
            subTitleStyle={{
              fontWeight: 500,
            }}
            subTitle="Are you sure, You want go back home page?"
            acceptButtonName="Yes"
            declineButtonName="Cancel"
            notifyBtnStyletwo={{
              bgcolor: 'primaryTints.200',
              color: 'primary.main',
              '&:hover': {
                bgcolor: 'primaryTints.200',
              },
            }}
            notifyBtnStyle={{
              bgcolor: 'primary.main',
              color: 'common.white',
            }}
            onDecline={onClose}
            onAccpet={onGoToHome}
          />
        )}
        {/* {addDrawerOpen && (
          <FreeConsultation
            addDrawerOpen={addDrawerOpen}
            setAddDrawerOpen={setAddDrawerOpen}
            PaidConsultant={paidConsultationFun}
            FreeConsultant={FreeConsultationNavFun}
          />
        )} */}
      </Screenlayout>
    </Box>
  );
}
