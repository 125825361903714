import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { AddSolidIcon, BpIcon, Button, VitalUpdateIcon } from '../../../atoms';
import { InfoIcon } from '../../../atoms/icons/iconSet4';
import { vitalMonitoringCard_style } from './style';

function VitalMonitoringCard(props) {
  const {
    className = '',
    icon = <BpIcon />,
    type = 'Blood Pressure',
    subType = 'mmHg',
    value = '170/200',
    onInfoClick = () => {},
    onAddOrUpdateClick = () => {},
    onCardClick = () => {},
    isAdd = false,
    rootStyle = {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...vitalMonitoringCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
     
    >
      <Grid container rowSpacing={1}>
        <Grid item xs={6}  onClick={onCardClick}>
          {icon}
        </Grid>
        <Grid item display="flex" justifyContent="end" xs={6}>
          <Box sx={{ cursor: 'pointer' }} onClick={onInfoClick}>
            <InfoIcon rootStyle={{ color: '#888888' }} />
          </Box>
        </Grid>
        <Grid item xs={12}  onClick={onCardClick}>
          <Typography fontSize={12} color="#5C6266" fontWeight={600}>
            {type}
          </Typography>
        </Grid>
        <Grid item xs={12}  onClick={onCardClick}>
          <Box display="flex">
            <Typography fontWeight={600}>{isAdd ? '_ _' : value}</Typography>
            <Typography mt="4px" ml="4px" fontSize={12} color="#888888">
              {subType}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={
              isAdd ? (
                <AddSolidIcon rootStyle={{ height: '12px', width: '12px' }} />
              ) : (
                <VitalUpdateIcon />
              )
            }
            buttonStyle={vitalMonitoringCard_style?.buttonSx}
            onClick={onAddOrUpdateClick}
          >
            {isAdd ? 'Add' : 'Update'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

VitalMonitoringCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  icon: PropTypes.any,
  type: PropTypes.string,
  subType: PropTypes.string,
  value: PropTypes.string,
  onInfoClick: PropTypes.func,
  onAddOrUpdateClick: PropTypes.func,
  onCardClick: PropTypes.func,
  isAdd: PropTypes.bool,
};

export { VitalMonitoringCard };
