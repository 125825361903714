/* eslint-disable no-unused-vars */
import { useBloodGroup, useProfile } from '@hc/store';
import { Button } from '@hc/ui/atoms';
import { AddFamilyProfile, Screenlayout } from '@hc/ui/components';
import { localStorageKeys } from '@hc/utils';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box } from '@mui/material';
import isEqual from 'react-fast-compare';
import { addProfile_style } from './style';

export default function AddProfile(props) {
  const { className = '', ...rest } = props;

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);

  const {
    profile,
    upsert,
    // handleProfileDataChange,
    clearProfile,
    updateProfileError,
    // updateMobileNumber,
    loading,
  } = useProfile(
    (state) => ({
      profile: state.profile,
      handleProfileDataChange: state.handleProfileDataChange,
      clearProfile: state.clearProfile,
      updateProfileError: state.updateProfileError,
      updateMobileNumber: state.updateMobileNumber,
      loading: state.loading,
      upsert: state.upsert,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { bloodGroupData, getBloodGroupData } = useBloodGroup((state) => ({
    bloodGroupData: state.bloodGroupData,
    getBloodGroupData: state.getBloodGroupData,
  }));

  const isIamValidToSave = () => {
    const profileCopy = JSON.parse(JSON.stringify(profile));

    let isValid = true;
    const error = profileCopy?.error;

    // Checking name
    if (!profile?.name) {
      isValid = false;
      error.name = 'Please enter your name.';
    } else {
      error.name = '';
    }

    // Checking gender
    if (!profile?.gender) {
      isValid = false;
      error.gender = 'Please enter your gender';
    } else {
      error.gender = '';
    }

    // Checking age
    if (!profile?.age) {
      isValid = false;
      error.age = 'Please enter your age';
    } else {
      error.age = '';
    }
    // Checking email_id
    const filter = /\S+@\S+\.\S+/;
    if (profile?.email_id?.length > 0 && !filter.test(profile?.email_id)) {
      isValid = false;
      error.email_id = 'Please enter the valid mail';
    } else {
      error.email_id = '';
    }
    if (profile?.mobile_no?.length > 0 && profile?.mobile_no?.length !== 10) {
      isValid = false;
      error.mobile_no = 'Please enter your valid mobilenumber';
    } else {
      error.mobile_no = '';
    }

    if (!profile?.blood_group > 0 || profile?.blood_group === 'k') {
      isValid = false;
      error.blood_group = 'Please select your blood group';
    } else {
      error.blood_group = '';
    }

    // UPDATE PROFILE ERROR
    updateProfileError(error);
    return isValid;
  };

  const handleSave = async () => {
    const result = isIamValidToSave();
    if (result) {
      const payload = {
        ...profile,
        country_code: profile?.country_code
          ? profile?.country_code
          : tokenData?.country_code
          ? tokenData?.country_code
          : '+91',
        mobile_no: profile?.mobile_no
          ? profile?.mobile_no
          : tokenData?.mobile_no
          ? tokenData?.mobile_no
          : '',
      };
      await upsert(payload, true);
      // CLEAR PROFILE STATE
      clearProfile();
    }
  };

  return (
    <Box sx={addProfile_style.rootSx} className={`${className}`} {...rest}>
      <Box sx={addProfile_style.boxRootSx}>
        <Screenlayout
          title="Complete your profile"
          footer={
            <Button onClick={handleSave} loading={loading}>
              Save
            </Button>
          }
        >
          <AddFamilyProfile />
        </Screenlayout>
      </Box>
    </Box>
  );
}
