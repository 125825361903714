import PropTypes from 'prop-types';
import React from 'react';
import { Box, Typography, Drawer } from '@mui/material';
import { SelectMemberType } from '../selectMemberType';
import { drawer_Select_MemeberStyle } from './style';

export function DrawerSelectMemberType(props) {
  const {
    addDrawerOpen,
    setAddDrawerOpen,
    routeExistingMember = () => false,
    routeAddFamily = () => false,
    className = '',
    ...rest
  } = props;
  return (
    <Box className={`${className}`} {...rest}>
      <Box>
        {addDrawerOpen && (
          <Drawer
            height="auto"
            anchor="bottom"
            sx={drawer_Select_MemeberStyle.rootSx}
            open={addDrawerOpen}
            onClose={() => setAddDrawerOpen(false)}
          >
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, pl: 2.5, pt: 2 }}
            >
              Select Member Type
            </Typography>
            <Box px={1} py={1}>
              <Box onClick={routeAddFamily}>
                <SelectMemberType
                  title="New"
                  subTitle="Bring your family me into your health circle"
                />
              </Box>
              <Box sx={{ mt: '-20px' }} onClick={routeExistingMember}>
                <SelectMemberType
                  title="Existing"
                  subTitle="Add an existing member of health circles to your family"
                />
              </Box>
            </Box>
          </Drawer>
        )}
      </Box>
    </Box>
  );
}
DrawerSelectMemberType.propTypes = {
  addDrawerOpen: PropTypes.bool,
  setAddDrawerOpen: PropTypes.bool,
  routeExistingMember: PropTypes.func,
  routeAddFamily: PropTypes.func,
  className: PropTypes.node,
  sx: PropTypes.object,
};
