import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { EditIcon, DropIcon, DropUpIcon } from '@atoms';
import { appointmentList_style } from './style';

export function AppointmentList(props) {
  const {
    heading = '',
    icon,
    list = [],
    className = '',
    editDownIconShown = true,
    editOnclick,
    noDataFound = '',
    expanded,

    ...rest
  } = props;

  const [expand, setExpand] = useState(expanded);

  return (
    <Box sx={appointmentList_style.rootSx} className={`${className}`} {...rest}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={appointmentList_style.titleSx}>
          {icon}
          <Typography sx={appointmentList_style.headerSx}>{heading}</Typography>
        </Box>
        {editDownIconShown && (
          <Box
            sx={{ ...appointmentList_style.titleSx, gap: 2, cursor: 'pointer' }}
          >
            <EditIcon onClick={editOnclick} />
            {expand ? (
              <DropIcon
                onClick={() => setExpand(false)}
                rootStyle={{ cursor: 'pointer' }}
              />
            ) : (
              <DropUpIcon
                onClick={() => setExpand(true)}
                rootStyle={{ cursor: 'pointer' }}
              />
            )}
          </Box>
        )}
      </Box>
      <Box sx={{ mt: 1 }}>
        {expand ? (
          list?.[0] ? (
            <ul style={{ paddingInlineStart: '20px' }}>
              <li style={{ fontWeight: 600, fontSize: '14px' }}>{list?.[0]}</li>
            </ul>
          ) : null
        ) : list && list.length > 0 ? (
          list.map((val, index) => (
            <ul key={index} style={{ paddingInlineStart: '20px' }}>
              <li style={{ fontWeight: 600, fontSize: '14px' }}>{val}</li>
            </ul>
          ))
        ) : (
          <Typography sx={appointmentList_style.noDataFound}>
            {noDataFound}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

AppointmentList.propTypes = {
  heading: PropTypes.string,
  list: PropTypes.array,
  editOnclick: PropTypes.func,
  editDownIconShown: PropTypes.bool,
  noDataFound: PropTypes.string,
  className: PropTypes.string,
  sx: PropTypes.object,
  icon: PropTypes.any,
  expanded: PropTypes.any,
};
