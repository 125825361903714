import { clinicalRoutes } from '@hc/routes';
import { useOnboarding } from '@hc/store/clinical/onboarding';
import { Button, Input, Label, MobileInput } from '@hc/ui/atoms';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { signIn_style } from './style';

export function ClinicalLogin() {
  // const { className = '', ...rest } = props;

  const { user, setUser, signIn, loading } = useOnboarding(
    (state) => ({
      signIn: state.signIn,
      user: state.user,
      setUser: state.setUser,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const [values, setValues] = useState(user);
  const [showpassword, setPassword] = useState();
  const handleClickShowPassword = () => {
    setPassword(!showpassword);
  };
  // const navigate = useNavigate();
  const handleChange = (val, index) => {
    if (index === 'mobileNumber') {
      setValues({
        ...values,
        countryCode: val.mobileCode,
        mobileNumber: val.mobile,
      });
    } else {
      setValues({ ...values, [index]: val });
    }
  };

  // Validation
  const isIamValideToSignIn = () => {
    let isValid = true;
    const error = values?.error;

    // Checking Mobile Number
    if (values?.mobileNumber.length === 0) {
      isValid = false;
      error.mobileNumber = 'Enter a valid 10 digit mobile number';
    } else if (values?.mobileNumber.length < 10) {
      isValid = false;
      error.mobileNumber = 'Enter a valid 10 digit mobile number';
    } else {
      error.mobileNumber = '';
    }

    // Checking password
    if (values?.password.length === 0) {
      isValid = false;
      error.password = 'Enter password';
    } else {
      error.password = '';
    }

    setValues({ ...values, error });
    return isValid;
  };
  //

  const onSignIn = () => {
    if (isIamValideToSignIn()) {
      const error = values?.error;
      error.password = '';
      error.mobileNumber = '';
      setValues({ ...values, error });
      // calling the signin api
      signIn(values);
    }
  };

  useEffect(() => {
    setUser({
      ...user,
      error: {
        password: '',
        confirmPassword: '',
        mobileNumber: '',
      },
    });
    return setValues({
      ...user,
      error: {
        password: '',
        confirmPassword: '',
        mobileNumber: '',
      },
    });
  }, []);
  return (
    <Box sx={signIn_style.paperSx}>
      <Typography variant="h5" sx={signIn_style.titleSx}>
        Welcome
      </Typography>
      <Box sx={signIn_style.inputGroupSx}>
        <Label htmlFor="mobileNumber" isRequired>
          Phone number
        </Label>
        <MobileInput
          id="mobileNumber"
          value={{
            mobile_code: values.countryCode ?? '+91',
            mobile: values.mobileNumber ?? '',
          }}
          isError={values.error.mobileNumber !== ''}
          helperText={values.error.mobileNumber}
          onChange={(value) => handleChange(value, 'mobileNumber')}
        />
      </Box>
      <Box sx={signIn_style.inputGroupSx}>
        <Label htmlFor="password" isRequired>
          Password
        </Label>
        <Input
          id="password"
          type={showpassword ? 'text' : 'password'}
          onChange={(e) => handleChange(e.target.value, 'password')}
          isError={values.error.password !== ''}
          errorMessage={values.error.password}
          value={values.password}
          endAdornment={
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClickShowPassword()}
              edge="end"
            >
              {showpassword ? (
                <MdVisibilityOff htmlColor="#848484" sx={signIn_style.eyeSx} />
              ) : (
                <MdVisibility htmlColor="#848484" sx={signIn_style.eyeSx} />
              )}
            </IconButton>
          }
        />
        <Link
          to={clinicalRoutes.forgotPassword}
          style={{ width: 'max-content', marginLeft: 'auto' }}
        >
          <Typography sx={signIn_style.forgotpasswordSx} variant="subtitle2">
            Forgot password?
          </Typography>
        </Link>
      </Box>
      <Box sx={{ mt: 2, display: 'grid', gap: 3 }}>
        <Button
          id="signIn"
          fullWidth
          onClick={() => onSignIn()}
          loading={loading}
          buttonStyle={signIn_style.buttonsStyle}
        >
          Sign in
        </Button>
        {/* <Box sx={{ width: '60%', mx: 'auto' }}>
            <Divider sx={{ color: 'grey.300' }}>or</Divider>
          </Box>
          <Box>
            <Button
              fullWidth
              startIcon={<GoogleSocialLogin />}
              variant="outlined"
              buttonStyle={signIn_style.googleStyle}
            >
              Sign in with google
            </Button>
          </Box> */}
      </Box>
      <Box>
        <Typography sx={signIn_style.lastText}>
          Become better version of yourself?
          <Link to={clinicalRoutes.signup} style={{ textDecoration: 'none' }}>
            <Box as="span" sx={signIn_style.signSx}>
              Sign Up
            </Box>
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}
