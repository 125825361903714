/* eslint-disable react/jsx-key */
import HC_Banner_1 from '@assets/HC_Banner_1.png';
import HC_Banner_2 from '@assets/HC_Banner_2.png';
import HC_Banner_3 from '@assets/HC_Banner_3.png';
import { Box } from '@mui/material';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

export function MBFHomeCarousel() {
  const carouselOptions = [
    {
      image: HC_Banner_1,
    },
    {
      image: HC_Banner_2,
    },
    {
      image: HC_Banner_3,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <Box
      sx={{
        mt: 3,
        height: { xs: '28vh', sm: '36vh', md: '25vh', lg: '36vh' },
      }}
    >
      <Slider {...settings} className="mbf">
        {carouselOptions.map((val, index) => (
          <Box key={index}>
            <img src={val?.image} alt="carousel" style={{ width: '100%' }} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
