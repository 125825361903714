export const report_upload_style = {
  rootSx: {},
  buttonSx: {
    textTransform: 'none',
    bgcolor: 'primaryTints.200',
    color: 'primary.main',
    border: '1px solid',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
  uploadFileSizeSx: {
    height: '240px',
  },
};
