/* eslint-disable react/jsx-key */
import notificationEmptyState from '@assets/notificationEmptyState.svg';
import { getDate } from '@hc/dayjs';
import { useMBFActivity, useNotification } from '@hc/store';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { localStorageKeys } from '@hc/utils/constants';
import { diffYMDHMS, isHttpURL, parseJwt } from '@hc/utils/helperFunctions';
import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { NotificationCard } from '../../notificationCard';

export function NotificationPanel() {
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const {
    getNotification,
    notificationState,
    softDeleteNotification,
    // loading,
  } = useNotification(
    (state) => ({
      getNotification: state.getNotification,
      notificationState: state.notificationState,
      softDeleteNotification: state.softDeleteNotification,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const {
    // loading,
    checkPlanDataDetails,
  } = useMBFActivity((state) => ({
    checkPlanDataDetails: state.checkPlanDataDetails,
  }));

  const { notificationData } = notificationState;

  const navigate = useNavigate();
  const redirectFnc = async (url, data) => {
    if (data?.is_actionable === false && data?.is_read === false) {
      softDeleteNotification(data?.id);
    }

    if (
      data?.is_actionable === true &&
      data?.is_read === false &&
      url === '/mindbodyfood/getstarted'
    ) {
      softDeleteNotification(data?.id);
    }

    if (isHttpURL(url)) {
      window.open(url, '_blank');
    } else if (url === '/familymembers') {
      navigate(url, {
        state: {
          fromNotification:
            data?.is_actionable === true && data?.is_read === false
              ? true
              : false,
          senderUserId: data?.Sender_profile?.user_id ?? '',
          senderUserName: data?.Sender_profile?.name ?? '',
          receiverUserId: data?.Reciever_profile?.user_id ?? '',
          familyMemberId: data?.family_member_id ?? '',
          notificationId: data?.id ?? '',
        },
      });
    } else if (url === '/mindbodyfood/getstarted') {
      await checkPlanDataDetails(true);
      navigate(url);
    } else if (typeof window !== 'undefined') {
      // var path = location.protocol + '//' + location.host + url; // (or whatever)
      navigate(url);
    } else {
      // work out what you want to do server-side...
    }
  };

  // GET NOTIFICATION DATA
  useEffect(() => {
    getNotification(data?.user_id);
  }, []);

  return (
    <Screenlayout
      title="Notifications"
      notshowFooter
      backRequired="/"
      backRoute={-1}
    >
      {Array.isArray(notificationData) && notificationData?.length > 0 ? (
        <>
          {notificationData.map((data, i) => (
            <NotificationCard
              key={i}
              prescription={JSON.parse(data?.content)?.message ?? ''}
              notificationTime={diffYMDHMS(getDate(data?.updatedAt))}
              redirectFnc={redirectFnc}
              data={data}
              is_read={data?.is_read}
              url={JSON.parse(data?.content)?.actionUrl ?? ''}
            />
          ))}
        </>
      ) : (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item mt={9}>
            <Box>
              <img src={notificationEmptyState} alt="" />
            </Box>
          </Grid>
          <Grid item mt={1.6}>
            <Typography fontSize={16} fontWeight={600}>
              No Records Found
            </Typography>
          </Grid>
          <Grid item mt={1}>
            <Box width={250} textAlign="center">
              <Typography fontSize={12} color="#888888">
                Records will appear once you upload your or family members
                documents
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </Screenlayout>
  );
}
