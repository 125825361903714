import { clinicalRoutes } from '@hc/routes';
import { useAwareness } from '@hc/store/clinical/awareness';
import { ForwardIconBtn } from '@hc/ui/atoms';
import { SkeletonCard } from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { awareness_style } from './style';

export default function Awareness() {
  const navigate = useNavigate();
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);

  const { awarenessState, getAwarenessData, updateAwarenessState, loading } =
    useAwareness(
      (state) => ({
        updateAwarenessState: state.updateAwarenessState,
        awarenessState: state.awarenessState,
        getAwarenessData: state.getAwarenessData,
        loading: state.loading,
      }),
      (prev, curr) => isEqual(prev, curr),
    );

  const { awarenessData } = awarenessState;

  const getInitialData = async () => {
    queryClient.invalidateQueries({
      queryKey: ['getAwarenessData'],
    });
    await getAwarenessData();
  };

  // GET AWARENESS LIST DATA
  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <Box sx={awareness_style.rootSx}>
      <Screenlayout
        appBarStyle={{
          bgcolor: '#239179',
          borderBottom: '1px solid #239179',
        }}
        backIconStyle={{ color: '#239179' }}
        masterchildStyle={{ pb: 4, backgroundColor: '#fff' }}
        title="Awareness"
        notshowFooter
        backRequired={data?.user_id}
        childrenStyle={{
          minHeight: '100vh',
          backgroundColor: '#fff',
        }}
      >
        <Box sx={{ pt: 2 }}>
          <Box sx={awareness_style.boxRootSx}>
            {/* EmptyState Design */}
            {!loading && (
              <Grid container spacing={0.5}>
                {Array.isArray(awarenessData) && awarenessData?.length > 0 ? (
                  awarenessData.map((val, i) => (
                    <Grid
                      key={i}
                      item
                      xs={6}
                      sx={{ display: 'flex', justifyContent: 'center' }}
                      onClick={() => {
                        if (data?.user_id) {
                          navigate(clinicalRoutes?.awarenessCategory);
                        } else {
                          navigate('/awarenesscategory');
                        }
                        updateAwarenessState('awareness_id', val.awareness_id);
                        updateAwarenessState(
                          'awareness_name',
                          val.awareness_name,
                        );
                      }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                          borderRadius: '8px',
                          cursor: 'pointer',
                          overflow: 'hidden',
                        }}
                      >
                        <img
                          src={val.awareness_image}
                          loading="lazy"
                          alt="Awarenesss"
                          style={{
                            borderRadius: '10px',
                            width: '100%',
                            objectFit: 'cover',
                          }}
                        />
                        <Typography sx={awareness_style.titleSx}>
                          {val.awareness_name}{' '}
                        </Typography>
                        <Box sx={awareness_style.navSx}>
                          <ForwardIconBtn />
                        </Box>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Typography
                    textAlign="center"
                    py={15}
                    color="#616161"
                    fontWeight={600}
                  >
                    No Awareness Data Found
                  </Typography>
                )}
              </Grid>
            )}
          </Box>
        </Box>
        {/* Loader */}
        {loading &&
          Array(2)
            .fill(1)
            .map((val, i) => (
              <SkeletonCard key={i} award color="#8ee0d3" awardTitle={false} />
            ))}
      </Screenlayout>
    </Box>
  );
}
