/* eslint-disable react/no-unescaped-entities */
import { differenceInDaysFnc } from '@hc/dayjs';
import { clinicalRoutes } from '@hc/routes';
import { useMBFActivity, useMBFLeaderboard, useMBFMoodStats } from '@hc/store';
import {
  ClinicalLayout,
  MbfEmptyState,
  MbfRenewEmptyState,
  MbfStepperBanner,
  PlanRenewalBanner
} from '@hc/ui/components';
// import { PlanRenewalBanner } from '@hc/ui/components';
import {
  HomeLeaderboardCard,
  MBFHomeActivityCard,
  MBFHomeBanner,
  MBFHomeCarousel,
  MBFIconTextOptions
} from '@hc/ui/components/clinical/index';
import { queryClient } from '@hc/utils';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { mbfHomeScreen_style } from './style';

export default function MindBodyFoodHome(props) {
  const { className = '', ...rest } = props;
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);
  const navigate = useNavigate();

  const { getLeaderboardData, mbfLeaderboardState } = useMBFLeaderboard(
    (state) => ({
      getLeaderboardData: state.getLeaderboardData,
      mbfLeaderboardState: state.mbfLeaderboardState,
    }),
  );

  const {
    // loading,
    checkPlanDataDetails,
    mbfState,
    getTodayActivity,
    getOverallPlanPercentage,
  } = useMBFActivity((state) => ({
    checkPlanDataDetails: state.checkPlanDataDetails,
    mbfState: state.mbfState,
    getTodayActivity: state.getTodayActivity,
    getOverallPlanPercentage: state.getOverallPlanPercentage,
    loading: state.loading,
  }));

  const { checkPlanData } = mbfState;

  const {
    isFeedBackSubmitted,
    isGoalsSubmitted,
    previousPlanEndDate,
    status,
    mbfUserPlanCount,
  } = checkPlanData;

  const { getTodayMoodStats } = useMBFMoodStats((state) => ({
    getTodayMoodStats: state.getTodayMoodStats,
  }));

  const { member_data, user_rank } = mbfLeaderboardState;

  const initialData = async () => {
    if (
      (typeof checkPlanData === 'object' &&
        Object.keys(checkPlanData)?.length === 0) ||
      status === 0
    ) {
      // Check plan status
      const { statusCode, status } = await checkPlanDataDetails(true);
      if (statusCode === 200 && status === 4) {
        await getTodayMoodStats(data?.user_id);
      }
    }

    if (status === 4) {
      // To refetch the query, which was cached
      await queryClient.invalidateQueries({
        queryKey: ['getOverallPlanPercentage'],
      });

      // To refetch the query, which was cached
      await queryClient.invalidateQueries({
        queryKey: ['getTodayActivity'],
      });
      await getOverallPlanPercentage(true);
      await getTodayActivity(true);
    }

    if (status === 3 && mbfUserPlanCount > 0) {
      navigate(clinicalRoutes.getStarted, {
        state: {
          from: 'RenewalFlow',
        },
      });
    }

    if (data?.employee_membership_code) {
      await getLeaderboardData({
        user_id: data?.user_id ?? '',
        organization_id: data?.organisation_id,
        start_date: '',
        end_date: '',
      });
    }
  };
  const onClickFnc = (type) => {
    if (type === 'viewPlan') {
      navigate(clinicalRoutes.mywellnessplan);
    } else if (type === 'getNewPlan') {
      navigate(clinicalRoutes?.PlanEvaluation);
    } else {
      navigate(clinicalRoutes.mywellnessplan);
    }
  };

  const onClickNext = () => {
    navigate(clinicalRoutes.getStarted, {
      state: {
        from: 'RenewalFlow',
      },
    });
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <ClinicalLayout isMindBodyFood isHealthCircle={false}>
      {/* {status === 4 && ( */}

      <Box sx={mbfHomeScreen_style.rootSx} className={`${className}`} {...rest}>
        <Box sx={mbfHomeScreen_style.containerSx}>
          <Box>
            {previousPlanEndDate ? (
              // Plan ongoing & not last 3 days
              differenceInDaysFnc(previousPlanEndDate) ===
              'more_than_3_days' ? (
                <MBFHomeBanner
                  btnDescription="View plan"
                  description={
                    <span>
                      You're on track!
                      <br />
                      Keep Going
                    </span>
                  }
                  onClickFnc={onClickFnc}
                  type="viewPlan"
                />
              ) : // Plan ongoing & last 3 days & yet to submit feedback / goal
              differenceInDaysFnc(previousPlanEndDate) === 'last_3_days' &&
                (!isFeedBackSubmitted || !isGoalsSubmitted) ? (
                <MBFHomeBanner
                  btnDescription="Get new plan"
                  description={
                    <Typography sx={mbfHomeScreen_style.descSx}>
                      Your plan is <br />
                      <span style={mbfHomeScreen_style.spanSx}>
                        Ending
                      </span>{' '}
                      soon!
                    </Typography>
                  }
                  onClickFnc={onClickFnc}
                  type="getNewPlan"
                />
              ) : // Plan ongoing & last 3 days & submit feedback / goal
              differenceInDaysFnc(previousPlanEndDate) === 'last_3_days' &&
                isFeedBackSubmitted &&
                isGoalsSubmitted ? (
                <MBFHomeBanner
                  btnDescription="View plan"
                  description={
                    <Typography sx={mbfHomeScreen_style.descSx}>
                      Hooray <br />
                      is you{' '}
                      <span style={mbfHomeScreen_style.spanSx}>nailed</span> it!
                    </Typography>
                  }
                  onClickFnc={onClickFnc}
                  type="viewPlan"
                />
              ) : null
            ) : null}

            {/* Plan ended & yet to submit feedback / goal */}
            {previousPlanEndDate &&
            differenceInDaysFnc(previousPlanEndDate) === 'plan_ended' &&
            (!isFeedBackSubmitted || !isGoalsSubmitted) ? (
              <PlanRenewalBanner
                description={
                  <Typography sx={mbfHomeScreen_style.descSx}>
                    Start <span style={mbfHomeScreen_style.spanSx}>NOW!</span>{' '}
                    <br />
                    not TOMORROW
                  </Typography>
                }
                btnDescription="Get new plan"
                onClickFnc={onClickFnc}
                type="getNewPlan"
              />
            ) : null}

            {/* Renewal plan request submitted & Waiting for expert analysis */}
            {mbfUserPlanCount > 0 && (status === 1 || status === 2) ? (
              <MbfStepperBanner
                onClickNext={onClickNext}
                title="Experts are curating your plan!"
                subTitle="Our experts will analyse your previous plan & current goals to prepare customized plan for you"
              />
            ) : null}
          </Box>
          {/* Journey & Performance & Mood Stats */}
          <Box>
            <MBFIconTextOptions />
          </Box>

          <Box>
            {previousPlanEndDate &&
              // Plan ongoing & not last 3 days || Plan ongoing & last 3 days
              (differenceInDaysFnc(previousPlanEndDate) ===
                'more_than_3_days' ||
              differenceInDaysFnc(previousPlanEndDate) === 'last_3_days' ? (
                <MBFHomeActivityCard />
              ) : // Plan ended &  yet to submit feedback / goal
              mbfUserPlanCount > 0 && status === null ? (
                <MbfEmptyState />
              ) : // Renewal plan request submitted & Waiting for expert analysis
              (mbfUserPlanCount > 0 && status === 1) ||
                (mbfUserPlanCount > 0 && status === 2) ? (
                <MbfRenewEmptyState />
              ) : null)}
          </Box>
          {/* // Leader board */}
          {data?.employee_membership_code && member_data?.length >= 1 ? (
            <Box mt={3}>
              <HomeLeaderboardCard
                member_data={member_data}
                user_rank={user_rank}
              />
            </Box>
          ) : (
            ''
          )}
          <MBFHomeCarousel />
        </Box>
      </Box>

      {/* )} */}
    </ClinicalLayout>
  );
}
