export const PrescDoctorDetailCard_style = {
  rootSx: {
    // height:'104px',
    weight: '375px',
    backgroundColor: 'primaryTints.300',
    borderBottom: '1.5px solid #239179',
    borderRadius: '0px 0px 16px 16px',
  },
  dotSx: {
    width: '4px',
    height: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'text.secondary',
    borderRadius: '100%',
  },
  cardWrapsx: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    padding: '36px 20px 18px',
    mt: -3,
  },
  docNameSx: {
    fontSize: '14px',
    fontWeight: 600,
  },
  departmentSx: {
    fontSize: '14px',
    color: 'text.secondary',
  },
  labelSxx: {
    color: '#868484',
    fontSize: '12px',
    margin: '0px 2px',
    fontWeight: 600,
  },
  professionalSx: {
    color: 'text.hint',
    fontSize: '12px',
    mt: 0.2,
  },
};
