import { useOnboarding } from '@hc/store/clinical/onboarding';
import { Button, Input, Label, MobileInput } from '@hc/ui/atoms';
import { Box, IconButton, Grid, Typography } from '@mui/material';
import { clinicalRoutes } from '@hc/routes';
import React, { useEffect, useState } from 'react';

import isEqual from 'react-fast-compare';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { signUp_style } from './style';

export function ClinicalCreateaccount() {
  // const { className = '', ...rest } = props;

  const { user, signUp, setUser, loading } = useOnboarding(
    (state) => ({
      signUp: state.signUp,
      setUser: state.setUser,
      user: state.user,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const [values, setValues] = useState(user);
  const [showpassword, setPassword] = useState();
  const handleClickShowPassword = () => {
    setPassword(!showpassword);
  };
  const [showConfirmPassword, setConfirmPassword] = useState();
  const handleClickshowConfirmPassword = () => {
    setConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (val, index) => {
    if (index === 'mobileNumber') {
      setValues({
        ...values,
        countryCode: val.mobileCode,
        mobileNumber: val.mobile,
      });
    } else {
      setValues({ ...values, [index]: val });
    }
  };

  // Validation
  const isIamValideToSignUp = () => {
    let isValid = true;
    const error = values?.error;

    // Checking Mobile Number
    if (values?.mobileNumber.length === 0) {
      isValid = false;
      error.mobileNumber = 'Enter a valid 10 digit mobile number';
    } else if (values?.mobileNumber.length < 10) {
      isValid = false;
      error.mobileNumber = 'Enter a valid 10 digit mobile number';
    } else {
      error.mobileNumber = '';
    }

    // Checking password
    if (values?.password.length === 0) {
      isValid = false;
      error.password = 'Enter the password';
    } else {
      error.password = '';
    }

    if (values?.password.length < 8) {
      isValid = false;
      error.password = 'Password must be at least 8 characters long';
    } else if(!values?.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/))  {
      isValid = false;
      error.password = 'Password must contain uppercase and lowercase letters, numbers, and special characters';
    } else {
      error.password = '';
    }


    // Checking Cofirm password
    if (values?.confirmPassword.length === 0) {
      isValid = false;
      error.confirmPassword = 'Enter the confirm password';
    } else {
      error.confirmPassword = '';
    }

    // Checking confirm and password are same
    if (
      values?.confirmPassword.length > 0 &&
      values?.confirmPassword !== values.password
    ) {
      isValid = false;
      error.confirmPassword = "Password doesn't matching, please check again";
    }
    setValues({ ...values, error });
    return isValid;
  };

  const onSignUp = () => {
    if (isIamValideToSignUp()) {
      const error = values?.error;
      error.confirmPassword = '';
      error.password = '';
      error.mobileNumber = '';
      setValues({ ...values, error });
      // calling the signup api
      signUp(values);
    }
  };
  useEffect(() => {
    setUser({
      ...user,
      error: {
        password: '',
        confirmPassword: '',
        mobileNumber: '',
      },
    });
    setValues({
      ...user,
      error: {
        password: '',
        confirmPassword: '',
        mobileNumber: '',
      },
    });
  }, []);
  return (
    <Box sx={signUp_style.paperSx}>
      <Grid container rowSpacing={2.5}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5" sx={signUp_style.titleSx}>
            Create Account
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Label htmlFor="mobileNumber" isRequired>
            Phone number
          </Label>
          <MobileInput
            value={{
              mobile_code: values.countryCode ?? '+91',
              mobile: values.mobileNumber ?? '',
            }}
            id="mobileNumber"
            isError={values.error.mobileNumber !== ''}
            helperText={values.error.mobileNumber}
            onChange={(value) => handleChange(value, 'mobileNumber')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Label htmlFor="password" isRequired>
            Create password
          </Label>
          <Input
            id="password"
            type={showpassword ? 'text' : 'password'}
            onChange={(e) => handleChange(e.target.value, 'password')}
            isError={values.error.password !== ''}
            errorMessage={values.error.password}
            value={values.password}
            endAdornment={
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClickShowPassword()}
                edge="end"
              >
                {showpassword ? (
                  <MdVisibilityOff
                    htmlColor="#848484"
                    sx={signUp_style.eyeSx}
                  />
                ) : (
                  <MdVisibility htmlColor="#848484" sx={signUp_style.eyeSx} />
                )}
              </IconButton>
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Label htmlFor="confirmPassword" isRequired>
            Confirm password
          </Label>
          <Input
            id="confirmPassword"
            type={showConfirmPassword ? 'text' : 'password'}
            onChange={(e) => handleChange(e.target.value, 'confirmPassword')}
            isError={values.error.confirmPassword !== ''}
            errorMessage={values.error.confirmPassword}
            value={values.confirmPassword}
            endAdornment={
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClickshowConfirmPassword()}
                edge="end"
              >
                {showConfirmPassword ? (
                  <MdVisibilityOff
                    htmlColor="#848484"
                    sx={signUp_style.eyeSx}
                  />
                ) : (
                  <MdVisibility htmlColor="#848484" sx={signUp_style.eyeSx} />
                )}
              </IconButton>
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Button
            fullWidth
            onClick={() => onSignUp()}
            loading={loading}
            buttonStyle={signUp_style.buttonsStyle}
          >
            Sign Up
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Typography sx={signUp_style.lastText}>
            Already joined with us?
            <Link to={clinicalRoutes.login} style={{ textDecoration: 'none' }}>
              <Typography variant="body1" sx={signUp_style.signSx}>
                Sign in
              </Typography>
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
